<template>
	<div ref="settlementmain" class="paid main-page">
		<div class="page-bg"></div>
		<div v-if="showloading">
			<Spin fix v-if="!noorder">
				<Icon color="#C25E4D" type="ios-loading" size=58 class="demo-spin-icon-load"></Icon>
				<div class="loadingtxt">正在更新支付状态,暂未检测到支付成功</div>
			</Spin>
			<div class="noorder" v-if="noorder">
				<img src="../../public/imgs/paidstatu.png" alt="">
				<div class="updatestatu" @click="updateit">点击查询订单状态</div>
			</div>
		</div>
		<div class="stepstatus" v-if="!showloading&&neng==1 && !isAlipay" :style="adviser_popup?'filter: blur(2px);':''">
			<div class="step-box">
				<div class="step1 steptab" :class="status==0?'active':'hui'">
					<div class="steptabbox">
						<div class="steptab-num">1</div>
						<div class="steptab-name">购买健康方案</div>
					</div>
					<div class="steptab-line"></div>
				</div>
				<div class="step2 steptab" :class="status==0?'hui':'active'">
					<div class="steptab-line"></div>
					<div class="steptabbox">
						<div class="steptab-num">2</div>
						<div class="steptab-name">激活营养顾问服务</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div v-if="!showloading&&neng==1">

			<div class="title" v-if="!hasyu&&reserve.is_reserve==0">支付完成</div>
			<div class="msg" v-if="!hasyu&&reserve.is_reserve==0">感谢购买，你的橘袋正在加紧包装中，你也可以在「我的」页面了解专属健康顾问相关问题。</div>

			<div class="title" v-if="hasyu">预约成功</div>
			<div class="msg" v-if="hasyu">感谢购买，你的橘袋正加紧包装中，请在预约到来的时刻保持电话畅通喔。</div>

			<div class="title" v-if="!hasyu&&reserve.is_reserve==1">支付成功</div>
			<div class="msg" v-if="!hasyu&&reserve.is_reserve==1">感谢购买，你的橘袋正在加紧包装中，你也可以在「我的」页面了解专属健康顾问相关问题。</div>

			<div class="title" v-if="reserve.is_reserve==2">支付成功</div>
			<div class="msg" v-if="reserve.is_reserve==2">感谢购买，你的橘袋正在加紧包装中，你也可以在「我的」页面查看订单列表和订单详情。</div>
			<div class="paybtns">
				<div class="pay-btn pay-btn-yu public-btn" @click="getadviser" v-if="reserve.is_reserve==1&&!hasyu&&!reserve.data&&neng==1">预约营养顾问</div>

				<div class="pay-btn pay-btn-yu public-btn" v-if="hasyu&&reserve.is_reserve==1&&reserve.data&&neng==1" @click="checkreserve">查看预约</div>

				<div class="pay-btn pay-btn-yu public-btn" v-if="!hasyu&&reserve.is_reserve==1&&reserve.data&&neng==1" @click="calladviser">联系营养顾问</div>

				<div class="pay-btn public-btn-css" @click="getorder">查看订单</div>
				<div class="pay-btn gohome" @click="gohome">返回首页</div>
			</div>
		</div>
		<div v-if="!showloading&&neng==0">
			<div class="title">支付完成</div>
			<div class="msg">感谢购买，你的橘袋正在加紧包装中，你也可以前往AI即刻定制，只需三分钟即可生成专属健康方案。</div>

			<div class="paybtns">
				<div class="pay-btn public-btn-css" @click="getorder">查看订单</div>
				<div class="pay-btn gohome" @click="gohome">返回首页</div>
			</div>
		</div> -->

		<div v-if="!showloading" :style="adviser_popup?'filter: blur(2px);':''">
			<div class="title">支付完成</div>
			<div class="msg">感谢购买，你的橘袋正在加紧包装中，你也可以前往AI即刻定制，只需三分钟即可生成专属健康方案。</div>

			<div class="paybtns">
				<div class="pay-btn public-btn-css" @click="getorder">查看订单</div>
				<div class="pay-btn gohome" @click="gohome">返回首页</div>
			</div>
		</div>


		<Drawer :closable="false" :width="bodywidth" v-model="value1" v-if="!isAlipay">
			<div class="getadviser-box">
				<div class="adviser-info-box">
					<div class="getadviser-title">你的专属营养顾问</div>
					<div class="getadviser-msg">系统已为你安排一名专属营养顾问，立即预约你的第一次营养咨询吧</div>
					<div class="getadviser-info">
						<img :src="reserve.nutri.img" alt="">
						<div class="getadviser-name-box">
							<div class="getadviser-name">{{reserve.nutri.name}}
								<!-- <img src="../../public/imgs/qus.png" alt=""> -->
							</div>
							<div class="getadviser-tabs">
								<div class="getadviser-tab">健康顾问 VC</div>
								<div class="getadviser-tab">注册营养师</div>
							</div>
						</div>
					</div>
				</div>
				<div class="getadviser-time">
					<div class="get-title">预约时间</div>
					<el-cascader v-if="!hasyu" v-model="ytime" :options="data" class="get-box" :placeholder="placeholder"></el-cascader>
					<div v-if="hasyu" class="get-box">{{reserve.data.year_month+' '+reserve.data.week+' '+reserve.data.time}}</div>
					<!-- <Cascader v-model="ytime" class="get-box" :placeholder="placeholder" :data="data"></Cascader> -->
					<!-- <div class="get-box">
						<div>请选择预约时间</div>
						<img src="../../public/imgs/more.png" alt="">
					</div> -->
				</div>
				<div class="getadviser-phone">
					<div class="get-title">预约电话</div>
					<div class="get-box">
						<div>{{info.phone}}</div>
						<!-- <img src="../../public/imgs/more.png" alt=""> -->
					</div>
				</div>
				<div class="getadviser-btns" v-if="reserve.is_reserve!=2&&!hasyu">
					<div class="getnow cursor public-btn" @click="goyue">立即预约</div>
					<div class="nonow cursor" @click="noyue">暂不预约</div>
				</div>
			</div>
		</Drawer>
		<popup v-if="showpopup" :rdata="popdata" @getback="getbackinfo"></popup>

		<Modal v-model="modal8" :mask-closable="false" :closable="false" width="423.9" :styles="{top: '30%'}" scrollable="true">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>支付未成功</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>支付未成功，请在订单页面重试。</p>
			</div>
			<div class="modalbtns">
				<!-- <div class="modalcancel modalbtn cursor">取消</div> -->
				<div class="modalok modalbtn cursor" @click="weixinnopay">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>


		<div class="adviser-bg" v-if="adviser_popup && !isAlipay" @click="click_bg" :style="adviser_detail?'background:none':''">
			<div class="adviser-box" @click="click_box" style="max-width: 545px;" v-if="reserve.is_reserve==1&&!hasyu&&!reserve.data&&neng==1">
			<!-- <div class="adviser-box" @click="click_box" style="max-width: 545px;"> -->
				<div class="adviser-top">
					<img :src="reserve.nutri.pop_up_img" alt="">
					<div class="adviserinfos">
						<div class="adviserinfo-txt">你的专属营养顾问</div>
						<div class="adviserinfo-name">
							<div>{{reserve.nutri.name}} </div>
							<img class="adviserinfo-name-img" src="../../public/imgs/blackqus.png" @click="show_adviser_detail" alt="">
						</div>
						<div class="adviserinfo-tips">
							<div class="adviserinfo-tip">健康顾问 VC</div>
							<div class="adviserinfo-tip">注册营养师</div>
						</div>
					</div>
					<img src="../../public/imgs/close.png" class="close-adviser" alt="" @click="click_bg">
				</div>
				<div class="adviser-main">
					<div class="adviser-txts">
						<p>我们已为你分配专属营养顾问</p>
						<p>立即联系你的第一次营养咨询吧</p>
					</div>
					<div class="adviser-gif">
						<img v-if="reserve.nutri.name=='刀天莉'" :src="publicwww + dao" alt="">
						<img v-if="reserve.nutri.name=='于抒玄'" :src="publicwww + yu" alt="">
					</div>
					<div class="saoma">扫码添加<span style="font-weight: bold;">「营养师」</span>微信</div>
				</div>
			</div>
			<div class="adviser-box" style="background: none;overflow: inherit;" v-if="neng==0" @click="click_box">
				<div class="adviser-qr-img">
					<img class="adviser-qr-close" @click="click_bg" src="../../public/imgs/noborderclose.png" alt="">
					<div class="red-circle red-c1"></div>
					<div class="red-circle red-c2"></div>
					<div class="red-circle red-c3"></div>
					<div class="red-circle red-c4"></div>
					<img class="the-adviser-qr" :src="publicwww + j1" alt="">
				</div>
			</div>
		</div>
		<div class="adviser-bg" v-if="adviser_detail && !isAlipay" @click="click_bg1">
			<div class="adviser-box adviser-detail-boxs" @click="click_box1" v-if="adviser_detail">
				<div class="adviser-top">
					<div class="adviser-detail-box">
						<img :src="reserve.nutri.img" alt="">
						<div class="adviser-detail-txt">
							<div class="adviser-detail-title">营养顾问口袋式服务</div>
							<div class="adviser-detail-msg">购买后，免费为你配置一名营养顾问VC。作为你的专属私人营养顾问，他会随时在线，为你答疑解惑并提供长期、深度的跟踪式营养咨询管理服务。</div>
						</div>
					</div>
					<img src="../../public/imgs/close.png" class="close-adviser" alt="" @click="adviser_detail=false">
				</div>
				<div class="adviser-main">
					<div class="main-ad-line">
						你将会获得以下服务
					</div>
					<div class="main-ad-list">
						<div class="main-ad-li">
							<div class="ad-li-title">
								<img src="../../public/imgs/true.png" alt="">
								<div>首次电话咨询后</div>
							</div>
							<div class="ad-li-txt">营养顾问会根据当前健康状态并结合问卷评估报告，建立个人健康档案库。</div>
						</div>
						<div class="main-ad-li">
							<div class="ad-li-title">
								<img src="../../public/imgs/true.png" alt="">
								<div>1对1陪伴式指导</div>
							</div>
							<div class="ad-li-txt">在线为你答疑解惑、持续跟踪健康状态、个性化补剂优化方案、就医指导、体重管理及科学护肤等相关健康服务。</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// var thepaidtime = ''
	import popup from '../components/popup.vue'
	export default {
		name: 'paid',
		components: {
			popup,
		},
		data: () => ({
			adviser_detail:false,
			adviser_popup:false,
			closebg:true,
			closebg1:true,
			status: 1,
			value1: false,
			modal8:false,
			shownotice: false,
			hasshownotice: false,
			showloading:false,
			addressList: [],
			noorder:false,
			cart_list: [],
			checkdata: '',
			showqr: false,
			sid: '',
			placeholder: '请选择预约时间',
			ytime: [],
			canbuy: false,
			info: '',
			hasyu: false,
			data: '',
			bid: '',
			tipsurl: 'https://mmbizurl.cn/s/yiDciNLJd',
			qr: '',
			showpopup: false,
			zhifu:true,
			popdata: '',
			time: 60,
			buytype: '',
			neng:0,
			type: '',
			reserve: '',
			bodywidth: 515,
			publicwww: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/',
			dao:'static/common/images/official-website/adviser/dao.png',
			yu:'static/common/images/official-website/adviser/yu.png',
			j1:'static/common/images/official-website/adviser/j1.png',
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		created() {},
		destroyed() {},
		watch: {
			$route() {
				let settlementmain = this.$refs.settlementmain
				settlementmain.scrollTo(0, 0)
			}
		},
		mounted() {
			//console.log(document.body.clientWidth)
			var bw = document.body.clientWidth
			this.bodywidth = bw < 515 ? bw : 515
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				this.$router.replace({
					path: 'login'
				})
			}
			var that = this
			that.info = info
			//console.log(this.$route.query)
			// var indexdata = JSON.parse(localStorage.getItem('indexdata'))
			// var team_img = indexdata.team_img
			// var sid = ''
				this.OrderDetail(this.$route.query.id)
				this.getAppointment()
				this.getAppointmentTime()
			var pay_type = localStorage.getItem('way')
			//console.log(pay_type)
			if (pay_type == 'h5'||pay_type == 'weixin') {
				that.showloading = true
				this.checkOrder(this.$route.query.id)
				//console.log(this.showloading)
			}else{
				setTimeout(function(){
					that.showloading = false
				},1000)
				setTimeout(function(){
					if(that.reserve.is_reserve==1&&!that.hasyu&&!that.reserve.data&&that.neng==1 && !that.isAlipay){
						that.adviser_popup = true
					that.$emit('getback',{value:'',way:'1'})
					}
					if(that.neng==0 && !that.isAlipay){
						that.adviser_popup = true
					that.$emit('getback',{value:'',way:'1'})
					}
				},1000)
			}
			// if (pay_type == 'h5') {
			// 	// this.showloading = true
			// 	this.checkOrder(this.$route.query.id)
			// 	//console.log(this.showloading)
			// } else {
			// 	this.getAppointment()
			// 	this.getAppointmentTime()
			// }

		},
		methods: {
			show_adviser_detail(){
				this.adviser_detail=true
			},
			click_box(){
				var that = this
				that.closebg = false
				setTimeout(function(){
					that.closebg = true
				},1000)
			},
			click_bg(){
				if(this.closebg==true){
					this.adviser_popup = false
					this.$emit('getback',{value:'',way:'0'})
				}
			},
			click_box1(){
				var that = this
				that.closebg1 = false
				setTimeout(function(){
					that.closebg1 = true
				},1000)
			},
			click_bg1(){
				if(this.closebg1==true){
					this.adviser_detail = false
				}
			},
			weixinnopay(){
				this.$router.replace({
					path: 'order-list',
				})
			},
			getAppointment() {
				var that = this
				// setTimeout(function(){
				// 	that.showloading = false
				// },2000)
				var info = that.info
				//获取预约详情
				this.$ajax.post(this.API.api.apiurl.getAppointment, {
						member_id: info.member_id
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							that.reserve = data.data
							if (data.data.data) {
								that.placeholder = data.data.data.week + data.data.data.time
							}else{
								that.placeholder = '请选择预约时间'
							}
						} else {
							that.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getAppointmentTime() {
				var that = this
				var info = that.info
				//获取营养师预约时段
				this.$ajax.post(this.API.api.apiurl.getAppointmentTime, {
						member_id: info.member_id
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							var time = data.data
							var time1 = []
							for (var i = 0; i < time.length; i++) {
								// time[i].children = time[i].time_list
								time1[i] = {
									value: '',
									label: '',
									children: ''
								}
								time1[i].value = time[i].real_time
								time1[i].label = time[i].day
								var child = time[i].time_list
								child.forEach((item, index) => {
									child[index].value = item.time_segment
									child[index].label = item.time_segment
									if(item.is_appointment==0){
										child[index].disabled = true
									}
								})
								time1[i].children = child
							}
							//console.log(time1)
							that.data = time1
						} else {
							that.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})

			},
			getit() {
				//console.log(e)
			},
			getdetail(id) {
				var that = this
				//获取营养师详情
				this.$ajax.post(this.API.api.apiurl.getNutritionistInfo, {
						id: id
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
						} else {
							this.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getadviser() { //预约
				this.value1 = !this.value1
			},
			goyue() {
				//console.log(this.ytime)
				var ytime = this.ytime
				var that = this
				if (ytime.length == 0) {
					that.$Message.info('请选择预约时间')
				} else {
					//检测时段是否被预约过
					this.$ajax.post(this.API.api.apiurl.checkTimeSegment, {
							member_id: that.info.member_id,
							real_time: ytime[0],
							period: ytime[1],
							type: '2'
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
						.then(res => {
							var data = res.data
							if (data.code == 0) {
								//console.log(data)
								that.$Message.info(data.message)
								that.value1 = false
								that.hasyu = true
								that.getAppointment()
							} else {
								that.$Message.info('错误：' + data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			noyue() {
				this.value1 = false
			},
			checkreserve() { //查看预约
				this.value1 = true
			},
			getorder() { //查看订单
				var that = this
				this.$router.push({
					path: 'order-detail',
					query: {
						id: that.$route.query.id
					}
				})
			},
			calladviser() { //联系
				var reserve = this.reserve
				//console.log(reserve)
				window.location.href = reserve.nutri.href
			},
			OrderDetail(id) {
				var that = this
				// this.showloading = true
				//订单详情
				this.$ajax.post(this.API.api.apiurl.OrderDetail, {
						order_id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						//console.log(res)
						var data = res.data.data
						if (res.data.code == 0) {
							var detail = data
							var cart_list = detail.cart_list
							var neng = 0
							for (var i = 0; i < cart_list.length; i++) {
								if(cart_list[i].source==1){
									neng = 1
								}
							}
							that.neng = neng
						}else if(res.data.code == 118){
							that.$router.replace({path:'404'})
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			// updateit(){
			// 	var that = this
			// 	that.noorder = false
			// 	that.showloading = false
			// 	that.$Message.info('支付成功')
			// 	that.getAppointment()
			// 	that.getAppointmentTime()
			// },
			updateit(){
				var that = this
				//获取报告推荐产品

				// thepaidtime = setInterval(function() {
					that.$ajax.post(that.API.api.apiurl.checkOrder, {
							// count_id:that.count_id,
							order_id: that.$route.query.id,
							member_id: that.info.member_id,
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							if (res.data.code == 0) {
								//console.log(res.data)
								// alert('支付完成，跳转页面（暂未开发）')
								if (res.data.data.order_status == 1||res.data.data.order_status == 11) {
									that.noorder = false
									that.showloading = false
									that.$Message.info('支付成功')
									that.getAppointment()
									that.getAppointmentTime()

									setTimeout(function(){
										if(that.reserve.is_reserve==1&&!that.hasyu&&!that.reserve.data&&that.neng==1&&!that.isAlipay){
											that.adviser_popup = true
											that.$emit('getback',{value:'',way:'1'})
										}
										if(that.neng==0&&!that.isAlipay){
											that.adviser_popup = true
											that.$emit('getback',{value:'',way:'1'})
										}
									},1000)
								}else{
									that.modal8 = true

									//console.log(res.data.msg)
								}
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
			},
			checkOrder(e) {
				//console.log(e)
				var that = this
				//获取报告推荐产品

				// thepaidtime = setInterval(function() {
					that.$ajax.post(that.API.api.apiurl.checkOrder, {
							// count_id:that.count_id,
							order_id: e,
							member_id: that.info.member_id,
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							if (res.data.code == 0) {
								//console.log(res.data)
								// alert('支付完成，跳转页面（暂未开发）')
								if (res.data.data.order_status == 1 || res.data.data.order_status == 11 ||res.data.data.order_status == 12) {
									that.showloading = false
									that.$Message.info('支付成功')
									that.getAppointment()
									that.getAppointmentTime()

									setTimeout(function(){
										if(that.reserve.is_reserve==1&&!that.hasyu&&!that.reserve.data&&that.neng==1&&!that.isAlipay){
											that.adviser_popup = true
											that.$emit('getback',{value:'',way:'1'})
										}
										if(that.neng==0&&!that.isAlipay){
											that.adviser_popup = true
											that.$emit('getback',{value:'',way:'1'})
										}
									},1000)
								}else{
									setTimeout(function(){
										that.noorder = true
									},5000)
									//console.log(res.data.msg)
								}
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				// },2000)
				// clearInterval(thepaidtime)
			},
			gohome() {
				this.$router.push({
					path: '/'
				})
			}
		}
	}
</script>

<style>
	.adviser-detail-boxs{
		position: fixed;
		padding: 40px;
		max-width: 600px!important;
	}
	.main-ad-li{
		width: 100%;
		margin-top: 40px;
	}
	.ad-li-title{
		width: 100%;
		display: flex;
		font-size: 18px;
		font-weight: bold;
		line-height: 1.5;
	}
	.ad-li-title img{
		width: auto;
		height: 13px;
		margin: 7px 0;
		margin-right: 8px;
	}
	.ad-li-txt{
		font-size: 16px;
		text-align: left;
		line-height: 1.5;
		margin-top: 8px;
	}
	.main-ad-list{
		width: 100%;
		text-align: left;
	}
	.main-ad-line{
		font-size: 20px;
		line-height: 2.5;
		font-weight: bold;
		border-bottom: 2px solid #1A1A1A;
		text-align: left;
	}
	.adviser-detail-boxs .adviser-main{
		width: 100%;
		padding: 50px 0 30px 0;
	}
	.adviser-detail-boxs .adviser-top .close-adviser{
		top: 0;
		right: 0;
	}
	.adviser-detail-box{
		width: 100%;
		height: auto;
		display: flex;

	}
	.adviser-top .adviser-detail-box img{
		width: 120px;
		height: 120px;
		margin-right: 15px;
	}
	.adviser-detail-txt{
		text-align: left;
		width: calc(100% - 170px);
	}
	.adviser-detail-title{
		font-size: 20px;
		font-weight: bold;
	}
	.adviser-detail-msg{
		font-size: 16px;
		margin-top: 8px;
		text-align: justify;
	}
	.adviser-top .close-adviser{
		width: 20px;
		height: 20px;
		position: absolute;
		top: 15px;
		left: auto;
		right: 30px;
	}
	.adviser-qr-img{
		width: 100%;
		height: auto;
		position: relative;
		margin: 0 auto;
		max-width: 453px;
	}
	.red-circle{
		position: absolute;
		background: #C25E4D;
	}
	.red-c1{
		width: 30px;
		height: 30px;
		border-radius: 30px;
		top: -50px;
		left: 44px;
	}
	.red-c2{
		width: 40px;
		height: 40px;
		border-radius: 40px;
		top: 24px;
		right: -56px;
	}
	.red-c3{
		width: 65px;
		height: 65px;
		border-radius: 65px;
		top: 24px;
		left: -56px;
	}
	.red-c4{
		width: 90px;
		height: 90px;
		border-radius: 90px;
		bottom: 28px;
		right: -141px;
	}
	.adviser-qr-close {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 0;
		right: 0;
		top: -20px;
		right: -20px;
	}
	.the-adviser-qr{
		width: 100%;
		height: auto;
	}
	.adviser-bg{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.7);
		z-index: 12;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.adviser-box{
		width: 84vw;
		height: auto;
		background: #FFFFFF;
		border-radius: 10px;
		overflow: hidden;
		margin: 0 auto;
		max-width: 453px;
	}
	.adviser-top{
		width: 100%;
		height: auto;
		position: relative;
		display: flex;
		align-items: center;
		overflow: hidden;
	}
	.adviser-top img{
		width: auto;
		height: 236px;
		/* position: absolute; */
		top: 0;
		left: 0;
	}
	.adviserinfos{
		position: absolute;
		left: 37.5%;
		height: auto;
		display: block;
	}
	.adviserinfo-txt{
		width: 100%;
		font-size: 18px;
		text-align: left;
	}
	.adviserinfo-name{
		width: 100%;
		display: flex;
		align-items: center;
		margin: 12px 0;
	}
	.adviserinfo-name div{
		font-size: 32px;
		font-weight: bold;
		margin-right: 8px;
	}
	.adviserinfo-name .adviserinfo-name-img{
		width: 24px;
		height: 24px;
		position: relative;
	}
	.adviserinfo-tips{
		width: 100%;
		display: flex;
	}
	.adviserinfo-tip{
		width: auto;
		height: auto;
		font-size: 14px;
		padding: 8px;
		background: #E3EAF2;
		border-radius: 6px;
		margin-right: 16px;
		color: #333333;
	}
	.adviser-main{
		width: 80%;
		height: auto;
		margin: 0 auto;
		display: block;
		padding: 48px 0;
		position: relative;
	}
	.adviser-txts{
		width: 100%;
		height: auto;
		text-align: center;
		font-size: 18px;
		line-height: 25px;
		color: #1A1A1A;
		margin: 0;
		padding: 0;
	}
	.adviser-txts p span{
		font-size: 14px;
		font-weight: bold;
	}
	.adviser-gif{
		width: 100%;
		height: auto;
		margin:32px 0 0 0;
	}
	.adviser-gif img{
		width: 260px;
		height: auto;
	}
	.adviser-btn{
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
	}
	.adviser-btn button{
		background: #C25E4D;
		color: #FFFFFF;
		width: 150px;
		line-height: 44px;
		border-radius: 44px;
		text-align: center;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
	}
	.close-popup{
		position: absolute;
		bottom: -40px;
		width: 20px;
		height: 20px;
		left: 50%;
		margin-left: -10px;
	}
	.close-popup img{
		width: 100%;
		height: 100%;
	}
		.adviser-main .saoma{
			font-size: 18px;
		}
	@media screen and (max-width:650px) {
		.adviser-top .close-adviser{
			right: 20px;
		}
		.adviser-top img{
			width: 100%;
			height: auto;
		}
		.adviserinfo-txt{
			font-size: 14px;
		}
		.adviserinfo-name{
			margin: 8px 0;
		}
		.adviserinfo-name div{
			font-size: 18px;
		}
		.adviserinfo-name .adviserinfo-name-img{
			width: 16px;
			height: 16px;
		}
		.adviserinfo-tip{
			padding: 4px;
			font-size: 12px;
			margin-right: 8px;
		}
		.adviser-main{
			padding: 24px 0;
		}
		.adviser-txts{
			font-size: 14px;
			line-height: 1.5;
		}
		.adviser-gif{
			margin: 8px 0 0 0;
		}
		.adviser-gif img{
			width: 200px;
		}
		.adviser-main .saoma{
			font-size: 14px;
		}
		.adviser-detail-boxs{
			width: 90vw;
			padding:40px 20px 20px 20px;
		}
		.adviser-detail-boxs .adviser-top{
			overflow: initial;
		}
		.adviser-top .adviser-detail-box img{
			width: 100px;
			height: 100px;
			margin-right: 10px;
		}
		.adviser-detail-title{
			font-size: 16px;
		}
		.adviser-detail-txt{
			width: calc(100% - 110px);
		}
		.adviser-detail-boxs .adviser-top .close-adviser{
			top: -25px;
		}
		.adviser-detail-msg{
			font-size: 12px;
		}
		.adviser-detail-boxs .adviser-main{
			padding:20px 0;
		}
		.main-ad-line{
			font-size: 16px;
		}
		.ad-li-txt{
			font-size: 12px;
		}
		.ad-li-title{
			font-size: 16px;
		}
		.ad-li-title img{
			height: 10px;
		}
		.main-ad-li{
			margin-top: 20px;
		}
	}






	.paid {
		width: 100%;
		/* max-width: 26rem; */
		margin: 0 auto;
	}

	.stepstatus {
		width: 100%;
		height: 2.33rem;
		/* background: #FFFFFF; */
		padding: 0.59rem 0;
	}

	.stepstatus .step-box {
		width: 100%;
		max-width: 12.5rem;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}

	.step-box .steptab {
		width: 45%;
		height: 1.15rem;
		display: flex;
	}

	.steptab .steptabbox {
		width: 1.77rem;
		height: 1.15rem;
	}

	.steptabbox .steptab-num {
		width: 0.59rem;
		height: 0.59rem;
		border-radius: 0.59rem;
		margin: 0 auto;
		border: 0.02rem solid #C25E4D;
		font-size: 0.35rem;
		color: #C25E4D;
		line-height: 0.59rem;
		text-align: center;
	}

	.steptabbox .steptab-name {
		width: 2.77rem;
		height: 0.41rem;
		text-align: center;
		line-height: 0.41rem;
		font-size: 0.3rem;
		margin-left: -0.5rem;
		margin-top: 0.15rem;
	}

	.steptab .steptab-line {
		width: calc(100% - 1.18rem);
		height: 0.04rem;
		background: #C25E4D;
		margin-top: 0.275rem;
	}

	.step1 .steptab-line {
		margin-left: -0.59rem;
	}

	.step2 .steptab-line {
		margin-right: -0.59rem;
		background: #cccccc;
	}

	.step2 .steptab-num {
		border: 0.02rem solid #cccccc;
		color: #cccccc;
	}

	.step1 .steptab-name {
		color: #C25E4D;
	}

	.step2 .steptab-name {
		color: #cccccc;
	}

	.active .steptab-name {
		color: #C25E4D;
	}

	.active .steptab-num {
		border: 0.02rem solid #C25E4D;
		color: #C25E4D;
	}

	.active .steptab-line {
		margin-right: -0.59rem;
		background: #C25E4D;
	}

	.hui .steptab-name {
		color: #cccccc;
	}

	.hui .steptab-num {
		border: 0.02rem solid #cccccc;
		color: #cccccc;
	}

	.hui .steptab-line {
		margin-right: -0.59rem;
		background: #cccccc;
	}

	.paid .title {
		width: 100%;
		height: 0.61rem;
		line-height: 0.61rem;
		text-align: center;
		color: #1A1A1A;
		font-size: 0.44rem;
		margin-top: 1.19rem;
		font-weight: bold;
	}

	.paid .msg {
		width: 100%;
		padding: 0 25%;
		height: auto;
		line-height: 0.41rem;
		text-align: center;
		color: #1A1A1A;
		font-size: 0.3rem;
		margin-top: 0.3rem;
	}

	.paid .paybtns {
		width: 100%;
		height: auto;
		/* display: flex;
		flex-wrap: wrap;
		justify-content: center; */
		margin: 0 auto;
		margin-top: 1.19rem;
	}

	.paybtns .pay-btn {
		margin-bottom: 0.59rem;
		height: 1.48rem;
		border-radius: 1.48rem;
		line-height: 1.48rem;
		text-align: center;
		font-size: 0.44rem;
		/* background: #FFFFFF; */
		color: #1A1A1A;

		border: 0.04rem solid #333333;
		width: 4rem;
		margin: 0.3rem auto;
		cursor: pointer;
	}

	.paybtns .pay-btn-yu {
		width: 9.39rem;
		background: #C25E4D;
		color: #FFFFFF;
		border: none;
	}

	.paybtns .gohome {
		border: none;
		height: 0.41rem;
		line-height: 0.41rem;
		width: 3rem;
		margin: 0 auto;
		color: #999999;
		font-size: 0.3rem;
		margin-top: 0.59rem;
	}

	.getadviser {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 15;
	}

	.getadviser-box {
		width: 100%;
		height: auto;
		background: #FFFFFF;
		padding: 0.25rem;
	}

	.adviser-info-box {
		width: 100%;
		height: auto;
	}

	.adviser-info-box .getadviser-title {
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		color: #1A1A1A;
		font-weight: bold;
		width: 100%;
		text-align: left;
	}

	.adviser-info-box .getadviser-msg {
		height: auto;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #1A1A1A;
		width: 100%;
		text-align: left;
		margin-top: 0.3rem;
	}

	.getadviser-info {
		width: 100%;
		height: 1.83rem;
		display: flex;
		justify-content: left;
		margin-top: 1.19rem;
	}

	.getadviser-info img {
		width: 1.83rem;
		height: 1.83rem;
		border-radius: 0.19rem;
	}

	.getadviser-info .getadviser-name-box {
		width: calc(100% - 2.15rem);
		margin-left: 0.3rem;
		height: 1.85rem;
	}

	.getadviser-name-box .getadviser-name {
		width: auto;
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.44rem;
		font-weight: bold;
		text-align: left;
	}

	.getadviser-name img {
		width: 0.44rem;
		height: 0.44rem;
		margin: 0.085rem;
		cursor: pointer;
	}

	.getadviser-name-box .getadviser-tabs {
		width: auto;
		height: 0.7rem;
		display: flex;
		margin-top: 0.37rem;
	}

	.getadviser-tab {
		width: auto;
		height: 0.7rem;
		font-size: 0.3rem;
		line-height: 0.7rem;
		background: #E9E9E9;
		border-radius: 0.11rem;
		padding: 0 0.15rem;
		margin-right: 0.15rem;
	}

	.getadviser-time {
		width: 100%;
		height: auto;
	}

	.get-title {
		width: 100%;
		height: 0.41rem;
		font-size: 0.3rem;
		line-height: 0.41rem;
		color: #1A1A1A;
		text-align: left;
		margin-top: 0.65rem;
	}

	.get-box {
		width: 100%;
		height: 1.11rem;
		margin-top: 0.17rem;
		font-size: 0.37rem;
		display: flex;
		justify-content: space-between;
		line-height: 1.11rem;
		padding: 0 0.59rem;
		cursor: pointer;

		background: #FFFFFF;
		border-radius: 1.11rem;
		border: 0.02rem solid #D5DDE6;
	}

	.get-box img {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.405rem 0;
	}

	.getadviser-btns {
		width: 100%;
		height: auto;
		margin-top: 1.69rem;
	}

	.getadviser-btns .getnow {
		color: #FFFFFF;
		font-size: 0.44rem;
		text-align: center;
		margin: 0 auto;



		width: 4.22rem;
		height: 1.11rem;
		line-height: 1.11rem;
		background: #C25E4D;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(194, 94, 77, 0.25);
		border-radius: 0.57rem;
		font-size: 0.3rem;
		color: #FFFFFF;
		text-align: center;
	}

	.getadviser-btns .nonow {
		width: 4.22rem;
		height: auto;
		color: #999999;
		font-size: 0.3rem;
		text-align: center;
		margin: 0.59rem auto 0 auto;
	}

	.ivu-cascader .ivu-input {
		width: 100%;
		height: 100%;
		border: none;
		font-size: 0.37rem;
	}

	.ivu-input-wrapper {
		height: 100%;
	}

	.ivu-input:hover {
		border: none;
	}

	.ivu-input:focus {
		border-color: none !important;
		box-shadow: none !important;
	}

	.el-cascader .el-input .el-input__inner {
		height: 1.11rem;
		border: none;
		background: none;
		font-size: 0.37rem;
		padding: 0;
	}

	.demo-spin-icon-load {
		animation: ani-demo-spin 1s linear infinite;
	}
	.loadingtxt{
		font-size: 0.37rem;
		margin-top: 0.19rem;
		color: #999999;
	}

	@keyframes ani-demo-spin {
		from {
			transform: rotate(0deg);
		}

		50% {
			transform: rotate(180deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	.demo-spin-col {
		height: 100px;
		position: relative;
		border: 1px solid #eee;
	}

	@media screen and (max-width:1000px) {
		.paid .msg {
			padding: 0 20%;
		}
		.noorder{
			width: 100%;
			height: auto;
			margin-top: 1rem;
		}
		.noorder img{
			width: 3rem;
			height: 3rem;
			margin: 0 auto;
		}
		.noorder .updatestatu{
			display: block;
			width: 5.22rem;
			height: 1.11rem;
			line-height: 1.11rem;
			padding: 0 0.2rem;
			border-radius: 1.11rem;
			font-size: 0.37rem;
			margin: 0 auto;
			margin-top: 0.59rem;
			border: 0.01rem solid #C25E4D;
			color: #C25E4D;
		}
	}

	@media screen and (max-width:650px) {
		.paid .msg {
			padding: 0 10%;
		}
	}
</style>
