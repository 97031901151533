<template>
 <div class="nutritionist"> 
    <img src="https://cdn-file.xiaojudai.cn/static/common/images/nutritionistBack.jpg">
    <a href="https://work.weixin.qq.com/ca/cawcdee2412502e000" class="goNutritionist"><img src="https://cdn-file.xiaojudai.cn/static/common/images/goNutritionist1.png"></a>
 </div>
</template>

<script>
export default {
 data(){
 },
 methods: {
  
 }

}

</script>

<style scoped>
.nutritionist{
    width:100vw;
}
.nutritionist>img{
    width:100%;
    height:auto
}
.goNutritionist{
    position:fixed;
    bottom:13px;
    left: 50%;
    transform: translate(-50%,0);
}
.goNutritionist >img{
    width: 305px;
    height: 93px;
}
</style>
