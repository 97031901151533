button<template>
	<div class="radiobox" v-if="qusdata">
		<div class="topicon" :class="qusdata.icon_url?'yesicon':'noicon'">
			<img v-if="qusdata.icon_url" :src="qusdata.icon_url" alt="">
		</div>
		<div class="title">{{qusdata.title}}</div>
		<div class="msg">{{qusdata.question_desc}}</div>
		<div class="tab"></div>
		<div v-if="hasgetit==false">
			<div class="chose more">
				<div class="chosebox" :class="get==item.id?'thisone':''" v-for="(item,i) in qusdata.answer" :key="i" @click="getthis(item.id,i)">
					<div class="icon" v-if="qusdata.question_type==5"><img :src="item.thumb_url" alt=""></div>
					<div class="ctxt" :class="qusdata.question_type!=5?'centertxt':''">{{item.item}}</div>
				</div>
			</div>
			<!-- <div class="chose" v-else :class="qusdata.answer[0].item.length>3||qusdata.answer[1].item.length>3?'more':''">
				<div class="chosebox" :class="get==item.id?'thisone':''" v-for="(item,i) in qusdata.answer" :key="i" @click="getthis(item.id,i)">
					<div class="icon" v-if="qusdata.question_type==5"><img :src="item.thumb_url" alt=""></div>
					<div class="ctxt" :class="qusdata.question_type!=5?'centertxt':''">{{item.item}}</div>
				</div>
			</div> -->
		</div>

		<div v-if="hasgetit==true">
			<div class="chose more">
				<div class="chosebox" :class="get==item.id?'thisone':''" v-for="(item,i) in qusdata.answer" :key="i">
					<div class="icon" v-if="qusdata.question_type==5"><img :src="item.thumb_url" alt=""></div>
					<div class="ctxt" :class="qusdata.question_type!=5?'centertxt':''">{{item.item}}</div>
				</div>
			</div>
			<!-- <div class="chose" v-else :class="qusdata.answer[0].item.length>3||qusdata.answer[1].item.length>3?'more':''">
				<div class="chosebox" :class="get==item.id?'thisone':''" v-for="(item,i) in qusdata.answer" :key="i">
					<div class="icon" v-if="qusdata.question_type==5"><img :src="item.thumb_url" alt=""></div>
					<div class="ctxt" :class="qusdata.question_type!=5?'centertxt':''">{{item.item}}</div>
				</div>
			</div> -->
		</div>
		<div class="btns">
			<button class="last show_mb" v-if="qusdata.current_num!=1" @click="last">上一题</button>
			<!-- <div class="next" @click="ok">确定</div> -->
			<button class="next" v-if="qusdata.is_end==1" @click="gotijiao">确定提交</button>
			<!-- <div class="next"  @click="gotijiao">确定提交</div> -->
		</div>
	</div>
</template>

<script>
  import checkIsInApp from '../util/app.js'
	export default {
		name: 'radio',
		props: {
			rdata: Object
		},
		watch: {
			rdata(n, o) { //n为新值,o为旧值;
				// console.log(n, o)
				this.o = o
				this.hasgetit = false
				this.qusdata = n;
				this.get = '';
				this.getids = []
			}
		},
		data: () => ({
			title: '请选择你的性别 ',
			msg: '请认真选择，这个关系着你后续的评估',
			o:'',
			choses: [{
				key: 0,
				value: '女',
				icon: '',
				type: 0
			}, {
				key: 1,
				value: '男',
				icon: '',
				type: 1
			}],
			qusdata: '',
			get: '',
			getids: [],
			hasgetit:false,
			tijiao: false
		}),
		mounted() {
			//console.log(this.rdata)
			this.qusdata = this.rdata?this.rdata:{}
		},
		methods: {
      checkIsInApp(){
        return checkIsInApp();
      },
			console(){
				//console.log('1231456')
			},
			getthis(e, index) {
				var that = this
				this.hasgetit = true
				var qusdata = this.qusdata
				var ids = []
				//console.log(e, this.getids,index, qusdata)
				if (qusdata.is_end == 1) {
					this.hasgetit = false
					//console.log('最后一题')
					this.get = e
					ids = [e]
					this.getids = ids
				} else {
					if (index == 0) {
						if (qusdata.keyword == 'age' || qusdata.title == '你的年龄？' || qusdata.title == '你的年龄是？') {
							that.$Message.info('很抱歉！目前只为成人开放营养补剂定制服务。')
							that.hasgetit = false
						} else {
							this.get = e
							ids = [e]
							this.getids = ids
							setTimeout(function(){
								that.ok()
							},500)
						}
					} else {
						this.get = e
						ids = [e]
						this.getids = ids
							setTimeout(function(){
								that.ok()
							},500)
					}
				}
			},
			gotijiao() {
				// //console.log(this.getids)
				if (this.tijiao == true) {
					//console.log('请别多次点击')
				} else if(this.getids.length==0){
					this.$Message.info('请先选择！')
				}else{
					this.ok()
				}
			},
			ok() {
				var ids = this.getids
				this.$emit('getback', {
					value: ids,
					way: '1'
				})
			},
			last() {
				this.tijiao = false
				this.$emit('getback', {
					value: '',
					way: '0'
				})
			}
		}
	}
</script>

<style>
	.radiobox {
		width: 100%;
		max-width: 26rem;
		height: auto;
		margin: 0 auto;
	}

	.radiobox .title {
		width: 100%;
		height: auto;
		font-size: 0.52rem;
		line-height: 1rem;
		font-weight: bold;
		/* margin-top: 0.7rem; */
	}

	.radiobox .msg {
		width: 100%;
		font-size: 0.3rem;
		line-height: 0.52rem;
		color: #1A1A1A;
	}

	.radiobox .tab {
		background: #C25E4D;
		width: 1.11rem;
		height: 0.145rem;
		border-radius: 0.145rem;
		margin: 0.44rem auto 0.59rem auto;
	}

	.radiobox .chose {
		width: 100%;
		margin: 0 auto;
		height: auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.chose .chosebox {
		width: auto;
		min-width: 2.78rem;
		height: 1.85rem;
		border-radius: 0.3rem;
		padding: 0.61rem 0.78rem;
		/* border: 0.01rem solid #eee; */
		background: #eee;
		margin: 0.2rem;
		margin-top: 0.5rem;
		cursor: pointer;
		align-items: center;
		position: relative;
		text-align: center;

		max-width: 4.2rem;
		height: 1.85rem;
		border-radius: 0.3rem;
		padding: 0.4rem 0.3rem 0.2rem 0.3rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		border: 0.04rem solid #D5DDE6;
	}

	.more .chosebox {
		width: 4.2rem;
		padding: 0 0.3rem;
		word-wrap: break-word;
		word-break: normal;
	}

	.chosebox .icon {
		width: 0.89rem;
		height: 0.89rem;
		margin: 0 auto;
	}

	.chosebox .ctxt {
		width: 100%;
		height: 0.52rem;
		margin-top: 0.26rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		font-weight: normal;
	}

	.chosebox .centertxt {
		width: calc(100% - 0.6rem);
		height: auto;
		line-height: 0.47rem;
		font-size: 0.3rem;
		margin-top: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.radiobox .thisone {
		border: 0.02rem solid #C25E4D;
		background: #fff;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);

		background: #FFFFFF;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(216, 118, 101, 0.26);
		border-radius: 0.19rem;
		border: 0.04rem solid #C25E4D;
	}

	.thisone .centertxt {
		color: #C25E4D;
	}
</style>
