<template>
  <div>
    <img
      src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/build/dist/imgs/loading.gif"
      alt="loading"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "hello world!",
    };
  },
  created() {
    sessionStorage.setItem("is_b_serve", 1);
    setTimeout(() => {
      this.$router.push({
        path: "/",
      });
    }, 1000);
  },
};
</script>

<style scoped></style>
