<template>
    <div>
    <ReturnBtn/>
    <div class="privacy-policy">
        <div class="v-description">
            <p
                style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;text-align:center;line-height:42px">
                <strong>
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 15px;">NUTRIPACKS橘袋隐私政策</span>
                </strong>
                <br></p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋（“我们”）非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《NUTRIPACKS专业平台隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">本政策将帮助您了解以下内容：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、我们如何收集和使用您的个人信息</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、我们如何使用 Cookie</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、我们如何共享、转让、公开披露您的个人信息</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">4、我们如何保护和保存您的个人信息</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">5、您如何管理个人信息</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">6、未成年人信息的保护</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">7、通知和修订</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">8、如何联系我们</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: Calibri; color: rgb(73, 73, 73); font-size: 15px;">&nbsp;</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 40px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">本隐私政策与您所使用的NUTRIPACKS橘袋服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px; line-height: 29px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">一、我们如何收集和使用您的个人信息</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（一）您需授权我们收集和使用您个人信息的情形</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们的产品与/或服务包括核心业务功能和附加功能。我们的产品与/或服务包括一些核心功能，这些功能包含了实现推广所必须的功能、改进我们的产品与/或服务所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些实现推广所必须的功能包括：</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px; line-height: 17px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、用户注册</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">您首先需要注册一个NUTRIPACKS橘袋账户成NUTRIPACKS橘袋用户。</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">当您注册时，您需要至少向我们提供您准备使用的NUTRIPACKS橘袋您本人的手机号码，真实姓名、身份证号等信息，我们可能通过发送短信验证码来验证您是否有效。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px; line-height: 17px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、提取佣金</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">为了使您通过推广获得的佣金可以提现至您的个人账户里，您需要绑定您的银行卡。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（二）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、与国家安全、国防安全有关的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、与公共安全、公共卫生、重大公共利益有关的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、与犯罪侦查、起诉、审判和判决执行等有关的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">5、所收集的个人信息是个人信息主体自行向社会公众公开的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">7、根据您的要求签订合同所必需的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">9、为合法的新闻报道所必需的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">11、法律法规规定的其他情形。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（三）我们从第三方获得您个人信息的情形</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您NUTRIPACKS橘袋账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（四）您个人信息使用的规则</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 40px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px; line-height: 29px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">二、我们如何使用&nbsp;Cookie</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies
                        是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的偏好、帮助您优化选择与互动、帮助判断您的登录状态、账户或数据安全、判断分享链接主体，以及实现记录及计算服务费。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 40px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、我们不会将
                        Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有
                        Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝
                        Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问NUTRIPACKS橘袋网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px; line-height: 29px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">三、我们如何共享、转让、公开披露您的个人信息</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（一）共享</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px; line-height: 17px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、我们不会与NUTRIPACKS橘袋以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（1）事先获得您明确的同意或授权；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（3）在法律法规允许的范围内，为维护NUTRIPACKS橘袋、NUTRIPACKS橘袋的关联方或合作伙伴、您或其他NUTRIPACKS橘袋用户或社会公众利益、财产或安全免遭损害而有必要提供；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（4） 只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（5）应您需求为您处理您与他人的纠纷或争议；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（7）基于学术研究而使用；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（8）基于符合法律法规的社会公共利益而使用。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span>
                    </strong>
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们的合作伙伴包括以下类型：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（1）商品或技术服务的供应商。</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们可能会将您的个人信息共享给支持我们功能的第三方</span>
                    </strong>
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（2）第三方服务商。</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们必须将您的订单信息与交易有关的必要信息与第三方服务商共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。</span>
                    </strong>
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。为了保障数据在第三方安全可控，我们推出了云鼎服务，在云端提供安全可靠的数据使用和存储环境，确保用户数据的安全性。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护NUTRIPACKS橘袋、您或其他NUTRIPACKS橘袋客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（二）转让</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、事先获得您明确的同意或授权；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（三）公开披露</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 40px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px; line-height: 29px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">四、我们如何保护和保存您的个人信息</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px; line-height: 22px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（一）我们保护您个人信息的技术与措施</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、数据安全技术措施</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、NUTRIPACKS橘袋为保护个人信息采取的其他安全措施</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、我们仅允许有必要知晓这些信息的NUTRIPACKS橘袋及NUTRIPACKS橘袋关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与NUTRIPACKS橘袋的合作关系。</span>
                    </strong>
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与NUTRIPACKS橘袋的合作关系。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。</span>
                    </strong>
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">5、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">6、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">7、安全事件处置</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">为应对个人信息泄露、损毁和丢失等可能出现的风险，NUTRIPACKS橘袋制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。NUTRIPACKS橘袋也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 40px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">8、如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px; line-height: 29px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">五、您如何管理您的个人信息</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 10px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">NUTRIPACKS橘袋非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、访问和更正您的个人信息</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（1）您的账户信息：您在注册是提供相关个人信息，如您需要变更您的实名认证信息，您可联系我们申请变更。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（2）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（3）您无法访问和更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用附加功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">在以下情形中，您可以向我们提出删除个人信息的请求：</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（1）如果我们处理个人信息的行为违反法律法规；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（2）如果我们收集、使用您的个人信息，却未征得您的同意；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（3）如果我们处理个人信息的行为违反了与您的约定；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（4）如果您注销了NUTRIPACKS橘袋账户；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（5）如果我们终止服务及运营。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、改变您授权同意的范围或撤回您的授权</span>
                    </strong>
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">&nbsp;</span>
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">您可以通过删除信息、关闭设备功能、在NUTRIPACKS橘袋软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、我们会给您推送一些公告和消息信息：</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性、疾病等相关敏感内容的促销或商品信息给您。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">4、响应您的请求</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为NUTRIPACKS橘袋存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至zhangfei@nutri-packs.com或通过本协议下方的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的30天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（1）与国家安全、国防安全有关的；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（2）与公共安全、公共卫生、重大公共利益有关的；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（3）与犯罪侦查、起诉和审判等有关的；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（4）有充分证据表明您存在主观恶意或滥用权利的；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 40px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px; line-height: 29px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">六、通知和修订</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、为给您提供更好的服务以及随着NUTRIPACKS橘袋业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在NUTRIPACKS橘袋网站、NUTRIPACKS橘袋移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问NUTRIPACKS橘袋以便及时了解最新的隐私政策。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">本政策所指的重大变更包括但不限于：</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（3）个人信息共享、转让或公开披露的主要对象发生变化；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">（6）个人信息安全影响评估报告表明存在高风险时。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 40px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、我们还会将本策略的旧版本存档，供您查阅。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 20px; line-height: 29px;">
                    <strong>
                        <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">七、如何联系我们</span>
                    </strong>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">1、如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请通过访问在线客服系统、发送邮件至zhangfei@nutri-packs.com或拨打我们的任何一部客服电话等多种方式与我们联系。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">2、我们设立了个人信息保护专职部门（或个人信息保护专员），您可以通过发送邮件到zhangfei@nutri-packs.com的方式与其联系。</span>
                </p>
                <p style="margin-top: 0px; margin-bottom: 0px;">
                    <span style="font-family: 微软雅黑; color: rgb(73, 73, 73); font-size: 12px;">3、一般情况下，我们将在三十天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</span>
                </p>
              </div>
  </div>
</div>
</template>

<script>
import ReturnBtn from "../components/ReturnBtn.vue";
  export default {
    name: 'privacy-policy',
    components: {
        ReturnBtn,
    },
    data() {
      return {}
    }
  }
</script>

<style>
.privacy-policy {
  max-width: 27.30667rem;
  width: 85vw;
  min-height: 14.4rem;
  margin: 1.86667rem auto 3.2rem;
  overflow: hidden;

  p {
    text-align: justify;
    font-size: 0.28rem;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(20,20,20,1);
    line-height: 0.88rem;
    letter-spacing: 0.02667rem;
    margin-bottom: 1.33333rem;
  }
}
</style>
