<!-- eslint-disable vue/no-parsing-error -->
<template>
	<div class="goods_unit1 main-page">
		<div class="show_mb goods_unit">
			<div class="unit-left">
				<img :src="prodetail.goods_detail.img_minshrink" alt="">
				<div class="lname">{{prodetail.goods_detail.goods_name}}</div>
			</div>
			<div class="goods_unit-box">
				<div class="unit-cat">
					功能分类： <span v-for="(item,index) in prodetail.goods_detail.cat" :key="index">{{item.title}}{{index+1==prodetail.goods_detail.cat.length?'':'、'}}</span>
				</div>
				<div class="eng_desc">{{prodetail.goods_detail.eng_desc}}</div>
				<div class="goods_unit-title">
					<div class="goods_unit-nums" style="text-align: left !important;">
						<div>每颗含成分</div>
						<div>Amount Per Serving</div>
					</div>
					<div class="goods_unit-nums">
						<div>每日营养摄入量百分比</div>
						<div>% Daily Value</div>
					</div>
				</div>
				<div class="goods_unit-line"
					:class="index+1==prodetail.goods_detail.goods_unit.length?'bborder':''"
					v-for="(ingredientsItem,index) in prodetail.goods_detail.goods_unit" :key="index">
						<!-- 有分组的情况 -->
							<div v-if="ingredientsItem.group_en_name" style="width:100%">
								<div class="ingredients_info">
									<div class="ingredient_item_one">{{ingredientsItem.group_en_name}}</div>
									<div class="ingredient_item_two">{{ingredientsItem.group_single_unit_size}}{{ingredientsItem.group_unit}}</div>
									<div class="ingredient_item_three">{{ingredientsItem.group_single_unit_percentage}}</div>
								</div>
								<div class="ingredients_group">
									<div class="ingredient_group_en"><div v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_eng}}</div></div>
									<div class="ingredient_group_zh">{{ingredientsItem.group_zh_name}}<span v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_name}}{{ index<(ingredientsItem.ingredients).length-1?',':''}}</span></div>
								</div>
							</div>
							<!-- 没有分组的情况 -->
							<div v-else style="width:100%">
								<div class="ingredients_info ingredients_info_single" v-for="(item,index) in ingredientsItem.ingredients" :key="index">
									<div class="ingredient_item_one">{{item.unit_eng}} {{item.unit_name}}</div>
									<div class="ingredient_item_two">{{item.single_unit_size}}{{item.unit}}</div>
									<div class="ingredient_item_three">{{item.single_unit_percentage}}</div>
								</div>
							</div>
					</div>
				<!-- <div class="goods_unit-line" :class="index+1==prodetail.goods_detail.goods_unit.length?'bborder':''" v-for="(item,index) in prodetail.goods_detail.goods_unit" :key="index">
					<div class="goods_unit-name">
						<div>{{item.unit_eng}}<p>{{item.zh}}</p></div>
					</div>
					<div class="goods_unit-num">
						<div>{{item.single_unit_size}}{{item.unit}}</div>
					</div>
					<div class="goods_unit-x">
						<div>{{item.single_unit_percentage}}</div>
					</div>
				</div> -->
				<div class="goods_unit-line bborder" v-if="prodetail.goods_detail.other_component">
					<!-- <div class="goods_unit-name"> -->
						<div>其他成分：{{prodetail.goods_detail.other_component}}</div>
					<!-- </div> -->
				</div>
				<div class="ingredient_suffix lasttxt">{{prodetail.goods_detail.ingredient_suffix}}</div>
				<div class="daynum1 lasttxt">* Percent Daily Values are based on a 2,000 calories diet
                    每日营养摄入量百分比按日饮食摄入2000卡路里计算</div>
				<div class="daynum2 lasttxt">** Daily Value not established 未规定每日摄入量</div>
			</div>
		</div>
		<div class="show_pc detail_pc goods_unit">
			<div class="unit-left">
				<img :src="prodetail.goods_detail.img_minshrink" alt="">
				<div class="lname">{{prodetail.goods_detail.goods_name}}</div>
			</div>
			<div class="goods_unit-box">
				<div class="unit-cat">
					功能分类：<span v-for="(item,index) in prodetail.goods_detail.cat" :key="index">{{item.title}}{{index+1==prodetail.goods_detail.cat.length?'':'、'}}</span>
				</div>
				<div class="eng_desc">{{prodetail.goods_detail.eng_desc}}</div>
				<div class="goods_unit-title">
					<div class="goods_unit-nums" style="text-align: left !important;">
						<div>每颗含成分</div>
						<div>Amount Per Serving</div>
					</div>
					<div class="goods_unit-nums">
						<div>每日营养摄入量百分比</div>
						<div>% Daily Value</div>
					</div>
				</div>
				<!-- <div class="goods_unit-line" :class="index+1==prodetail.goods_detail.goods_unit.length?'bborder':''" v-for="(item,index) in prodetail.goods_detail.goods_unit" :key="index">
					<div class="goods_unit-name">
						<div>{{item.unit_eng}} <p>{{item.zh}}</p></div>
					</div>
					<div class="goods_unit-num">
						<div>{{item.single_unit_size}}{{item.unit}}</div>
					</div>
					<div class="goods_unit-x">
						<div>{{item.single_unit_percentage}}</div>
					</div>
				</div> -->
				<div class="goods_unit-line"
					:class="index+1==prodetail.goods_detail.goods_unit.length?'bborder':''"
					v-for="(ingredientsItem,index) in prodetail.goods_detail.goods_unit" :key="index">
						<!-- 有分组的情况 -->
							<div v-if="ingredientsItem.group_en_name" style="width:100%">
								<div class="ingredients_info">
									<div class="ingredient_item_one">{{ingredientsItem.group_en_name}}</div>
									<div class="ingredient_item_two">{{ingredientsItem.group_single_unit_size}}{{ingredientsItem.group_unit}}</div>
									<div class="ingredient_item_three">{{ingredientsItem.group_single_unit_percentage}}</div>
								</div>
								<div class="ingredients_group">
									<div class="ingredient_group_en"><div v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_eng}}</div></div>
									<div class="ingredient_group_zh">{{ingredientsItem.group_zh_name}}<span v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_name}}{{ index<(ingredientsItem.ingredients).length-1?',':''}}</span></div>
								</div>
							</div>
							<!-- 没有分组的情况 -->
							<div v-else style="width:100%">
								<div class="ingredients_info ingredients_info_single" v-for="(item,index) in ingredientsItem.ingredients" :key="index">
									<div class="ingredient_item_one">{{item.unit_eng}} {{item.unit_name}}</div>
									<div class="ingredient_item_two">{{item.single_unit_size}}{{item.unit}}</div>
									<div class="ingredient_item_three">{{item.single_unit_percentage}}</div>
								</div>
							</div>
					</div>
				<div class="goods_unit-line bborder" v-if="prodetail.goods_detail.other_component">
					<!-- <div class="goods_unit-name"> -->
						<div>其他成分：{{prodetail.goods_detail.other_component}}</div>
					<!-- </div> -->
				</div>
				<div class="ingredient_suffix lasttxt">{{prodetail.goods_detail.ingredient_suffix}}</div>
				<div class="daynum1 lasttxt">* Percent Daily Values are based on a 2,000 calories diet
                    每日营养摄入量百分比按日饮食摄入2000卡路里计算</div>
				<div class="daynum2 lasttxt">** Daily Value not established 未规定每日摄入量</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'goods_unit',
		components:{
		},
		data:()=>({
			info:'',
			goodslist:'',
			prodetail:''
		}),
		mounted() {
			var that = this
			var id = this.$route.query.id
			this.$ajax.post(this.API.api.apiurl.getGoodsInfo, {
					goods_id:id
				})
				.then(res => {
					////console.log(res)
					var data = res.data.data
					if (res.data.code == 0) {
						// //console.log(data)
							var goods_units = data.goods_detail.goods_unit
							// goods_units.forEach((item,index)=>{
							// 	var eng = item.unit_eng
							// 	var name = item.unit_name
							// 	var ar = name.split(eng)
							// 	if (eng) {
							// 		goods_units[index].zh = ar[1]
							// 	} else {
							// 		goods_units[index].zh = name
							// 	}
							// })
							data.goods_detail.goods_unit = goods_units
							//console.log(data)
						that.prodetail = data
						console.log(that.prodetail,'45645645645')
					} else {
						that.$Message.info('错误：' + res.data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
		},
		methods:{
		},
	}
</script>

<style>
	.goods_unit1 .show_mb {
		margin: 0 auto;
	}
	.goods_unit{
		width: 100%;
		height: auto;
		/* margin: 0.2rem auto 0.8rem auto; */
		display: flex;
		justify-content: space-between;
	}
	.goods_unit1{
		width: 100% !important;
		height: 100%;
		min-height: 100% !important;
		overflow-y: auto;
		padding: 0 10px;
	}
	.unit-left{
		width: 1.6rem;
		height: auto;
		background: #DCE4E3;
		padding: 0 0.1rem;
	}
	.unit-left img{
		width: 1.4rem;
		height: 1.4rem;
		margin-top: 0.8rem;
	}
	.lname{
		width: 100%;
		height: auto;
		font-size: 0.26rem;
		font-size: 14px;
		margin-top: 0.2rem;
	}
	.goods_unit .goods_unit-box{
		width: calc( 100% - 1.8rem );
	}
	.goods_unit .goods_unit-name{
		width: 56%;
		font-size: 0.28rem;
		font-size: 14px;
		text-align: left;
	}
	.goods_unit-title{
		font-size: 14px;
	}
	.goods_unit-line .goods_unit-name div{
		font-size: 14px!important;
	}
	.goods_unit-title .goods_unit-name{
		width: 50%;
	}
	.goods_unit .goods_unit-num{
		font-size: 0.28rem;
		font-size: 14px;
	}
	.goods_unit-line{
		text-align: left;
	}
	.lasttxt{
		width: 100%;
		font-size: 0.2rem;
		font-size: 14px;
		text-align: left;
		color: #1A1A1A;
		margin: 0.17rem 0;
	}
	.unit-cat{
		width: 100%;
		height: auto;
		font-size: 0.3rem;
		font-size: 14px;
		color: #1A1A1A;
		text-align: left;
		font-weight: bold;
		line-height: 0.46rem;
	}
	.unit-cat span{
		color: #666666;
		font-weight: 350;
	}
	.eng_desc{
		width: 100%;
		height: auto;
		font-size: 0.2rem;
		font-size: 14px;
		color: #1A1A1A;
		border-bottom: 0.08rem solid #000000;
		/* margin-top: 0.3rem; */
		padding: 0.2rem 0;
		text-align: left;
	}
	.goods_unit1 .detail_pc{
		width: 100%;
		margin: 0 auto;
	}
</style>
