<template>
	<div id="app" :class="youdi?'dibu':''" :style="shownav == false || shengyi == true || ketogenesis == true? 'padding-top:0' : ''" ref="detailmain">
		<navs v-if="shownav && !shengyi && !ketogenesis" @getback="getbackinfo" :class="mainmohu?'mohu':''"></navs>
		<!-- <navs v-if="shownav" @getback="getbackinfo"></navs> -->
		<router-view @getback="getbackinfo_main" :class="mohu?'mohu':''" />
		<foot v-if="!hiddenFooter && showwei&&showit && !shengyi && !ketogenesis" :class="mainmohu?'mohu':''"></foot>
		<foot2 v-if="!hiddenFooter && !showwei&&showit && !shengyi && !ketogenesis" :class="mainmohu?'mohu':''"></foot2>
		<div class="qusbottomheight" v-if="!showwei"></div>
		<div class="bottomheight" v-if="bottomshow"></div>
	</div>
</template>

<script>
	import navs from './components/navs.vue';
	import foot from './components/footer.vue';
	import foot2 from './components/qus-foot.vue';
	export default {
		name: 'App',
		components: {
			navs,foot,foot2
		},
		data: () => ({
			hiddenFooter: true,
			path: '',
			bottomshow:false,
			showwei:true,
			shownav:false,
			youdi:false,
			showit:false,
			islogin:false,
			mohu:false,
			mainmohu:false,
			to:'',
			changepath:'',
			from:'',
			shengyi:false,
		}),
		watch: {
			'$route': {
				handler(to,from) { //监听路由是否变化
					this.to = to
					this.from = from

					window.scrollTo(0, 0)
					var that = this
					that.showit = false

					this.hiddenFooter = this.$route.meta.hiddenFooter || sessionStorage.hiddenFooter === 'hidden' || false
					this.hiddenNav = this.$route.meta.hiddenNav === 'hidden' || sessionStorage.hiddenNav === 'hidden' ? false : true
					this.hiddenTitle = this.$route.meta.title === 'hidden' || sessionStorage.hiddenTitle === 'hidden' ? true : false
                    if(this.hiddenTitle){
                        document.title = "";
                    }
				},
				immediate: true
			}
		},
        created() {
		this.showAi = 	sessionStorage.getItem('showAI')*1;	
            try {
                document.body.removeChild(document.getElementById('appLoading'))
                setTimeout(function() {
                    document.getElementById('app').style.display = 'block';
                }, 500)
            } catch (e) {
                console.log(e)
            }
        },
        destroyed() {},
		mounted() {
			var info = localStorage.getItem('info')?JSON.parse(localStorage.getItem('info')):''
							if(info){
								if(info.token){
									//console.log('')
								}else{
									localStorage.removeItem("info")
									info = ''
								}
							}
			localStorage.setItem('q2',false)
			var ua = navigator.userAgent.toLowerCase();
			var isWeixin = ua.indexOf('micromessenger') != -1;
			if (isWeixin) {
				localStorage.setItem('way', 'weixin')
			} else {
				if (this._isMobile()) {
					localStorage.setItem('way', 'h5')
				} else {
					localStorage.setItem('way', 'pc')
				}
			}

			this.path = this.$route.path
			const shanghaishow = sessionStorage.getItem('shanghaishow')
			var that = this
			console.log(this.path)
			var changepath = setInterval(function(){
				if(that.$route.path=='/report'||that.$route.path=='/report-detail'||that.$route.path=='/define'||that.$route.path=='/settlement'||that.$route.path=='/cart'||that.$route.path=='/order-detail'){
					that.bottomshow=true
				}else{
					that.bottomshow=false
				}
				if(that.$route.path=='/report-detail'||that.$route.path=='/settlement'||that.$route.path=='/order-detail'||that.$route.path=='/cart'){
					that.youdi=true
				}else{
					that.youdi=false
				}
				if(that.$route.path=='/login'||that.$route.path=='/getreport'){
					that.islogin=true
				}else{
					that.islogin=false
				}
				if(that.$route.path=='/service'||that.$route.path=='/wxpaid' ||that.$route.path=='/shanghaishow'){
					that.showit = false
				}else if(that.$route.path=='/meal-detail'&&localStorage.getItem('way')=='h5'){
					that.showit = false
				}else{
					that.showit = true
				}
				if(that.$route.path=='/wxpaid' || that.$route.path=='/shanghaishow' || shanghaishow){
					that.shownav=false
				}else{
					that.shownav=true
				}
				if(that.$route.path == '/shengyiafter' || that.$route.path =='/shengyibefor' || that.$route.path == '/shengyinow'){
					that.shengyi = true
				}else{
					that.shengyi = false
				}
				if(that.$route.path == '/ketogenesis' || that.$route.path == '/loss-camp' || that.$route.path == '/ketogenesis2' || that.$route.path == '/bmi' || that.$route.path == '/ketogenesis3' || that.$route.path == '/ketogenesis4' || that.$route.path == '/userJudai' || that.$route.path=='/checkInEveryDay' || that.$route.path == '/NoPermission'){
					that.ketogenesis = true
				}else{
					that.ketogenesis = false
				}
			}, 1000)
			this.changepath = changepath
			//console.log(changepath)
			info = JSON.parse(localStorage.getItem('info'))

			if (info) {
				var timestamp = (new Date()).getTime();
				var t1 = info.timestamp?info.timestamp:0
				var cha = timestamp-t1
				if(cha>86400000){
					// this.$router.push({
					// 	path: 'login'
					// })
					localStorage.removeItem("info")
				}else{
					this.$ajax.post(this.API.api.apiurl.getIndex, {})
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							localStorage.setItem('indexdata', JSON.stringify(data.data))
						} else {
							//console.log('错误：' + data.message)
						}
					})
					.catch(error => {
						//console.log(error)
						alert(error)
					})
				}
			}
		},
		methods: {
			getbackinfo(e) {
				if(e.way==1){
					this.mohu = true
				}else{
					this.mohu = false
				}
			},
			getbackinfo_main(e){
				console.log('11111',e)
				if(e.way==1){
					this.mainmohu = true
				}else{
					this.mainmohu = false
				}
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			formatDate(now) {
				now = new Date(now)
				var year=now.getFullYear();
				var month=now.getMonth()+1;
				var date=now.getDate();
				var hour=now.getHours();
				var minute=now.getMinutes();
				var second=now.getSeconds();
				return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second;
			}
		}
	}
</script>

<style>
	.mohu{
		filter: blur(2px);
	}
	@font-face
	{
		font-family: Oswald-Medium;
		src: url('../public/Oswald-Medium.ttf'),url('../public/Oswald-Medium.ttf'); /* IE9 */
	}
	@font-face
	{
		font-family: MADE Evolve;
		src: url('../public/MADE Evolve Sans Bold (PERSONAL USE).otf'),url('../public/MADE Evolve Sans Bold (PERSONAL USE).otf'); /* IE9 */
	}
	.dibu .rightqr{
		bottom: 2.07rem;
	}
	#app .Evolve{
		font-family: MADE Evolve;
	}
	.gocart img{
		width: 0.5rem;
		height: 0.5rem;
		margin: 0.045rem;
	}
	#app {
		/* font-family: Avenir, Helvetica, Arial, sans-serif; */
		/* font-family: "PingFang SC"; */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #1A1A1A;
		padding-top: 80px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,微软雅黑,Arial,sans-serif;
		width: 100%;
	}
	#app button{
		border: none;
	}
	#app a{
		display: inline-block;
		-webkit-tap-highlight-color:rgba(0,0,0,0);
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		-webkit-user-select: none;
		-moz-user-focus: none;
		-moz-user-select: none;
	}
	#app .abox{
		width: 100%;
		justify-content: center;
	}
	#app .yinz{
		font-family: Oswald-Medium;
		text-transform:uppercase;
		font-weight: bold;
	}
	#app .bold{
		font-weight: bold;
	}
	.rightqr{
		z-index: 2;
	}
	.mleft{
		margin-left: 0.3rem;
	}

	.cursor {
		cursor: pointer;
	}

	.disnone {
		display: none;
	}

	input {
		-webkit-appearance: none !important;
	}

	.noplay {
		display: none;
	}

	#app .main-page {
		width: 100%;
		max-width: 18.5rem;
		margin: 0 auto;
		min-height: calc( 100vh - 1.02rem );
	}
	.loginheight{
		width: 100%;
		height: calc( 100vh - 1.02rem );
	}

	#app .lodding-box {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		background: rgba(255, 255, 255, 1);
	}

	#app .loadingmain {
		width: 9rem;
		height: auto;
		opacity: 1;
		position: fixed;
		border-radius: 0.3rem;
		top: 50%;
		left: 50%;
		margin: 0 0 0 -4.5rem;
		transform: translateY(-50%);
		background: #ffffff;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		padding: 0.59rem;
		overflow: hidden;
	}

	#app .loadEffect {
		width: 100%;
		height: 100%;
		/* position:relative;
		margin:25% auto; */
	}

	#app .loadEffect .loadimg {
		width: 4rem;
		height: 4rem;
		margin: 0 auto;
		position: relative;
	}

	#app .loadimg img {
		width: 100%;
		height: 100%;
		margin: 0;
	}
	#app .bottomheight{
		width: 100%;
		height: 3.5rem;
	}

	#app .loadimg div {
		position: absolute;
		width: 100%;
		text-align: center;
		height: 0.42rem;
		line-height: 0.42rem;
		font-size: 0.37rem;
		font-weight: bold;
		top: 50%;
		margin-top: -0.21rem;
	}

	#app .loadEffect .txts {
		width: 100%;
		height: 2rem;
		background: #fff;
		display: flex;
	}

	#app .txts p {
		width: 100%;
		height: 2rem;
		line-height: 2rem;
		text-align: center;
	}

	.slide-fade-enter-active {
		transition: all .3s ease;
	}

	.slide-fade-leave-active {
		transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade-enter {
		transform: translateX(4rem);
		opacity: 0;
	}

	.slide-fade-leave-to

	/* .slide-fade-leave-active 用于 2.1.8 以下版本 */
		{
		transform: translateX(-4rem);
		opacity: 0;
	}

	#app .public-btn {
		width: 4.22rem;
		height: 1.11rem;
		line-height: 1.11rem;
		background: #C25E4D;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(194, 94, 77, 0.25);
		border-radius: 0.57rem;
		font-size: 0.3rem;
		color: #FFFFFF;
		text-align: center;
	}

	#app .public-btn-css {
		width: 4.22rem;
		height: 1.11rem;
		line-height: 1.11rem;
		border-radius: 0.57rem;
		font-size: 0.3rem;
		text-align: center;
	}

	#app .page-bg {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: none;
		/* background-image: url(../public/imgs/main_bg.png); */
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
	.modaltitle{
		line-height: 0.52rem;
		height: 0.52rem;
		display: flex;
	}
	.modaltitle span{
		display: inline-block;
		width: auto;
		height: 24px;
		line-height: 24px;
		font-size: 0.37rem;
		font-weight: bold;
		margin-left: 0.3rem;
		color: #1A1A1A;
	}
	.modalmain{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 0.57rem;
		line-height: 0.41rem;
		font-size: 0.37rem;
		color: #1A1A1A;
	}
	.modalmain p{
		width: 5.93rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		text-align: left;
		color: #1A1A1A;
	}
	.modalbtns{
		width: 100%;
		height: 0.59rem;
		margin-top: 0.59rem;
		display: flex;
		justify-content: flex-end;
	}
	.modalbtn{
		min-width: 1.5rem;
		height: 0.59rem;
		border-radius: 0.59rem;
		padding: 0 0.2rem;
		text-align: center;
		line-height: 0.59rem;
		margin-left: 0.2rem;
		font-size: 0.3rem;
	}
	.ivu-modal-content .ivu-modal-body{
		padding: 0.59rem;
	}
	.ivu-modal-content .ivu-modal-footer{
		display: none;
	}
	.modalcancel{
		border: 0.02rem solid #000000;
		color: #1A1A1A;
	}
	.modalok{
		background: #C25E4D;
		color: #FFFFFF;
	}
	#app .main-page .foot{
		margin-left: calc( 9.25rem - 50vw );
	}

	@media screen and (min-width:1000px) {
		#app .show_pc {}

		#app .show_mb {
			display: none;
		}
		.ivu-carousel-arrow .ivu-icon{
			margin-top: 4px!important;
		}
		.ivu-icon-ios-arrow-forward:before {
			margin-left: 3px;
		}

		.ivu-carousel-arrow-hover {
			opacity: 1 !important;
		}
		#app .fontsize{
			font-size: 16px;
		}
	}
	@media screen and (min-width:1440px) {
		#app .main-page{
			max-width: 22.22rem;
		}
		.cart .cart-box .cart-txt{
			width: 12.51rem;
		}
		.cart .cart-bottom{
			max-width: 22.22rem;
		}
		.cart .cart-box .cart-img{
			margin:0 0.3rem 0 1rem;
		}
		.myreport .thereport{
			width: 10.6rem;
		}
		.addresslist .address-box{
			max-width: 22.22rem;
		}
		.addresslist .address-box .defaultaddress{
			width: 10.39rem;
		}
		.addressdetail .address-box{
			max-width: 22.22rem;
		}
		.addressdetail .newaddress-mainbox{
			width: 8.6rem;
		}
		.orderdetail .detail-info-left,.orderdetail .detail-info-right{
			width: 9.85rem;
		}
		.personal .personinfo-box{
			max-width: 22.22rem;
		}
		.home .show_pc .goqus-pc-btns{
			right: 1.8rem;
		}
		#app .main-weight{
			max-width: 22.22rem;
		}
		.settlement .orderdetail{
			max-width: 22.22rem;
		}
		.settlement .orderdetail .orderbox{
			width: 10rem;
		}
		#app .contactBox{
			max-width: 1200px;
		}
		#app .privacy-policy{
			max-width: 1200px;
		}
		#app .reportbox .reporttitle,#app .personinfo,#app .moduleinfo .report-detail{
			width: 100%;
		}
		#app .myreport-title,#app .reportlistbox,#app .orderdetail-box{
			padding: 0;
		}
		.orderdetail .orderdetail-top{
			background-size:100% auto;
		}
	}
	@media screen and (max-width:999px) {

		#app {
			padding-top: 1.4rem;
			padding-top: 72px;
		}

	.qusbottomheight{
		width: 100%;
		height: 1.8rem;
	}
		#app .show_pc {
			display: none;
		}

		#app .show_mb {}

		#app .main-page {
			width: 96%;
			margin: 0 auto;
		}

		#app .page-bg {
			background-image: none;
		}

		.ivu-icon-ios-arrow-forward:before {
			margin-left: 1px;
		}

		.ivu-carousel-arrow-hover {
			opacity: 1 !important;
		}

		.swiper1 .ivu-icon {
			/* margin: 12px; */
		}

		.ivu-carousel-arrow.left {
			z-index: 6;
		}

		#app .public-btn {
			font-size: 0.37rem;
		}

		#app .public-btn-css {
			font-size: 0.37rem;
		}
	}
	#app .module-margin{
		margin: 164px 0;
	}
	#app .small-module-margin{
		margin-top: 82px;
	}
	#app .line-margintop{
		margin-top: 16px;
	}
	#app .block-margintop{
		margin-top: 32px;
	}
	#app h1{
		font-size: 64px;
		line-height: 1;
		font-weight: bold;
	}
	#app h2{
		font-size: 56px;
		line-height: 1.2;
		font-weight: bold;
	}
	#app h3{
		font-size: 48px;
		line-height: 1.2;
		font-weight: bold;
	}
	#app h4{
		font-size: 40px;
		line-height: 1.2;
		font-weight: bold;
	}
	#app h5{
		font-size: 32px;
		line-height: 1.2;
		font-weight: bold;
	}

	@media screen and (max-width:1439px) {
		#app h3{
			line-height: 1;
		}
	}

	.meal-three-tabs .meal-pc-main-title-box .meal-pc-main-title p{
		font-size: 48px!important;
	}
</style>
