// import 'babel-polyfill'
// import promise from 'es6-promise'
// promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './util/api.js'
import axios from './util/axios.js'
axios.defaults.withCredentials = true
import qs from 'qs'
import Vant from 'vant'
import 'vant/lib/index.css'
import ViewUI from 'view-design'
Vue.use(ViewUI)
import 'view-design/dist/styles/iview.css'
Vue.use(Vant)

// import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI)
import { Cascader ,Scrollbar} from 'element-ui'
// import { ElScrollbar } from 'element-ui'
Vue.use(Cascader)
Vue.use(Scrollbar)

import alertmsg from '@/components/alertmsg.vue'
Vue.prototype.$alert = alertmsg
Vue.prototype.$ajax = axios
Vue.prototype.$qs = qs
Vue.prototype.API = api
Vue.prototype.showalert = false
Vue.mainshowalert = false

import { PullRefresh } from 'vant';
Vue.use(PullRefresh);

//this.$forceUpdate()  //赋值后页面没有改变

// //删除某个
// localStorage.removeItem("hou")

// // 删除所有
// localStorage.clear();

// sessionStorage.key(int index) //返回当前 sessionStorage 对象的第index序号的key名称。若没有返回null。

// sessionStorage.getItem(string key) //返回键名(key)对应的值(value)。若没有返回null。

// sessionStorage.setItem(string key, string value) //该方法接受一个键名(key)和值(value)作为参数，将键值对添加到存储中；如果键名存在，则更新其对应的值。

// sessionStorage.removeItem(string key) //将指定的键名(key)从 sessionStorage 对象中移除。

// sessionStorage.clear() //清除 sessionStorage 对象所有的项。

import 'swiper/css/swiper.min.css'
import 'swiper/js/swiper.min'

import 'lib-flexible/flexible.js'
Vue.config.productionTip = false
new Vue({
  // Vuetify,
  router,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if(to.meta.title == undefined || (to.meta.title != "" && to.meta.title.trim().length == 0) ){
      document.title = "NUTRIPACKS 橘袋";
  }
  next()
})

