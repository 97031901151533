<template>
	<div class="orderdetail main-page" v-if="detail&&logisticsdata">
		<div class="page-bg"></div>
		<div class="orderlist-title">
			订单详情
		</div>
		<div class="orderdetail-box">
			<div class="orderdetail-top">
				<div class="top-statu-box">
					<img v-if="detail.orderDetail.order_status==2" src="../../public/imgs/orderdetail-icon.png" alt="">
					<img v-else-if="detail.orderDetail.order_status==3" src="../../public/imgs/getit.png" alt="">
					<img v-else-if="detail.orderDetail.order_status==0" src="../../public/imgs/nopay.png" alt="">
					<img v-else-if="detail.orderDetail.order_status==-1" src="../../public/imgs/no.png" alt="">
					<img v-else-if="detail.orderDetail.order_status==5" src="../../public/imgs/refund.png" alt="">
					<img v-else src="../../public/imgs/orderdetail-icon.png" alt="">
					<div class="top-statu">{{detail.orderDetail.order_status_text}}</div>
				</div>
			</div>
			<div class="address-msg orderdetail-box-css">
				<div class="orderdetail-box-title">收货信息</div>
				<div class="order-address">
					<div class="order-address-name fontsize">收货人地址</div>
					<div class="order-address-value">
						<div class="order-address-txt fontsize">{{detail.memberAddress.province_name+' '+detail.memberAddress.city_name+' '+detail.memberAddress.area_name}}</div>
						<div class="order-address-detail fontsize">{{detail.memberAddress.address}}</div>
					</div>
				</div>
				<div class="order-address">
					<div class="order-address-name fontsize">收货人信息</div>
					<div class="order-address-value">
						<div class="order-address-txt fontsize">{{detail.memberAddress.name}} {{detail.memberAddress.phone.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')}}
							{{detail.memberAddress.id_card.replace(/^(\w{3})\w{11}(.*)$/, '$1******$2')}}</div>
					</div>
				</div>
			</div>
			<div class="orderdetail-info orderdetail-box-css">
				<div class="detail-info-left">
					<div class="orderdetail-box-title">商品信息</div>
					<div class="orderdetail-box-main orderdetail-box-main2">
						<div class="orderdetail-box-goods" v-for="(item,index) in detail.cart_list" :key="index">
							<div class="orderdetail-box-good">
								<img class="order-goodimg" :src="item.img" alt="">
								<div class="order-goodinfo">
									<div class="goodinfo-name">
										<div class="order-goods-name fontsize">{{item.name}}</div>
										<div class="order-goods-price fontsize">￥{{Number((item.total_price).toFixed(2))}}<s v-if="item.total_price<item.original_price">￥{{Number((item.original_price).toFixed(2))}}</s></div>
									</div>
									<div class="goodinfo-type">
										{{item.plan_title}}({{item.buy_days*item.num}}天)
									</div>
									<div class="goodinfo-num">
										<div class="order-goods-num">
											{{item.num}}套
										</div>
										<div class="order-goods-detail cursor" @click="item.showlist=!item.showlist">
											查看详情 <img :class="item.showlist==true?'morelist':''" src="../../public/imgs/chevron-down.png" alt="">
										</div>
									</div>
								</div>
							</div>
							<div class="orderdetail-box-goodlist" v-if="item.showlist==true">
								<div class="orderdetail-good-box" v-for="(items,indexs) in item.goods_info" :key="indexs">
									<img :src="items.index_img" alt="">
									<div class="orderdetail-good-info">
										<div class="goodinfo-name">
											<div class="order-goods-name">{{items.goods_name}}</div>
											<div class="order-goods-price">￥{{items.dayprice}}</div>
										</div>
										<div class="goodinfo-type">
											{{items.goods_num}}颗/天
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="center-line"></div>
				<div class="detail-info-right">
					<div class="orderdetail-box-title">物流信息</div>
					<div class="orderdetail-box-main orderdetail-box-main1">
						<div class="logistics"  v-if="detail.orderDetail.order_status==-1">
							<div class="logistics-time-this">
								<!-- {{item.time}} -->
							</div>
							<div class="logistics-step">
								<div class="logistics-circle-this"></div>
								<div class="logistics-line" v-if="logisticsdata.Logistics_obj.length>0"></div>
							</div>
							<div class="logistics-info">
								<!-- <div :class="index==0?'logistics-title-this':'logistics-title'"></div> -->
								<div class="logistics-txt-this">订单已取消</div>
							</div>
						</div>
						<div class="logistics" v-for="(item,index) in logisticsdata.Logistics_obj" :key="index">
							<div :class="index==0&&detail.orderDetail.order_status!=-1?'logistics-time-this':'logistics-time'">
								{{item.time}}
							</div>
							<div class="logistics-step">
								<div :class="index==0&&detail.orderDetail.order_status!=-1?'logistics-circle-this':'logistics-circle'"></div>
								<div class="logistics-line" v-if="index+1!=logisticsdata.Logistics_obj.length"></div>
							</div>
							<div class="logistics-info">
								<!-- <div :class="index==0?'logistics-title-this':'logistics-title'"></div> -->
								<div :class="index==0&&detail.orderDetail.order_status!=-1?'logistics-txt-this':'logistics-txt'">{{item.context}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="orderdetail-pay orderdetail-box-css">
				<div class="detail-info-left">
					<div class="orderdetail-box-title">支付信息</div>
					<div class="orderdetail-box-payinfo">
						<div class="payinfo-line">
							<div class="payinfo-left fontsize">商品小计</div>
							<div class="payinfo-center"></div>
							<div class="payinfo-right fontsize">￥{{detail.orderDetail.order_total}}</div>
						</div>
						<div class="payinfo-line">
							<div class="payinfo-left fontsize">关税服务 <img src="../../public/imgs/qus.png" alt="" @click="showgs=true"></div>
							<div class="payinfo-center"></div>
							<div class="payinfo-right fontsize">￥{{detail.orderDetail.gs}}</div>
						</div>
						<div class="payinfo-line">
							<div class="payinfo-left fontsize">运费</div>
							<div class="payinfo-center"></div>
							<div class="payinfo-right fontsize">{{detail.orderDetail.member_deliver_money=='0.00'?'免邮':'￥'+detail.orderDetail.member_deliver_money}}</div>
						</div>
						<div class="payinfo-line" v-if="detail.orderDetail.sale_discount!=0">
							<div class="payinfo-left fontsize">专业人士优惠</div>
							<div class="payinfo-center"></div>
							<div class="payinfo-right fontsize">-￥{{detail.orderDetail.sale_discount}}</div>
						</div>
						<div class="payinfo-line" v-for="(item,index) in detail.orderDetail.coupons" :key="index">
							<div class="payinfo-left fontsize">{{item.couponTitle}}</div>
							<div class="payinfo-center"></div>
							<div class="payinfo-right fontsize">-￥{{item.money}}</div>
						</div>
						<div class="payinfo-line" v-if="hastiyan">
							<div class="payinfo-left fontsize">体验装优惠</div>
							<div class="payinfo-center"></div>
							<div class="payinfo-right fontsize">-￥{{tiyannum}}</div>
						</div>
					</div>
					<div class="total-price">实际支付：￥{{detail.orderDetail.need_pay}}</div>
				</div>
				<div class="center-line"></div>
				<div class="detail-info-right">
					<div class="orderdetail-box-title">订单信息</div>
					<div class="orderdetail-box-orderinfo">
						<div class="orderinfo-line">
							<div class="orderinfo-left fontsize">订单编号 </div>
							<div class="orderinfo-right fontsize">
								{{detail.orderDetail.order_no}}
							</div>
						</div>
						<div class="orderinfo-line">
							<div class="orderinfo-left fontsize">创建时间 </div>
							<div class="orderinfo-right fontsize">
								{{detail.orderDetail.create_time}}
							</div>
						</div>
						<div class="orderinfo-line">
							<div class="orderinfo-left fontsize">货运单号 </div>
							<div class="orderinfo-right fontsize">
								{{logisticsdata.logisticCode?logisticsdata.logisticCode:'无'}}
								<div v-if="logisticsdata.logisticCode&&logisticsdata.logisticCode!='无'" class="copy" @click="copyid(logisticsdata.logisticCode?logisticsdata.logisticCode:'无')">复制单号</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-btns" v-if="detail.orderDetail.order_status==0||detail.orderDetail.order_status==2||detail.orderDetail.order_status==-1||detail.orderDetail.order_status==5">
			<div class="center-box">
				<div class="kefu public-btn-css cursor" v-if="detail.orderDetail.order_status==-1||detail.orderDetail.order_status==5" @click="delthis">删除订单</div>
				<div class="kefu public-btn-css cursor" v-if="detail.orderDetail.order_status==0" @click="cancel">取消订单</div>
				<!-- <div class="kefu public-btn-css">联系客服</div> -->
				<div class="kefu public-btn cursor" v-if="detail.orderDetail.order_status==2" @click="receiveOrder(detail.orderDetail.id)">确认收货</div>
				<div class="kefu public-btn cursor" v-if="detail.orderDetail.order_status==0" @click="payOrder(detail.orderDetail.id)">确认支付</div>
			</div>
		</div>
		<div class="code_url" v-if="showqr">
			<div class="qr-box">
				<div class="close-box">
					<div class="qrtitle">扫码支付</div>
					<img class="cursor" src="../../public/imgs/close.png" alt="" @click="goclose">
				</div>
				<div class="wxtxt"><img class="wicon" src="../../public/imgs/weixin-icon.png" alt="">请使用微信扫码支付</div>
				<img class="qrimg" :src="qr" alt="">
			</div>
		</div>

		<div class="gsbg" v-if="showgs" @click="showgs=false"></div>
		<div class="guanshui" v-if="showgs">
			<div class="gstitle"><div>税费说明</div><img @click="showgs=false" src="../../public/imgs/close.png" alt=""></div>
			<div class="gstxt">
				<p>1.关税服务费用由跨境电商综合税及服务费用组成。</p>
				<p>2.跨境电商综合税=（补剂营养品价格+清关服务费及运费）*9.1%；清关服务费起步金额为每单2元，如有特殊产品，如应急营养系列，则每10盒需加8元，不满10盒按10盒算。</p>
				<p>3.跨境电商综合税须按一般贸易增值税及消费税税额的70%征收，税费以提交订单时的金额为准。</p>
				<p>4.根据跨境电子商务零售进口税收政策，自2019年1月1日起，个人单笔交易限值人民币5000元，个人年度交易限值人民币26000元。</p>
			</div>
		</div>

		<Modal v-model="modal3" width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>取消确定</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>确认取消该订单吗？</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="noshowit">取消</div>
				<div class="modalok modalbtn cursor" @click="cancelOrder">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
		<Modal v-model="modal2" width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>删除确定</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>确认删除该订单吗？</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="noshowit">取消</div>
				<div class="modalok modalbtn cursor" @click="del">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
		<Modal v-model="modal4" width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>收货确定</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>确认收到货了吗？</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="noshowit">取消</div>
				<div class="modalok modalbtn cursor" @click="receive">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
		<Modal v-model="modal8" :mask-closable="false" :closable="false"  width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>支付未成功</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>请确认是否中断支付，或在订单页面重试。</p>
			</div>
			<div class="modalbtns">
				<!-- <div class="modalcancel modalbtn cursor">取消</div> -->
				<div class="modalok modalbtn cursor" @click="weixinnopay">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
	</div>
</template>
<script>
	if (navigator.userAgent.indexOf('AliApp') > -1) {
		document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>');
	}
</script>
<script>
	var therepaytime = ''
	export default {
		name: 'orderdetail',
		components: {},
		data: () => ({
			info: '',
			showqr:false,
			showgs:false,
			detail: '',
			logisticsdata: '',
			modal2:false,
			hastiyan:false,
			tiyannum:0,
			modal3:false,
			modal4:false,
			modal8:false,
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		mounted() {
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				//console.log('用户未登录')
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login'
				})
			} else {
				this.info = info
				if (this.$route.query.id) {
					this.orderid = this.$route.query.id
					this.OrderDetail(this.$route.query.id)
					this.getLogistics(this.$route.query.id)
				}
			}
		},
		methods: {
			goclose() {
				this.showqr = false
				clearInterval(therepaytime)
			},
			OrderDetail(id) {
				var that = this
				//订单详情
				this.$ajax.post(this.API.api.apiurl.OrderDetail, {
						order_id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						//console.log(res)
						var data = res.data.data
						if (res.data.code == 0) {
							var detail = data
							var cart_list = detail.cart_list
							var orderDetail = detail.orderDetail
							for (var i = 0; i < cart_list.length; i++) {
								cart_list[i].showlist = false
								var glist = cart_list[i].goods_info
								if(cart_list[i].buy_id==14){
									//console.log('体验装')
									that.hastiyan = true
									that.tiyannum = cart_list[i].total_price
								}
								glist.forEach((item, index) => {
									glist[index].dayprice = Number(item.goods_num * item.goods_price).toFixed(2)
								})
								cart_list[i].goods_info = glist
							}
							orderDetail.need_pay = Number(Number(orderDetail.need_pay).toFixed(2))
							orderDetail.order_total = Number(Number(orderDetail.order_total).toFixed(2))
							orderDetail.yun = Number(orderDetail.need_pay - orderDetail.order_total - orderDetail.tariff).toFixed(2)
							orderDetail.gs = Number(Number(Number(orderDetail.tariff)+Number(orderDetail.other_price)+Number(orderDetail.customs_price)).toFixed(2))
							// orderDetail.gs = Number((((Number(orderDetail.need_pay)*1000+orderDetail.coupon_discount*1000+orderDetail.member_discount*1000+orderDetail.sale_discount*1000+Number(orderDetail.share_discount)*1000-Number(orderDetail.order_total)*1000-orderDetail.yun*1000)/100).toFixed(1)/10).toFixed(2))
							detail.orderDetail = orderDetail
							detail.cart_list = cart_list
							that.detail = detail
							//console.log(detail)
						}else if(res.data.code == 118){
							that.$router.replace({path:'404'})
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			noshowit(){
				this.modal2 = false
				this.modal4 = false
				this.modal3 = false
			},
			cancel(){
				//取消订单
				var that = this
				that.modal3 = true
			},
			cancelOrder() {
				var id = this.detail.orderDetail.id
				var that = this
				//取消未支付订单
				this.$ajax.post(this.API.api.apiurl.cancelOrder, {
						order_id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log(data)
							that.$Message.info('取消成功');
							that.$router.go(-1)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			delOrder(id) {
				var that = this
				//删除订单
				this.$ajax.post(this.API.api.apiurl.delOrder, {
						order_id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							that.$Message.info('删除成功');
							that.$router.go(-1)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			receiveOrder(id) {
				//完成订单（确认收货）
				var that = this
				that.modal4 = true
				that.receiveid = id
			},
			receive() {
				this.modal4 = false
				var id = this.receiveid
				var that = this
				//完成订单（确认收货）
				this.$ajax.post(this.API.api.apiurl.receiveOrder, {
						order_id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							that.$Message.info('收货成功');
							that.$router.go(-1)
						} else {
							that.$Message.info('错误：' + res.data.message)
							location.reload()
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getLogistics(id) {
				var that = this
				//物流轨迹查询
				this.$ajax.post(this.API.api.apiurl.getLogistics, {
						order_id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						var data = res.data
							//console.log(data)
							var logisticsdata = data.data
							var list = ''
						if(data.code == 0) {
							list = logisticsdata.Logistics_obj
							logisticsdata.Logistics_obj = list.reverse()
							that.logisticsdata = logisticsdata
						}else if(data.code == 1) {
							list = logisticsdata.Logistics_obj
							logisticsdata.Logistics_obj = list.reverse()
							that.logisticsdata = logisticsdata
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			copyid(e) {
				//console.log(e)
				if(e=='无'){
					this.$Message.info('暂无运单号');
				}else{
					var _input = document.createElement("input"); // 直接构建input
					_input.value = e; // 设置内容
					document.body.appendChild(_input); // 添加临时实例
					_input.select(); // 选择实例内容
					document.execCommand("Copy"); // 执行复制
					document.body.removeChild(_input);
					this.$Message.info('复制成功');
				}
			},
			payOrder(e) {
				var that = this
				if(localStorage.getItem('way')=='pc'){
					that.gopay(e,3)
				}else if(localStorage.getItem('way')=='weixin'){
					that.gopay(e,4)
				}else{
					if(that.isAlipay) {
							that.gopay(e,5)
						} else {
							that.gopay(e,2)
						}
				}
			},
			gopay(e,tp) {
				//console.log(e)
				this.littelbtn = true
				//确认支付
				var that = this
				var pay_type = localStorage.getItem('way')
				//console.log(tp)
				this.$ajax.post(this.API.api.apiurl.pay, {
						order_id: e,
						member_id: that.info.member_id,
						pay_type: tp
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							if (tp == 3) {
								var qr = data.data.returnData.qr_url
								that.checkOrder(data.data.orderId)
								that.qr = qr
								that.showqr = true
							} else if (tp == 1) {
								var returnData = data.data
								var wurl = window.location.href
								//console.log(returnData.returnData)
								if(pay_type=='h5'){
									var url = 'https://wx.tenpay.com/cgi-bin/mmpayweb-bin/checkmweb?prepay_id=' + returnData.returnData.prepay_id +
										'&amp;package=' + returnData.returnData.package + '&amp;redirect_url=' + wurl
									//console.log(url)
									window.location.href = url
								}else{
									if (typeof WeixinJSBridge == "undefined") {
										if (document.addEventListener) {
											document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(returnData.returnData), false);
										} else if (document.attachEvent) {
											document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(returnData.returnData));
											document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(returnData.returnData));
										}
									} else {
										that.onBridgeReady(returnData.returnData);
									}
								}
								that.checkOrder(data.data.orderId)
							} else if (tp == 2) {
								var rdata = data.data
								//console.log(rdata)
								// var rurl = window.location.href
								var zurl = rdata.returnData.payinfo

								that.$router.push({
									path: 'paid',
									query: {
										id: data.data.orderId,
									}
								})
								setTimeout(function(){
									window.location.href = zurl
								},2000)
							}else if(tp==4){
								//console.log(data.data)
								that.H5params = data.data.returnData
								that.weixinpay()
							}else if(tp == 5) {
										console.log('创建订单接口',data.data.returnData)
									console.log('传输数据到支付宝小程序',my)
											my.postMessage({
												data: {
													action: 'startPayment',
													orderInfo: data.data.returnData // 传递支付参数
												}
											});
											that.$router.replace({
												path: 'paid',
												query: {
													id: data.data.orderId
												}
											})
									}
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						that.$Message.info(error)
					})
			},
			// 拉起微信h5支付
			weixinpay(){
				let _H5params = this.H5params
				//console.log(this.H5params)
				var z = 'https://syb.allinpay.com/apiweb/h5unionpay/unionorder'
				// let _url   = this.API.api.apiurl.zhifuurl + "?";
				let _url   = z + "?";
				_url=  _url + "cusid="+ _H5params.cusid;
				_url=  _url + "&appid="+ _H5params.appid;
				_url=  _url + "&version="+ _H5params.version;
				_url=  _url + "&trxamt="+ _H5params.trxamt;
				_url=  _url + "&reqsn="+ _H5params.reqsn;
				_url=  _url + "&charset="+ _H5params.charset;
				_url=  _url + "&returl="+ _H5params.returl;
				_url=  _url + "&notify_url="+ _H5params.notify_url;
				_url=  _url + "&body="+ _H5params.body;
				_url=  _url + "&remark="+ _H5params.remark;
				_url=  _url + "&randomstr="+ _H5params.randomstr;
				_url=  _url + "&validtime="+ _H5params.validtime;
				_H5params.limit_pay?_url=  _url + "&limit_pay="+ _H5params.limit_pay:'';
				_url=  _url + "&sign="+ _H5params.sign
				// //console.log(_url)
				// window.open(_url );
				window.location.href=_url;
			},
			onBridgeReady(e) {
				var that = this
				window.WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId": e.appId, //公众号名称，由商户传入
						"timeStamp": e.timeStamp, //时间戳，自1970年以来的秒数
						"nonceStr": e.nonceStr, //随机串
						"package": e.package,
						"signType": e.signType, //微信签名方式：
						"paySign": e.paySign //微信签名
					},
					function(res) {
						////console.log(res)
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						}else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
							that.modal8 = true
						} else if (res.err_msg == 'get_brand_wcpay_request:fail') {
							that.$Message.info('失败')
						}
					});
			},
			weixinnopay(){
				this.$router.push({
					path: 'order-list',
				})
			},
			checkOrder(e) {
				//console.log(e)
				var that = this
				therepaytime = setInterval(function() {
					//获取报告推荐产品
					that.$ajax.post(that.API.api.apiurl.checkOrder, {
							// count_id:that.count_id,
							order_id: e,
						member_id: that.info.member_id,
						},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
						.then(res => {
							////console.log(res)
							if (res.data.code == 0) {
								//console.log(res.data)
								// alert('支付完成，跳转页面（暂未开发）')
								if (res.data.data.order_status == 1||res.data.data.order_status == 11 ||res.data.data.order_status == 12) {
									clearInterval(therepaytime)
									// location.reload()
									// that.$router.go(0)
									that.$router.push({
										path: 'paid',
										query: {
											id: e
										}
									})
								}
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}, 1000)
			},
			//删除

			delthis() {
				//删除订单
				var that = this
				that.modal2 = true
			},
			del(){
				//console.log('确认删除')
				this.modal2 = false

				var that = this
				this.$ajax.post(this.API.api.apiurl.delOrder, {
						order_id: that.detail.orderDetail.id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							that.$router.go(-1)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getit(){

			}
		}
	}
</script>

<style>
	.bottom-btns {
		width: 100%;
		width: 100vw;
		position: fixed;
		bottom: 0;
		left: 0;
		height: 2.07rem;
		background: #FFFFFF;
		box-shadow: 0rem -0.15rem 0.3rem 0rem rgba(102, 102, 102, 0.1);
		z-index: 5;
	}

	.orderdetail {
		padding-bottom: 2.2rem;
	}

	.orderdetail .page-bg {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: #FFFFFF;
	}

	.center-box {
		width: 100%;
		max-width: 18.5rem;
		height: 2.07rem;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
	}

	.center-box div {
		margin: 0.48rem 0;
	}

	.center-box .public-btn-css {
		margin-left: 0.27rem;
		margin-right: 0.27rem;
		border-radius: 1.11rem;
		border: 0.04rem solid #333333;
	}

	.orderdetail-box {
		width: 100%;
		height: 100%;
		padding: 0 0.19rem;
	}

	.orderdetail-top {
		width: 100%;
		height: 2.22rem;
		background: url(../../public/imgs/orderdetailbg.png);
		background-repeat: no-repeat;
		background-size: auto 100%;
		padding: 0.5rem 0;
		border-radius: 0.29rem;
	}

	.top-statu-box {
		width: auto;
		height: 1.22rem;
		margin: 0 auto;
	}

	.top-statu-box img {
		width: 0.41rem;
		height: 0.41rem;
		margin: 0 auto;
	}

	.top-statu {
		width: auto;
		height: 0.52rem;
		line-height: 0.52rem;
		margin-top: 0.29rem;
		font-size: 0.37rem;
		text-align: center;
		color: #FFFFFF;
	}

	.orderdetail-box-css {
		width: 100%;
		height: auto;
		padding: 0.37rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		border: 0.02rem solid #D5DDE6;
		margin-top: 0.37rem;
	}

	.orderdetail-box-title {
		width: 100%;
		height: 0.41rem;
		line-height: 0.41rem;
		color: #1A1A1A;
		font-size: 0.34rem;
		margin-bottom: 0.37rem;
		text-align: left;
		font-weight: bold;
	}

	.order-address {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: left;
	}

	.order-address-name {
		width: 2.3rem;
		height: 0.49rem;
		line-height: 0.49rem;
		font-size: 0.26rem;
		color: #666666;
		text-align: left;
	}

	.order-address-value {
		width: calc( 100% - 2.45rem );
		height: auto;
		margin-left: 0.15rem;
	}

	.order-address-txt {
		height: auto;
		line-height: 0.49rem;
		font-size: 0.26rem;
		color: #1A1A1A;
		text-align: left;
	}

	.order-address-detail {
		font-size: 0.3rem;
		height: 0.37rem;
		line-height: 0.37rem;
		color: #1A1A1A;
		margin-bottom: 0.22rem;
		text-align: left;
	}

	.orderdetail-info,
	.orderdetail-pay {
		width: 100%;
		height: 8.39rem;
		display: flex;
		justify-content: space-between;
	}

	.orderdetail-pay {
		height: auto;
		min-height: 5.46rem;
	}

	.detail-info-left,
	.detail-info-right {
		width: 7.85rem;
		height: 100%;
	}
	.orderdetail-pay .center-line {
		min-height:5.46rem ;
	}

	.center-line {
		width: 0.02rem;
		height: 100%;
		background: rgba(213, 221, 230, 0.3);
	}

	.orderdetail-box-main {
		width: 100%;
		height: calc(100% - 0.78rem);
		overflow: hidden;
		overflow-y: scroll;
		/* -ms-overflow-style: none;
		scrollbar-width: none; */
	}

	.orderdetail-box-main2::-webkit-scrollbar {
		display: none
	}
	.orderdetail-box-main1{
		overflow: hidden;
		overflow-y: scroll;
	}
	.orderdetail-box-main1::-webkit-scrollbar {
		/* display: none */
		width:10px;
		height:9px;
	}
	.orderdetail-box-main1::-webkit-scrollbar-thumb {
		background-color:#dddddd;
		background-clip:padding-box;
		min-height:28px;
	}

	.orderdetail-box-goods {
		width: 100%;
		height: auto;
		margin-bottom: 0.22rem;
	}

	.orderdetail-box-good {
		width: 100%;
		height: 2.05rem;
		border-bottom: 0.02rem solid #D5DDE6;
		display: flex;
		justify-content: space-between;
	}

	.order-goodimg {
		width: 1.83rem;
		height: 1.83rem;
		border-radius: 0.19rem;
	}

	.order-goodinfo {
		width: 5.59rem;
		height: 1.83rem;
	}

	.goodinfo-name {
		width: 100%;
		height: 0.41rem;
		display: flex;
		justify-content: space-between;
		line-height: 0.41rem;
		margin-top: 0.11rem;
	}

	.order-goods-name {
		font-size: 0.26rem;
		color: #1A1A1A;
	}

	.order-goods-price {
		font-size: 0.3rem;
		color: #1A1A1A;
	}

	.order-goods-price s{
		font-size: 0.26rem;
		color: #999999;
		margin-left: 0.2rem;
	}

	.goodinfo-type {
		width: 100%;
		height: 0.37rem;
		line-height: 0.37rem;
		margin-top: 0.15rem;
		text-align: left;
		color: #666666;
		font-size: 0.26rem;
	}

	.goodinfo-num {
		width: 100%;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #1A1A1A;
		margin-top: 0.24rem;
		display: flex;
		justify-content: space-between;
	}

	.orderdetail-box-goods .goodinfo-name {
		font-weight: bold;
	}

	.order-goods-num {
		font-weight: bold;
	}

	.orderdetail-box-goodlist {
		width: 100%;
		height: auto;
	}

	.orderdetail-good-box {
		width: calc(100% - 0.63rem);
		height: 1.13rem;
		display: flex;
		justify-content: space-between;
		margin-left: 0.63rem;
		margin-top: 0.2rem;
	}

	.orderdetail-good-box img {
		width: 1.13rem;
		height: 1.13rem;
	}

	.orderdetail-good-info {
		width: calc(100% - 1.37rem);
		height: 0.83rem;
	}

	.orderdetail-good-info .goodinfo-type {
		color: #666666;
		font-size: 0.22rem;
	}

	.orderdetail-good-info .goodinfo-name {
		font-size: 0.22rem;
		font-weight: 450;
	}

	.logistics {
		width: 100%;
		height: 1.19rem;
		display: flex;
		justify-content: left;
	}

	.logistics .logistics-time-this {
		width: 1.3rem;
		height: 0.63rem;
		line-height: 0.31rem;
		font-size: 0.22rem;
		text-align: right;
		margin-right: 0.3rem;
		color: #1A1A1A;
	}

	.logistics .logistics-time {
		width: 1.3rem;
		height: 0.63rem;
		line-height: 0.31rem;
		color: #CCCCCC;
		font-size: 0.22rem;
		text-align: right;
		margin-right: 0.3rem;
		margin-top: 0.08rem;
	}

	.logistics-step {
		width: 0.44rem;
		height: 1.19rem;
		margin-right: 0.15rem;
	}

	.logistics-info {
		width: calc(100% - 2.15rem);
		height: 1.19rem;
	}

	.logistics-info .logistics-title-this,
	.logistics-info .logistics-txt-this {
		color: #1A1A1A;
		width: 100%;
		height: 0.37rem;
		line-height: 0.37rem;
		font-size: 0.26rem;
		text-align: left;
	}

	.logistics-info .logistics-title,
	.logistics-info .logistics-txt {
		width: 100%;
		height: 0.37rem;
		line-height: 0.37rem;
		font-size: 0.26rem;
		color: #CCCCCC;
		text-align: left;
	}

	.logistics-info .logistics-txt-this,
	.logistics-info .logistics-txt {
		height: 0.74rem;
		overflow: hidden;
	}
	.logistics-info .logistics-txt{
		margin-top: 0.18rem;
	}

	.logistics-info .logistics-title {
		font-weight: bold;
	}

	.logistics-step .logistics-circle-this {
		width: 0.44rem;
		height: 0.44rem;
		background: #C25E4D;
		/* background: url(../../public/imgs/orderdetail-icon1.png); */
		border-radius: 0.44rem;
	}

	.logistics-step .logistics-circle {
		width: 0.3rem;
		height: 0.3rem;
		background: #D8D8D8;
		border-radius: 0.3rem;
		margin: 0 auto;
		margin-top: 0.2rem;
	}

	.logistics-step .logistics-line {
		width: 0.04rem;
		height: 1.19rem;
		background: #D8D8D8;
		margin: 0 auto;
	}

	.orderdetail-box-payinfo {
		width: 100%;
		height: auto;
	}

	.payinfo-line {
		width: 100%;
		height: 0.48rem;
		display: flex;
		justify-content: space-between;
		line-height: 0.48rem;
		font-size: 0.26rem;
		margin-bottom: 0.22rem;
		position: relative;
	}

	.payinfo-left {
		width: auto;
		height: 0.48rem;
		text-align: left;
		color: #666666;
		background: #FFFFFF;
		padding-right: 0.19rem;
		z-index: 1;
	}

	.payinfo-left img{
		width: 0.37rem;
		height: 0.37rem;
	}

	.payinfo-center {
		color: #666666;
		height: 0.01rem;
		width: 100%;
		position: absolute;
		top: 0.24rem;
		border-top: 0.02rem dashed #666666;
		z-index: 0;
	}

	.payinfo-right {
		width: auto;
		text-align: right;
		color: #1A1A1A;
		background: #FFFFFF;
		padding-left: 0.19rem;
		z-index: 1;
	}

	.total-price {
		width: 100%;
		height: 0.41rem;
		display: flex;
		justify-content: flex-end;
		font-size: 0.3rem;
		color: #1A1A1A;
		font-weight: bold;
	}

	.orderinfo-line {
		width: 100%;
		height: 0.48rem;
		line-height: 0.48rem;
		text-align: left;
		margin-bottom: 0.22rem;
		font-size: 0.26rem;
		display: flex;
		justify-content: left;
	}

	.orderinfo-left {
		width: 1.56rem;
		color: #666666;
	}

	.orderinfo-right {
		width: calc(100% - 1.56rem);
		display: flex;
		justify-content: left;
		color: #1A1A1A;
	}

	.copy {
		width: auto;
		height: 0.48rem;
		line-height: 0.48rem;
		margin-left: 0.37rem;
		cursor: pointer;
		color: #C25E4D;
	}
	.morelist{
		transform:rotate(180deg);
		-ms-transform:rotate(180deg); 	/* IE 9 */
		-moz-transform:rotate(180deg); 	/* Firefox */
		-webkit-transform:rotate(180deg); /* Safari 和 Chrome */
		-o-transform:rotate(180deg); 	/* Opera */
	}


	@media screen and (max-width:1000px) {
		.orderdetail-info, .orderdetail-pay{
			height: auto;
			justify-content: center;
			flex-wrap: wrap;
		}
		.detail-info-right{
			margin-top: 0.59rem;
		}
		.center-box{
			justify-content: center;
		}
		.orderdetail-good-box{
			width: calc( 100% - 0.37rem );
			margin-left: 0.37rem;
		}
		.orderdetail-box-goods{
			margin-bottom: 0.59rem;
		}
		.orderdetail-pay .center-line{
			display: none;
		}
	}
</style>
