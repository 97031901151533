<template>
	<div ref="detailmain" class="detail main-page" v-if="reportdata&&countinfo">
		<div class="page-bg"></div>
		<div class="reportdetail-top">
			<div class="detail-title">
				<div class="detail-name"><div class="name-top">{{reportdata.count_info.nickname}}</div>的定制健康方案</div>
				<div class="detail-msg">{{countinfo.count_info.create_date}}</div>
				<div class="icons">
					<img :src="item" alt="" :key="'key' + icon" v-for="(item,icon) in reportdata.count_info.recommend_class">
				</div>
			</div>
		</div>
		<div class="day-pro detailmainbox">
			<div class="box-title"><div class="box-title-top">方案内容一</div><div class="box-title-bottom">你的每日补剂：<span>{{reportdata.select_re.length}}种</span></div></div>
			<div class="pros-line">
				<div class="daypro-box" :key="'key1' + i" v-for="(item,i) in reportdata.select_re" @click="getdetail(item)">
					<div class="daypro-img">
						<img :src="item.goods_img" alt="">
					</div>
					<div class="daypro-name fontsize">{{item.goods_name}} ×{{item.re_day_use}}</div>
					<div class="daypro-msg">{{item.index_desc}}</div>
					<a class="daypro-btn cursor" @click="goprodetail(item.goods_id)">
						查看品质 <img src="../../public/imgs/more.png" alt="">
					</a>
				</div>
			</div>
		</div>
		<div class="adviser detailmainbox">
			<div class="box-title"><div class="box-title-top">方案内容二</div><div class="box-title-bottom">专属健康顾问服务 <div class="box-title-right">限时免费</div></div></div>
			<div class="advisermain">
				<div class="adviserinfo">
					<img :src="reportdata.bind_team.img" alt="">
					<div class="advisermaininfo">
						<div class="advisermsg">坚持服用补剂 + 专业的营养指导，更有效的改善你的健康问题。购买方案后，我们会免费为你配置一名专属健康顾问 VC*</div>
						<div class="adviserway">*Verified Consultant 认证顾问，由国家认证注册/临床营养师担任</div>
					</div>
				</div>
				<div class="advisertitle"><img src="../../public/imgs/true.png" alt="">可帮助改善以下问题</div>
				<div class="helpbox">
					<div class="help-bar" :key="'key2' + bar" v-for="(item , bar) in countinfo.tag_list"><img :src="item.demand_icon.thumb_url" alt="">
						<div class=" fontsize" v-html="item.tag.length==0?'帮助你解决<span>'+item.demand_name+'</span>的问题':item.demand_name+':'+item.tag.join(',')"></div>
					</div>
					<!-- <div class="help-bar"><img src="../../public/imgs/true.png" alt="">帮助你解决消化的问题</div>
					<div class="help-bar"><img src="../../public/imgs/true.png" alt="">帮助你解决眼睛的问题</div> -->
				</div>
				<div class="advisertitle"><img src="../../public/imgs/true.png" alt="">持续跟进提供健康优化方案</div>
				<div class="txt fontsize">除协助改善你关心的健康问题外，他还会持续提供包括跟踪你的健康状态变化、提供个性化补剂优化方案、体重管理、科学护肤、疾病干预、就医指导和建立个人及家庭档案等多种健康服务。</div>
				<div class="advisertitle"><img src="../../public/imgs/true.png" alt="">服务流程</div>
				<div class="adviser-progress show_pc">
					<div class="step">
						<div class="step-top">
							<div class="stepnum">1</div>
							<div class="stepline"></div>
						</div>
						<div class="stepinfo">
							<div class="stepname fontsize">预约电话咨询</div>
							<div class="stepmsg">了解你的身体状况，建立个人健康档案</div>
						</div>
					</div>
					<div class="step">
						<div class="step-top">
							<div class="stepnum">2</div>
							<div class="stepline"></div>
						</div>
						<div class="stepinfo">
							<div class="stepname fontsize">陪伴式跟踪管理</div>
							<div class="stepmsg">解答你的健康顾虑，跟进体验感受及身体状态变化，指导你改善不良生活饮食习惯。</div>
						</div>
					</div>
					<div class="step">
						<div class="step-top">
							<div class="stepnum">3</div>
						</div>
						<div class="stepinfo">
							<div class="stepname fontsize">健康Pro 报告</div>
							<div class="stepmsg">一个月体验完成后，根据健康数据反馈，深度分析制定你的个人健康评估报告。</div>
						</div>
					</div>
				</div>
				<div class="show_mb">
					<Steps direction="vertical">
						<Step title="预约电话咨询" content="了解你的身体状况，建立个人健康档案"></Step>
						<Step title="陪伴式跟踪管理" content="解答你的健康顾虑，跟进体验感受及身体状态变化，指导你改善不良生活饮食习惯。"></Step>
						<Step title="健康Pro 报告" content="一个月体验完成后，根据健康数据反馈，深度分析制定你的个人健康评估报告。"></Step>
					</Steps>
				</div>
				<div class="contact">
					<div class="contacttxt"><img src="../../public/imgs/wx.png" alt="">添加微信 - 口袋式陪伴指导，不懂就问，随时回答</div>
					<div class="contacttxt"><img src="../../public/imgs/orde.png" alt="">持续跟踪你的健康状态变化 持续更新你的健康档案库</div>
				</div>
			</div>
		</div>
		
		<div class="lookdetail" v-if="showdetail">
			<div class="lookbg"></div>
			<div class="lookbox">
				<div class="look-title"></div> 
				<div class="look-list">
					<div class="prodetail-imgs">
						<img :src="prodetail.goods_img_text.img1" alt="">
					</div>
					<div class="function-list">
						<div class="function-box" v-for="(item,index) in prodetail.goods_img_text.function" :key="'key3' + index">
							<img :src="item.img" alt="">
							<div class="function-txts">
								<div class="function-title">{{item.title}}</div>
								<div class="function-msg">
									<span v-for="(items,indexs) in item.label" :key="'key4' + indexs">{{items}}{{indexs+1==item.label.length?'':' | '}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="advantage-list">
						<div class="advantage-box"  v-for="(item,indexl) in prodetail.goods_img_text.advantage" :key="'key5' + indexl">
							<div class="advantage-title">{{item.title}}</div>
							<div class="advantage-txt">
								<div class="advantage-border"></div>
								<div class="advantage-msg">{{item.describe}}</div>
							</div>
						</div>
					</div>
					<div class="prodetail-imgs">
						<img :src="prodetail.goods_img_text.img2" alt="">
					</div>
					<div class="hbg">
						<div class="pro-info-title">
							<div class="title-l-l1"></div>
							<div class="title-l-l2"></div>
							<div class="pro-info-box">
								<div class="pro-info-name">产品信息</div>
								<div class="pro-info-msg">Product Information</div>
							</div>
							<div class="title-r-r2"></div>
							<div class="title-r-r1"></div>
						</div>
						<div class="pro-infobg-box">
							<div class="infobg-title">
								<div class="t">{{prodetail.goods_detail.goods_name}}</div>
								<div>{{prodetail.goods_detail.eng_name}}</div>
							</div>
							<div class="infobg-cats">
								<img :src="item.url" alt="" v-for="(item,indexc) in prodetail.goods_detail.cat" :key="'key6' + indexc">
							</div>
							<div class="goods_label-box">
								<div class="goods_label" v-for="(item,indexg) in prodetail.goods_detail.goods_label" :key="'key7' + indexg">
									<img :src="item.img_url" alt="">
									<div>{{item.title}}</div>
								</div>
							</div>
							<div class="redayuse">
								<span class="b">每日营养推荐量</span>
								<span>Suggested Use</span>
							</div>
							<div class="day-use-box">
								<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/pill.png" alt="" v-for="(item,indexd) in prodetail.goods_detail.day_use" :key="'key8' + indexd">
								<div class="day-use-num">{{prodetail.goods_detail.day_use}}{{prodetail.goods_detail.min_unit}}</div>
							</div>
							
							<div class="bg-title">
								<span class="b">营养标签</span><span>Supplement Facts</span>
							</div>
							<div class="goods_unit-box">
								<div class="goods_unit-title">
									<div class="goods_unit-name">
										<div>每份含成分({{prodetail.goods_detail.day_use}}颗)</div>
										<div>Amount Per Serving</div>
									</div>
									<div class="goods_unit-num">
										<div>每日营养摄入量百分比</div>
										<div>% Daily Value</div>
									</div>
								</div>
								<div class="goods_unit-line" :class="index+1==prodetail.goods_detail.goods_unit.length?'bborder':''" v-for="(item,indext) in prodetail.goods_detail.goods_unit" :key="'key9' + indext">
									<div class="goods_unit-name">
										<div>{{item.unit_name}}</div>
									</div>
									<div class="goods_unit-num">
										<div>{{item.unit_size}}{{item.unit}}</div>
									</div>
									<div class="goods_unit-x">
										<div>{{item.unit_percentage}}</div>
									</div>
								</div>
								<div class="goods_unit-line bborder" v-if="prodetail.goods_detail.other_component">
									<!-- <div class="goods_unit-name"> -->
										<div>其他成分:{{prodetail.goods_detail.other_component}}</div>
									<!-- </div> -->
								</div>
							</div>
							<div class="exclusive">
								没有添加{{prodetail.goods_detail.exclusive}}
							</div>
							<div class="gochecklist" @click="gounitdetail(prodetail.goods_detail.id)">
								<div>100%透明单粒成分表</div>
								<img src="../../public/imgs/more.png" alt="">
							</div>
						</div>
						<div class="pro-info-title">
							<div class="title-l-l1"></div>
							<div class="title-l-l2"></div>
							<div class="pro-info-box">
								<div class="pro-info-name">注意事项</div>
								<div class="pro-info-msg">Cautions</div>
							</div>
							<div class="title-r-r2"></div>
							<div class="title-r-r1"></div>
						</div>
						<div class="populationlist">
							<div class="population-line">
								<div class="population-title">
									<img src="../../public/imgs/pro/ren.png" alt="">
									<div>建议人群</div>
								</div>
								<div class="population-txt">
									{{prodetail.goods_img_text.suggested_population}}
								</div>
							</div>
							<div class="population-line">
								<div class="population-title">
									<img src="../../public/imgs/pro/jianyi.png" alt="">
									<div>服用建议</div>
								</div>
								<div class="population-txt">
									{{prodetail.goods_img_text.advice_on_taking}}
								</div>
							</div>
							<div class="population-line">
								<div class="population-title">
									<img src="../../public/imgs/pro/jin.png" alt="">
									<div>禁忌人群</div>
								</div>
								<div class="population-txt">
									{{prodetail.goods_img_text.taboo_population}}
								</div>
							</div>
							<div class="population-line">
								<div class="population-title">
									<img src="../../public/imgs/pro/tips.png" alt="">
									<div>特别注意</div>
								</div>
								<div class="population-txt">
									{{prodetail.goods_img_text.points_for_attention}}
								</div>
							</div>
						</div>
						<div class="prodetail-imgs">
							<img :src="prodetail.goods_img_text.img3" alt="">
						</div>
						<div class="yanjiu">
							<div class="yan-title">
								<div class="yan-name"><span class="b">相关研究</span><span>Related Research</span></div>
								<img :src="prodetail.goods_img_text.img5" alt="">
							</div>
							<div class="yan-list">
								<div class="yan-line" v-for="(item,indexp) in prodetail.report_list" :key="'key10' + indexp">
									<div class="yan-line-title">{{item.title}}</div>
									<div class="yan-line-txt">{{item.intro}}</div>
									<div class="yan-detail" @click="goreportdetail(item.id)">
										<div>详情</div>
										<img src="../../public/imgs/pro/more.png" alt="">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="buy-box">
			<div class="buy-btns">
				<div class="main-weight">
					<div class="day-price">
						<div class="day-num">每日 <span>{{reportdata.select_re.length}}</span> 种</div>
						<div class="day-money">￥{{price}}/天</div>
					</div>
					<div class="actions-btns">
						<!-- <div class="shopcar" @click="addcart">加入购物车</div> -->
						<a class="buythis public-btn" @click="buythiss">购买方案</a>
					</div>
				</div>
			</div>
		</div>
		<Drawer :closable="false" :width="bodywidth" v-model="value1">
			<div class="chose-box">
				<div class="chose-title">
					<img src="../../public/imgs/goback.png" alt="" @click="goclosechou"><span>选择你的方案</span>
				</div>
				<div class="type-list">
					<div class="type-btn" :class="item.action==1?'action':''" :key="'key11' + iindex" v-for="( item , iindex ) in buyplan" @click="thisplan(iindex)">
						<img :src="item.thumb_url" alt="">
						<div class="type-txt theprice" :class="item.canspecial==0?'hui':''">
							<div class="type-name">{{item.title}}({{item.days}}天)</div>
							<div class="type-price"><span class="chose-price" :class="item.canspecial==1?'':'canno'">￥{{item.planprice}}</span><span class="lastprice" v-if="item.lastplanprice!=item.planprice">￥{{item.lastplanprice}}</span></div>
							<div class="tips tips1" v-if="item.plan_brief&&item.canspecial==1&&item.is_special==2">
								<div class="tips-msg">{{item.plan_brief}}</div>
								<div class="tipbottom"></div>
							</div>
							<div class="cannospecial" v-if="item.canspecial==0">每日方案价格{{item.price_limit}}元以上即可享受</div>
						</div>
						<!-- <div class="experience buy-btn">
							<span class="name" :class="item.canspecial==1?'':'canno'">{{item.title}}({{item.days}}天)</span>
							<div class="theprice">
								<span class="chose-price" :class="item.canspecial==1?'':'canno'">￥{{item.planprice}}</span>
								<span class="lastprice" v-if="item.lastplanprice!=item.planprice">￥{{item.lastplanprice}}</span>
								<div class="tips tips1" v-if="item.plan_brief&&item.canspecial==1&&item.is_special==2">
									<div class="tips-msg">{{item.plan_brief}}</div>
									<div class="tipbottom"></div>
								</div>
							</div>
							<div class="cannospecial" v-if="item.canspecial==0">每日方案价格{{item.price_limit}}元以上即可享受</div>
						</div> -->
					</div>
				</div>
				<div class="abox">
					<button class="ok public-btn" @click="ok">前往结算</button>
					<button class="shopcar public-btn-css" @click="addcart1">加入购物车</button>
				</div>
			</div>
		</Drawer>
		<div class="rgba" @click="closepopup" v-if="showpopup"></div>
		<popup v-if="showpopup" :rdata="popdata" @getback="getbackinfo"></popup>
	</div>
</template>

<script>
	import popup from '../components/popup.vue'
	export default {
		name: 'reportdetail',
		components: {
			popup
		},
		data: () => ({
			show: false,
			reportdata: '',
			countinfo: '',
			price: '',
			nimg1:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/report-plan3.1/wechat3.1.png',
			nimg2:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/report-plan3.1/service3.2.png',
			value1:false,
			actionid: '',
			action:'',
			checked:-1,
			buyplan: '',
			count_id: '',
			showpopup: false,
			tipsurl: 'https://mmbizurl.cn/s/yiDciNLJd',
			type: 1,
			bid:'',
			info: '',
			user: '',
			way:'',
			prodetail:'',
			bodywidth:515,
			showdetail:false,
			hasgetid:false,
			cid:''
		}),
		watch: {
			$route() {
				let detailmain = this.$refs.detailmain
				detailmain.scrollTo(0, 0)
			}
		},
		mounted() {
			//console.log(document.body.clientWidth)
			var bw  = document.body.clientWidth
			this.bodywidth = bw<515?bw:515
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login'
				})
			}
			var that = this
			that.info = info
			//console.log(this.$route.query)
			this.count_id = this.$route.query.id
			this.getCountInfoNew(this.$route.query.id)
			//获取报告推荐产品
			this.$ajax.post(this.API.api.apiurl.getCountGoods, {
					count_id: that.$route.query.id,
					member_id: that.info.member_id,
				},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
				.then(res => {
					////console.log(res)
					if (res.data.code == 0) {
						var data = res.data.data
						//console.log(data)
						that.reportdata = data
						var select_re = data.select_re
						var price = 0
						for (var i = 0; i < select_re.length; i++) {
							price += Number(select_re[i].re_day_use * select_re[i].shop_price)
						}
						that.price = price.toFixed(2)
					} else {
						//console.log('错误：' + res.res.data.message)
					}
				})
				.catch(error => {
					console.log(error)
				})


			//请求购买方案
			this.$ajax.post(this.API.api.apiurl.getBuyPlanV2, {
					member_id: that.info.member_id,
				},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
				.then(res => {
					////console.log(res)
					if (res.data.code == 0) {
						var data = res.data.data
						//console.log(data)
						that.buyplan = data
						// that.getCartOne()
					} else {
						//console.log('错误：' + res.res.data.message)
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		methods: {
			closepopup(){
				this.showpopup = false
			},
			showbuyways() {
				this.show = true
			},
			buythiss(){
				this.getbuyplans(1)
				this.value1 = true
			},
			buythis() {
				//console.log('bt')
				var that = this
				// this.value1 = true
				
				if (this.actionid) {
					//console.log('1')
					this.checkCartV2(that.count_id,that.actionid)
				} else {
					//console.log('2')
					var bp = this.buyplan
					var aid = ''
					for(var i=0;i<bp.length;i++){
						//console.log(bp[i])
						if(bp[i].action==1){
							that.actionid = bp[i].id;
							aid = bp[i].id;
							//console.log(aid)
							that.checkCartV2(that.count_id,aid)
							break;
						}
					}
					// this.getbuyplans(1)
					// this.show = true
				}
				
				// this.getbuyplans(1)
				// //console.log(this.checked)
				// if (this.actionid) {
				// 	this.$router.push({
				// 		path: 'define',
				// 		query: {
				// 			id: that.count_id,
				// 			bid: that.actionid
				// 		}
				// 	})
				// } else 
				// if(this.checked!=-1&&this.hasgetid == true){
				// 	this.value1 = true
				// 	this.actionid = this.buyplan[this.checked].id
				// 	//console.log(this.actionid)
				// 	this.$router.push({
				// 		path: 'define',
				// 		query: {
				// 			id: that.count_id,
				// 			bid: that.actionid
				// 		}
				// 	})
				// }else {
				// 	this.value1 = true
				// }
			},
			checkCartV2(e,aid) {
				//console.log(e,aid)
				var that = this
				if (that.actionid) {
					//添加产品，套餐，报告到新购物车
					this.$ajax.post(this.API.api.apiurl.addCart, {
							// member_id: that.info.member_id,
							source: 1,
							buy_id: aid,
							// count_id:that.count_id,
							count_id: e,
							meal_id: '',
							meal_type: '',
							goods_id: '',
							goods_num: '',
							is_pro: 1
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							if ( res.data.code == 0) {
							var data = res.data.data
								//console.log('添加购物车成功！')
								that.cart_id = data.cart_id
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength',l+1)
								// that.replaceCartGoodsNew(data.cart_id)
								var cid = [data.cart_id]
								that.settleAccounts(cid)
								that.gocheckCartV2(cid)
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					// this.getbuyplans(0)
					this.show = true
				}
			},
			replaceCartGoodsNew(e) {
				//console.log(e)
				var that = this
				var goods = that.reportdata.select_re
				var arr = []
				for (var i = 0; i < goods.length; i++) {
					arr[i] = {
						goods_id: goods[i].goods_id,
						is_add: 0
					}
				}
				//批量替换橘袋[报告加入购物车时]
				this.$ajax.post(this.API.api.apiurl.replaceCartGoodsNew, {
						goods_ids: arr,
						cart_id: e,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log('批量替换橘袋成功！')
							var ids = []
							ids.push(that.count_id)
							var cids = []
							cids.push(e)
							that.cid = cids
							// cids = JSON.stringify(cids)
							// that.$router.push({ path: 'settlement',query: {id:ids,cid:cids,type:1}})
							that.settleAccounts(cids)
							that.gocheckCartV2(cids)
						} else {
							//console.log('错误：' + res.data.message)
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			saveTheArrivalNoticeapi(e) {
				var that = this
				//保存到货通知消息推送任务
				this.$ajax.post(this.API.api.apiurl.saveTheArrivalNotice, {
						type: that.buytype,
						member_id: that.info.member_id,
						goods_id: e,
						meal_id: '',
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 1) {
							//console.log(data)
						} else {
							that.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			gopay() {
				var that = this
				var ids = []
				ids.push(that.count_id)
				that.$router.push({
					path: 'settlement',
					query: {
						// id: ids,
						cid: that.cid,
						type: 1
					}
				})
			},
			saveTheArrivalNotice() {
				var that = this
				var popdata = this.popdata
				//console.log(popdata)
				// var saveids = []
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					// saveids[i]=popdata.goodsReason[i].goods_id
					that.saveTheArrivalNoticeapi(popdata.goodsReason[i].goods_id)
				}
			
				window.location.href = that.tipsurl
			},
			settleAccounts(id) {
				var that = this
				//结算页面获取数据
				this.$ajax.post(this.API.api.apiurl.settleAccounts, {
						cart_ids: id,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
						var data = res.data.data
						//console.log(data)
							var cart_list = data
							that.cart_list = cart_list
							//console.log(cart_list)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			gocheckCartV2(cid) {
				var that = this
				//校验商品信息V2
				this.$ajax.post(this.API.api.apiurl.checkCartV2, {
						cart_ids: cid,
						member_id: that.info.member_id,
						coupon_ids: '',
						enjoy_sharing: 0,
						source: 1
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						var data = res.data.data
						var goodsdata = ''
						if (res.data.code == 0) {
							// //console.log(data)
							that.checkdata = data
							that.canbuy = true
							var ids = []
							ids.push(that.count_id)
							that.$router.push({
								path: 'settlement',
								query: {
									// id: ids,
									cid: cid,
									type: 1
								}
							})
						}  else if (res.data.code == 113) {
							// //console.log(data)
							that.value1 = false
							goodsdata = data
							that.popdata = goodsdata
							that.showpopup = true
							that.canbuy = false
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getbackinfo(e) {
				//console.log(e)
				var that = this
				// var checkdata = this.checkdata
				// var way = ''
				// if(checkdata.status==7){
				// 	way=''
				// }
				// //console.log(way)
				if (e.value == 1) {
					this.gopay()
				} else if (e.value == 0) {
					this.saveTheArrivalNotice()
					// window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100004266&idx=1&sn=b4b6aeb22c2375391eda621cb0899cf2&chksm=7d84d9074af3501188729533d69130a12eeb15de1ff0ecea4160e403de5896fa233fc588eb29#rd'
				} else if (e.value == 3) {
					// var address = ''
					var goodids = []
					var goodsdata = []
					// var addressList = that.addressList
					var cart_list = that.cart_list
					// for (var i = 0; i < addressList.length; i++) {
					// 	if (addressList[i].is_default == 1) {
					// 		address = addressList[i]
					// 	}
					// }
					// //console.log(address, cart_list)
					for (var j = 0; j < cart_list.length; j++) {
						var goods = cart_list[j].goods_info
						goods.forEach((item) => {
							//console.log(index)
							goodids.push(item.goods_id)
							goodsdata.push({
								id: item.goods_id,
								num: 1
							})
						})
					}
					that.gopay()
				} else if (e.value == 4) {
					this.$router.push({
						path: 'home'
					})
				} else if (e.value == 5) {
					this.delCartGoodsAll()
				}
				this.showpopup = false
			},

			delCartGoodsAll() {
				var that = this
				var popdata = this.popdata
				var ids = []
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					ids[i] = popdata.goodsReason[i].goods_id
				}
				//批量删除橘袋产品
				this.$ajax.post(this.API.api.apiurl.delCartGoodsAll, {
						cart_ids: [that.cart_id],
						// member_id: that.info.member_id,
						goods_ids: ids
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							if(data.data.cart_ids.length==0){
								that.$Message.info('方案内已没有补剂可以购买')
							}else{
								that.gocheckCartV2(data.data.cart_ids)
								that.delarr(ids)
							}
						} else {
							that.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getdetail() {
				//console.log(e)
			},
			getbuyplans(e) {
				this.way = e
				var that = this
				// var cart = this.reportdata.class_re
				var goods_info = this.reportdata.select_re
				var buyplan = this.buyplan
				//console.log(buyplan, goods_info)
				for (var i = 0; i < buyplan.length; i++) {
					buyplan[i].action = 0
					if(buyplan[i].id==1){
						buyplan[i].action = 1
						that.checked = i
					}
					var planprice = 0
					goods_info.forEach((item) => {
						planprice += Number(item.shop_price * item.re_day_use)
					})
					if (planprice < buyplan[i].price_limit) {
						buyplan[i].canspecial = 0
					} else {
						buyplan[i].canspecial = 1
					}
					buyplan[i].dayprice = Number(planprice.toFixed(2)).toFixed(2)
					buyplan[i].lastplanprice = Number(planprice * buyplan[i].days.toFixed(2)).toFixed(2)
					buyplan[i].planprice = Number(Number(buyplan[i].discount) * planprice * buyplan[i].days.toFixed(2)).toFixed(2)
				}
				// that.updateBuyPlan(buyplan[1],buyplan[1].id,1)
				// buyplan[1].action = 1
				// that.actionid = buyplan[1].id
				// that.checked = 1
				// buyplan[0].action = 1
				// that.actionid=buyplan[0].id
				that.buyplan = buyplan
			},
			getCountInfoNew(e) {
				var that = this
				//获取报告详情3.0版本
				this.$ajax.post(this.API.api.apiurl.getCountInfoNew, {
						count_id: e,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
						var data = res.data.data
							//console.log(data)
							that.countinfo = data
							that.getbuyplans(3)
						}else if(res.data.code == '无效报告！'){
							that.$router.replace({path:'404'})
						}  else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			goclosechou(){
				this.value1 = false
				this.hasgetid = false
			},
			thisplan(e) {
				this.hasgetid = true
				var buyplan = this.buyplan
				//console.log(e, buyplan)
				if (buyplan[e].canspecial == 0) {
					this.$Message.info('每日方案价格' + buyplan[e].price_limit + '元以上即可享受')
				} else {
					for (var i = 0; i < buyplan.length; i++) {
						buyplan[i].action = 0
					}
					buyplan[e].action = 1
					// this.actionid = buyplan[e].id
					this.bid = buyplan[e].id
					this.checked = e
					this.buyplan = buyplan
					this.$forceUpdate()
				}
			},
			ok() {
				this.actionid = this.bid
				this.hasgetid = true
				//console.log('确认方案')
				this.action = this.checked
				this.show = false
				this.$forceUpdate()
				//console.log(this.way)
				if (this.way == 0) {
					this.addcart()
				} else if (this.way == 1) {
					this.buythis()
				}
			},
			lookdetail(id){
				var that = this
				that.gid = this.$route.query.id
				this.$ajax.post(this.API.api.apiurl.getGoodsInfo, {
						goods_id:id
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						var data = res.data
						if (data.code == 1) {
							var goods_detail = data.goods_detail
							var sprice = goods_detail.shop_price
							var dayprice =  Number(sprice*goods_detail.day_use).toFixed(2)
							goods_detail.dayprice = dayprice
							data.goods_detail = goods_detail
							that.showdetail=true
							that.prodetail = data
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						that.$Message.info(error)
					})
			},
			goprodetail(id){
				//console.log(id)
				// window.location.href = 'https://web.xiaojudai.cn/product_detail?id='+id
				this.$router.push({ path: 'product-detail',query:{
					id:id,way:'report'
				}})
			},
			addcart1(){
				this.actionid = this.bid
				this.hasgetid = true
				//console.log('加入购物车',this.bid)
				this.action = this.checked
				this.$forceUpdate()
				this.getbuyplans(1)
				
				var that = this
					this.$ajax.post(this.API.api.apiurl.addCart, {
							member_id: that.info.member_id,
							source: 1,
							buy_id: that.actionid,
							count_id: that.count_id,
							meal_id: '',
							meal_type: '',
							goods_id: '',
							goods_num: '',
							is_pro: 1
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								that.$Message.info('添加购物车成功!');
								that.value1 = false
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength',l+1)
								//跳转购物车
								//that.$router.push({ path: 'reportdetail'})
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
			},
			addcart() {
				var that = this
				if (that.actionid) {
					//添加产品，套餐，报告到新购物车
					this.$ajax.post(this.API.api.apiurl.addCart, {
							member_id: that.info.member_id,
							source: 1,
							buy_id: that.actionid,
							count_id: that.count_id,
							meal_id: '',
							meal_type: '',
							goods_id: '',
							goods_num: '',
							is_pro: 1
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								that.$Message.info('添加购物车成功!');
								
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength',l+1)
								//跳转购物车
								//that.$router.push({ path: 'reportdetail'})
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					this.getbuyplans(0)
					this.show = true
				}
			}
		}
	}
</script>

<style>
	.detail {
		width: 100%;
		max-width: 26rem;
		margin: 0 auto;
		/* display: flex;
		justify-content: space-between;
		flex-wrap: wrap; */
/* https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/reportDetailTopImage.png */
		background-image: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/reportDetailTopImage.png);
		background-repeat: no-repeat;
		background-position: right top;
		background-size: 5.385rem 5rem;
	}

	.reportdetail-top {
		width: 100%;
		height: 4.29rem;
		max-width: 22.78rem;
		margin: 0 auto;
		display: flex;
		justify-content: left;
		/* background-image:url(../../public/imgs/reportdetailbg.png);
		background-repeat:no-repeat;
		background-position:1rem -3rem;
		background-size: 100% 300%; */
	}

	.reportdetail-top .detail-title {
		width: auto;
		height: 1.6rem;
		margin: 0.87rem 0 0.91rem 0;
	}

	.detail-title .detail-name {
		line-height: 0.83rem;
		height: 0.83rem;
		font-weight: bold;
		font-size: 0.59rem;
		display: flex;
		color: #1A1A1A;
	}

	.detail-title .detail-msg {
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		text-align: left;
		margin-top: 0.3rem;
	}

	.detail-title .icons {
		width: 100%;
		height: 0.44rem;
		display: flex;
		justify-content: left;
		/* padding: 0 0.3rem; */
		margin-top: 0.3rem;
	}

	.icons img {
		width: 0.44rem;
		height: 0.44rem;
		margin-right: 0.3rem;
	}

	.buy-box {
		width: 100%;
		width: 100vw;
		height: 2.07rem;
		background: #fff;
		box-shadow: 0rem -0.07rem 0.22rem 0rem rgba(0, 0, 0, 0.1);
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 5;
	}

	.buy-num {
		width: calc(100% - 0.56rem);
		height: 1.41rem;
		margin: 0 auto;
		background: #F9FAFB;
		border-radius: 0.37rem 0.37rem 0rem 0rem;
		position: fixed;
		bottom: 2.44rem;
		left: 0.28rem;
	}

	.detail .main-weight {
		width: 100%;
		height: 100%;
		max-width: 18.5rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.buy-way {
		width: 3.37rem;
		height: 0.81rem;
		line-height: 0.81rem;
		background: #ffffff;
		border-radius: 0.19rem;
		border: 0.02rem solid #C25E4D;
		margin: 0.3rem 0;
		color: #C25E4D;
		font-size: 0.37rem;
		cursor: pointer;
	}

	.price {
		width: auto;
		height: 0.83rem;
		color: #1a1a1a;
		font-size: 0.37rem;
		line-height: 0.83rem;
		font-weight: bold;
		margin: 0.29rem 0;
	}

	.price span {
		font-size: 0.2rem;
	}

	.day-price {
		height: 1.17rem;
		margin-top: 0.65rem;
		color: #1A1A1A;
		text-align: left;
	}

	.day-price .day-num {
		font-size: 0.44rem;
		font-weight: bold;
	}

	.day-num span {
		color: #C25E4D;
	}

	.day-price .day-money {
		font-size: 0.3rem;
		margin-top: 0.15rem;
	}

	.actions-btns {
		width: auto;
		height: 1.26rem;
		margin: 0.59rem 0;
		display: flex;
	}

	.actions-btns div {
		width: 4.44rem;
		height: 1.26rem;
		border-radius: 1.26rem;
		margin-right: 0.56rem;
		line-height: 1.26rem;
		text-align: center;
		font-size: 0.44rem;
		background: #FFFFFF;
		border: 0.04rem solid #000000;
		color: #1A1A1A;
		cursor: pointer;
	}

	.actions-btns .buythis {
		margin-right: 0;
		background: #C25E4D;
		color: #ffffff;
		border: none;
	}

	.detailmainbox {
		width: 100%;
		height: auto;
		margin: 0 auto;
		margin-bottom: 1.19rem;
	}

	.box-title {
		width: auto;
		height: 1.09rem;
		text-align: left;
		line-height: 0.59rem;
		border-left: 0.15rem solid #C25E4D;
		text-indent: 0.15rem;
		font-size: 0.44rem;
		color: #1A1A1A;
		margin-bottom: 0.59rem;
	}
	.box-title-top{
		height: 0.41rem;
		font-size: 0.3rem;
		line-height: 0.41rem;
		margin-bottom: 0.07rem;
	}
	.box-title-bottom {
		width: 100%;
		height: 0.59rem;
		text-align: left;
		line-height: 0.59rem;
		/* border-left: 0.07rem solid #C25E4D; */
		text-indent: 0.15rem;
		font-size: 0.44rem;
		color: #1A1A1A;
		margin-bottom: 0.59rem;
		position: relative;
	}
	.box-title-right{
		position: absolute;
		right: 0;
		top: 0;
		color: #C25E4D;
		line-height: 0.6rem;
		font-size: 0.44rem;
	}

	.box-title span {
		color: #C25E4D;
	}

	.pros-line {
		width: 100%;
		height: auto;
		/* min-height: 7rem; */
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		/* overflow: hidden;
		overflow-x: scroll; */
		padding: 0.1rem 0;
	}

	.pros-line::-webkit-scrollbar {
		/* display:none; */
	}

	.daypro-box {
		width: 4.2rem;
		height: 4.93rem;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		position: relative;
		margin: 0 0.18rem;
		margin-bottom: 0.79rem;
	}

	.daypro-box .daypro-img {
		width: 1.63rem;
		height: 1.63rem;
		margin: 0.59rem 1.29rem 0.15rem 1.29rem;
	}

	.daypro-img img {
		width: 100%;
		height: 100%;
	}

	.daypro-name {
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.3rem;
		color: #1a1a1a;
		text-align: center;
		width: 100%;
		margin-bottom: 0.15rem;
		font-weight: bold;
	}

	.daypro-msg {
		width: 90%;
		/* height: 0.41rem; */
		margin: 0 auto;
		line-height: 0.37rem;
		text-align: center;
		overflow: hidden;
		font-size: 0.26rem;
		color: #1A1A1A;
	}

	.daypro-btn {
		width: 2.22rem;
		height: 0.67rem;
		line-height: 0.67rem;
		font-size: 0.26rem;
		color: #1A1A1A;
		border-radius: 0.67rem;
		text-align: center;
		background: #D5DDE6;
		position: absolute;
		bottom: -0.335rem;
		left: 50%;
		margin-left: -1.11rem;
	}

	.advisermain {
		width: 100%;
		max-width: 22.78rem;
		height: auto;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		padding: 0.59rem;
		margin: 0 auto;
	}

	.adviser {
		margin-bottom: 3rem;
	}

	.adviserinfo {
		width: auto;
		height: 2.78rem;
		display: flex;
	}

	.adviserinfo img {
		width: 2.78rem;
		height: 2.78rem;
		border-radius: 0.19rem;
	}

	.advisermaininfo {
		color: #1A1A1A;
		text-align: left;
		width: 6.11rem;
		margin-left: 0.56rem;
	}

	.advisermaininfo .advisername {
		height: 0.52rem;
		font-size: 0.37rem;
		line-height: 0.52rem;
	}

	.advisermaininfo .advisermsg {
		line-height: 0.41rem;
		font-size: 0.3rem;
		margin: 0.15rem 0 0.26rem 0;
	}

	.advisermaininfo .adviserway {
		line-height: 0.31rem;
		font-size: 0.22rem;
		color: #666666;
	}

	.advisertitle {
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		text-align: left;
		font-weight: bold;
		margin-bottom: 0.3rem;
		margin-top: 1.06rem;
	}

	.advisertitle img {
		width: 0.33rem;
		height: 0.24rem;
		margin: 0.14rem 0;
		margin-right: 0.19rem;
	}

	.helpbox {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: left;
		flex-wrap: wrap;
	}

	.helpbox .help-bar {
		width: 100%;
		height: auto;
		line-height: 1.11rem;
		font-size: 0.3rem;
		text-align: left;
		background: #FBFCFD;
		border-radius: 0.19rem;
		display: flex;
		margin-bottom: 0.3rem;
		padding: 0 0.2rem 0 0;
	}

	.help-bar img {
		width: 0.44rem;
		height: 0.44rem;
		margin: 0.335rem 0.2rem;
	}
	.help-bar div{
		line-height: 0.61rem;
		margin: 0.25rem 0;
	}

	.txt {
		line-height: 0.41rem;
		font-size: 0.3rem;
		text-align: left;
	}

	.contact {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 0.94rem;
	}

	.contact .contacttxt {
		width: 7.5rem;
		width: calc(50% - 0.56rem);
		height: 0.96rem;
		line-height: 0.96rem;
		background: #FBFCFD;
		border-radius: 0.19rem;
		color: #1A1A1A;
		font-size: 0.26rem;
		text-align: center;
		margin-right: 0.56rem;
	}

	.contacttxt img {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.31rem 0.2rem 0.31rem 0;
	}

	.adviser-progress {
		width: 100%;
		display: flex;
		height: auto;
	}

	.adviser-progress .step {
		width: 33%;
		height: auto;
	}

	.step .step-top {
		width: 100%;
		height: 0.77rem;
		display: flex;
	}

	.step-top .stepnum {
		width: 0.59rem;
		height: 0.59rem;
		font-size: 0.26rem;
		line-height: 0.41rem;
		text-align: center;
		background: #D5DDE6;
		border-radius: 0.59rem;
		border: 0.09rem solid #EDF1F6;
		color: #ffffff;
	}

	.step-top .stepline {
		width: calc(100% - 0.59rem);
		height: 0.04rem;
		background: #D5DDE6;
		margin-top: 0.29rem;
	}

	.stepinfo {
		width: 60%;
		height: auto;
		color: #1A1A1A;
		text-align: left;
	}

	.stepinfo .stepname {
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		font-weight: bold;
		margin-bottom: 0.15rem;
	}

	.stepinfo .stepmsg {
		height: auto;
		line-height: 0.41rem;
		font-size: 0.26rem;
	}

	.experience {
		position: relative;
	}

	.cannospecial {
		width: auto;
		height: 0.41rem;
		line-height: 0.41rem;
		color: #999999;
		font-size: 0.3rem;
		font-weight: 300;
		text-align: left;
		position: absolute;
		left: 0.59rem;
		bottom: 0.205rem;
	}

	.experience .canno {
		color: #999999;
	}
	
	.ivu-drawer-body .chose-box{
		width: 100%;
		height: 100%;
		padding: 1rem 0.35rem;
		margin-top: 0;
	}
	.chose-title{
		height: 0.83rem;
		width: 100%;
		line-height: 0.83rem;
		font-size: 0.59rem;
		font-weight: bold;
		color: #1A1A1A;
	}
	.type-list{
		width: 100%;
		height: auto;
		margin-top: 1.19rem;
	}
	.type-list .type-btn{
		width: 100%;
		height: 2.22rem;
		border-radius: 0.19rem;
		border: 0.04rem solid #D5DDE6;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.89rem;
		cursor: pointer;
	}
	.type-list .action{
		height: 2.22rem;
		background: #FFFFFF;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(216, 118, 101, 0.26);
		border-radius: 0.19rem;
		border: 0.04rem solid #C25E4D;
	}
	.type-btn img{
		width: 1.63rem;
		height: 1.63rem;
		margin: 0.3rem;
		border-radius: 0.19rem;
	}
	.type-txt{
		width: calc( 100% - 2.53rem );
		height: 1.63rem;
		line-height: 1.63rem;
		display: flex;
		margin: 0.3rem 0;
		justify-content: space-between;
		position: relative;
		font-size: 0.37rem;
		color: #1A1A1A;
		margin-right: 0.3rem;
	}
	.type-txt .tips{
		position: absolute;
		text-align: center;
		top: -1rem;
		right: 0;
		height: 0.79rem;
		line-height: 0.79rem;
		font-size: 0.3rem;
		border-radius: 0.1rem;
	}
	.tip .tips-msg{
		text-align: center;
	}
	.type-txt .type-name{
		font-size: 0.37rem;
		font-weight: bold;
		height: 0.52rem;
		line-height: 0.52rem;
		margin: 0.505rem 0;
		color: #1A1A1A;
	}
	.type-txt .type-price{
		font-size: 0.37rem;
		font-weight: bold;
		height: 0.52rem;
		line-height: 0.52rem;
		margin: 0.505rem 0;
	}
	.type-price span{
		font-size: 0.37rem;
		font-weight: 400;
	}
	.daypro-btn img{
		width: 0.2rem;
		height: 0.2rem;
		margin-top: -0.03rem;
	}
	.type-price .lastprice{
		font-size: 0.26rem;
	}
	.cannospecial{
		position: absolute;
		left: 0;
	}
	.type-list .hui .type-name,.type-list .hui .chose-price{
		color: #999999;
	}
	.chose-box .abox{
		width: 100%;
		justify-content: center;
	}
	.chose-box button{
		border: none;
		display: block;
		margin: 0 auto;
	}
	.chose-box .ok{
		margin-top: 0.81rem;
		width: 4.22rem;
		height: 1.11rem;
		background: #C25E4D;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.25);
		border-radius: 1.11rem;
		font-size: 0.3rem;
		line-height: 1.11rem;
	}
	.chose-box .shopcar{
		margin: 0 auto;
		margin-top: 0.51rem;
		width: 4.22rem;
		/* height: 1.11rem; */
		color: #C25E4D;
		background: #FFFFFF;
		box-shadow: 0rem 0.09rem 0.15rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 1.11rem;
		font-size: 0.3rem;
		line-height: 1.11rem;
		text-align: center;
		cursor: pointer;
	}
	.hui .type-name,.hui .type-price{
		margin: 0.205rem 0;
	}
	

	@media screen and (max-width:1000px) {
		.main-weight {
			padding: 0 0.6rem;
		}

		.pros-line {
			width: calc( 100% - 0.6rem );
			justify-content: space-between;
		}
		.daypro-box{
			margin: 0;
			margin-bottom: 0.79rem;
			height: 4.93rem;
		}
		/* .detailmainbox{
			width: 100vw;
		} */
		.helpbox .help-bar {
			width: 100%;
			padding: 0 0.3rem;
		}

		.contact .contacttxt {
			width: auto;
			padding: 0 0.3rem;
			margin: 0.3rem 1.63rem 0.3rem 0;
		}

		.stepinfo {
			width: 80%;
		}

		.stepinfo .stepname {
			height: 0.82rem;
		}

		.reportdetail-top .detail-title {
			width: calc( 100% - 0.6rem );
			text-align: left;
			height: auto;
			margin: 0.59rem auto;
		}
		.reportdetail-top{
			height: auto;
		}
		
		.detail-title .detail-name{
			height: auto;
			/* width: 50%; */
			display: block;
			font-size: 0.59rem;
		}
		.detail-title .icons{
			display: none;
		}
		.detailmainbox{
			margin-bottom: 0.59rem;
		}
		.contact .contacttxt{
			text-align: left;
		}
		#app .detail {
			margin-bottom: 2.3rem;
		}
		
		.ivu-drawer-body .chose-box .chose-title{
			display: block;
			height: auto;
		}
		.chose-box .chose-title span{
			display: block;
			margin-top: 0.39rem;
		}
		.ivu-drawer-body .chose-title img{
			margin: 0 0 0.29rem 0;
		}
		.ivu-steps-vertical .ivu-steps-main{
			text-align: left;
		}
		.ivu-steps-item.ivu-steps-status-process .ivu-steps-title,.ivu-steps-item.ivu-steps-status-wait .ivu-steps-title{
			color: #1A1A1A!important;
		}
		.ivu-steps-item.ivu-steps-status-wait .ivu-steps-content{
			color: #666666!important;
		}
		.ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner,.ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner{
			background: #C8D0D8!important;
			color: #FFFFFF!important;
			border: 0rem solid #C8D0D8;
		}
		.ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner span{
			color: #FFFFFF!important;
			height: 26px;
			line-height: 26px;
		}
	}

	@media screen and (max-width:650px) {

		.advisermain,
		.box-title {
			width: calc( 100% - 0.6rem );
			margin: 0 auto;
		}


		.contact .contacttxt {
			height: auto;
			margin: 0.3rem 0;
		}

		.box-title {
			margin-bottom: 0.59rem;
		}

		.actions-btns div {
			width: 3rem !important;
			font-size: 0.37rem !important;
			margin-right: 0.3rem !important;
		}

		.actions-btns .buythis {
			margin-right: 0 !important;
		}
		.ivu-drawer-body .chose-box{
			padding: 0;
		}
		.advisermaininfo .advisermsg{
			margin: 0.05rem 0;
		}
		.detail .main-weight{
			padding: 0 0.45rem;
		}
		.detail .actions-btns{
			height: 1.11rem;
			margin: 0.48rem 0;
		}
		.detail .day-price{
			margin-top: 0.45rem;
		}
		.adviserinfo{
			justify-content: space-between;
		}
		.advisermaininfo{
			width: calc( 100% - 3rem );
			margin-left: 0;
		}
		.advisermaininfo .adviserway{
			margin-top: 0.3rem;
			line-height: 0.41rem;
		}
		.lookdetail{
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;
		}
		.lookbg{
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.6);
		}
		.lookbox{
			width: 100%;
			height: 80VH;
			
		}
		.looklist{
			
		}
		.looktitle{}
	}
</style>
