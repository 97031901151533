<template>
	<div class="login main-page">
		<div v-if="isAlipay">
			<div></div>
		</div>
		<div v-else>
		<div class="page-bg"></div>
		<div class="login-box">
			<div class="logintitle">登录注册</div>
			<div class="phonenum">
				<input type="text" class="phoneinput" maxlength="11" v-model="phone" placeholder="请输入手机号">
			</div>
			<div class="code">
				<input type="text" class="codeinput" maxlength="6" v-model="code" @keyup.enter="ok" placeholder="请输入验证码">
				<div class="time sendbtn cursor" v-if="send==1">{{time}}s后重新获取</div>
				<div class="sendbtn cursor" v-if="send==0" @click="getcode">获取验证码</div>
			</div>
			<div class="loginbtn public-btn cursor" @click="ok">确定</div>
			<div class="loginmsg">一旦登录，即表明你同意NUTRIPACKS的 <span @click="yinsi">隐私政策</span>和 <span
					@click="shiyong">使用条款</span>。</div>
		</div>
		</div>
	</div>
</template>
<script>
	if (navigator.userAgent.indexOf('AliApp') > -1) {
		document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>');
	}
</script>
<script>
my.onMessage = function(e) {
    console.log(e); // {'sendToWebView': '1'}
  }
</script>

<script>
	import {
		md5
	} from '../util/md5.js'
	export default {
		name: 'login',
		components: {},
		data: () => ({
			send: 0,
			time: 60,
			phone: '',
			hascode: false,
			code: '',
			info: '',
			biaoshi: '',
			phonetest: '^1(3([0-35-9]\\d|4[1-8])|4[14-9]\\d|5([0-35689]\\d|7[1-79])|66\\d|7[2-35-8]\\d|8\\d{2}|9[13589]\\d)\\d{7}$',
			way: '',
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		created() {
		},
		mounted() {
			if(this.isAlipay) {
				var zhi = localStorage.getItem('zhi') ? localStorage.getItem('zhi') : '';
				var ztimestamp = localStorage.getItem('ztimestamp') ? localStorage.getItem('ztimestamp') : '';
				var tid = localStorage.getItem('tid') ? localStorage.getItem('tid') : ''
				var info = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')) : ''

				localStorage.setItem('tid', tid)
				localStorage.setItem('zhi', zhi)
				localStorage.setItem('ztimestamp', ztimestamp)
				this.way = this.$route.query.way ? this.$route.query.way : ''
				if (info) {
					//console.log('已登录')
					this.$router.push({
						path: '/'
					})
				} else {
					my.postMessage({
												data: {
													action: 'pageStorage',
													pageName: this.way // 传递支付参数
												}
											});
					localStorage.clear();
					my.redirectTo({
						url: '/pages/login/login'
				})
				}
			} else {
				var zhi = localStorage.getItem('zhi') ? localStorage.getItem('zhi') : '';
				var ztimestamp = localStorage.getItem('ztimestamp') ? localStorage.getItem('ztimestamp') : '';
				var tid = localStorage.getItem('tid') ? localStorage.getItem('tid') : ''
				var info = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')) : ''
				if (info) {
					//console.log('已登录')
					this.$router.push({
						path: '/'
					})
				} else {
					localStorage.clear();
				}
				if (zhi) {
					var tzhi = zhi + ztimestamp
					// //console.log(md5(tzhi).toUpperCase())
					this.biaoshi = md5(tzhi).toUpperCase()
				} else {
					//console.log('请刷新页面')
				}
				localStorage.setItem('tid', tid)
				localStorage.setItem('zhi', zhi)
				localStorage.setItem('ztimestamp', ztimestamp)
				this.way = this.$route.query.way ? this.$route.query.way : ''
			}
		},
		methods: {
			getcode() {
				var testphone = new RegExp(this.phonetest)
				if (this.hascode == false) {
					var phone = this.phone
					var that = this
					if (!phone || !testphone.test(phone)) {
						that.$Message.info('请输入正确的手机号')
					} else {
						this.gettime()
						this.hascode = true
						this.$ajax.post(this.API.api.apiurl.nsendSms, {
								phone: phone,
							})
							.then(res => {
								////console.log(res)
								var data = res.data
								if (data.code == 0) {
									//console.log('验证码发送成功')
									that.send = 1
								} else {
									that.$Message.info('错误：' + data.message)
								}
							})
							.catch(error => {
								console.log(error)
							})
					}
				} else {
					//console.log('已经发送验证码了')
				}
			},
			getl(info) {
				var that = this
				that.$ajax.post(that.API.api.apiurl.getCartList, {
						member_id: info.member_id,
					}, {
						headers: {
							'Authorization': 'Bearer ' + that.info.token,
						}
					})
					.then(res => {
						//console.log(res, 'res')
						var data = res.data.data
						var clist = []
						if (res.data.code == 0) {
							var cartlist = data
							for (var i = 0; i < cartlist.length; i++) {
								if (cartlist[i].source == 1 || cartlist[i].source == 2) {
									clist.push(cartlist[i])
								}
							}
							var ll = 0
							clist.forEach((item) => {
								ll += item.num
							})
							localStorage.setItem('clength', ll)

							//缓存
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						// clearInterval(changel)
						console.log(error)
					})
			},
			ok() {
				var testphone = new RegExp(this.phonetest)
				var phone = this.phone
				var code = this.code
				var that = this
				if (!phone || !code || !testphone.test(phone)) {
					that.$Message.info('请输入正确的手机号和验证码')
				} else {
					this.$ajax.post(this.API.api.apiurl.getIndex, {})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								localStorage.setItem('indexdata', JSON.stringify(data.data))
							} else {
								//console.log('错误：' + data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
						const  IS_B_SERVE = sessionStorage.getItem('is_b_serve')						
						if(IS_B_SERVE){
							that.biaoshi = sessionStorage.getItem('newBiaoshi')
						}
					this.$ajax.post(this.API.api.apiurl.nphoneRegister, {
							phone: phone,
							check_code: code,
							sid: that.biaoshi,
							IS_B_SERVE:IS_B_SERVE ? 1 : ''
						})
						.then(res => {
							var data = res.data
							if (data.code == 0) {
								if (data.data.memberInfo) {
									//console.log('成功')
									var timestamp = (new Date()).getTime();
									var info = data.data.memberInfo
									if (info.status == -1) {
										that.$Message.info({
											content: '该账户异常，无法继续访问！',
											duration: 10,
											closable: true
										});
									} else {
										info.timestamp = timestamp
										info.member_id = info.mid
										info.token = data.data.token
										that.info = info
										that.getl(info)
										localStorage.setItem('info', JSON.stringify(info))
										var way = that.way
										//console.log('way1',way)
										var ua = navigator.userAgent.toLowerCase();
										var isWeixin = ua.indexOf('micromessenger') != -1;
										sessionStorage.setItem('qrCode',data.data.url)
										if(IS_B_SERVE){
											that.$router.replace({
												path: 'qrcode',
											})
											return
										}
										if (isWeixin) {
											// this.$Message.info('微信打开');
											localStorage.setItem('way', 'weixin')
										} else {
											if (that._isMobile()) {
												// this.$Message.info("手机端");
												localStorage.setItem('way', 'h5')
											} else {
												// this.$Message.info("pc端");
												localStorage.setItem('way', 'pc')
											}
										}
											console.log('way',way)
										if (way) {
											if (way == 'questionnaire') {
												that.$router.push({
													path: 'home'
												})
											} else {
												var sp = way.split("&nologin=")
												if(sp[1]==1){
													var sp1 = sp[0].split("=")
													that.gobuythis(sp1[1])
												}else{
													that.$router.push({
														path: way
													})
												}
											}
										} else {
											that.$router.push({
												path: '/'
											})
										}
									}

								} else {
									that.$Message.info(data.message)
								}
								// that.gettime()
							} else {
								that.$Message.info('错误：' + data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			gobuythis(id){
				var that = this
				this.$ajax.post(this.API.api.apiurl.addCart, {
						source: 2,
						buy_id: '',
						count_id: '',
						meal_id: id,
						meal_type: 1,
						goods_id: '',
						goods_num: '',
						is_pro: 1
					}, {
						headers: {
							'Authorization': 'Bearer ' + that.info.token,
						}
					})
					.then(res => {
						if (res.data.code == 0) {
							//console.log(res.data)
							var cart_id = res.data.data.cart_id
							that.$router.replace({
								path: 'settlement',
								query: {
									cid: cart_id,
									type: 4
								}
							})
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			gettime() {
				var that = this
				var time = this.time
				var thetime = setInterval(function() {
					if (time > 0) {
						time--
						that.time = time
					} else {
						that.send = 0
						that.hascode = false
						that.time = 60
						clearInterval(thetime);
					}
				}, 1000)
			},
			yinsi() {
				this.$router.push({
					path: '/terms-of-use'
				})
				// window.location.href = 'https://h5.nutri-packs.cn/privacy'
			},
			shiyong() {
				this.$router.push({
					path: '/privacy-policy'
				})
				// window.location.href = 'https://h5.nutri-packs.cn/clause'
			}
		}
	}
</script>

<style>
	#app .login {
		width: 100%;
		max-width: 100%;
	}

	.login {
		width: 100%;
		height: 100%;
		/* position: fixed; */
		top: 0;
		left: 0;
		padding-top: calc(50vh - 5.14rem);
		/* background-image: url(../../public/imgs/loginbg1.png);
		background-repeat:no-repeat;
		background-position:0 100%;
		background-size: 50vw 75vw; */


		/* background-image: url(../../public/imgs/main_bg.png); */
		/* background-repeat:no-repeat; */
		/* background-position:100% 100%; */
		/* background-size: 100%; */
	}

	.login-box {
		width: 11.2rem;
		height: 10.28rem;
		/* background: #FFFFFF; */
		/* box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1); */
		border-radius: 0.19rem;

		/* position: fixed; */
		/* right: 0.28rem; */
		/* left: 50%;
		margin-left: -5.6rem; */
		margin-left: calc(50% - 5.6rem);
		/* margin-top: calc( 50vh - 5.14rem ); */
	}

	.logintitle {
		width: 100%;
		height: auto;
		margin-top: 0.59rem;
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		font-weight: bold;
		color: #1A1A1A;
	}

	.phonenum {
		width: 100%;
		height: auto;
		padding: 1.19rem 1.11rem 0 1.11rem;
	}

	.phonenum input {
		width: 100%;
		height: 1.11rem;
		background: #f5f5f5;
		border-radius: 1.11rem;
		text-indent: 0.705rem;
		line-height: 1.11rem;
		font-size: 0.37rem;
		border: none;
		box-shadow: none;
		background: #FFFFFF;
		border: 0.02rem solid #D5DDE6;
	}

	.code {
		width: calc(100% - 2.22rem);
		height: 1.11rem;
		margin: 0.59rem 1.11rem 0 1.11rem;
		display: flex;
		justify-content: space-between;
		background: #f5f5f5;
		border-radius: 1.11rem;
		box-shadow: none;
		background: #FFFFFF;
		border-radius: 0.7rem;
		border: 0.02rem solid #D5DDE6;
	}

	.code input {
		width: calc(100% - 2.8rem);
		height: 1.01rem;
		/* background: #f5f5f5; */
		border-radius: 1.11rem;
		text-indent: 0.705rem;
		line-height: 1.01rem;
		font-size: 0.37rem;
		border: none;
		box-shadow: none;
		background: none;
		/* background: #FFFFFF; */
		/* border: 0.02rem solid #D5DDE6; */
	}

	.sendbtn {
		width: 2.74rem;
		height: 0.7rem;
		border-radius: 0.7rem;
		margin: 0.195rem;
		color: #ffffff;
		line-height: 0.7rem;
		background: #C25E4D;
		font-size: 0.26rem;
	}

	.time {
		width: 2.74rem;
		height: 0.67rem;
		border-radius: 0.67rem;
		margin: 0.195rem;
		color: #1A1A1A;
		line-height: 0.67rem;
		background: #D5DDE6;
		font-size: 0.26rem;
	}

	.loginbtn {
		width: 4.44rem;
		height: 1.48rem;
		border-radius: 1.48rem;
		line-height: 1.48rem;
		background: #C25E4D;
		font-size: 0.52rem;
		color: #ffffff;
		margin: 1.19rem auto 0 auto;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24);
	}

	.loginmsg {
		width: 100%;
		height: 0.41rem;
		font-size: 0.3rem;
		line-height: 0.41rem;
		text-align: center;
		margin-top: 0.59rem;
		color: #666666;
	}

	.loginmsg span {
		color: #498AFF;
		cursor: pointer;
	}

	#app .login .public-btn {
		height: 1rem;
		line-height: 1rem;
	}

	@media screen and (max-width:1250px) {
		/* .login{
			background-image: url(../../public/imgs/loginbg1.png);
			background-repeat:no-repeat;
			background-position:0 80%;
			background-size: 100% 133vw;
		}
		.login-box{
			width: 94%;
			max-width: 12.78rem;
			height: 10.28rem;
			background: #FFFFFF;
			box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
			border-radius: 0.19rem;
			padding-top: 0.01rem;
			position: absolute;
			right: auto;
			top:  calc( 50% - 5.14rem );
			left: 50%;
			transform: translateX(-50%);
		} */

	}

	@media screen and (max-width:600px) {
		.login-box {
			width: 100%;
			padding: 0 3%;
			margin: 0 auto;
			position: relative;
			left: auto;
		}

		.login {
			background-repeat: no-repeat;
			/* background-position:0 80%; */
			background-size: auto;
			padding-top: calc(50vh - 6.64rem);
		}

		.phonenum {
			padding: 0.59rem 1.11rem 0 1.11rem;
		}

		.code input {
			width: calc(100% - 2.8rem);
		}

		.loginmsg {
			padding: 0 18%;
			height: auto;
		}
	}
</style>
