<!-- 上海展会过渡页 -->
<template>
    <!-- <div class="title">正在加载......</div> -->
    <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/web/build/dist/imgs/loading.gif" alt="loading" />
</template>

<script>
export default {
    data() {
        return {

        }
    },
    created() {
        sessionStorage.setItem('shanghaishow', true)
        setTimeout(()=>{
            this.$router.push({
            path: '/'
        })
        },
        1000)      
    }
}
</script>
<style>
.title {
    font-size: 24px;
}
</style>