<template>
	<div ref="detailmain" class="research main-page">
		<div class="researchbg"></div>
		<div class="researchtxt" style="z-index: 1;" v-html="reportinfo.content"></div>
	</div>
</template>

<script>
	export default{
		name:'research',
		components:{
		},
		data:()=>({
			info:'',
			goodslist:'',
			reportinfo:''
		}),
		mounted() {
			let detailmain = this.$refs.detailmain
			detailmain.scrollTo(0, 0)
			var that = this
			var id = this.$route.query.id
			this.$ajax.post(this.API.api.apiurl.getReportInfo, {
					id:id
				})
				.then(res => {
					////console.log(res)
					var data = res.data
					if (data.code == 0) {
						// //console.log(data)
						that.reportinfo = data.data
					} else {
						that.$Message.info('错误：' + data.msg)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
		},
		methods:{
		},
	}
</script>

<style>
	.researchbg{
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		background: #F5F5F5;
		z-index: 0;
	}
	#app .research{
		width: 100%;
		max-width: 768px;
		height: auto;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}
	.researchtxt{
		width: 90%;
		height: auto;
		margin: 0.8rem auto;
		z-index: 1;
		position: absolute;
		top: 11.4vw;
	}
		.research p strong span{
			font-size: 24px!important;
		}
		.research p span{
			font-size: 18px!important;
			background: #F5F5F5!important;
		}
	
	@media screen and (min-width:768px) {
		#app .research .researchtxt{
			width: 100%;
			max-width: 768px;
			height: auto;
			margin: 0 auto;
		}
		.research p strong span{
			font-size: 24px!important;
		}
		.research p span{
			font-size: 18px!important;
		}
	}
</style>
