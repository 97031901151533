<template>
	<div class="home main-page">
		<div class="page-bg"></div>
		<div class="show_pc">
			<Carousel autoplay v-model="value2" loop :autoplay-speed="3000">
				<CarouselItem>
					<div class="qus-box-pc">
						<div class="goqus-pc">
							<img class="limg" src="../../public/imgs/goqus1.png" alt="">
						</div>
						<div class="goqus-pc pc-right">
							<div class="goqus-pc-title">还在用老办法挑选补剂吗？</div>
							<div class="goqus-pc-msg">
								<div>跟随网络热帖</div>
								<div>听询药房或客服推荐</div>
							</div>
						</div>
					</div>
				</CarouselItem>
				<CarouselItem>
					<div class="qus-box-pc">
						<div class="goqus-pc">
							<img class="limg" src="../../public/imgs/goqus2.png" alt="">
						</div>
						<div class="goqus-pc pc-right">
							<div class="goqus-pc-title">NUTRIPACKS橘袋营养评估</div>
							<div class="goqus-pc-msg">
								<div>6大维度评估你身体营养所需</div>
								<div>解锁更多生活方式建议</div>
							</div>
						</div>
					</div>
				</CarouselItem>
				<CarouselItem>
					<div class="qus-box-pc">
						<div class="goqus-pc">
							<img class="limg3" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/goqus3.png" alt="">
						</div>
						<div class="goqus-pc pc-right">
							<div class="goqus-pc-title">告别盲从，只需3分钟</div>
							<div class="goqus-pc-msg">
								<div>获取你的专属营养健康方案</div>
								<div>更有中美营养师专属服务</div>
							</div>
						</div>
					</div>
				</CarouselItem>
			</Carousel>

			<div class="goqus-pc-btns" v-if="bt">
				<div class="goqus-pc-btns-list" v-if="record&&record.is_record==1&&record.current_num>1&&!shanghaishow&&!isbserve">
					<button class="goqus-btn-pc" @click="gooldqus">
						继续 AI 定制
					</button>
					<button class="goqus-btn-pc goqus-btn-pc-new" @click="gonewqus">
						重新开始
					</button>
				</div>
				<button class="goqus-btn-pc" @click="goqus" v-if="record&&record.is_record==1&&record.current_num==1&&!shanghaishow">
					开始 AI 定制
				</button>
				<button class="goqus-btn-pc" @click="goqus" v-if="(!record||record.is_record==2||record.is_record==3)&&!shanghaishow">
					开始 AI 定制
				</button>
				<button class="goqus-btn-pc" @click="goqus" v-if="shanghaishow">
					开始 AI 定制
				</button>
			</div>
		</div>
		<div class="show_mb">

      <!-- 审核过后关闭 -->
      <div v-if="false">

          <b style='font-size:20px; margin-top:300px'>开始AI评估</b>
          <p style="margin-top:10px">
          本评估根据<br>《China Health and Nutrition Survey》 <br>http://www.cpc.unc.edu/projects/china/
          得出结果。
          </p>


					<div class="phone goqus-pc hide">
						<img class="limg" src="../../public/imgs/goqus2-app.png" alt="">
					</div>

            <img style="width:100%" v-if="false" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/questionnaire-guidance/questionnaire-guidance-banner.png" alt="">
            <img style="width:100%" v-if="false" src="../../public/imgs/goqus2.png" alt="">
      </div>
      <!-- 审核过后开启 -->
			<Carousel autoplay  v-if="true" v-model="value2" loop class="swiper1" :autoplay-speed="3000">
				<CarouselItem>
					<div class="phone goqus-pc">
						<img class="limg" src="../../public/imgs/goqus1.png" alt="">
					</div>
					<div class="phone goqus-pc">
						<div class="goqus-pc-title">还在用老办法挑选补剂吗？</div>
						<div class="goqus-pc-msg">
							<div>跟随网络热帖</div>
							<div>听询药房或客服推荐</div>
						</div>
						<div class="goqus-pc-btns">
							<div class="left-btns">
								<img src="../../public/imgs/last.png" alt="" @click="lastone">
								<img src="../../public/imgs/next.png" alt="" @click="nextone">
							</div>
							<button class="goqus-btn-pc" @click="goqus" v-if="bt && shanghaishow">
								开始 AI 定制
							</button>
						</div>
					</div>
				</CarouselItem>
				<CarouselItem>
					<div class="phone goqus-pc">
						<img class="limg" src="../../public/imgs/goqus2.png" alt="">
					</div>
					<div class="phone goqus-pc">
						<div class="goqus-pc-title">NUTRIPACKS橘袋营养评估</div>
						<div class="goqus-pc-msg">
							<div>6大维度评估你身体营养所需</div>
							<div>解锁更多生活方式建议</div>
						</div>
						<div class="goqus-pc-btns">
							<div class="left-btns">
								<img src="../../public/imgs/last.png" alt="" @click="lastone">
								<img src="../../public/imgs/next.png" alt="" @click="nextone">
							</div>
							<button class="goqus-btn-pc" @click="goqus" v-if="bt && shanghaishow">
								开始 AI 定制
							</button>
						</div>
					</div>
				</CarouselItem>
				<CarouselItem>
					<div class="phone goqus-pc">
						<img class="limg limg3" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/goqus3.png" alt="">
					</div>
					<div class="phone goqus-pc">
						<div class="goqus-pc-title">告别盲从，只需3分钟</div>
						<div class="goqus-pc-msg">
							<div>获取你的专属营养健康方案</div>
							<div>更有中美营养师专属服务</div>
						</div>
						<div class="goqus-pc-btns">
							<div class="left-btns">
								<img src="../../public/imgs/last.png" alt="" @click="lastone">
								<img src="../../public/imgs/next.png" alt="" @click="nextone">
							</div>
							<button class="goqus-btn-pc" @click="goqus" v-if="bt && shanghaishow">
								开始 AI 定制
							</button>
						</div>
					</div>
				</CarouselItem>
			</Carousel>
			<div class="goqus-pc-btns phone" v-if="(!record||record.is_record==2||record.is_record==3)&&!shanghaishow">
				<button class="goqus-btn-pc" @click="goqus" v-if="bt">
					开始 AI 定制
				</button>
			</div>
			<div class="goqus-pc-btns phone" v-if="record&&record.is_record==1&&record.current_num==1&&!shanghaishow">
				<button class="goqus-btn-pc" @click="gonewqus" v-if="bt">
					开始 AI 定制
				</button>
			</div>
			<div class="phonebtns" v-if="record&&record.is_record==1&&record.current_num>1&&!shanghaishow&&!isbserve">
				<div class="goqus-pc-btns phone" v-if="bt">
					<button class="goqus-btn-pc" @click="gooldqus">
						继续 AI 定制
					</button>
				</div>
				<div class="goqus-pc-btns phone" v-if="bt">
					<button class="goqus-btn-pc goqus-btn-pc-new" @click="gonewqus">
						重新开始
					</button>
				</div>
			</div>
		</div>
		<!-- <div class="goqus" style="width: 4rem;margin:0.5rem auto 0 auto;margin-top: 0.5rem;color: #C7254E;" @click="logout">前往/退出登录</div> -->
		<!-- <div class="goicon" @click="gowx"><img src="../../public/imgs/weixin-icon.png" alt=""></div> -->
	</div>
</template>

<script>
    import checkIsInApp from '../util/app.js'
	import {md5} from '../util/md5.js'
	import Util from '../util/util.js'
	export default {
		name: 'Home',
		components: {},
		data: () => ({
			page: 'home',
			swiperOption: {
				autoplay: {
					delay: 2000,
					//当用户滑动图片后继续自动轮播
					disableOnInteraction: true,
				},
				loop: true,
			},
			show: false,
			nowtab: 0,
			value1: 0,
			way: 'left',
			timers: '',
			info: '',
			flag: '',
			value2: 0,
			thestyle: '',
			record: '',
			ip: '123',
			bt:true,
			biaoshi:'',
		}),
		mounted() {

            // 如果是app完成激活操作
            {
                if(checkIsInApp()){
                    console.log('app active');
                    this.$ajax.post(this.API.api.apiurl.appActive, {
                    },{}).then(res => {
                        console.log('app active', res);
                    });
                }
            }
			var zhi =localStorage.getItem('zhi')?localStorage.getItem('zhi'):'';
			var ztimestamp = localStorage.getItem('ztimestamp')?localStorage.getItem('ztimestamp'):'';
			if(zhi){
				var tzhi = zhi+ztimestamp
				// //console.log(md5(tzhi).toUpperCase())
				this.biaoshi = md5(tzhi).toUpperCase()
			}else{
				//console.log('请刷新页面')
			}
			var that = this
            that.bt = true
						console.log('ccccccccccccccccc',that.bt)
			// //console.log(returnCitySN["cip"])
			// this.$router.push({
			// 	path: 'home'
			// })
			var info = JSON.parse(localStorage.getItem('info'))?JSON.parse(localStorage.getItem('info')):{token:''}
			//console.log(this.$route.path)


			// //console.log(localStorage.getItem('ip'))
			that.info = info
			window.addEventListener("scroll", this.gundong);
			setTimeout(that.showit, 1000)

			// location.reload()
			// this.$router.go(0)
			that.changeimg()
				that.isQuestionRecord()
		},
		created(){
			this.shanghaishow = sessionStorage.getItem('shanghaishow')
			this.isbserve = sessionStorage.getItem('is_b_serve')
		},
		methods: {
            checkIsInApp(){
                return checkIsInApp();
            },
			gowx(){
				location.href = 'weixin://dl/business/?t=ol5tqfwt5su'
			},
			gooldqus() {
				// window._hmt.push(['_trackEvent', 'search', 'click', 123]);
				var that = this
				localStorage.setItem('goq', 1)
				// continueQuestion
				this.$router.push({
					name: 'Questionnaire',
					// path: 'questionnaire',
					query: {
						way: 'old',
						id: that.record.record_id
					},
					params: {
						way: 'home'
					}
				})
			},
			gonewqus() {
				// window._hmt.push(['_trackEvent', 'search', 'click', 123]);
				localStorage.setItem('hasnewqus', '')
				localStorage.setItem('goq', 1)
				var that = this
				this.$router.push({
					name: 'Questionnaire',
					// path: 'questionnaire',
					query: {
						way: 'new',
						id: that.record.record_id
					},
					params: {
						way: 'home'
					}
				})
			},
			isQuestionRecord() {
				const shanghaishow =sessionStorage.getItem('shanghaishow')
				const isbserve = sessionStorage.getItem('is_b_serve')
				var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
				var zhi = "";
				for (var i = 0; i < 16; i++) {
					zhi += chars[Math.ceil(Math.random() * 35)];
				}
				var timestamp = (new Date()).getTime();
				const newzhi = zhi+timestamp
				const newbiaoshi = md5(newzhi).toUpperCase()
				sessionStorage.setItem('newBiaoshi',newbiaoshi)
				var that = this
				this.$ajax.post(this.API.api.apiurl.nisQuestionRecord, {
						// member_id: that.info.member_id,
						sid:shanghaishow || isbserve ? newbiaoshi : that.biaoshi,
						test_level: !that.checkIsBserve() ? 27 : ''
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							that.record = data.data
						}else if (data.code == 2001) {
							location.reload()
						} else {
							//console.log('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			checkIsShanghai(){
				const shanghaishow = sessionStorage.getItem('shanghaishow')
				return shanghaishow
			},
			checkIsBserve(){
				const IS_B_SERVE = sessionStorage.getItem('is_b_serve')
				return IS_B_SERVE
			},
			goqus() {
                // 如果是app完成激活操作
                {
                    if(checkIsInApp()){
                        console.log('app active');
                        this.$ajax.post(this.API.api.apiurl.appActive, {
                        },{}).then(res => {
                            console.log('app active', res);
                        });
                    }
                    // 判断是否登录，没用登录跳转，上海展会接口进入不用登录
                    // console.log(!Util.checkIsLogin(),!this.checkIsShanghai());
                    if(!Util.checkIsLogin() && !this.checkIsShanghai() && !this.checkIsBserve()){
                        this.$router.push({
                            path: 'login',
                            query:{
                                way:'questionnaire'
                            }
                        })
                        return;
                    }
                }
                //console.log(window._hmt)
                // window._hmt.push(['_trackEvent', 'search', 'click', 123]);
                //console.log('123')
                localStorage.setItem('goq', 1)
                this.$router.push({
                    name: 'Questionnaire',
                    // path: 'questionnaire',
                    query: {
                        way: ''
                    },
                    params: {
                        way: 'home'
                    }
                })
            },
                logout() {
				// localStorage.clear();

				this.$router.push({
					path: 'login'
				})
			},
			lastone() {
				var that = this
				clearInterval(this.timers);
				this.timers = ''
				this.way = 'right'
				var nowtab = this.nowtab
				this.nowtab = 4
				setTimeout(function() {
					if (nowtab == 0) {
						that.nowtab = 2
					} else {
						that.nowtab = nowtab - 1
					}
					that.changeimg()
				}, 300)
				//console.log('上一页')
			},
			nextone() {
				this.way = 'left'
				var that = this
				clearInterval(this.timers);
				this.timers = ''
				//console.log('下一页')
				var nowtab = this.nowtab
				this.nowtab = 4
				setTimeout(function() {
					if (nowtab == 2) {
						that.nowtab = 0
					} else {
						that.nowtab = nowtab + 1
					}
					that.changeimg()
				}, 300)
			},
			changeimg() {
				var that = this
				var nowtab = this.nowtab
				this.timers = setInterval(function() {
					that.nowtab = 4
					that.way = 'left'
					setTimeout(function() {
						if (nowtab == 2) {
							nowtab = 0
						} else {
							nowtab++
						}
						that.nowtab = nowtab
					}, 300)

					// //console.log(nowtab)
				}, 3000)
			}
		}
	}
</script>

<style>
	.home {
		width: 100%;
		height: 100%;
	}
	#app .home{
		min-height: 80vh;
	}

	/* .slide-fade-enter-active{
		transition: all .6s ease;
	}
	.slide-fade-enter-active{
		transition: all .9s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slide-fade-enter, .slide-fade-leave-to{
		transform: translateX(4rem);
		opacity: 0;
	}
	.slides-fade-enter-active{
		transition: all .3s ease;
	}
	.slides-fade-enter-active{
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slides-fade-enter, .slide-fade-leave-to{
		transform: translateX(4rem);
		opacity: 0;
	} */
	.slide-fade-right-enter-active {
		transition: all .3s ease;
	}

	.slide-fade-right-leave-active {
		transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade-right-enter {
		transform: translateX(-4rem);
		opacity: 0;
	}

	.slide-fade-right-leave-to

	/* .slide-fade-leave-active 用于 2.1.8 以下版本 */
		{
		transform: translateX(4rem);
		opacity: 0;
	}

	.goqus {
		width: auto;
		height: 0.83rem;
		line-height: 0.83rem;
		margin: auto;
		cursor: pointer;
		font-size: 0.52rem;
	}

	.qus-box {
		width: 9rem;
		height: auto;
		display: none;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		background: #ffffff;
		margin: 0 auto;
		padding: 0.59rem;
		margin-top: 1rem;
	}

	.qus-box .title {
		width: 100%;
		height: auto;
		padding: 0.3rem 0;
		line-height: 0.83rem;
		font-size: 0.52rem;
		font-weight: bold;
		text-align: left;
		border-bottom: 0.04rem solid #000000;
	}

	.qus-box .title span {
		color: #C25E4D;
	}

	.msg-list {
		width: 100%;
		height: auto;
		padding: 0.5rem 0;
	}

	.msg-list div {
		width: 100%;
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.37rem;
		display: flex;
		margin: 0.5rem 0;
	}

	.msg-list div img {
		width: 0.31rem;
		height: 0.21rem;
		margin: 0.2rem;
	}

	.msg-list div span {
		font-weight: bold;
	}

	.goqusbtn {
		width: 6rem;
		height: 0.83rem;
		background: #C25E4D;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24);
		border-radius: 0.74rem;
		line-height: 0.83rem;
		color: #ffffff;
		font-size: 0.37rem;
		margin: 0.5rem auto;
		cursor: pointer;
	}

	.qus-box-pc {
		width: 100%;
		max-width: 18.5rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		/* margin-top: 17%; */
		overflow: hidden;
	}

	.home .show_pc {
		margin-top: 17%;

	}

	.qus-box-pc .goqus-pc {
		width: 9.3rem;
		height: 8.5rem;
	}

	.qus-box-pc .pc-right {
		width: 8.98rem;
	}

	.goqus-pc img {
		width: 100%;
		height: 100%;
	}

	.goqus-pc .left-btns img {
		width: 0.59rem;
		height: 0.59rem;
	}

	.goqus-pc-title {
		width: auto;
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		font-weight: bold;
		color: #1A1A1A;
		text-align: left;
		margin-top: 1.26rem;
	}

	.goqus-pc-msg {
		width: auto;
		height: auto;
		margin-top: 0.8rem;
		margin-bottom: 0.38rem;
	}

	.goqus-pc-msg div {
		height: 0.56rem;
		line-height: 0.56rem;
		font-size: 0.37rem;
		text-align: left;
	}

	.goqus-pc-btns {
		width: 100%;
		height: 1.11rem;
		display: flex;
		justify-content: left;
		margin-top: 2.79rem;
	}

	.goqus-pc-btns .left-btns {
		width: 1.5rem;
		height: 0.59rem;
		display: flex;
		justify-content: space-between;
		margin-top: 0.52rem;
	}

	.goqus-btn-pc {
		width: 4.22rem;
		height: 1.11rem;
		background: #C25E4D;
		/* box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24); */
		border-radius: 1.11rem;
		color: #FFFFFF;
		line-height: 1.11rem;
		font-size: 0.3rem;
		cursor: pointer;
		box-shadow: 0rem 0.15rem 0.1rem 0rem rgba(194, 94, 77, 0.25);
	}

	.goqus-btn-pc-new {
		color: #C25E4D;
		background: #FFFFFF;
		box-shadow: 0rem 0.09rem 0.15rem 0rem rgba(0, 0, 0, 0.1)
	}

	.home .swiper-slide {
		height: auto;
	}

	.phone {
		display: none;
	}

	.goqus-pc-btns-list {
		width: 100%;
		height: 1.41rem;
		display: flex;
		justify-content: space-between;
		padding: 0.15rem 0.15rem;
	}

	/* .ivu-carousel-list {
		display: none !important;
	}

	.ivu-carousel-dots-inside {
		bottom: -0.69rem !important;
	} */

	@media screen and (min-width:1001px) {
		/* .home .ivu-carousel-item{
			width: 100%!important;
		} */
		.goqus-pc .limg3 {
			/* width: 8.15rem;
			height: 8.5rem;
			margin: 0 0.575rem; */
			width: auto;
			height: 100%;
			margin: 0 auto;
		}

		.show_pc .ivu-carousel-dots-inside {
			bottom: -36px;
		}

		.ivu-carousel-arrow.right {
			right: -45px !important;
			z-index: 1;
		}

		.ivu-carousel-arrow.left {
			left: -45px !important;
		}

		.show_pc {
			position: relative;
		}

		.show_pc .goqus-pc-btns {
			width: 8.98rem;
			position: absolute;
			bottom: 0.5rem;
			right: 0;
		}

		.qus-box-pc .goqus-pc {
			padding: 0.93rem 0.85rem;
		}

		.qus-box-pc .pc-right {
			padding: 0;
		}
	}

	@media screen and (max-width:1000px) {

		/* .ivu-carousel-list {
			display: block !important;
		}

		.ivu-carousel-dots-inside {
			bottom: -0.19rem !important;
		} */

		.qus-box-pc .goqus-pc {
			width: 88vw;
			height: 83vw;
			margin: 0 auto;
		}

		.qus-box-pc {
			display: none;
		}

		.goqus-pc .limg {
			width: auto;
			max-width: 9rem;
			height: 7rem;
			margin: 0 auto;
		}

		.ivu-carousel {
			margin-top: 0.59rem;
		}

		.goqus-pc-title {
			margin-top: 0.5rem;
			text-align: center;
			font-size: 0.6rem;
		}

		.goqus-pc-msg div {
			text-align: center;
		}

		.goqus-pc-btns {
			margin: 0.5rem 0;
			/* display: none; */
		}

		.phone {
			display: block;
		}

		.goqus-pc-btns .left-btns {
			display: none;
		}

		.goqus-btn-pc {
			margin: 0 auto;
		}

		.phonebtns {
			display: flex;
			justify-content: space-between;
			margin-top: 0.59rem;
		}

		.ivu-carousel-arrow.right {
			z-index: 8;
		}
	}
</style>
