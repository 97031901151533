<template>
	<!-- <div class="popup"> -->
		<div class="popup-box">
			<div class="popup-title">
				<div class="popup-name">{{rdata.title}}</div>
				<div class="popup-msg" v-if="!rdata.fpage||rdata.status!=8">{{rdata.msg}}</div>
				<div class="popup-msg" v-if="rdata.status==8&&rdata.fpage=='cart'">以下补剂已升级新配方，请浏览你的评估报告，下单获取最新方案。</div>
				<!-- <div class="popup-name" v-if="rdata==1||rdata==5||rdata==6||rdata==8">配方升级啦</div>
				<div class="popup-name" v-if="rdata==2">抱歉，补剂缺货</div>
				<div class="popup-name" v-if="rdata==3||rdata==7">抱歉，补剂暂时缺货</div>
				<div class="popup-name" v-if="rdata==4">抱歉，补剂下架了</div>
				<div class="popup-msg" v-if="rdata==1">以下补剂已升级新配方，请重新填写评估报告，以获取最新推荐方案。</div>
				<div class="popup-msg" v-if="rdata==2">以下补剂暂时缺货或已下架，你可以选择等待到货或者继续结算。</div>
				<div class="popup-msg" v-if="rdata==3">以下补剂处于补货状态，你可以选择等待到货或者继续结算。</div>
				<div class="popup-msg" v-if="rdata==4">以下补剂已永久下架，你可以选择移除它们并继续下单。</div>
				<div class="popup-msg" v-if="rdata==5">以下补剂已升级新配方，请浏览你的评估报告，下单获取最新方案。</div>
				<div class="popup-msg" v-if="rdata==6">以下补剂已升级新配方，请重新自选定制补剂，以购买最新方案。</div>
				<div class="popup-msg" v-if="rdata==7">以下补剂处于补货状态，请重新选择需要购买的橘袋。</div>
				<div class="popup-msg" v-if="rdata==8">以下补剂已升级新配方，请联系客服更新你的报告，或重新评估。</div> -->
			</div>
			<div class="popup-list">
				<template v-if="rdata.status == 9 || rdata.status == 10 || rdata.status == 11">
					<!-- 单个定制预售 -->
					<template v-if="rdata.status == 9">
						<div v-for="(item,i) in rdata.goodsReason" :key="i">
						<div class="popup-good">
							<img :src="item.img_url" alt="">
							<div class="popup-goodsinfo">
								<div class="popup-goodsname">{{item.goods_name}}</div>
								<div class="popup-goodsmsg">{{item.reason}}</div>
							</div>
						</div>
						<div class="popup-line" v-if="i+1!=rdata.goodsReason.length"></div>
					</div>
					</template>
					<!-- 单个应急包预售 -->
					<template v-if="rdata.status == 10">
						<div class="popup-good">
							<img :src="rdata.cartData[0].img" alt="">
							<div class="popup-goodsinfo"
							style="margin-left:0.11rem;
							display: flex;flex-direction: column;
							justify-content: space-around">
								<div class="popup-goodsname" style="margin:0">{{rdata.cartData[0].name}}</div>
								<div class="popup-goodsmsg" style="margin:0">{{rdata.cartData[0].meal_tips}}</div>
							</div>
						</div>
					</template>
					<!-- 多个橘袋预售（定制+定制、定制+应急包、应急包+应急包） -->
					<template v-if="rdata.status == 11">
						<div  v-for="(item,i) in rdata.cartData" :key="i">
							<!-- 应急包 -->
							<div class="popup-good" v-if="item.source == 2">
								<img :src="item.img" alt="">
								<div class="popup-goodsinfo"
								style="margin-left:0.11rem;
								display: flex;flex-direction: column;
								justify-content: space-around">
									<div class="popup-goodsname" style="margin:0">{{item.name}}</div>
									<div class="popup-goodsmsg" style="margin:0">{{item.meal_tips}}</div>
								</div>
							</div>
							<!-- 定制 -->
							<div class="popup-cart" v-else>
								<div class="popup-cart-item">
									<img :src="item.img" alt="">
									<div class="popup-cart-name" style="text-align: left;margin:0">{{ item.name }}</div>
								</div>
								<div class="popup-cart-goods-wrap">
									<div class="popup-cart-goods" v-for="(item2,i2) in item.goods_list" :key="i2">
										<div class="popup-cart-goods-item">
											<div class="popup-cart-goods-name">{{item2.goods_name}}</div>
											<div class="popup-cart-goods-tips">{{item2.reason}}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="popup-line" v-if="i+1!=rdata.cartData.length"></div>
						</div>
					</template>

				</template>
				<template v-else>
					<div v-for="(item,i) in rdata.goodsReason" :key="i">
						<div class="popup-good">
							<img :src="item.img_url" alt="">
							<div class="popup-goodsinfo">
								<div class="popup-goodsname">{{item.goods_name}}</div>
								<div class="popup-goodsmsg">{{item.reason}}</div>
							</div>
						</div>
						<div class="popup-line" v-if="i+1!=rdata.goodsReason.length"></div>
					</div>

				</template>
				<!-- <div class="popup-good">
					<img src="../../public/imgs/pic1.png" alt="">
					<div class="popup-goodsinfo">
						<div class="popup-goodsname">冰原红景天</div>
						<div class="popup-goodsmsg">说明下架产品理由，提供科学依据说服客户。</div>
					</div>
				</div>
				<div class="popup-line"></div> -->
			</div>
			<!-- 预售 -->
			<template v-if="rdata.status == 9 || rdata.status == 10 || rdata.status == 11">
				<div class="popup-btn">
					<div class="public-btn" @click="preSell()">预购橘袋</div>
				</div>
			</template>
			<!-- 非预售 -->
			<template v-else>
				<div class="popup-btn" v-if="rdata.zu!=1">
					<!-- <div @click="ok">我知道了</div> -->
					<div class="public-btn" v-if="rdata.status==1" @click="ok">同意并继续</div>
					<!-- <div class="leftbtn" v-if="rdata.status==9" @click="del()">删除并下单</div>
					<div v-if="rdata.status==9" @click="gopay()">继续结算</div>
					<div v-if="rdata.status==6">重新自选</div>
					<div v-if="rdata.status==7" @click="ok">我知道了</div> -->

					<div class="leftbtn public-btn-css" v-if="rdata.status==9" @click="gb()">放弃购买</div>
					<div class="public-btn" v-if="rdata.status==9" @click="gopay()">继续结算</div>
					<div class="public-btn" v-if="rdata.status==4" @click="del()">移除并结算</div>
					<!-- 下架 -->
					<!-- <div v-if="rdata.status==8" class="leftbtn">联系客服</div> -->
					<div class="public-btn" v-if="rdata.status==8&&!rdata.fpage" @click="again">重新评估</div>
					<div class="public-btn" v-if="rdata.status==8&&rdata.fpage=='cart'" @click="goreport">去报告下单</div>

					<!-- <div class="public-btn" v-if="rdata.status==8" @click="del()">移除并结算</div> -->
					<!-- 升级 -->
					<div class="leftbtn public-btn-css" v-if="rdata.status==7" @click="okl()">到货通知</div>
					<div class="public-btn" v-if="rdata.status==7" @click="del()">移除并结算</div>
					<div class="leftbtn public-btn-css" v-if="rdata.status==2" @click="okl()">到货通知</div>
					<div class="public-btn" v-if="rdata.status==2" @click="del()">移除并结算</div>
					<!-- 售罄 + 下架 -->
					<div class="leftbtn public-btn-css" v-if="rdata.status==3" @click="okl()">到货通知</div>
					<div class="public-btn" v-if="rdata.status==3" @click="del()">移除并结算</div>
					<!-- 售罄  -->
				</div>
				<div class="popup-btn" v-if="rdata.zu==1">
					<div class="public-btn" @click="xingba()">我知道了</div>
				</div>
			</template>
		</div>
	<!-- </div> -->
</template>

<script>
	//type:	1.升级+永久下架 2.售罄+下架	3.售罄	4.下架	5.报告产品升级	 6.自选产品升级跳转产品列表	 7.组合购买，套餐购买。提示无法购买 	8.报告购买时，有产品升级
	export default{
		name:'popup',
		props: {
			rdata: Object
		},
		watch:{
				rdata(n,o){ //n为新值,o为旧值;
					//console.log(n,o)
					this.o = o
					this.n = n
				}
		},
		data:()=>({
			o:'',
			n:'',
		}),
		mounted() {
			console.log(this.rdata)
		},
		methods:{
			ok(){
				this.$emit('getback',{value:'1',way:''})
			},
			okl(){
				this.$emit('getback',{value:'0',way:''})
			},
			gopay(){
				this.$emit('getback',{value:'3',way:''})
			},
			again(){
				this.$emit('getback',{value:'4',way:''})
			},
			del(){
				this.$emit('getback',{value:'5',way:''})
			},
			gb(){
				this.$emit('getback',{value:'6',way:''})
			},
			xingba(){
				console.log('xingba')
				this.$emit('getback',{value:'111',way:''})
			},
			goreport(){
				this.$emit('getback',{value:'9',way:''})
			},
			// 预购
			preSell() {
				console.log('preSell')
				this.$emit('getback',{value:'10',way:''})
			}
		}
	}
</script>

<style>
	.popup{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(26, 26, 26, 0.7);
	}
	.popup-box{
		width: 100%;
		max-width: 10.09rem;
		height: 12.72rem;
		background: #ffffff;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.25);
		border-radius: 0.19rem;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translateX(-50%);
		margin-top: -6.86rem;
		z-index: 11;
	}
	.popup-box .popup-title{
		width: 100%;
		height: 3rem;
		background-image: url(../../public/imgs/bg.png);
		background-repeat:no-repeat;
		/* background-position:top right; */
		background-size: 10.09rem 3rem;
		padding: 0.6rem 0.56rem;
		color: #1A1A1A;
		text-align: left;
	}
	.popup-title .popup-name{
		width: 100%;
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		font-weight: bold;
	}
	.popup-title .popup-msg{
		width: 100%;
		line-height: 0.41rem;
		font-size: 0.3rem;
		margin-top: 0.15rem;
	}
	.popup-box .popup-btn{
		width: 100%;
		height: 1.11rem;
		display: flex;
		justify-content: space-around;
	}
	.popup-box .popup-list{
		width: 100%;
		height: 7.71rem;
		margin-bottom: 0.4rem;
		overflow: hidden;
		overflow-y: scroll;
	}
	.popup-box .popup-list::-webkit-scrollbar{
		display:none;
	}
	.popup-btn div{
		width: 4.44rem;
		height: 1.48rem;
		line-height: 1.48rem;
		font-size: 0.52rem;
		text-align: center;
		background: #C25E4D;
		color: #ffffff;
		border-radius: 0.74rem;
		cursor: pointer;
	}
	.popup-good{
		width: 100%;
		padding: 0.59rem;
		display: flex;
		justify-content: space-between;
	}
	.popup-cart {
		width: 100%;
		padding: 0.59rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.popup-cart-item {
		display: flex;
		align-items: center;
	}

	.popup-cart-name {
		/* margin-left: 0.11rem; */
	}

	.popup-cart-goods-wrap {
		display: flex;
		flex-direction: column;
		margin-left: 1.8rem;
		text-align: left;
	}

	.popup-cart-goods {
	}

	.popup-cart-goods-item {
		margin-top: 0.11rem;
	}

	.popup-good img,
	.popup-cart img{
		width: 1.63rem;
		height: 1.63rem;
		margin-right: 0.11rem;
	}
	.popup-good .popup-goodsinfo{
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: calc( 100% - 1.63rem );
		text-align: left;
	}
	.popup-goodsinfo .popup-goodsname,
	.popup-cart-name{
		width: 100%;
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		margin-top: 0.3rem;
	}

	.popup-cart-goods-name {
		font-size: 0.3rem;
	}

	.popup-goodsinfo .popup-goodsmsg,
	.popup-cart-goods-tips{
		width: 100%;
		color: #C25E4D;
		line-height: 0.41rem;
		font-size: 0.3rem;
		margin-top: 0.11rem;
	}
	.popup-line{
		width: calc( 100% - 1.18rem );
		height: 0.02rem;
		background: #cccccc;
		margin-left: 0.59rem;
	}
	.popup-btn .leftbtn{
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 1.11rem;
		color: #C25E4D;
	}

	@media screen and (max-width:1000px){
		.popup-box{
			max-width: 8rem;
			overflow: hidden;
			height: 12rem;
			transform: none;
			margin-left: -4rem;
			position: fixed;
			/* margin: 0 auto; */
		}
		.popup-box .popup-list{
			height: 6.71rem;
		}
		.popup-btn div{
			width: 3.44rem;
			height: 1rem;
			line-height: 1rem;
			font-size: 0.41rem;
			margin: 0.24rem 0;
			border-radius: 1rem;
		}
		#app .popup-box .public-btn{
			width: 3.22rem;
		}
		#app .popup-box .public-btn-css{
			width: 3.22rem;
		}
	}
</style>
