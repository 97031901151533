<template>
	<div class="foot foot1">
		<div class="mb-foot">
			<div>版权所有</div>
			<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/logo.png" alt="">
		</div>
		<div class="beian">
			<span @click="beian">蜀ICP备18038614号-2</span>
		</div>
	</div>
</template>

<script>
	export default{
		name:'qus-foot',
		components:{
		},
		data:()=>({
			
		}),
		mounted(){
			
		},
		methods:{
			beian(){
				window.location.href='https://beian.miit.gov.cn/'
			}
		}
	}
</script>

<style>
	.foot{
		width: 100%;
	}
	.foot1{
		height: 2.5rem;
	}
	.foot1 .beian{
		line-height: 1.09rem;
		background: none;
	}
	.f-logo img{
		width: 100%;
		height: auto;
	}
	.tiplist{
		width: 100%;
		height: 3.59rem;
		display: flex;
		justify-content: space-between;
		/* margin-top: 0.59rem; */
	}
	.ftiptxt span{
		display: inline-block;
	}
	.foottip img{
		width: 1.63rem;
		height: 1.63rem;
		margin: 0 auto;
	}
	
	@media screen and (max-width:1000px) {
		.foot .beian{
			padding-top: 1rem;
			line-height: 1.09rem;
			font-size: 0.2rem;
		}
	}
</style>
