<template>
	<div class="orderlist main-page" v-if="orderlist">
		<div class="page-bg"></div>
		<div class="orderlist-title">
			订单管理
		</div>
			<div class="orderList-mainbox">
				<div class="nomsg" v-if="orderlist.data.length==0">暂无订单</div>
				<div class="orderList-list order3 show_pc">
					<div class="order-mainbox cursor" v-for="(item,index) in orderlist.data" :key="index" @click="godetail(item.id)">
						<div class="order-topline">
							<div class="topline-left">下单时间：{{item.create_time}}</div>
							<div class="topline-right">{{item.order_status_text}}</div>
						</div>
						<div class="order-bodyline-list">
							<div class="order-bodyline">
								<img :src="item.cart_list[0].img" alt="" class="orderimg">
								<div class="order-namebox">
									<div class="order-name fontsize">{{item.cart_list[0].name}} <span style="color: #999999;"> {{item.cart_list.length>1?'等'+item.cart_list.length+'件商品':''}}</span></div>
									<div class="order-price">
										<div class="order-pricebox">
											<div class="order-buytype" v-if="item.cart_list[0].source!=2">{{item.cart_list[0].plan_title}}({{item.cart_list[0].buy_days}}天)</div>
											<div class="order-buytype" v-if="item.cart_list[0].source==2">{{item.cart_list[0].num}}套</div>
											<div class="orderlist-price fontsize">￥{{Number((item.need_pay)).toFixed(2)}}</div>
										</div>
										<div class="orderlist-btns">
											<div class="orderlist-btn fontsize" v-if="item.order_status==5||item.order_status==3" @click="logistics">查看物流</div>
											<div class="orderlist-btn fontsize" v-if="item.order_status==-1" @click="delthis(item)">删除订单</div>
											<div class="orderlist-btn fontsize" v-if="item.order_status==0" @click="gopaythis(item)">确认支付</div>
											<div class="orderlist-btn fontsize" v-if="item.order_status==2" @click="receive(item.id)">确认收货</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="orderList-list orderList-list-phone show_mb">
					<div class="order-mainbox" v-for="(item,index) in orderlist.data"
					:key="index" @click="godetail(item.id)">
						<div class="order-topline">
							<div class="topline-left">下单时间：{{item.create_time}}</div>
							<div class="topline-right">{{item.order_status_text}}</div>
						</div>
						<div class="order-bodyline-list">
							<div class="order-bodyline">
								<img :src="item.cart_list[0].img" alt="" class="orderimg">
								<div class="order-namebox">
									<div class="order-name">{{item.cart_list[0].name}} <span style="color: #999999;"> {{item.cart_list.length>1?'等'+item.cart_list.length+'件商品':''}}</span></div>
									<div class="order-price">
										<div class="order-pricebox">
											<div class="order-buytype" v-if="item.cart_list[0].source!=2">{{item.cart_list[0].plan_title}}({{item.cart_list[0].buy_days}}天)</div>
											<div class="order-buytype" v-if="item.cart_list[0].source==2">{{item.cart_list[0].num}}套</div>
											<div class="orderlist-price">￥{{Number((item.need_pay)).toFixed(2)}}</div>
										</div>
										<div class="orderlist-btns">
											<div class="orderlist-btn" v-if="item.order_status==5||item.order_status==3||item.order_status==12" @click="logistics(item)">查看物流</div>
											<div class="orderlist-btn" v-if="item.order_status==-1" @click="delthis(item)">删除订单</div>
											<div class="orderlist-btn" v-if="item.order_status==0" @click="gopaythis(item)">确认支付</div>
											<div class="orderlist-btn" v-if="item.order_status==2" @click="receive(item.id)">确认收货</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

					<div class="getmorelist" v-if="orderlist.data.length!=0">
						<div class="nomorelist" v-if="page==all_page">没有更多数据了</div>
						<div class="havemorelist cursor" v-else @click="getmore">点击加载更多</div>
					</div>
			</div>
		<div class="code_url" v-if="showqr">
			<div class="qr-box">
				<div class="close-box">
					<div class="qrtitle">扫码支付</div>
					<img class="cursor" src="../../public/imgs/close.png" alt="" @click="goclose">
				</div>
				<div class="wxtxt"><img class="wicon" src="../../public/imgs/weixin-icon.png" alt="">请使用微信扫码支付</div>
				<img class="qrimg" :src="qr" alt="">
			</div>
		</div>




		<Modal v-model="modal2" width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>删除确定</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>确认删除该订单吗？</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="noshowit">取消</div>
				<div class="modalok modalbtn cursor" @click="del">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>

		<Modal v-model="modal3" width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>收货确定</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>确认收到货了吗？</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="noshowit">取消</div>
				<div class="modalok modalbtn cursor" @click="receiveOrder">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
		<Modal v-model="modal8" :mask-closable="false" :closable="false" width="423.9" :styles="{top: '30%'}" >
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>支付未成功</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>请确认是否中断支付，或在订单页面重试。</p>
			</div>
			<div class="modalbtns">
				<!-- <div class="modalcancel modalbtn cursor">取消</div> -->
				<div class="modalok modalbtn cursor" @click="weixinnopay">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
	</div>
</template>
<script>
	if (navigator.userAgent.indexOf('AliApp') > -1) {
		document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>');
	}
</script>
<script>
	// import wxPay from '../util/wxPay.js'
	var thepaytime = ''
	export default {
		name: 'orderlist',
		components: {},
		data: () => ({
			info: '',
			orderlist: '',
			modal2: false,
			modal3:false,
			page: 1,
			modal8:false,
			all_page: 1,
			liststatus: 1,
			showqr: false,
			delid: '',
			qr: '',
			list1: [],
			list2: [],
			list3: [],
			receiveid:'',
			littelbtn: false,
			hei:0,
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		created() {
			window.addEventListener('beforeunload', e => this.test(e))
		},
		destroyed() {
			window.removeEventListener('beforeunload', e => this.test(e))
			window.removeEventListener('popstate', this.test(1), false);
		},
		mounted() {
			//console.log(window.innerHeight)
			var hh = Number(window.innerHeight) - 243
			//console.log(hh)
			this.hei = hh
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				//console.log('用户未登录')
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login?way=order-list'
				})
			} else {
				this.info = info
				this.getOrderList()
			}
		},
		methods: {
			noshowit(){
				this.modal2=false
				this.modal3=false
			},
			getmore(){
				var that = this
				var page = this.page + 1
				var all_page = that.all_page
				var lorderlist = that.orderlist
				var llist = lorderlist.data
				// //console.log(llist)
				if (page > all_page) {
					this.$Message.info('没有更多数据了!');
				} else {
						that.$ajax.post(that.API.api.apiurl.getOrderList, {
							member_id: that.info.member_id,
							page: page,
						order_type:1,
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							var data = res.data.data
							if (res.data.code == 0) {
								var orderlist = data
								// var l1 = [],l2=[],l3=[],l4=[];
								var l1 = that.list1,
									l2 = that.list2,
									l3 = that.list3;
								var ll = []
								//console.log(orderlist.data)
								for (var i = 0; i < orderlist.data.length; i++) {
									var y = i % 3
									var can = true
									var cart_list = orderlist.data[i].cart_list
									cart_list.forEach((item,index)=>{
										// if(item.source!=1&&item.source!=2){
										// 	can=false
										// }
										if(index+1==cart_list.length){
											if(can){
												ll.push(orderlist.data[i])
											}
										}
									})
										if (y == 0) {
											l1.push(orderlist.data[i])
										} else if (y == 1) {
											l2.push(orderlist.data[i])
										} else if (y == 2) {
											l3.push(orderlist.data[i])
										}
								}
								//console.log(ll)
								var llll = llist.concat(ll)
								//console.log(llll)
								lorderlist.data = llll
								that.orderlist = lorderlist
								that.all_page = orderlist.all_page
								that.page = page
								that.list1 = l1
								that.list2 = l2
								that.list3 = l3
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			receive(id){
				this.littelbtn = true
				this.modal3 = true
				this.receiveid = id
			},
			receiveOrder() {
				var id = this.receiveid
				this.modal3 = false
				var that = this
				//完成订单（确认收货）
				this.$ajax.post(this.API.api.apiurl.receiveOrder, {
						order_id: id,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log(data)
							location.reload()
						} else {
							that.$Message.info(res.data.message)
							location.reload()
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getliststatus(e) {
				this.liststatus = e
			},
			onBridgeReady(e) {
				var that = this
				window.WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId": e.appId, //公众号名称，由商户传入
						"timeStamp": e.timeStamp, //时间戳，自1970年以来的秒数
						"nonceStr": e.nonceStr, //随机串
						"package": e.package,
						"signType": e.signType, //微信签名方式：
						"paySign": e.paySign //微信签名
					},
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						}else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
							that.modal8 = true
						} else if (res.err_msg == 'get_brand_wcpay_request:fail') {
							that.$Message.info('失败')
						}
					});
			},
			weixinnopay(){
				this.modal8=false
			},
			changesize(e) {
				//切换每页条数时的回调，返回切换后的每页条数
				console.log(e)
			},
			getOrderList() {
				var that = this
				//订单列表
				this.$ajax.post(this.API.api.apiurl.getOrderList, {
						member_id: that.info.member_id,
						page: 1,
						order_type:1,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data.data
						if (res.data.code == 0) {
							var orderlist = data
							//console.log(orderlist)
							// var l1 = [],l2=[],l3=[],l4=[];
							var l1 = [],
								l2 = [],
								l3 = [];
								var ll = []
							for (var i = 0; i < orderlist.data.length; i++) {
								// var order_status = orderlist.data[i].order_status
								var y = i % 3
								var can = true
								// var cart_list = orderlist.data[i].cart_list
								// cart_list.forEach((item)=>{
								// 	if(item.source!=1&&item.source!=2){
								// 		can=false
								// 	}
								// })
								if(can){
									ll.push(orderlist.data[i])
								}
								// if (order_status == -1 || order_status == 0 || order_status == 5 || order_status == 3) {
									if (y == 0) {
										l1.push(orderlist.data[i])
									} else if (y == 1) {
										l2.push(orderlist.data[i])
									} else if (y == 2) {
										l3.push(orderlist.data[i])
									}
								// }
							}
							orderlist.data = ll
							that.orderlist = orderlist
							that.all_page = orderlist.all_page
							that.list1 = l1
							that.list2 = l2
							that.list3 = l3
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			logistics(e) {
				console.log(e)
				//查看物流
			},
			delthis(e) {
				this.littelbtn = true
				//console.log(e)
				//删除订单
				var that = this
				that.modal2 = true
				this.delid = e.id
			},
			del() {
				//console.log('确认删除')
				this.modal2 = false

				var that = this
				this.$ajax.post(this.API.api.apiurl.delOrder, {
						order_id: that.delid,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0 ){
							//console.log(data)
							that.getOrderList()
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			cancelOrder(e) {
				//console.log(e)
				//取消未支付订单
				var that = this
				this.$ajax.post(that.API.api.apiurl.cancelOrder, {
						order_id: e.id,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			godetail(e) {
				if (this.littelbtn == true) {
					//console.log('点的小的')
					this.littelbtn = false
				} else {
					//console.log('godetail', e, this.littelbtn)
					this.$router.push({
						path: 'order-detail',
						query: {
							id: e
						}
					})
					clearInterval(thepaytime)
				}
			},

			gopaythis(e) {
				this.littelbtn = true
				var that = this
				if(localStorage.getItem('way')=='pc'){
					that.gopay(e,3)
				}else if(localStorage.getItem('way')=='weixin'){
					that.gopay(e,4)
				}else{

					if(that.isAlipay) {
							that.gopay(e,5)
						} else {
							that.gopay(e,2)
						}
				}
			},
			gopay(e,tp) {
				console.log(e,tp)
				//确认支付
				var that = this
				var pay_type = localStorage.getItem('way')
				//console.log(tp)
				this.$ajax.post(this.API.api.apiurl.pay, {
						order_id: e.id,
						member_id: that.info.member_id,
						pay_type: tp
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						console.log(res)
						var data = res.data
						if (res.data.code == 0) {
							console.log(data)
							if (tp == 3) {
								var qr = data.data.returnData.qr_url
								that.checkOrder(data.data.orderId)
								that.qr = qr
								that.showqr = true
							} else if (tp == 1) {
								var returnData = data.data
								var wurl = window.location.href
								//console.log(returnData.returnData)
								if(pay_type=='h5'){
									var url = 'https://wx.tenpay.com/cgi-bin/mmpayweb-bin/checkmweb?prepay_id=' + returnData.returnData.prepay_id +
										'&amp;package=' + returnData.returnData.package + '&amp;redirect_url=' + wurl
									//console.log(url)
									window.location.href = url
								}else{
									if (typeof WeixinJSBridge == "undefined") {
										if (document.addEventListener) {
											document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(returnData.returnData), false);
										} else if (document.attachEvent) {
											document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(returnData.returnData));
											document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(returnData.returnData));
										}
									} else {
										that.onBridgeReady(returnData.returnData);
									}
								}
								that.checkOrder(data.data.orderId)
							} else if (tp == 2) {
								var rdata = data.data
								//console.log(rdata)
								// var rurl = window.location.href
								var zurl = rdata.returnData.payinfo

								that.$router.push({
									path: 'paid',
									query: {
										id: data.data.orderId,
									}
								})
								setTimeout(function(){
									window.location.href = zurl
								},2000)
							}else if(tp==4){
								//console.log(data.data)
								that.H5params = data.data.returnData
								that.weixinpay()
							}else if(tp == 5) {
										console.log('创建订单接口',data.data.returnData)
									console.log('传输数据到支付宝小程序',my)
											my.postMessage({
												data: {
													action: 'startPayment',
													orderInfo: data.data.returnData // 传递支付参数
												}
											});
											this.checkOrderAliPay(data.data.orderId)
									}
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						that.$Message.info(error)
					})
			},
			// 拉起微信h5支付
			weixinpay(){
				let _H5params = this.H5params
				//console.log(this.H5params)
				var z = 'https://syb.allinpay.com/apiweb/h5unionpay/unionorder'
				// let _url   = this.API.api.apiurl.zhifuurl + "?";
				let _url   = z + "?";
				_url=  _url + "cusid="+ _H5params.cusid;
				_url=  _url + "&appid="+ _H5params.appid;
				_url=  _url + "&version="+ _H5params.version;
				_url=  _url + "&trxamt="+ _H5params.trxamt;
				_url=  _url + "&reqsn="+ _H5params.reqsn;
				_url=  _url + "&charset="+ _H5params.charset;
				_url=  _url + "&returl="+ _H5params.returl;
				_url=  _url + "&notify_url="+ _H5params.notify_url;
				_url=  _url + "&body="+ _H5params.body;
				_url=  _url + "&remark="+ _H5params.remark;
				_url=  _url + "&randomstr="+ _H5params.randomstr;
				_url=  _url + "&validtime="+ _H5params.validtime;
				_H5params.limit_pay?_url=  _url + "&limit_pay="+ _H5params.limit_pay:'';
				_url=  _url + "&sign="+ _H5params.sign
				// //console.log(_url)
				// window.open(_url );
				window.location.href=_url;
			},
			goAlipay(e) {
				//console.log(e)
				// const data = {
				// 	returnUrl: window.location.href
				// }
				const div = document.createElement('div')
				/* 此处form就是后台返回接收到的数据 */
				div.innerHTML = e.data.alipayInfo
				document.body.appendChild(div)
				document.forms[0].submit()
			},
			test() {
				//console.log(e, '刷新或关闭')
				clearInterval(thepaytime);
			},
			checkOrder(e) {
				//console.log(e)
				var that = this
				thepaytime = setInterval(function() {
					//获取报告推荐产品
					that.$ajax.post(that.API.api.apiurl.checkOrder, {
							// count_id:that.count_id,
							order_id: e,
						member_id: that.info.member_id,
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							if (res.data.code == 0) {
								//console.log(res.data)
								// alert('支付完成，跳转页面（暂未开发）')
								if (res.data.data.order_status == 1 || res.data.data.order_status == 11 || res.data.data.order_status == 12) {
									clearInterval(thepaytime)
									// location.reload()
									// that.$router.go(0)
									that.$router.push({
										path: 'paid',
										query: {
											id: e
										}
									})
								}
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}, 1000)
			},
			// 支付宝支付结果
			checkOrderAliPay(e) {
				var that = this
					//获取报告推荐产品
					that.$ajax.post(that.API.api.apiurl.checkOrder, {
							// count_id:that.count_id,
							order_id: e,
						member_id: that.info.member_id,
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							console.log('checkOrderAliPay',res)
							console.log('res.data.code',res.data.code == 0)
							if (res.data.code == 0) {
								console.log('checkOrderAliPay成功',res.data)
								// alert('支付完成，跳转页面（暂未开发）')
									that.$router.replace({
										path: 'paid',
										query: {
											id: e
										}
									})
								// if (res.data.data.order_status == 1 || res.data.data.order_status == 11 || res.data.data.order_status == 12) {
								// }
							} else {
								// 取消支付的情况，弹出弹窗跳转到订单列表页
									alert(res.data.message)
									this.$router.replace({
										path: 'order-list',
									})
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
			},
			goclose() {
				this.showqr = false
				clearInterval(thepaytime)
			}
		}
	}
</script>

<style>
	.orderlist .page-bg {
		background: #FFFFFF;
	}

	.orderlist-title {
		width: 100%;
		height: 0.83rem;
		font-size: 0.59rem;
		line-height: 0.83rem;
		font-weight: bold;
		margin: 0.87rem 0 0.72rem 0;
		text-align: left;
	}

	.orderlist-box {
		width: 100%;
		height: 12.5rem;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.orderList-left {
		width: 3.53rem;
	}

	.orderList-left .orderleft-line {
		width: 100%;
		height: 0.41rem;
		display: flex;
		margin-bottom: 0.59rem;
		cursor: pointer;
	}

	.statu-icon {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.055rem 0;
	}

	.statu-txt {
		width: 1.09rem;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		margin-left: 0.15rem;
		color: #1A1A1A;
	}

	.orderleft-line .listactive {
		color: #C25E4D;
	}

	.statu-right {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.055rem 0;
		margin-left: 1.69rem;
	}

	.orderList-right {
		width: 13.76rem;
		height: auto;
	}

	.orderList-right .orderList-list {
		/* width: 100%; */
		width: 6rem;
		height: auto;
		/* min-height: 7rem; */
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		/* overflow-y: scroll; */
	}
	.orderList-mainbox .orderList-list{
		width: 100%;
	}

	.orderList-list::-webkit-scrollbar {
		display: none
	}

	.orderList-list .order-mainbox {
		width: 100%;
		height: auto;
		/* min-height: 3.37rem; */
		background: #FFFFFF;
		border-radius: 0.19rem;
		/* border: 0.04rem solid #D5DDE6; */
		box-shadow: 0rem 0.1rem 0.18rem 0rem rgba(0, 0, 0, 0.1);
		padding: 0.3rem;
		margin-bottom: 0.4rem;
	}

	.order-topline {
		width: 100%;
		height: 0.67rem;
		border-bottom: 0.04rem solid #333333;
		line-height: 0.37rem;
		font-size: 0.3rem;
		display: flex;
		justify-content: space-between;
	}

	.order-topline .topline-right {
		color: #C25E4D;
	}

	.order-bodyline-list {
		width: 100%;
		height: auto;
		min-height: 1.48rem;
		margin-top: 0.59rem;
	}

	.order-bodyline {
		width: 100%;
		height: 1.48rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.3rem;
	}

	.order-bodyline .orderimg {
		width: 1.48rem;
		height: 1.48rem;
		border-radius: 0.19rem;
	}

	.order-namebox {
		width: calc(100% - 1.68rem);
		height: 1.48rem;
		text-align: left;
	}

	.order-namebox .order-name {
		width: 100%;
		height: 0.41rem;
		color: #1A1A1A;
		font-size: 0.3rem;
	}

	.order-price {
		margin-top: 0.26rem;
		display: flex;
		justify-content: space-between;
	}

	.order-pricebox {
		width: auto;
		height: 0.82rem;
	}

	.order-pricebox .order-buytype {
		height: 0.37rem;
		line-height: 0.37rem;
		font-size: 0.26rem;
		color: #666666;
	}

	.orderlist-price {
		height: 0.41rem;
		line-height: 0.41rem;
		margin-top: 0.04rem;
		font-size: 0.3rem;
		color: #1A1A1A;
	}

	.orderlist-btns {
		width: 1.78rem;
		height: 0.59rem;
		margin-top: 0.22rem;
	}

	.orderlist-btn {
		width: 1.78rem;
		height: auto;
		line-height: 0.59rem;
		text-align: center;
		border-radius: 0.59rem;
		border: 0.02rem solid #333333;
		color: #1A1A1A;
		font-size: 0.22rem;
		cursor: pointer;
	}

	.orderList-page {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: flex-end;
		margin-top: 0.3rem;
	}

	.orderList-mainbox {
		width: 100%;
		/* height: 10.5rem;
		overflow-y: scroll; */
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	.orderList-mainbox::-webkit-scrollbar {
		display: none;
	}

	header {
		color: #f60;
		text-align: center;
	}

	footer .public-btn {
		width: 4.22rem;
		height: 1.11rem;
		line-height: 1.11rem;
		background: #C25E4D;
		box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(194, 94, 77, 0.25);
		border-radius: 0.57rem;
		font-size: 0.3rem;
		color: #FFFFFF;
		text-align: center;
	}

	.order-no{
		display: none;
	}

	@media screen and (min-width:1001px) {
		.order-namebox{
			display: flex;
		}
		.order-pricebox{
			display: flex;
			width: calc( 100% - 2rem );
			justify-content: space-between;
		}
		.order-namebox .order-name{
			width: 5.5rem;
			height: 0.52rem;
			line-height: 0.52rem;
			font-size: 0.37rem;
			font-weight: bold;
			color: #1A1A1A;
			margin: 0.48rem 0;
			margin-left: 0.1rem;
		}
		.order-price{
			width: calc( 100% - 5.8rem );
			margin-top: 0;
			height: 1.48rem;
		}
		.orderlist-btns{
			margin-top: 0;
		}
		.orderlist-btn{
			margin: 0.445rem 0;
		}
		.orderlist-price{
			width: 3.5rem;
			height: 0.52rem;
			line-height: 0.52rem;
			font-size: 0.37rem;
			font-weight: bold;
			color: #1A1A1A;
			margin: 0.48rem 0;
			text-align: left;
		}
		.order-pricebox .order-buytype{
			width: auto;
			height: 0.52rem;
			line-height: 0.52rem;
			font-size: 0.37rem;
			color: #666666;
			margin: 0.48rem 0;
		}
	}
	@media screen and (max-width:1000px) {
		.orderList-mainbox {
			justify-content: center;
		}

		.orderList-list .order-mainbox {
			width: 100%;
			padding-bottom: 0.19rem;
			padding-top: 0.59rem;
		}
		.orderList-list{
			width: 100%;
			padding: 0.19rem;
		}
		.orderlist-title{
			padding: 0 0.19rem;
			margin: 0.59rem auto;
		}
		.orderList-page{
			padding: 0 0.19rem;
		}
		.orderlist .order-namebox .order-name,.orderlist .orderlist-price{
			font-size: 0.44rem;
			height: 0.65rem;
			line-height: 0.5rem;
			font-weight: 500;
		}
		.orderlist .order-price{
			margin-top: 0.16rem;
		}
		.orderlist .order-pricebox .order-buytype{
			font-size: 0.3rem;
		}
		.order-bodyline .orderimg{
			width: 2rem;
			height: 2rem;
		}
		.order-bodyline{
			height: 2rem;
			margin-bottom: 0.3rem;
		}
		.orderlist .order-pricebox .order-buytype{
			margin-top: 0.17rem;
		}
		.orderlist-btns{
			margin-top: 0.68rem;
		}
		.order-namebox{
			width:calc( 100% - 2.3rem );
			height: 2rem;
		}
	}
</style>
