<template>
 <div> <a href="https://work.weixin.qq.com/ca/cawcde25aa258fb9f9">点击跳转</a></div>
</template>

<script>
export default {
 data(){
 return {
 msg: 'hello world!'
 }

 }

}

</script>

<style scoped>
</style>
