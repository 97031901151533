import Vue from "vue";
import Router from "vue-router";
import HelloWorld from "@/components/HelloWorld";
import index from "@/pages/index.vue";
import home from "@/pages/home.vue";
import questionnaire from "@/pages/questionnaire.vue";
import report from "@/pages/report.vue";
import reportdetail from "@/pages/reportdetail.vue";
import define from "@/pages/define.vue";
import settlement from "@/pages/settlement.vue";
import login from "@/pages/login.vue";
import cart from "@/pages/cart.vue";
import paid from "@/pages/paid.vue";
import personal from "@/pages/personal.vue";
import addresslist from "@/pages/addresslist.vue";
import newaddress from "@/pages/newaddress.vue";
import orderdetail from "@/pages/orderdetail.vue";
import orderlist from "@/pages/orderlist.vue";
import myreport from "@/pages/myreport.vue";
import notFound from "@/pages/404.vue";
import productDetail from "@/pages/pro_detail.vue";
import productList from "@/pages/prolist.vue";
import research from "@/pages/research.vue";
import goods_unit from "@/pages/goods_unit.vue";
import getreport from "@/pages/getreport.vue";
import kefu from "@/pages/kefu.vue";
import meal_list from "@/pages/meal_list.vue";
import meal_detail from "@/pages/meal_detail";
import wxpaid from "@/pages/wxpaid";
import privacy_policy from "@/pages/privacyPolicy";
import terms_of_use from "@/pages/termsOfUse";
import fulfillment from "@/pages/fulfillment";
import blog from "@/pages/blog";
import about from "@/pages/about";
import nutritionQueryIndex from "@/pages/nutritionQuery/index"
import nutritionQueryCategory from "@/pages/nutritionQuery/category"
import shanghai from '@/pages/shanghai'
import qrcode from '@/pages/qrcode'
import isbserve from '@/pages/is_b_serve'
import shengyiafter from "@/pages/shengyiafter";
import shengyibefor from "@/pages/shengyibefor";
import shengyinow from "@/pages/shengyinow";
import weixin from '@/pages/weixin'
import ketogenesis from '@/pages/ketogenesis'
import ketogenesis2 from '@/pages/ketogenesis2'
import userJudai from '@/pages/userJudai'
import checkInEveryDay from '@/pages/checkInEveryDay'
import NoPermission from '@/pages/NoPermission'
import ketogenesis3 from '@/pages/ketogenesis3'
import ketogenesis4 from '@/pages/ketogenesis4'
import bmi from "@/pages/bmi"
import camp from '@/pages/nutritionQuery/weight_loss_camp'
Vue.use(Router)

// export default new Router({
const routes = [{
		path: "/",
		name: 'index',
		component: index,
		meta: {}
	}, 
	{
		path:'/weixin',
		name:'weixin',
		component:weixin
	},
	{
		path:'/shanghaishow',
		name:'shanghai',
		component:shanghai,
	},
	{
		path:'/isbserve',
		name:'isbserve',
		component:isbserve
	},
	{
		path:'/shengyiafter',
		name:'shengyiafter',
		component:shengyiafter,
	},
	{
		path:'/shengyibefor',
		name:'shengyibefor',
		component:shengyibefor,
	},
	{
		path:'/shengyinow',
		name:'shengyinow',
		component:shengyinow,
	},
	{
		path:'/qrcode',
		name:'qrcode',
		component:qrcode
	},
	{
		path:'/ketogenesis',
		name:'ketogenesis',
		component:ketogenesis
	},
	{
		path:'/ketogenesis2',
		name:'ketogenesis2',
		component:ketogenesis2
	},
	{
		path:'/ketogenesis3',
		name:'ketogenesis3',
		component:ketogenesis3
	},
	{
		path:'/ketogenesis4',
		name:'ketogenesis4',
		component:ketogenesis4
	},
	{
		path:'/bmi',
		name:'bmi',
		component:bmi
	},
	{
		path:'/userJudai',
		name:'userJudai',
		component:userJudai
	},
	{
		path:'/checkInEveryDay',
		name:'checkInEveryDay',
		component:checkInEveryDay
	},
	{
		path:'/NoPermission',
		name:'NoPermission',
		component:NoPermission
	},
	{
		path: "/index",
		name: 'index',
		component: index,
		meta: {}
	}, {
		path: "/home",
		name: 'Home',
		component: home,
		meta: {}
	}, {
		path: "/helloWorld",
		name: 'HelloWorld',
		component: HelloWorld,
		meta: {}
	}, {
		path: "/privacy-policy",
		name: 'privacy_policy',
		component: privacy_policy,
		meta: {}
	}, {
		path: "/terms-of-use",
		name: 'terms_of_use',
		component: terms_of_use,
		meta: {}
	}, {
		path: "/questionnaire",
		name: 'Questionnaire',
		component: questionnaire,
		meta: {}
	}, {
		path: "/report",
		name: 'Report',
		component: report,
		meta: {}
	}, {
		path: "/report-detail",
		name: 'Reportdetail',
		component: reportdetail,
		meta: {}
	}, {
		path: "/define",
		name: 'Define',
		component: define,
		meta: {}
	}, {
		path: "/settlement",
		name: 'Settlement',
		component: settlement,
		meta: {}
	}, {
		path: "/login",
		name: 'Login',
		component: login,
		meta: {}
	}, {
		path: "/cart",
		name: 'Cart',
		component: cart,
		meta: {}
	}, {
		path: "/paid",
		name: 'Paid',
		component: paid,
		meta: {}
	}, {
		path: "/personal",
		name: 'Personal',
		component: personal,
		meta: {}
	}, {
		path: "/my-report",
		name: 'Myreport',
		component: myreport,
		meta: {}
	}, {
		path: "/order-detail",
		name: 'Orderdetail',
		component: orderdetail,
		meta: {}
	}, {
		path: "/order-list",
		name: 'Orderlist',
		component: orderlist,
		meta: {}
	}, {
		path: "/new-address",
		name: 'Newaddress',
		component: newaddress,
		meta: {}
	}, {
		path: "/address-list",
		name: 'Addresslist',
		component: addresslist,
		meta: {}
	}, {
		path: "/404",
		name: '404',
		component: notFound,
		meta: {}
	}, {
		path: "/product-detail",
		name: 'productDetail',
		component: productDetail,
		meta: {}
	}, {
		path: "/product-list",
		name: 'productList',
		component: productList,
		meta: {}
	}, {
		path: "/research",
		name: 'research',
		component: research,
		meta: {}
	}, {
		path: "/goods-unit",
		name: 'goods_unit',
		component: goods_unit,
		meta: {}
	}, {
		path: "/get-report",
		name: 'getreport',
		component: getreport,
		meta: {}
	}, {
		path: "/service",
		name: 'kefu',
		component: kefu,
		meta: {}
	}, {
		path: "/care-packs",
		name: 'care_packs',
		component: meal_list,
		meta: {}
	}, {
		path: "/meal-detail",
		name: 'meal_detail',
		component: meal_detail,
		meta: {}
	},{
		path: "/fulfillment",
		name: 'fulfillment',
		component: fulfillment,
		meta: {}
	},{
		path: "/blog",
		name: 'blog',
		component: blog,
		meta: {}
	},{
		path: "/about",
		name: 'about',
		component: about,
		meta: {}
	},{
		path: "/food-index",
		name: 'nutritionQueryIndex',
		component: nutritionQueryIndex,
		meta: {}
	},{
		path: "/food-category",
		name: 'nutritionQueryCategory',
		component: nutritionQueryCategory,
		meta: {
			hiddenFooter: true
		}
	},
	// {
	// 	path: '*', // 页面不存在的情况下会跳到404页面
	// 	redirect: '/404',
	// 	name: '404',
	// 	hidden: true
	// },
	{
		path: '/wxpaid',
		name: 'wxpaid',
		component: wxpaid,
		meta: {}
	},
	{
		path: '/food-search',
		name: 'foodSearch',
		component: () => import('@/pages/nutritionQuery/foodSearch.vue'),
		meta: {
			title: '搜索',
			hiddenFooter: true
		}
	},
	{
		path: '/food-nutrition',
		name: 'foodNutritionDetail',
		component: () => import('@/pages/nutritionQuery/foodNutritionDetail.vue'),
		meta: {
			title: '食物营养详情',
			hiddenFooter: true
		}
	},
	{
		path: '/food-more',
		name: 'moreNutrientElements',
		component: () => import('@/pages/nutritionQuery/moreNutrientElements.vue'),
		meta: {
			title: '食物营养详情',
			hiddenFooter: true
		}
	},
	{
		path:'/loss-camp',
		name:'camp',
		component:camp,
		meta: {
			title: '专业减肥',
			hiddenFooter: true
		}
	}
]
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
	mode: 'history',
	routes: routes //注意是routes而不是routers,坑就在这里
})
router.beforeEach((to, from, next) => {
	var topath = to.path
	var rou = routes
	var ar = from.path.split("/")
	var br = topath.split("/")

	// 从中转页去做问卷隐藏底部和顶部
	// if (from.path === '/transfer-page') {
	// 	to.meta.hiddenFooter = true
	// 	to.meta.hiddenNav = 'hidden'
  // }

	if (ar.length == 3 || br.length == 3) {
		if (ar.length == 3) {
			if (br.length == 3 && br[2] != '') {
				for (var ii = 0; ii < rou.length; ii++) {
					var ite = rou[ii]
					if (ite.path == topath) {
						next({
							path: '/' + br[2]
						})
						break
					} else {
						if (ii + 1 == rou.length && ite.path != topath) {
							next({
								path: '/404'
							})
						}
					}
				}
			}
		}
		if (br.length == 3 && br[2] != '') {
			next({
				path: '/' + br[2]
			})
		}
	} else {
		for (var i = 0; i < rou.length; i++) {
			var item = rou[i]
			if (item.path == topath) {
				break
			} else {
				if (i + 1 == rou.length && item.path != topath) {
					next({
						path: '/404'
					})
				}
			}
		}
		var gq = localStorage.getItem('goq') ? localStorage.getItem('goq') : '';
		if (from.name == 'Paid' && to.name == 'Define') {
			next({
				path: 'cart'
			})
		}
		if (from.name == 'Questionnaire' && to.name == 'Home') {
			// var q = localStorage.getItem('q1') ? localStorage.getItem('q1') : 'false'
			var q2 = localStorage.getItem('q2') ? localStorage.getItem('q2') : 'false'
			localStorage.setItem('q1', true)
			if (q2 == 'false') {
				//console.log(q)
			} else {
				localStorage.setItem('q1', false)
				localStorage.setItem('q2', false)
				next()
			}
		} else if (from.name == 'Report' && to.name == 'Questionnaire') {
			next({
				path: 'my-report'

			})
		} else if (from.name == 'Report' && to.name == 'getreport') {
			next({
				path: 'my-report'
			})
		} else if (from.name == 'Report' && to.name == 'Home') {
			if (gq == 1) {
				next()
			} else {
				next({
					path: 'my-report'
				})
			}
		} else if (to.name == 'Questionnaire') {
			if (from.name == 'Home') {
				if (gq == 1) {
					next()
					localStorage.setItem('goq', 2)
				}
			} else {
				next({
					path: 'home'
				})
			}
		} else {
			next()
		}
	}
})
export default router
