<template>
	<div class="prolist">
		<div class="show_mb">
			<div class="pro-list" v-for="(item,index) in goodslist" :key="index">
				<transition name="slide-fade1" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==0" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<transition name="slide-fade2" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==1" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<div class="pro-title">{{item.label_name}}</div>
				<div class="title-line"></div>
				<div class="pro-msg">{{item.label_desc}}</div>
				<div class="pro-goodslist">
					<div class="pro-goodsbox" v-for="(items,indexs) in item.goods_list" :key="indexs">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="show_pc detail_pc" id="all">
			<div class="list-top-bg">
				<div class="list-top-center">
					<div class="center-txt">
						<div class="center-title"><h1>Start With</h1><h1>The Freshest Source</h1></div>
						<div class="center-msg">全球甄选最优质安全 非转基因的原料</div>
						<!-- <div class="center-title"><p>全球甄选</p><p>从最新鲜来源开始</p></div>
						<div class="center-msg">最优质安全、非转基因的原料在全球范围内甄选，我们与供应商协同采购，更明确配方中确切的成分，确保进入体内的成分仅仅来自信誉良好的来源。</div> -->
					</div>
				</div>
			</div>
			<div class="pro-list-box">
				<div class="ab-img ab1">
					<img src="../../public/imgs/prolist/p1.png" alt="">
				</div>
				<div class="ab-img ab2">
					<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/c8757878fffe29eb2b42d59338aef9f0.png" alt="">
				</div>
				<div class="pro-lis-bg-box">
					<div class="pro-bg-title">
						<div class="pro-bg-name">Vitamins</div>
						<div class="pro-bg-msg">{{goodslist[0].label_name}}</div>
					</div>
					<div class="pro-bg-txt">
						{{goodslist[0].label_desc}}
					</div>
				</div>
				<div class="pro-list-pros">
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[0].goods_list" :key="indexs">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
				</div>
			</div>
			<div class="plist-h1">
				<div class="h1-box">
					<div class="h1-title">
						<p>Personalized</p>
						<p>Dietary Supplements</p>
					</div>
					<div class="h1-msg">太多的选择，什么才最适合我？</div>
					<a class="h1-button" href="/home">开始 AI 定制</a>
				</div>
				<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/bgshou.png" alt="">
			</div>
			<div class="pro-list-box pro-list-box1">
				<div class="ab-img ab3">
					<img src="../../public/imgs/prolist/p3.png" alt="">
				</div>
				<div class="ab-img ab4">
					<img src="../../public/imgs/prolist/p4.png" alt="">
				</div>
				<div class="pro-lis-bg-box">
					<div class="pro-bg-title">
						<div class="pro-bg-name">Minerals</div>
						<div class="pro-bg-msg">{{goodslist[1].label_name}}</div>
					</div>
					<div class="pro-bg-txt">
						{{goodslist[1].label_desc}}
					</div>
				</div>
				<div class="pro-list-pros">
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[1].goods_list" :key="indexs" v-show="indexs<2">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[1].goods_list" :key="indexs" v-show="indexs>=2">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="pro-list-box pro-list-box2">
				<div class="ab-img ab5">
					<img src="../../public/imgs/prolist/p5.png" alt="">
				</div>
				<div class="ab-img ab6">
					<img src="../../public/imgs/prolist/p6.png" alt="">
				</div>
				<div class="pro-lis-bg-box">
					<div class="pro-bg-title">
						<div class="pro-bg-name">Herbs</div>
						<div class="pro-bg-msg">{{goodslist[2].label_name}}</div>
					</div>
					<div class="pro-bg-txt">
						{{goodslist[2].label_desc}}
					</div>
				</div>
				<div class="pro-list-pros">
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[2].goods_list" :key="indexs">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
				</div>
			</div>
			<div class="plist-h2">
				<div class="plist-h2-bg">
					
				</div>
				<div class="h2-img">
					<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/goods/images/20200804/afd91b1fa48e3da41ecc3d1db94ce6e2.jpg" alt="">
				</div>
			</div>
			<div class="pro-list-box pro-list-box3">
				<div class="ab-img ab7">
					<img src="../../public/imgs/prolist/p7.png" alt="">
				</div>
				<div class="ab-img ab8">
					<img src="../../public/imgs/prolist/p8.png" alt="">
				</div>
				<div class="pro-lis-bg-box">
					<div class="pro-bg-title">
						<div class="pro-bg-name">Probiotics</div>
						<div class="pro-bg-msg">{{goodslist[3].label_name}}</div>
					</div>
					<div class="pro-bg-txt">
						{{goodslist[3].label_desc}}
					</div>
				</div>
				<div class="pro-list-pros">
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[3].goods_list" :key="indexs">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="plist-h3">
				<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/xs/Mask.png" alt="">
			</div>
			<div class="pro-list-box pro-list-box4">
				<div class="ab-img ab9">
					<img src="../../public/imgs/prolist/p9.png" alt="">
				</div>
				<div class="ab-img ab10">
					<img src="../../public/imgs/prolist/p10.png" alt="">
				</div>
				<div class="pro-lis-bg-box">
					<div class="pro-bg-title">
						<div class="pro-bg-name">Specialty Products</div>
						<div class="pro-bg-msg">{{goodslist[4].label_name}}</div>
					</div>
					<div class="pro-bg-txt">
						{{goodslist[4].label_desc}}
					</div>
				</div>
				<div class="pro-list-pros">
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[4].goods_list" :key="indexs" v-show="indexs<2">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[4].goods_list" :key="indexs" v-show="indexs>=2">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
				</div>
			</div>
			<div class="list-bottom-bg">
				<img src="../../public/imgs/prolist/pbg.png" alt="">
				<div class="lbottom-box">
					<div class="lb-title">
						<p>告别盲从，只需3分钟</p>
						<p>已为 <span>200,000+</span> 国人提供个性化营养方案</p>
					</div>
					<a href="/home" class="pro-godingzhi">
						<div>开始 AI 定制</div>
						<img src="../../public/imgs/index/more.png" alt="">
					</a>
				</div>
			</div>
			<!-- <div class="tp-list" :class="puttop?'puttop':''">
				<div class="maolist">
					<a href="#all" :class="tid=='all'?'active':''" @click="tid='all'">全部</a>
					<a :href="'#'+item.id" :class="tid==item.id?'active':''" @click="tid=item.id" v-for="(item,index) in goodslist" :key="index" >{{item.label_name}}</a>
				</div>
			</div> -->
			<!-- <div class="pro-list" v-for="(item,index) in goodslist" :key="index">
				<div class="bg-bg" :style="index==0?'height:164px':''" :id="item.id"></div>
				<transition name="slide-fade1" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==0" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<transition name="slide-fade2" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==1" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<div class="pro-title">
					<div class="meal-pc-main-title-line"></div>
					<p>{{item.label_name}}</p>
				</div>
				<div class="title-line"></div>
				<div class="pro-msg fontsize">{{item.label_desc}}</div>
				<div class="pro-goodslist">
					<div class="pro-goodsbox" :class="indexs%4==3?'noright':''" v-for="(items,indexs) in item.goods_list" :key="indexs">
						<div class="pro-name-box">
							<div class="pro-goodname">{{items.goods_name}}</div>
						</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_shrink" alt="">
						</div>
						<div class="goods-daynum" @click="godetail(items.id)">
							查看详情<div class="goods-right"><img src="../../public/imgs/more.png" alt=""></div>
						</div>
					</div>
				</div>
			</div>
			<div class="pc_bottom">
				<div class="bimg1">
				</div>
				<div class="btitle">AI 定制营养</div>
				<div class="bimg2">
				</div>
				<div class="bmsg">
					<span>3分钟在线 AI 评估生成你的专属健康报告</span>
					<span>帮助你获得精准补剂方案</span>
				</div>
				<div class="bgoqus cursor" @click="goqus">AI 即刻定制</div>
			</div> -->
		</div>
		<!-- <foot></foot> -->
	</div>
</template>

<script>
	// import foot from '../components/footer.vue'
	export default {
		name: 'productList',
		components: {
			// foot
		},
		data: () => ({
			info: '',
			tid:'all',
			goodslist: '',
			puttop:false,
			show: false
		}),
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true)
			var that = this
			setTimeout(function() {
				that.show = true
			}, 600)
			this.$ajax.post(this.API.api.apiurl.getGoodsList, {
					cid: 0
				})
				.then(res => {
					var data = res.data
					if (data.code == 0) {
						var list = data.data
						list.sort(that.compare('id'))
						that.goodslist = list
					} else {
						that.$Message.info('错误：' + data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
		},
		methods: {
			compare(key){
				return function (o1,o2){
					var a=o1[key],b=o2[key];
					return a-b
				}
			},
			handleScroll() {
			//方法一
			//var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
			////console.log(top)
			
			//方法二
			//如果获取不到高度可换成 
				// this.nextTick(()=>{
					var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
					if(top>350){
						this.puttop=true
					}else{
						this.puttop=false
					}
				// })
			},
			goqus(){
				this.$router.push({
					path:'home'
				})
			},
			godetail(id) {
				this.$router.push({
					path: 'product-detail',
					query: {
						id: id
					}
				})
			}
		},
	}
</script>

<style>
	.lbottom-box{
		width: auto;
		height: auto;
		position: absolute;
	}
	.lb-title{
		font-size: 28px;
		font-weight: bold;
	}
	.lb-title p span{
		color: #C25E4D;
	}
	.pro-godingzhi{
		width: 193px;
		line-height: 60px;
		border-radius: 60px;
		background: #C25E4D;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		margin: 0 auto;
		margin-top: 32px;
		display: flex!important;
		justify-content: center;
		align-items: center;
		font-weight: 500;
	}
	.pro-godingzhi:hover{
		color: #FFFFFF;
	}
	.list-bottom-bg .pro-godingzhi img{
		width: auto;
		height: 20px;
		margin-left: 3px;
	}
	.plist-h1{
		width: 100%;
		max-width: 1200px;
		margin: 0  auto;
		height: 400px;
		background: #F5F5F5;
		position: relative;
		overflow: hidden;
		margin-top: 123px;
		align-items: center;
	}
	.h1-box{
		width: auto;
		height: auto;
		text-align: left;
		margin: 78px 0;
		margin-left: 99px;
	}
	.h1-box .h1-title{
		font-size: 50px;
		line-height: 59px;
		font-weight: bold;
		font-family: MADE Evolve;
	}
	.h1-msg{
		font-size: 18px;
		line-height: 25px;
		margin-top: 16px;
	}
	.h1-button{
		width: 170px;
		line-height: 54px;
		border-radius: 54px;
		background: #C25E4D;
		font-size: 14px;
		color: #FFFFFF;
		text-align: center;
		margin-top: 32px;
	}
	.h1-button:hover{
		color: #FFFFFF;
	}
	.plist-h1 img{
		width: 690px;
		height: auto;
		position: absolute;
		right: 0;
		bottom: 23px;
	}
	.plist-h2{
		width: 100%;
		max-width: 1200px;
		margin: 0  auto;
		height: 528px;
		position: relative;
		display: flex;
		align-items: center;
		margin-top: 123px;
	}
	.plist-h2 .plist-h2-bg{
		width: 584px;
		height: 528px;
		background: #F5F5F5;
		border-radius: 10px;
		position: relative;
	}
	.h2-img{
		width: 1000px;
		height: 400px;
		position: absolute;
		right: 0;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.h2-img img{
		width: 100%;
		height: auto;
	}
	.plist-h3{
		width: 100%;
		max-width: 1200px;
		margin: 0  auto;
		height: 400px;
		background: #F5F5F5;
		position: relative;
		overflow: hidden;
		margin-top: 123px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 302px;
		margin-top: 172px;
	}
	.plist-h3 img{
		width: 100%;
		height: auto;
	}
	.list-bottom-bg{
		width: 100%;
		height: 500px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 161px;
		margin-top: 123px;
	}
	.list-bottom-bg img{
		width: auto;
		height: 100%;
	}
	.pro-goodsbox-none{
		opacity: 0;
		z-index: 0;
	}
	.pro-list-box{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		margin-top: 164px;
	}
	.pro-list-box2{
		margin-top: 336px;
		margin-top: 123px;
	}
	.pro-list-box3{
		margin-top: 213px;
		margin-top: 164px;
	}
	.pro-list-box4{
		margin-top: 255px;
		margin-top: 164px;
	}
	.pro-list-box1{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		margin-top: 321px;
		margin-top: 164px;
	}
	.pro-lis-bg-box{
		width: 682px;
		height: 625px;
		border-radius: 10px;
		background: #C6D0D7;
		background: rgba(198,208,215,0.8);
		position: absolute;
		/* opacity: 0.8; */
		left: 0;
		top: 0;
		padding: 64px;
	}
	.pro-bg-title{
		width: auto;
		height: auto;
		text-align: left;
	}
	.pro-bg-title .pro-bg-name{
		width: auto;
		line-height: 50px;
		border-left: 10px solid #C25E4D;
		font-size: 60px;
		font-size: 48px;
		font-weight: bold;
		text-indent: 26px;
		font-family: MADE Evolve;
	}
	.pro-bg-msg{
		font-size: 18px;
		margin-top: 16px;
		text-indent: 36px;
	}
	.pro-bg-txt{
		width: 388px;
		height: auto;
		text-align: justify;
		margin-left: 36px;
		font-size: 18px;
		line-height: 30px;
		margin-top: 32px;
	}
	.pro-list-pros{
		position: inherit;
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
		padding-top: 64px;
		/* justify-content: space-around; */
	}
	.prolist .detail_pc .pro-list-pros .pro-goodsbox{
		margin: 0 15px;
		width: calc(25% - 37.5px);
		margin-bottom: 41px;
	}
	
	
	.ab-img{
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
	}
	.ab-img img{
		width: 70%;
		height: auto;
	}
	.ab1{
		width: 274px;
		/* height: 274px; */
		top: calc(164px - 256px);
		right: calc(245px - 360px);
	}
	.ab1 img{
		width: 60%;
	}
	.ab2{
		width: 260px;
		/* height: 268px; */
		left: -150px;
		top: 234px;
	}
	.ab3{
		width: 199px;
		left: calc(290px - 360px);
		top: calc(164px - 221px);
	}
	.ab4{
		width: 240px;
		right: calc(246px - 360px);
		top: 240px;
	}
	.ab5{
		width: 251px;
		right: calc(290px - 360px);
		top: calc(164px - 236px);
	}
	.ab6{
		width: 365px;
		left: calc(123px - 360px);
		top: 206px;
	}
	.ab7{
		width: 273px;
		left: calc(282px - 360px);
		top: calc(164px - 213px);
	}
	.ab8{
		width: 200px;
		right: calc(245px - 360px);
		top: auto;
		bottom: -78px;
	}
	.ab9{
		width: 383px;
		right: calc(185px - 360px);
		top: calc(164px - 255px);
	}
	.ab10{
		width: 393px;
		left: calc(130px - 360px);
		top: 640px;
		z-index: 0;
	}
	.pro-list-box1 .pro-lis-bg-box{
		height: 468px;
		/* opacity: 0.8; */
		background: #B9C7BB;
		background: rgba(185,199,187,0.8);
		left: auto;
		right: 0;
		padding-left: 165px;
	}
	.pro-list-box2 .pro-lis-bg-box{
		height: 625px;
		/* opacity: 0.8; */
		background: #BDBCC8;
		background: rgba(189,188,200,0.8);
		padding-left: 70px;
	}
	.pro-list-box3 .pro-lis-bg-box{
		height: 468px;
		/* opacity: 0.8; */
		background: #D8C7BF;
		background: rgba(216,199,191,0.8);
		left: auto;
		right: 0;
		padding-left: 169px;
	}
	.pro-list-box4 .pro-lis-bg-box{
		height: 625px;
		/* opacity: 0.8; */
		background: #E4DBCC;
		background: rgba(228,219,204,0.8);
		left: auto;
		right: 0;
		padding-left: 169px;
	}
	
	
	/* 可以设置不同的进入和离开动画 */
	/* 设置持续时间和动画函数 */
	.slide-fade1-enter-active {
		transition: all .8s ease;
	}

	.slide-fade1-leave-active {
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade1-enter,
	.slide-fade1-leave-to {
		transform: translateX(40px);
		opacity: 0;
	}

	.slide-fade2-enter-active {
		transition: all .8s ease;
	}

	.slide-fade2-leave-active {
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade2-enter,
	.slide-fade2-leave-to {
		transform: translateX(-40px);
		opacity: 0;
	}

	.prolist {
		width: 100%;
		height: auto;
		overflow: hidden;
	}

	.pro-list {
		width: 100%;
		margin: 0 auto;
		padding: 0 0.45rem;
		margin-bottom: 3.25rem;
		position: relative;
	}

	.pro-title {
		width: 5.67rem;
		height: 1.24rem;
		line-height: 1.24rem;
		color: #1A1A1A;
		font-size: 0.64rem;
		font-weight: 400;
		margin: 0 auto;
		margin-top: 0.91rem;
	}

	.title-line {
		width: 1.1rem;
		height: 0.14rem;
		background: #C25E4D;
		border-radius: 0.14rem;
		margin: 0 auto;
		margin-top: 0.23rem;
	}

	.prolist .pro-msg {
		width: 84%;
		line-height: 0.75rem;
		font-size: 0.435rem;
		color: #1A1A1A;
		margin: 0 auto;
		margin-top: 1rem;
		text-align: left;
	}

	.pro-goodslist {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 1.13rem;
	}

	.pro-goodsbox {
		width: calc(50% - 0.2rem);
		height: auto;
		background: #FFFFFF;
		box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.1);
		border-radius: 0.17rem;
		padding: 0.3rem;
		margin-bottom: 0.4rem;
	}

	.prolist .pro-name-box {
		width: 100%;
		/* height: 0.93rem;
		line-height: 0.93rem; */
		font-size: 0.38rem;
		font-size: 20px;
		color: #1A1A1A;
		font-weight: bold;
		margin-top: 0.3rem;
		padding: 3px 0;
		overflow: hidden;
	}

	.prolist .pro-img {
		width: 100%;
		height: 3.16rem;
		height: auto;
		margin-top: 0.43rem;
		padding-bottom: 0.32rem;
		border-bottom: 0.02rem solid #000000;
	}

	.prolist .pro-img img {
		width: 100%;
		height: auto;
		cursor: pointer;
		border-radius: 0.19rem;
	}

	.goods-daynum {
		width: 100%;
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.3rem;
		margin-top: 0.44rem;
		text-align: left;
		display: flex;
		justify-content: space-between;
	}

	.goods-daynum .goods-right img {
		width: 0.4rem;
		height: 0.4rem;
		margin: 0.105rem 0;
	}

	.pimgs {
		position: absolute;
		width: 3rem;
		height: auto;
	}

	.pimg1 {
		top: 0;
		left: -1.05rem;
	}

	.pimg2 {
		top: 0;
		right: -1.05rem;
	}

	.pimg3 {
		width: 2.5rem;
		top: 4.8rem;
		left: -1.45rem;
	}

	.pimg4 {
		width: 2.5rem;
		top: 4.8rem;
		right: -1.45rem;
	}
	
	
	
	/* pc */
	.list-top-bg{
		width: 100%;
		height: 500px;
		background: #f1f4f9;
	}
	.list-top-center{
		width: 22.22rem;
		height: 6.48rem;
		height: 500px;
		margin: 0 auto;
		background: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/list_bg.png);
		background-repeat: no-repeat;
		/* background-size: 18.5rem 6.48rem ; */
		background-size: 1140px 400px ;
		background-position: right center;
		/* padding-top: 1.3rem; */
		display: flex;
		align-items: center;
	}
	.center-txt{
		width: 8.41rem;
		width: auto;
		height: auto;
		/* margin-left: 0.57rem; */
	}
	.center-title{
		width: 100%;
		/* height: 0.83rem; */
		line-height: 1.2;
		font-size: 56px;
		font-weight: bold;
		text-align: left;
		font-family: MADE Evolve;
		color: #1A1A1A;
	}
	.center-msg{
		width: 482px;
		height: auto;
		margin-top: 0.75vw;
		margin-top: 32px;
		line-height: 1.5;
		font-size:18px;
		color: #1A1A1A;
		text-align: justify;
		/* font-size: 24px; */
	}
	.tp-list{
		width: 100%;
		height: 1.7rem;
		margin: 0 auto;
		background: #FFFFFF;
		display: flex;
		justify-content: center;
		z-index: 8;
	}
	.puttop{
		width: 100vw;
		height: 1.7rem;
		background: #FFFFFF;
		position: fixed;
		top: 1.02rem;
		left: 0;
		box-shadow: 0rem 0.1rem 0.2rem 0rem #Efefef;
	}
	.maolist{
		width: 18.5rem;
		height: 1.7rem;
		margin: 0 auto;
		display: flex;
		justify-content: left;
	}
	/* .ivu-anchor-ink{
		display: none;
	} */
	.maolist a{
		padding: 0;
		width: auto;
		padding: 0 0.1rem;
		height: 0.5rem;
		line-height: 0.5rem;
		margin: 0.6rem 0.595rem;
		font-size: 0.3rem;
		color: #1A1A1A;
	}
	.maolist a:hover{
		color: #1A1A1A!important;
		text-decoration: none!important;
	}
	.maolist .active{
		color: #1A1A1A;
		height: 0.5rem;
		line-height: 0.5rem;
		font-weight: bold;
		border-bottom: 0.07rem solid #C25E4D;
		text-decoration: none;
	}
	/* .ivu-affix{
		top: 1.02rem!important;
		width: 100vw!important;
	}
	.ivu-anchor-link-title{
		color: #1A1A1A!important;
		text-decoration: none!important;
	} */
	.detail_pc .pro-list{
		width: 18.5rem;
		height: auto;
		margin: 0 auto;
		padding: 0;
		/* padding-top: 1.19rem;
		margin-bottom: 1.74rem; */
		padding-top: 0;
		margin-bottom: 0;
	}
	
	.detail_pc .pimg1 {
		width: 3rem;
		top: 2.6rem;
		left: -0.85rem;
		z-index: -1;
		top: 10vw;
		top: 3.1rem;
	}
	
	.detail_pc .pimg2 {
		width: 2.96rem;
		top: 2.52rem;
		top: 9.5vw;
		top: 3rem;
		right: -2rem;
		z-index: -1;
	}
	
	.detail_pc .pimg3 {
		width: 2.5rem;
		top: 6.8rem;
		top: 22vw;
		top: 6.7rem;
		left: -2rem;
		z-index: -1;
	}
	
	.detail_pc .pimg4 {
		width: 2.5rem;
		top: 5.4rem;
		top: 20vw;
		top: 6.2rem;
		right: -1.45rem;
		z-index: -1;
	}
	.detail_pc .pro-title{
		margin: 0 auto;
		color: #1A1A1A;
		height: auto;
		line-height: 1.2;
		font-size: 48px;
		font-weight: bold;
		font-family: MADE Evolve;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.pro-title .meal-pc-main-title-line{
		height: 2.4vw;
		max-height: 57px;
	}
	.detail_pc .title-line{
		width: 0.7rem;
		height: 0.07rem;
		background: #C25E4D;
		border-radius: 0.04rem;
		margin-top: 0.31rem;
		display: none;
	}
	.detail_pc .pro-msg{
		width: 10.1rem;
		margin-top: 0.75vw;
		margin-top: 32px;
		text-align: justify;
		text-align: center;
		font-size: 0.3rem;
		color: #1A1A1A;
		font-size: 18px!important;
		line-height: 1.5;
	}
	.detail_pc .pro-goodslist{
		width: 18.5rem;
		justify-content: left;
		margin: 0 auto;
		margin-top: 82px;
	}
	.detail_pc .pro-goodsbox{
		width: 4.2rem;
		padding: 0.44rem 0.28rem;
		margin-right: 0.56rem;
		margin-bottom: 0.61rem;
	}
	.detail_pc .prolist .pro-name-box{
		line-height: 0.52rem;
		height: 0.52rem;
		font-size: 0.37rem;
		margin-top: 0;
	}
	.detail_pc .prolist .pro-img{
		/* height: 2.62rem; */
		margin-top: 0.52rem;
		padding-bottom: 0.3rem;
	}
	.detail_pc .goods-daynum{
		height: 0.37rem;
		font-size: 0.26rem;
		font-size: 18px;
		margin-top: 0.44rem;
		line-height: 0.37rem;
		cursor: pointer;
	}
	.detail_pc .goods-daynum .goods-right img{
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.035rem 0;
	}
	.pc_bottom{
		width: 10.19rem;
		height: auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 auto;
		margin-bottom: 1.74rem;
		margin-top: calc(164px - 0.44rem);
	}
	.pc_bottom .bimg1,.pc_bottom .bimg2{
		width: 2.13rem;
		height: 2.3rem;
		display: none;
	}
	.pc_bottom .bimg1 img,.pc_bottom .bimg2 img{
		width: 100%;
		height: 100%;
	}
	.pc_bottom .btitle{
		width: 100%;
		/* height: 2.3rem; */
		line-height: 1.2;
		text-align: center;
		font-size: 48px;
		color: #1A1A1A;
		font-weight: bold;
	}
	.bmsg{
		width: 100%;
		height: auto;
		font-size: 18px;
		margin-top: 0.75vw;
		margin-top: 32px;
		line-height: 1.5;
	}
	.bmsg span{
		display: inline-block;
		width: 100%;
		color: #1A1A1A;
		text-align: center;
		font-size: 18px;
		line-height: 1.5;
	}
	.bgoqus{
		width: 4.2rem;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.3rem;
		font-size: 18px;
		text-align: center;
		color: #FFFFFF;
		background: #C25E4D;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.25);
		border-radius: 1rem;
		margin: 0.59rem auto 0.89rem auto;
	}
	.bg-bg{
		width: 100%;
		height: calc(164px - 0.44rem);
	}
	
	
	
	@media screen and (min-width:1001px) and (max-width:1439px){
		
		.list-top-center{
			width: 1000px;
		}
	}
	@media screen and (min-width:1440px) {
		.list-top-center{
			width: 1200px;
		}
		.prolist .detail_pc .pro-list{
			width: 1200px;
		}
		.prolist .detail_pc .pro-goodslist{
			width: 1200px;
		}
		.prolist .detail_pc .pro-goodsbox{
			width: calc(4.2rem + 50px);
		}
		.prolist .pro-img{
			height: auto;
		}
	}
</style>
