<template>
	<div class="fulfillment">
		<div class="pc_box">
			<div class="top-banner">
				<div class="fulfillment_banner">
					<div class="bg-box">
						<div class="bgbg">
							<div class="fbg">
								<div class="fbgimg">
									<div class="lz-parallax-content" style="position:relative;top:0;left:0;width:100%;height:100%;z-index:0"></div>
									<div class="lz-parallax-bg" style="position: absolute; inset: 0px; clip: rect(0px, 100vw, 500px, 0px);left: calc(50% - 50vw);top: 0;">
										<div class="lz-parallax-bg-inner" style="position:fixed;top:0;left:px;width:100vw;bottom:0;z-index:-1;pointer-events:none;">
											<div class="lz-parallax-bg-img" :style="'position: absolute; width: 100%; pointer-events: none; background-image: url('+publicurl+'static/common/images/official-website/storeimgs/fulfillment/bg.png); background-position: 50% 50%; background-repeat: no-repeat; background-size: cover; background-color: rgba(0, 0, 0, 0); height: 120%; transform: translate(0px, 0px);'"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="fulfillment-banner-txt">
						定制保健品 一站式解决方案
					</div>

				</div>

				<div class="fulfillment-step">
					<div class="fulfillment-step-box">
						<div class="fulfillment-step-img-box">
							<img class="fulfillment-step-img" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/jiejue.png'" alt="">
						</div>
						<div class="fulfillment-step-txt">一体化解决方案</div>
						<div class="fulfillment-step-msg">从评估系统到仓装配解决方案</div>
					</div>
					<div class="fulfillment-step-box">
						<div class="fulfillment-step-img-box">
							<img class="fulfillment-step-img" style="width: 77px;" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/dingzhi.png'" alt="">
						</div>
						<div class="fulfillment-step-txt">第一条定制线</div>
						<div class="fulfillment-step-msg">国内第一条定制补剂全自动分装线</div>
					</div>
					<div class="fulfillment-step-box">
						<div class="fulfillment-step-img-box">
							<img class="fulfillment-step-img" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/renzheng.png'" alt="">
						</div>
						<div class="fulfillment-step-txt">标准化认证</div>
						<div class="fulfillment-step-msg">分装环境通过FDA注册和ISO认证</div>
					</div>
				</div>
			</div>
			<div class="fulfillment-center-box">
				<div class="fulfillment-center-line">
					<div class="fulfillment-center-left">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/yewu.jpg'" alt="">
					</div>
					<div class="fulfillment-center-right">
						<div class="fulfillment-center-title">
							<p>助你突破更多业务场景</p>
							<p>打造更好个性化定制体验</p>
						</div>
						<div class="fulfillment-center-list">
							<div class="fulfillment-center-li">
								<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/d1.jpg'" alt="">
								<div class="fulfillment-center-msg">定制每日补剂</div>
							</div>
							<div class="fulfillment-center-li">
								<img style="width: 21px;height: 26px;margin-top: 1px;" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/d2.jpg'" alt="">
								<div class="fulfillment-center-msg">定制日装袋</div>
							</div>
							<div class="fulfillment-center-li">
								<img style="width: 25px;height: 25px;margin-top: 1.5px;" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/d3.jpg'" alt="">
								<div class="fulfillment-center-msg">非补剂内容物</div>
							</div>
							<div class="fulfillment-center-li">
								<img style="width: 32px;height: 33px;margin-top: -2.5px;" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/d4.jpg'" alt="">
								<div class="fulfillment-center-msg">外包装盒</div>
							</div>
							<div class="fulfillment-center-li">
								<img style="width: 29px;height: 29px;margin-top: -0.5px;" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/d5.jpg'" alt="">
								<div class="fulfillment-center-msg">物流箱</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="fulfillment-mb-name" style="height: 172px;padding-top: 47px;">
					<div class="fulfillment-mb-name-txt" style="font-size: 36px;font-weight: bold;">营养科技  卓越实力</div>
					<div class="fulfillment-mb-name-msg" style="font-size: 12px;">Excellent strength in nutrition technology</div>
				</div>
			<div class="fulfillment-pc-main">
				<div class="fulfillment-pc-cont-box">
					<div class="fulfillment-pc-cont-info ms1" style="width: 384px;margin:136px 136px 0 calc(56vw - 136px - 384px);">
						<div class="fulfillment-pc-cont-title">全球专家团队</div>
						<div class="fulfillment-pc-cont-msg">NHRC营养科学与食品安全团队帮助您选择更优的成分和剂量，制定出更优质有效的配方</div>
					</div>
					<div class="fulfillment-pc-cont-img" style="width: 44vw;align-items: baseline;">
						<img  :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/group.jpg'" alt="">
					</div>
				</div>
				<div class="fulfillment-pc-cont-box">
					<div class="fulfillment-pc-cont-img" style="width: 63vw;">
						<img  :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/cang.jpg'" alt="">
					</div>
					<div class="fulfillment-pc-cont-info ms2" style="width: 342px;margin:130px calc(37vw - 130px - 342px) 0 130px">
						<div class="fulfillment-pc-cont-title">供应链资源</div>
						<div class="fulfillment-pc-cont-msg">我们向你开放美国及全球供应链联盟资源，提供更适合你的优质生产制造方案。</div>
					</div>
				</div>
				<div class="fulfillment-pc-cont-box">
					<div class="fulfillment-pc-cont-info ms1" style="width: 360px;margin:130px 120px 0 calc(39vw - 120px - 360px);">
						<div class="fulfillment-pc-cont-title">全链追溯</div>
						<div class="fulfillment-pc-cont-msg" style="margin-top:0 ;"><p>对于服食产品安全性问题，我们和你一样关注</p><p>产品追溯精确到最小容器</p><p>从生产到用户手中，全链数据追溯可查</p></div>
					</div>
					<div class="fulfillment-pc-cont-img" style="width: 61vw;">
						<img  :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/chang.png'" alt="">
					</div>
				</div>
				<div class="fulfillment-pc-cont-box">
					<div class="fulfillment-pc-cont-img" style="width: 50vw;align-items: flex-end;">
						<img  :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/jia.jpg'" alt="">
					</div>
					<div class="fulfillment-pc-cont-info ms2" style="width: 432px;margin:115px calc(50vw - 133px - 432px) 0 133px">
						<div class="fulfillment-pc-cont-title">全自动分装</div>
						<div class="fulfillment-pc-cont-msg">根据订单数据进行个性化的全自动分装定制，一切都在<b>高标准</b>*洁净间内完成。</div>
						<div class="fulfillment-pc-cont-msg1">*通过FDA注册及ISO 9001:2005与ISO 22000:2018标准化认证</div>
					</div>
				</div>
				<div class="fulfillment-pc-cont-box">
					<div class="fulfillment-pc-cont-info ms1" style="width: 324px;margin:125px 125px 0 calc(36vw - 125px - 324px);">
						<div class="fulfillment-pc-cont-title">AI营养评估系统</div>
						<div class="fulfillment-pc-cont-msg"><p>基于DigiHealth个性化营养推荐引擎</p><p>可以根据您的特定业务需求进行二次开发</p></div>
					</div>
					<div class="fulfillment-pc-cont-img" style="width: 64vw;">
						<img  :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/ai.jpg'" alt="">
					</div>
				</div>
			</div>
			<div class="fulfillment-linebg" style="width: 100%;height: 172px;background: rgb(200, 208, 216);"></div>
			<div class="fulfillment-pc-fuwu" style="margin-top: 0;">
				<div class="fulfillment-pc-fuwubg" style="height: 370px;position: relative;overflow: hidden;align-items: flex-end;display: flex;">
					<img class="fulfillment-pc-fuwuimg"  :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/bg.jpg'" alt="">
					<!-- <div class="bgmeng" style="height: 370px;"></div> -->
					<div class="fulfillment-pc-fuwu-txt">
						<p>更多服务</p>
						<div class="fulfillment-pc-fuwu-msg">More Services</div>
					</div>
				</div>
				<div class="fulfillment-pc-fulist">
					<div class="fulfillment-pc-fuwutitle" style="background: rgb(153, 169, 149);">海外</div>
					<div class="fulfillment-pc-fuwubox">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/l1.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/l2.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/l3.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/l4.jpg'" alt="">
					</div>
					<div class="fulfillment-pc-fuwutitle" style="width: 120px;background: rgb(194, 97, 86);">国内保税区</div>
					<div class="fulfillment-pc-fuwubox">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/k1.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/k2.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/k3.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/k4.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/k5.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/k6.jpg'" alt="">
					</div>
					<div class="fulfillment-pc-fuwutitle" style="background: rgb(206, 183, 154);">线上</div>
					<div class="fulfillment-pc-fuwubox">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/j1.jpg'" alt="">
						<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/j2.jpg'" alt="">
					</div>
				</div>
			</div>
			<div class="fulfillment-pc-bottom-box">
				<div class="fulfillment-pc-bottom-center">
					<img :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/bottom1.jpg'" alt="">
					<div class="fulfillment-pc-phone">
						<div class="fulfillment-pc-phone-box">028-85211401</div>
						<div class="fulfillment-pc-phone-msg">致电了解更多定制产品线解决方案</div>
					</div>
					<img style="height: 380px;width: auto;" :src="publicurl+'static/common/images/official-website/storeimgs/fulfillment/bottom2.jpg'" alt="">
				</div>
			</div>
		</div>
		<div class="mb_box">
			<div class="fulfillment-mb-bg">
				<div class="img"></div>
				<!-- <img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/mb_bg.jpg" alt=""> -->
			</div>
			<div class="fulfillment-mb-step">
				<div class="fulfillment-mb-step-title">
					<p>定制保健品</p>
					<p>一站式解决方案</p>
				</div>
				<div class="fulfillment-mb-step-line">
				</div>
				<div class="fulfillment-mb-step-box">
					<div class="fulfillment-mb-step-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/jiejue.png" alt="">
					</div>
					<div class="fulfillment-mb-step-txt">一体化解决方案</div>
					<div class="fulfillment-mb-step-msg">从评估系统到仓装配解决方案</div>
				</div>
				<div class="fulfillment-mb-step-box">
					<div class="fulfillment-mb-step-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/dingzhi.png" alt="">
					</div>
					<div class="fulfillment-mb-step-txt">第一条定制线</div>
					<div class="fulfillment-mb-step-msg">国内第一条定制补剂全自动分装线</div>
				</div>
				<div class="fulfillment-mb-step-box">
					<div class="fulfillment-mb-step-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/renzheng.png" alt="">
					</div>
					<div class="fulfillment-mb-step-txt">标准化认证</div>
					<div class="fulfillment-mb-step-msg">分装环境通过FDA注册和ISO认证</div>
				</div>
			</div>
			<div class="fulfillment-mb-main">
				<div class="fulfillment-center-title">
					<p>助你突破更多业务场景</p>
					<p>打造更好个性化定制体验</p>
				</div>
				<div class="fulfillment-center-list">
					<div class="fulfillment-center-li">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/d1.jpg" alt="">
						<div class="fulfillment-center-msg">定制每日补剂</div>
					</div>
					<div class="fulfillment-center-li">
						<img style="width: 21px;height: 26px;margin-top: 1px;" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/d2.jpg" alt="">
						<div class="fulfillment-center-msg">定制日装袋</div>
					</div>
					<div class="fulfillment-center-li">
						<img style="width: 25px;height: 25px;margin-top: 1.5px;" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/d3.jpg" alt="">
						<div class="fulfillment-center-msg">非补剂内容物</div>
					</div>
					<div class="fulfillment-center-li">
						<img style="width: 32px;height: 33px;margin-top: -2.5px;" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/d4.jpg" alt="">
						<div class="fulfillment-center-msg">外包装盒</div>
					</div>
					<div class="fulfillment-center-li">
						<img style="width: 29px;height: 29px;margin-top: -0.5px;" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/d5.jpg" alt="">
						<div class="fulfillment-center-msg">物流箱</div>
					</div>
				</div>
			</div>
				<div class="fulfillment-mb-name">
					<div class="fulfillment-mb-name-txt">营养科技  卓越实力</div>
					<div class="fulfillment-mb-name-msg">Excellent strength in nutrition technology</div>
				</div>
			<div class="fulfillment-mb-main">
				<div class="fulfillment-mb-cont-box">
					<div class="fulfillment-mb-cont-title">全球专家团队</div>
					<div class="fulfillment-mb-cont-msg">NHRC营养科学与食品安全团队帮助您选择更优的成分和剂量，制定出更优质有效的配方。</div>
					<div class="fulfillment-mb-cont-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/group.jpg" alt="">
					</div>
				</div>
				<div class="fulfillment-mb-cont-box">
					<div class="fulfillment-mb-cont-title">供应链资源</div>
					<div class="fulfillment-mb-cont-msg">我们向你开放美国及全球供应链联盟资源，提供更适合你的优质生产制造方案。</div>
					<div class="fulfillment-mb-cont-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/cang.jpg" alt="">
					</div>
				</div>
				<div class="fulfillment-mb-cont-box">
					<div class="fulfillment-mb-cont-title">全链追溯</div>
					<div class="fulfillment-mb-cont-msg">对于服食产品安全性问题，我们和你一样关注。产品追溯精确到最小容器。从生产到用户手中，全链数据追溯可查。</div>
					<div class="fulfillment-mb-cont-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/chang.png" alt="">
					</div>
				</div>
				<div class="fulfillment-mb-cont-box">
					<div class="fulfillment-mb-cont-title">全自动分装</div>
					<div class="fulfillment-mb-cont-msg">根据订单数据进行个性化的全自动分装定制，一切都在高标准*洁净间内完成。</div>
					<div class="fulfillment-mb-cont-msg1">*通过FDA注册及ISO 9001:2005与ISO 22000:2018标准化认证</div>
					<div class="fulfillment-mb-cont-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/jia.jpg" alt="">
					</div>
				</div>
				<div class="fulfillment-mb-cont-box">
					<div class="fulfillment-mb-cont-title">AI营养评估系统</div>
					<div class="fulfillment-mb-cont-msg">基于DigiHealth个性化营养推荐引擎，可以根据您的特定业务需求进行二次开发</div>
					<div class="fulfillment-mb-cont-img">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/ai.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="fulfillment-mb-fuwu">
				<div class="fulfillment-mb-fuwubg">
					<div class="bgmeng"></div>
					<div class="fulfillment-mb-fuwu-txt">
						<p>更多服务</p>
						<div class="fulfillment-mb-fuwu-msg">More Services</div>
					</div>
				</div>
				<div class="fulfillment-mb-fulist">
					<div class="fulfillment-mb-fuwutitle" style="background: rgb(153, 169, 149);">海外</div>
					<div class="fulfillment-mb-fuwubox">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/l1.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/l2.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/l3.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/l4.jpg" alt="">
					</div>
					<div class="fulfillment-mb-fuwutitle" style="width: 120px;background: rgb(194, 97, 86);">国内保税区</div>
					<div class="fulfillment-mb-fuwubox">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/k1.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/k2.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/k3.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/k4.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/k5.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/k6.jpg" alt="">
					</div>
					<div class="fulfillment-mb-fuwutitle" style="background: rgb(206, 183, 154);">线上</div>
					<div class="fulfillment-mb-fuwubox">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/j1.jpg" alt="">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/j2.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="fulfillment-mb-phone">
				<div class="fulfillment-mb-phone-box">028-85211401</div>
				<div class="fulfillment-mb-phone-msg">致电了解更多定制产品线解决方案</div>
			</div>
			<div class="fulfillment-mb-lastimg">
				<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/fulfillment/bottom2.jpg" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'fulfillment',
		components: {},
		data: () => ({
			data1: '',
			publicurl:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/',
		}),
		mounted() {},
	}
</script>

<style>
	/* pc */
	.fulfillment-pc-phone-box{
		width: 474px;
		line-height: 91px;
		font-size: 48px;
		font-weight: bold;
		margin: 100px auto 30px auto;
		border:2px solid #000000;
		letter-spacing:3px;
	}
	.fulfillment-pc-phone-msg{
		width: 100%;
		text-align: center;
		font-size: 22px;
	}
	.fulfillment-pc-phone{
		width: 700px;
		height: 380px;
		text-align: center;
	}
	.fulfillment-pc-bottom-center img{
		width: calc(50% - 350px);
		height: auto;
		align-items: center;
	}
	.fulfillment-pc-bottom-center{
		width: 100%;
		height: 380px;
		max-width: 1590px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		overflow: hidden;
	}
	.fulfillment-pc-bottom-box{
		width: 100%;
		height: 380px;
		margin-top: 1438px;
		background: rgb(200, 208, 216);
	}
	.fulfillment-pc-fuwubox{
		width: 900px;
		margin: 0 auto;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 72px;
	}
	.fulfillment-pc-fuwutitle{
		width: 155px;
		line-height: 44px;
		font-size: 20px;
	}
	.fulfillment-pc-fuwubox img{
		width: 161px;
		height: 211px;
		margin: 44px 32px 0 32px;
	}
	.fulfillment-pc-fulist{
		width: 1000px;
		height: auto;
		position: absolute;
		left: calc(50% - 500px);
		margin-top: -158px;
		padding: 75px 0 40px 0;
		background: #FFFFFF;
		box-shadow: 0px 5px 23px rgba(204, 204, 204, 0.64);
	}
	.fulfillment-pc-fuwu-txt{
		position: absolute;
		top: 90px;
		left: 0;
		width: 100%;
		text-align: center;
		color: #FFFFFF;
		font-size: 36px;
	}
	.fulfillment-pc-fuwu-txt p{
		font-weight: bold;
		line-height: 40px;
	}
	.fulfillment-pc-fuwu-msg{
		font-size: 12px;
	}
	.fulfillment-pc-fuwuimg{
		position: absolute;
		width: 100%;
		height: auto;
		left: 0;
		align-items: flex-end;
	}
	.fulfillment-pc-cont-box{
		width: 100%;
		height: 400px;
		display: flex;
		justify-content: space-between;
	}
	.fulfillment-pc-cont-img{
		height: 400px;
		display: flex;
		align-items: center;
		overflow: hidden;
	}
	.fulfillment-pc-cont-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	@media screen and (max-width:1150px){
		.fulfillment-pc-cont-img img{
			width: 120%;
			height: auto;
			align-items: center;
		}
		.fulfillment-pc-fuwuimg{
			width: 120%;
		}
	}
	.fulfillment-pc-cont-title{
		width: 100%;
		font-size: 25px;
		color: #1A1A1A;
	}
	.fulfillment-pc-cont-msg{
		margin-top: 20px;
	}
	.fulfillment-pc-cont-msg,.fulfillment-pc-cont-msg1{
		width: 100%;
		font-size: 18px;
		line-height: 36px;
		color: #8e9da9;
	}
	.fulfillment-pc-cont-msg1{
		font-size: 14px;
	}
	.ms1{
		text-align: right;
	}
	.ms2{
		text-align: left;
	}
	
	@media screen and (max-width:1000px) {
		.pc_box{
			display: none;
		}
		.mb_box{
			display: block;
		}
		body{
			font: 12px/1 "Microsoft Yahei","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
		}
	}
	@media screen and (min-width:1001px) {
		.pc_box{
			display: block;
			/* margin-bottom: 50px; */
		}
		.mb_box{
			display: none!important;
		}
	}
	
	.fulfillment-mb-fuwutitle{
		width: 90px;
		line-height:40px;
		text-align: center;
		font-size: 18px;
	}
	.fulfillment-mb-fuwubox{
		margin-bottom: 40px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.fulfillment-mb-fuwubox img{
		width: 40%;
		margin:40px 3.33% 0 3.33%;
		height: auto;
	}
	.fulfillment-mb-fuwu{
		width: 100%;
		height: auto;
		margin-top: 70px;
		position: relative;
	}
	.bgmeng{
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.4);
	}
	.fulfillment-mb-fuwu .fulfillment-mb-fuwubg{
		width: 100%;
		height: 77vw;
		background-image: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/mb_bg.jpg);
		background-repeat: no-repeat;
		background-size:100% 100%;
		position: relative;
	}
	.fulfillment-mb-fuwu-txt{
		width: 100%;
		text-align: center;
		position: absolute;
		top: 40px;
	}
	.fulfillment-mb-fuwu .fulfillment-mb-fuwu-txt p{
		width: 100%;
		height: auto;
		line-height: 40px;
		font-size: 30px;
		font-weight: bold;
		color: #FFFFFF;
	}
	.fulfillment-mb-fuwu-msg{
		font-size: 16px;
		color: #FFFFFF;
	}
	.fulfillment-mb-fulist{
		width: 86%;
		height: auto;
		box-shadow: 0px 5px 23px rgba(204, 204, 204, 0.64);
		background: #FFFFFF;
		margin: 0 auto;
		padding-top: 24px;
		padding-bottom: 1px;
		/* margin-top: -160px; */
		position: absolute;
		top: calc(77vw - 160px);
		left: 7%;
	}
	.fulfillment-mb-phone{
		width: 100%;
		height: 200px;
		background: rgb(200, 208, 216);
		padding-top: 50px;
		/* margin-top: calc( 240.8vw + 384px ); */
		margin-top: 378.4vw;
	}
	.fulfillment-mb-phone-box{
		width: 86%;
		height: 60px;
		line-height: 60px;
		border:3px solid #000000;
		font-size: 28px;
		line-height: 54px;
		font-weight: bold;		
		margin: 0 auto;
	}
	.fulfillment-mb-phone-msg{
		width: 100%;
		font-size: 17px;
		margin-top: 20px;
	}
	.fulfillment-mb-lastimg{
		width: 100%;
		height: auto;
		margin-bottom: 70px;
	}
	.fulfillment-mb-lastimg img{
		width: 100%;
		height: auto;
	}
	.fulfillment-mb-cont-box{
		margin-top: 55px;
		width: 100%;
		height: auto;
	}
	.fulfillment-mb-cont-title{
		line-height: 30px;
		font-size: 18px;
		font-weight: bold;
		text-align:left;
		margin-bottom: 8px;
	}
	.fulfillment-mb-cont-msg{
		line-height: 24px;
		font-size: 15px;
		text-align:left;
	}
	.fulfillment-mb-cont-msg1{
		width: 80%;
		line-height: 24px;
		font-size: 15px;
		font-style: italic;
		text-decoration: underline;
		text-align:left;
		margin-bottom: 20px;
	}
	.fulfillment-mb-cont-img{
		width: 100%;
		height: 60vw;
		overflow: hidden;
		position: relative;
		align-items: center;
		display: flex;
	}
	.fulfillment-mb-cont-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.fulfillment-mb-name{
		width: 100%;
		height: auto;
		padding: 30px 0 38px 0;
		margin-top: 70px;
		background: rgb(200, 208, 216);
	}
	.fulfillment-mb-name-txt{
		color: #1A1A1A;
		font-size: 34px;
		width: 100%;
		text-align: center;
	}
	.fulfillment-mb-name-msg{
		color: #1A1A1A;
		font-size: 16px;
		line-height: 16px;
		width: 100%;
		text-align: center;
	}
	.fulfillment-mb-bg{
		width: 100%;
		height: auto;
		background-image: none;
	}
	.fulfillment-mb-bg .img{
		width: 100%;
		height: 75vw;
		opacity: 1;
		background-image: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/storeimgs/mb_bg.jpg);
		background-repeat: no-repeat;
		background-size:100% 100%;
	}
	.fulfillment-mb-step{
		width: 80%;
		height: auto;
		margin: 0 auto;
		margin-top: -72px;
		background: rgb(196, 209, 217);
		z-index: 2;
		padding: 30px 0;
	}
	.fulfillment-mb-step-title{
		width: 100%;
		line-height: 30px;
		font-size: 24px;
		font-weight: bold;
		text-align: center;
		color: #1A1A1A;
	}
	.fulfillment-mb-step-line{
		width: 100px;
		height: 0;
		border-bottom: 3px solid #000000;
		margin: 0 auto;
		margin-top: 25px;
	}
	.fulfillment-mb-step-img{
		width: 60px;
		height: 60px;
		margin: 0 auto;
		margin-top: 45px;
	}
	.fulfillment-mb-step-img img{
		width: 60px;
		height: auto;
		margin: 0 auto;
	}
	.fulfillment-mb-step-txt{
		width: 100%;
		text-align: center;
		height: 23px;
		line-height: 16px;
		font-weight: bold;
		font-size: 14px;
		margin: 0 auto;
		margin-top: 24px;
	}
	.fulfillment-mb-step-msg{
		width: 100%;
		text-align: center;
		line-height: 16px;
		font-size: 14px;
		margin: 0 auto;
		margin-bottom: 20px;
	}
	
	.fulfillment-mb-main{
		width: 86%;
		height: auto;
		margin: 0 auto;padding-top: 70px;
	}
	.fulfillment-mb-main .fulfillment-center-title{
		width: 100%;
		height: 100px;
		margin: 0 auto;
		line-height: 35px;
		font-size: 24px;
		border-bottom: 2.5px solid #000000;
	}
	.fulfillment-mb-main .fulfillment-center-list{
		margin-top: 15px;
	}
	
	.fulfillment {
		width: 100%;
		height: auto;
		margin-top: 20px;
	}

	.fulfillment_banner {
		width: 100%;
		height: 500px;
		/* position: relative; */
		top: 0;
		left: 0;
		overflow: hidden;
	}

	.bg-box {
		width: 100%;
		height: 100%;
		overflow: visible;
	}

	.lz-parallax-bg-img {}

	.bgbg {
		padding: 0;
		margin: 0;
		height: 100%;
		width: 100%;
		position: relative;
	}

	.fbg {
		padding: 0;
		margin: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		height: 100%;
		z-index: 1;
		border-top: 0 solid transparent;
		border-bottom: 0 solid transparent;
		border-radius: 0;
		box-shadow: 0 0 0 0 transparent;
		background-color: transparent;
		background-image: -webkit-linear-gradient(none, none);
		background-image: -moz-linear-gradient(none, none);
		background-image: -ms-linear-gradient(none, none);
		background-image: linear-gradient(none, none);
		box-sizing: border-box;
		width: 126%;
		left: -13%;
	}

	.fbgimg {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		background-image: url(//nwzimg.wezhan.cn/contents/sitefiles2036/10182444/images/17069997.png);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-attachment: scroll;
		background-size: cover;
		opacity: 1;
		transition: all, .3s;
		background-image: none;
		position: relative;
	}

	.fulfillment-banner-txt {
		width: 100%;
		height: auto;
		font-size: 46px;
		line-height: 133px;
		position: absolute;
		top: 268px;
		font-weight: bold;
		text-align: center;
		color: #FFFFFF;
		z-index: 1;
	}

	.fulfillment-step {
		width: 1000px;
		height: 274px;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		background-color: rgb(200, 208, 216);
		background-image: none;
		background-repeat: no-repeat;
		background-position: center;
		margin-top: -150px;
		position: absolute;
		left: calc(50% - 500px);
		z-index: 3;
	}

	.fulfillment-step-box {
		width: 330px;
		height: auto;
	}

	.fulfillment-step-img-box {
		width: 72px;
		height: 72px;
		margin: 0 auto;
		margin-top: 63px;
	}

	.fulfillment-step-img {
		width: 72px;
		height: auto;
	}

	.fulfillment-step-txt {
		margin-top: 27px;
		width: 100%;
		line-height: 19px;
		height: 23px;
		font-size: 14px;
		color: #1A1A1A;
		font-weight: bold;
	}

	.fulfillment-step-msg {
		font-size: 12px;
		line-height: 12px;
	}
	.fulfillment-center-box{
		width: 100%;
		height: 489px;
		margin: 248px auto 130px auto;
	}
	.fulfillment-center-line{
		width: 100%;
		height: 489px;
		display: flex;
		justify-content: space-between;
	}
	.fulfillment-center-line .fulfillment-center-left{
		width: 40%;
		height: 489px;
		overflow: hidden;
		position: relative;
	}
	.fulfillment-center-left img{
		width: 100%;
		height: auto;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -244px;
	}
	@media screen and (max-width:1500px){
		.fulfillment-center-left img{
			height: 489px;
			width: auto;
		}
	}
	.fulfillment-center-right{
		width: calc(50% + 52px);
		height: 489px;
	}
	.fulfillment-center-title{
		width: 100%;
		height: 110px;
		line-height: 42px;
		padding-top: 15px;
		font-size: 28px;
		font-weight: bold;
		text-align: left;
		border-bottom: 2px solid #000000;
	}
	.fulfillment-center-list{
		width: auto;
		height: auto;
		text-align: left;
		margin-top: 40px;
	}
	.fulfillment-center-li{
		width: auto;
		display: flex;
		justify-content: left;
		margin-top: 24px;
		margin-left: 3px;
	}
	.fulfillment-center-li img{
		width: 22px;
		height: 28px;
	}
	.fulfillment-center-li .fulfillment-center-msg{
		line-height: 28px;
		font-size: 18px;
		color: #444444;
		margin-left: 15px;
	}
</style>
