<template>
  <div class="terms-of-use" id="Contact">
    <ReturnBtn/>

	<div id='contactPage'>
		<div class='contactBox'>
			<div class='contactName'>版权声明</div>
			<div class='contactTitle'  style="text-indent: 2em;">NUTRIPACKS橘袋上的所有内容诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件、商标都是NUTRIPACKS橘袋或其内容提供者的财产，受中国和国际版权法的保护。未经NUTRIPACKS橘袋书面授权或许可 ，不得以任何目的对NUTRIPACKS橘袋网站或其任何部分进行复制、复印、仿造、出售、转售、访问、或以其他方式加以利用。</div>

			<div class='contactName'>您的账户</div>
			<div class='contactTitle' style="text-indent: 2em;">如果您使用NUTRIPACKS橘袋网站，您有责任对您的账户和密码保守秘密并对进入您的计算机作出限制，并且您同意对在您的账户和密码下发生的所有活动承担责任。如果您在18岁以下，您只能在父母或监护人的参与下才能使用NUTRIPACKS橘袋。NUTRIPACKS橘袋及其关联公司保留在中华人民共和国法律允许的范围内独自决定拒绝服务、关闭账户、清除或编辑内容或取消订单的权利。</div>

			<div class='contactName'>评论、意见、消息和其他内容</div>
			<div class='contactTitle' style="text-indent: 2em;">访问者可以张贴评论、意见及其他内容，以及提出建议、主意、意见、问题或其他信息，只要内容不是非法、淫秽、威胁、诽谤、侵犯隐私、侵犯知识产权或以其他形式对第三者构成伤害或侵犯或令公众讨厌，也不包含软件病毒、政治宣传、商业招揽、连锁信、大宗邮件或任何形式的"垃圾邮件"。您不可以使用虚假的电子邮件地址、冒充任何他人或实体或以其它方式对卡片或其他内容的来源进行误导。NUTRIPACKS橘袋保留清除或编辑这些内容的权利（但非义务），但不对所张贴的内容进行经常性的审查。</div>
			<div class='contactTitle' style="text-indent: 2em;">如果您确实张贴了内容或提交了材料，除非我们有相反指示，您授予NUTRIPACKS橘袋及其关联公司非排他的、免费的、永久的、不可撤销的和完全的再许可权而在全世界范围内任何媒体上使用、复制、修改、改写、出版、翻译、创作衍生作品、分发和展示这样的内容。您授予NUTRIPACKS橘袋及其关联公司和被转许可人使用您所提交的与这些内容有关的名字的权利，如果他们选择这样做的话。您声明并担保您拥有或以其它方式控制您所张贴内容的权利，内容是准确的，对您所提供内容的使用不违反本政策并不会对任何人和实体造成伤害。您声明并保证对于因您所提供的内容引起的对NUTRIPACKS橘袋或其关联公司的损害进行赔偿。NUTRIPACKS橘袋有权（但非义务）监控和编辑或清除任何活动或内容。NUTRIPACKS橘袋对您或任何第三方所张贴的内容不承担责任。</div>

			<div class='contactName'>合同缔结</div>
			<div class='contactTitle' style="text-indent: 2em;">如果您通过我们网站订购产品，您的订单就成为一种购买产品的申请或要约。我们将发送给您一封确认收到订单的电子邮件，其中载明订单的细节。但是只有当我们向您发出送货确认的电子邮件通知您我们已将产品发出时，我们对您合同申请的批准与接受才成立。如果您在一份订单里订购了多种产品并且我们只给您发出了关于其中一部分的发货确认电子邮件，那么直到我们发出关于其他产品的发货确认电子邮件，关于那部分产品的合同才成立。当您所购买的商品离开了NUTRIPACKS橘袋或其关联公司的库房时，该物品的所有权和灭失风险即转移到您这一方。</div>

			<div class='contactName'>产品说明</div>
			<div class='contactTitle' style="text-indent: 2em;">NUTRIPACKS橘袋及其关联公司努力使产品说明尽可能准确。不过，我们并不保证产品说明或NUTRIPACKS橘袋网站上的其他内容是准确的、完整的、可靠的、最新的或无错误的。如果NUTRIPACKS橘袋提供的产品本身并非如说明所说，您唯一的救济是将该未经使用过的产品退还我们。</div>

			<div class='contactName'>价格</div>
			<div class='contactTitle'>直到您发出订单，我们才能确认商品的价格。尽管我们做出最大的努力，我们的商品目录里的一小部分商品可能会有定价错误。如果我们发现错误定价，我们将采取下列之一措施：<br/>
（i） 如果某一商品的正确定价低于我们的错误定价，我们将按照较低的定价向您销售交付该商品。<br/>
（ii） 如果某一商品的正确定价高于我们的错误定价，我们会根据我们的情况决定,是否在交付前联系您寻求您的指示, 或者取消订单并通知您。</div>

			<div class='contactName'>免责条款</div>
			<div class='contactTitle' style="text-indent: 2em;">如因不可抗力或其他无法控制的原因造成网站销售系统崩溃或无法正常使用，从而导致网上交易无法完成或丢失有关的信息、记录等，网站将不承担责任。但是我们将会尽合理的可能协助处理善后事宜，并努力使客户减少可能遭受的经济损失。</div>


			<div class='contactTitle' style="text-indent: 2em;">NUTRIPACKS橘袋可以按买方的要求代办相关运输手续，但我们的责任义务仅限于按时发货，遇到物流（邮政）意外时协助买方查询，不承担任何物流（邮政）提供给顾客之外的赔偿，一切查询索赔事宜均按照物流（邮政）的规定办理。在物流（邮政）全程查询期限未满之前，买方不得要求赔偿。提醒买方一定核实好收货详细地址和收货人电话，以免耽误投递。 </div>

		</div>

	</div>
  </div>

</template>

<script>
import ReturnBtn from "../components/ReturnBtn.vue";
export default {
    name: 'termsOfUse',
    components: {
        ReturnBtn,
    },
    data() {
        return {}
    },
    methods:{}
}
</script>
<style scoped>
#Contact{
    width: 100%;
    height:100%;
}
#contactPage{

}
.bannerBox{
    width: 100%;
    height:44vw;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position:center;
    -moz-background-size: 100% 100%;
}
.contactBox{
	max-width: 1024px;
	width: 85vw;
	min-height:540px;
	margin:70px auto 120px;
	overflow: hidden;

}
.contactName{
	text-align:center;

	font-size:0.32rem;
	font-family:"PingFangSC-Medium";
	font-weight:600;
	color:rgba(20,20,20,1);
	line-height:42px;
	letter-spacing:1px;
	margin:29px 0 17px;
}
.contactTitle{
	text-align:left;
	text-align: justify;
	font-size:0.28rem;
	font-family:"PingFangSC-Medium";
	font-weight:500;
	color:rgba(20,20,20,1);
	line-height:33px;
	letter-spacing:1px;
	margin-bottom: 50px;
}
.contactBox input{
	display: block;
	width:520px;
	background:rgba(249,249,250,1);
	margin:0 auto 10px;
	outline: none;
	border: 0;
	padding: 15px 13px;
	font-size: 16px;

}
.nameIn{
	height:48px;
}

.mailIn{
	height:48px;
}
.talkIn{
    height:140px;
    display: block;
	width:520px;
	background:rgba(249,249,250,1);
	margin:0 auto 20px;
	outline: none;
	border: 0;
	padding: 15px 13px;
	font-size: 16px;
	resize: none;
}

.contactBox input::-webkit-input-placeholder{
        color: #BBB;
}
.contactBox input::-moz-placeholder{
        color: #BBB;
}
.contactBox input::-ms-input-placeholder{
        color: #BBB;
}
.contactBox textarea::-webkit-input-placeholder{
        color: #BBB;
}
.contactBox textarea::-moz-placeholder{
        color: #BBB;
}
.contactBox textarea::-ms-input-placeholder{
        color: #BBB;
}

.contactBtn{
	width:520px;
	height:48px;
	line-height: 48px;
	background:rgba(194,94,77,1);
	font-size:16px;
	font-family:PingFangSC-Medium;
	font-weight:500;
	color:rgba(255,255,255,1);
	letter-spacing:1px;
	margin:0 auto;
}
</style>
