<template>
	<div class="test-page">
		<div v-if="!loading">
			<div v-if="isCheck">
				<div class="top">
					<div><span style="fontWeight:600">{{cartInfo.name}}</span><span class="golist" @click="userList()">用户的橘袋列表<img class="list-more" src="../../public/imgs/pro/more.png" alt=""></span></div>
					<div style="marginTop:0.6rem"><span>{{bujiTypes}}种/{{bujiNum}}颗</span><span>{{orderId}}</span></div>
				</div>
				<div class="content">
					<ul class="month bottom-line">
						<!--点击会触发pickpre函数，重新刷新当前日期 -->
						<li class="monthtips"> 连续打卡<span style="fontSize:0.4rem;fontWeight:600;color:black">{{continuous_number ? continuous_number:0}}</span></li>
						<li class="monthtips">
							漏卡天数<span style="fontSize:0.4rem;fontWeight:600;color:black">{{daysDifference ? daysDifference : 0}}</span>
						</li>
						<li class="monthtips">可用补卡<span style="fontSize:0.4rem;fontWeight:600;color:black">{{retroactive_number ? retroactive_number :0}}</span></li>
					</ul>
						<!-- 年份 月份 -->
					<ul class="month bottom-line">
						<!--点击会触发pickpre函数，重新刷新当前日期 -->
						<li class="arrow" @click="lastMonth()"><van-icon name="arrow-left"/> 上个月</li>
						<li class="year-month">
								<span>{{year}}年{{months}}月</span>
						</li>
						<li class="arrow" @click="nextMonth()">下个月 <van-icon name="arrow"/></li>
					</ul>
						<!-- 日期 -->
					<ul class="days bottom-line">
						<li  v-for="(item,index) in calendar" :key="index" :class="[{'haveSign':item.haveSign}]">
							<!--本月已签到日期-->
							<div v-if="item.haveSign" class="cli"  @click="handleDay(item)">
								<img v-if="item.isGetGift" class="days-item-icon" :src="item.isCashGift ? 'https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/money-red-icon.png' : 'https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/goods-red-icon.png'">
								<img v-else-if="item.isToday" class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/today-check-icon.png">
								<img v-else-if="item.showSevenGift" class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/money-icon.png">
								<img v-else-if="item.showThirtyGift" class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/goods-icon.png">
								<img v-else class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/ischeck-icon.png">
								{{ item.day }}
							</div>
							<!--本月未签到日期-->
							<div v-if="!item.haveSign" class="cli" @click="handleDay(item)">
								<!-- <img class="days-item-icon" src='https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/ban-icon.png'> -->
								<img v-if="item.isHistory" class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/ischeck-icon.png">
								<img v-else-if="item.isDisable" class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/ban-icon.png">
								<img v-else-if="item.showSevenGift" class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/money-icon.png">
								<img v-else-if="item.showThirtyGift" class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/goods-icon.png">
								<img v-else-if="item.canRetroactive && retroactive_number > 0 " class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/buka-icon.png">
								<img v-else class="days-item-icon" src="https://cdn-file.xiaojudai.cn/static/dist/activity/check-in/coin-icon-gray.png">
								<div>{{item.isToday ? '今' : item.day}}</div>
							</div>
						</li>
					</ul>
					<button class="checkButton" @click="signIn()" :style="haveSign ? 'background:#cccccc;': ''">{{signInfo?.data?.data?.signIn.status === 2 ? '打卡已结束' : haveSign ? '今日已打卡' : '立即打卡'}}</button>
				</div>
				<!-- 一种情况领取补剂优惠 -->
				<div class="footer" v-if="awards && awards.jianliType == 'buji' && !thirtyGiftAwards.length && !sevenGiftAwards.length">
					<div style="fontSize:0.4rem;fontWeight:600">连续奖励</div>
					<div class="checkList">
						<div style="flexShrink: 0;marginRight:0.2rem;">连续{{awards.dayNum}}天：</div>
						<van-radio-group v-model="radio" direction="horizontal" checked-color="#C25E4D" icon-size="12px" style="width:80%;fontSize:0.35rem">
							<van-radio v-for="(item,index) in giftGoodsList" :name="item.goodsType" class="checkItem" icon-size='12px' :key="index">{{item.goodsName}}</van-radio>				
						</van-radio-group>
					</div>		
					<button class='getcoupon' @click="handleSureGift(awards.id)" :style="awards.is_received == 0 ? '' : 'background:#cccccc'">{{awards.is_received == 0 ? '立即领取' : '已领取'}}</button>
				</div>
				<!-- 一种情况领取补剂优惠 -->
				<template v-if="thirtyGiftAwards.length">
					<div class="footer"  v-for="(item,index) in thirtyGiftAwards" :key="index">
						<div style="fontSize:0.4rem;fontWeight:600">连续奖励</div>
						<div class="checkList">
							<div style="flexShrink: 0;marginRight:0.2rem;">连续{{item.dayNum}}天：</div>
							<van-radio-group v-model="radio" direction="horizontal" checked-color="#C25E4D" icon-size="12px" style="width:80%;fontSize:0.35rem">
								<van-radio v-for="(item,index) in giftGoodsList" :name="item.goodsType" class="checkItem" icon-size='12px' :key="index">{{item.goodsName}}</van-radio>				
							</van-radio-group>
						</div>		
						<button class='getcoupon' :style="item.is_received == 0 ? '' : 'background:#cccccc'"  @click="handleSureGift(item.id,true)">{{item.is_received == 0 ? '立即领取' : '已领取'}}</button>
					</div>
				</template>
				<!-- 一种情况领取现金 -->
				<div class='footer' v-if="awards && awards.jianliType == 'money' && !thirtyGiftAwards.length && !sevenGiftAwards.length"> 
					<div style="fontSize:0.4rem;fontWeight:600">连续奖励</div>
					<div class="hexiao">连续{{awards.dayNum}}天：<span>{{awards.trade_no}}</span><button class="write-Off"  :style="awards.isCancel ? '' : 'background:#cccccc'" @click="onCancel(awards.trade_no,awards.isCancel)">{{awards.isCancel ? '前往核销' : '已核销'}}</button></div>
				</div>
				<!-- 多种情况领取现金 -->
				<template v-if="sevenGiftAwards.length">
					<div class='footer' v-for="(item,index) in sevenGiftAwards" :key="index"> 
						<div style="fontSize:0.4rem;fontWeight:600">连续奖励</div>
						<div class="hexiao">连续{{item.dayNum}}天：<span>{{item.trade_no}}</span><button class="write-Off" :style="item.isCancel ? '' : 'background:#cccccc'" @click="onCancel(item.trade_no,item.isCancel )">{{item.isCancel ? '前往核销' : '已核销'}}</button></div>
					</div>
				</template>
			</div>
			<div v-else>
				当前没有正在打卡橘袋{{time}}秒后跳转用户橘袋列表
			</div>
		</div>
		<div v-else style="fontSize:0.8rem;textAlign:center;marginTop:2rem">
			加载中.........
		</div>
	</div>
</template>

<script>
	import { Cell, CellGroup, Field, Popup, Button, Icon } from 'vant';
	import Vue from 'vue'
	let wx = window.wx
	export default {
		components: {
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Field.name]: Field,
			[Popup.name]: Popup,
			[Button.name]: Button,
			[Icon.name]: Icon
		},
		data() {
			return {
				year:'',
				month:'',
				months:'',
				day:'',
				currentDate:'',
				currentDateTime:'',
				currentTimeStamp:'',
				calendar:[],
				judaiName:'',
				bujiTypes:'',
				bujiNum:'',
				orderId:'',
				checkDays:0,
				radio:'',
				user_id:'',
				continuous_number:'',
				daysDifference:'',
				retroactive_number:'',
				signInfo:{},
				cartInfo:{},
				showSevenGiftList:[],
				showThirtyGiftList:[],
				giftGoodsList: [{
					goodsName:'高活维E',
					goodsType:1
					},
					{
					goodsName:'高活维D',
					goodsType:2
					},
					{
					goodsName:'爱尔兰有机镁',
					goodsType:3
					},
					{
					goodsName:'橘币',
					goodsType:4
					},
    			],
				awards:{},
				thirtyGiftAwards:[],
				sevenGiftAwards:[],
				isCheck:true,
				time:3,
				haveSign:false,
				loading:false,
			}			
		},
		created() {
			var date = new Date() // 今天的日期
			var year = date.getFullYear();
			var months = date.getMonth() + 1;
			var day = date.getDate();
			var currentDate =  `${year}-${months < 10 ? '0'+ months : months}-${day < 10 ? '0'+ day : day}`
			var currentDateTime = new Date(Date.parse(`${year}-${months < 10 ? '0'+ months : months}-${day < 10 ? '0'+ day : day}`))
			var currentTimeStamp = new Date(Date.parse(`${year}-${months < 10 ? '0'+ months : months}-${day < 10 ? '0'+ day : day}`)).getTime();
			this.year =year
			this.months =months
			this.day =day
			this.currentDate = currentDate
			this.currentDateTime =currentDateTime
			this.currentTimeStamp =currentTimeStamp
			this.getConfig()
		},

		methods: {	
			getConfig(){
				this.$ajax.post(this.API.api.apiurl.getConfig,{path:window.location.href}).then(res=>{
					wx.config({...JSON.parse(res.data.data.config)})
					wx.agentConfig(JSON.parse(res.data.data.agentConfig));
					setTimeout(() => {
						wx.ready(() => {
								wx.invoke('getCurExternalContact', {}, (res) => {
									if (res.err_msg === "getCurExternalContact:ok") {
										this.user_id = res.userId;
										this.getCartInfo(res.userId)
										// 回调
									} else {
										//错误处理
										alert(JSON.stringify(res) + " 调用微信接口失败, 请右键点击重新加载!")
									}
								});

							});
							wx.error((res) => {
								this.resInfo.push("失败")
								this.resInfo.push(JSON.stringify(res))
							});
							this.loading = false
					}, 1000);					
				})
			},
			getCartInfo(user_id){
				this.$ajax.post(this.API.api.apiurl.getCartInfo,{user_id}).then(res=>{					
					const {data} = res.data					
					if(res.data.code == 0){
						if(data.length == 0){
							this.isCheck = false
							setInterval(() => {
								if(this.time == 0){
									window.location.href = `https://www.nutri-packs.cn/userJudai?user_id=${user_id}`
								}
								this.time = this.time - 1
							}, 1000);
							return
						}
						this.cartInfo = data.cart;
						this.orderId = data.order_no;
						this.bujiTypes = data?.cart?.goods_info?.length;
						let num = 0
						for(var i = 0; i < data?.cart?.goods_info?.length; i++){
							num = num+data.cart.goods_info[i].goods_num
						}
						this.bujiNum = num
						this.user_id = user_id,
						this.cart_id = data.cart.id
						this.getSignInfo(user_id,data.cart.id)
					}
				})
			},

			getSignInfo(user_id,cart_id){
				this.$ajax.post(this.API.api.apiurl.getSignInfo,{user_id:user_id,cart_id:cart_id}).then(res=>{
					const {data} = res.data				
					if(res.data.code == 0){
						this.continuous_number = data.signIn.continuous_number
						this.daysDifference =data.daysDifference
						this.retroactive_number  = data.signIn.retroactive_number
						this.signInfo = res
						let signDays = []
						if(res.data.code === 0) {
							if(data.logs.length){
								signDays = data.logs.map(item => {
									if(item.award.length !== 0 ) {
										item.award.forEach(item2 => {											
											this.isGetAwardOne= item2.type === 1 ? true: false,
											this.isGetAwardTwo= item2.type === 2 ? true: false,
											this.isGetAwardThree= item2.type === 3 ? true: false,
											this.isGetAwardFour= item2.type === 4 ? true: false,
											this.isGetAwardFive= item2.type === 5 ? true: false,
											this.isGetAwardSix= item2.type === 6 ? true: false,
											this.isGetAwardSeven= item2.type === 7 ? true: false,
											this.isGetAwardEight= item2.type === 8 ? true: false
										})
									}
									return {
									'day': item.day,
									'date': new Date(Date.parse(item.day.replace(/-/g, '/'))),
									'award': item.award
									}
								})
								signDays.sort(function(a,b){return a.date > b.date ? -1 : 1})
							}													
						}
						console.log(signDays,'-------------')
						this.signDays = signDays
						this.getcalendarList(signDays,res)
					}
				})
			},
			// 获取日历信息
			getcalendarList(signDays,res) {
				var lastDay = new Date( this.year,  this.months, 0).getDate(); // 最后一天的日期
				var firstDay = new Date( this.year,  this.months - 1, 1).getDay();  // 第一天是周几  0表示周日
				var lastWeekDay = new Date( this.year,  this.months - 1, lastDay).getDay();  // 最后一天是周几  0表示周日

				// 获取本月
				let calendar = []
				for(let i=1; i < lastDay+1; i++) {
					calendar.push(
						{
							year: this.year,
							month: this.months,
							day:i,
							date: `${ this.year}-${ this.months < 10 ? '0'+  this.months :  this.months}-${i < 10 ? '0'+ i : i}`,
							dateTime: new Date(Date.parse(`${ this.year}/${ this.months < 10 ? '0'+  this.months :  this.months}/${i < 10 ? '0'+ i : i}`)),
							timeStamp:new Date(Date.parse(`${ this.year}/${ this.months < 10 ? '0'+  this.months :  this.months}/${i < 10 ? '0'+ i : i}`)).getTime(),
							weekDay: new Date( this.year,  this.months - 1, i).getDay(),
						}
					)
				}
				// 获取上个月的末尾
				let prevMonthLastDay = new Date( this.year,  this.months-1, 0).getDate();
				let empytDayBefore = [] 
				let empytDayAfter = [] 

				for (let i = firstDay - 1; i >= 0; i--) {
					let months =  this.months > 1 ?   this.months - 1 : 12
					let year =  this.months > 1 ?   this.year :  this.year - 1
					empytDayBefore.push({
						day: prevMonthLastDay - i,
						isCurrentMonth: false,
						year: year,
						month: months,
						date: `${year}-${months < 10 ? '0'+ months : months}-${prevMonthLastDay - i}`,
						dateTime: new Date(Date.parse(`${year}/${months < 10 ? '0'+ months : months}/${prevMonthLastDay-i}`)),
						timeStamp:new Date(Date.parse(`${year}/${months < 10 ? '0'+ months : months}/${prevMonthLastDay-i}`)).getTime(),
						weekDay: new Date(Date.parse(`${year}/${months < 10 ? '0'+ months : months}/${prevMonthLastDay-i}`)).getDay()
					});
				}

				// 获取下个月的开始
				for (let i = 1 ; i <= 6 - lastWeekDay; i++) {
					let months =  this.months <  12 ?   this.months + 1 : 1
					let year =  this.months < 12 ?   this.year :  this.year + 1
					empytDayAfter.push({
						day: i,
						isCurrentMonth: false,
						year: year,
						month: months,
						date: `${year}-${months < 10 ? '0'+ months : months}-${'0'+ i}`,
						dateTime: new Date(Date.parse(`${year}/${months < 10 ? '0'+ months : months}/${'0'+ i}`)),
						timeStamp:new Date(Date.parse(`${year}/${months < 10 ? '0'+ months : months}/${'0'+ i}`)).getTime(),
						weekDay: new Date(Date.parse(`${year}/${months < 10 ? '0'+ months : months}/${'0'+ i}`)).getDay()
					});
				}

				let calendarAll = [...empytDayBefore, ...calendar, ...empytDayAfter]
				//计算打卡的第一天
				let fristCheckDay 			
				if(signDays.length > 1) {
					for(let i = 0; i<signDays.length - 1; i++) {
						if(signDays[i].date - signDays[i+1].date !== 86400000) {
							fristCheckDay = signDays[i]
							break
						} else {
							fristCheckDay = signDays[signDays.length-1]
						}
					}
				} else if(signDays.length === 0) {
					
					fristCheckDay = {
						date: new Date()
					}
				} else if (signDays.length === 1) {
					fristCheckDay = signDays[0]
				}
				
				// 计算获得补卡奖励日期
				let lastDate = new Date()
				let preLastDate = new Date(lastDate); 
				preLastDate.setDate(preLastDate.getDate()-1)
				if(signDays.length !== 0){
					preLastDate = signDays[0].date
				}
				
				var getRetroactiveDates = []
				let getSignNumberDay = res.code === 0 ? res.data.getSignNumberDay[res.data.signIn.version] : [10, 20, 26, 35, 45, 55] // 线上版本

				let sign_in_number =  res.code === 0 ? res.data.signIn.sign_in_number : 0 
				if(getSignNumberDay.length !== 0) {
						getSignNumberDay.forEach(item => {
						if(item - sign_in_number > 0 && item < this.cartInfo.buy_days) {
							let date = new Date(preLastDate)
							date.setDate(preLastDate.getDate() + (item - sign_in_number));
							let getMonth = date.getMonth() + 1
							
							let getRetroactiveDate = `${date.getFullYear()}-${getMonth < 10 ? '0'+ getMonth  : getMonth}-${date.getDate() < 10 ? '0'+ date.getDate() : date.getDate()}`
							getRetroactiveDates.push(getRetroactiveDate)
						}
					})
				}
				
				// 7天、14天、40天、42天、50天获取现金红包，30天和60天获取补剂奖励				
				const giftDays = [7, 14, 30, 40, 42, 50, 60];
				const giftDates = giftDays.map(days => {
					const date = new Date(fristCheckDay.date);
					date.setDate(fristCheckDay.date.getDate() + days - 1);
					const year = date.getFullYear();
					const month = (date.getMonth() + 1).toString().padStart(2, '0');
					const day = date.getDate().toString().padStart(2, '0');
					return `${year}-${month}-${day}`;
				});
				
				this.calendar = [];
				for(let i = 0; i<calendarAll.length; i++){
					if( this.currentDate === calendarAll[i].date) {
						calendarAll[i].isToday = true  // 判断是否是今天
						calendarAll[i+1].isTomorrow = true  // 判断是否是明天
					}
					
					calendarAll[i].showSevenGift = false
					calendarAll[i].showThirtyGift = false
					calendarAll[i].showBoomAnimation = false
					calendarAll[i].canGetRetroactive = false
					calendarAll[i].isDisable = false
					calendarAll[i].showBeginTips = false
					calendarAll[i].isHistory = false
					signDays.map(item=>{
						if(item.day === calendarAll[i].date){
							calendarAll[i].haveSign = true
						}else{
							calendarAll[i].haveSign = false
						}
					})
					const caontain  = signDays.find((item => {
						return item.day === calendarAll[i].date
					}))
				
					if(caontain) {
						calendarAll[i].haveSign = true
						calendarAll[i].canRetroactive = false
					} else {
						if(!calendarAll[i].isEmpty && calendarAll[i].dateTime > signDays[signDays.length - 1]?.date && calendarAll[i].dateTime < this.currentDateTime && calendarAll[i].date !== this.currentDate){
							calendarAll[i].canRetroactive = true
						}
					}						
					
					if(calendarAll[i].date === giftDates[0] && !this.isGetAwardFive) {
						calendarAll[i].showSevenGift = true
					} else if(calendarAll[i].date === giftDates[1] && !this.isGetAwardSix) {
						calendarAll[i].showSevenGift = true
					}else if(calendarAll[i].date === giftDates[2] && !this.isGetAwardTwo) {
						calendarAll[i].showThirtyGift = true
					} else if(calendarAll[i].date === giftDates[3] && !this.isGetAwardSeven && +this.cartInfo.buy_days === 60) {
						calendarAll[i].showSevenGift = true
					}else if(calendarAll[i].date === giftDates[5] && !this.isGetAwardEight && +this.cartInfo.buy_days === 60) {
						calendarAll[i].showSevenGift = true
					} else if(calendarAll[i].date === giftDates[6] && !this.isGetAwardFour && +this.cartInfo.buy_days === 60) {
						calendarAll[i].showThirtyGift = true
					}
					let showSevenGiftList = this.showSevenGiftList
					if(calendarAll[i].showSevenGift) {
						showSevenGiftList.push(calendarAll[i])
					}
					let showThirtyGiftList = this.showThirtyGiftList
					if(calendarAll[i].showThirtyGift) {
						showThirtyGiftList.push(calendarAll[i])
					}
					this.showSevenGiftList=showSevenGiftList
					this.showThirtyGiftList=showThirtyGiftList        
					getRetroactiveDates.forEach(item => {
						if(calendarAll[i].date === item) {
							calendarAll[i].canGetRetroactive = true
						}
					})
					signDays.forEach(item => {
						if(item.day === calendarAll[i].date) {
							calendarAll[i].award = item.award
						}
					})
					if(signDays.length === 0) {
						if(calendarAll[i].dateTime < this.currentDateTime && calendarAll[i].date !== this.currentDate ) {
							calendarAll[i].isDisable = true
						}
					} else {
						if(calendarAll[i].dateTime < signDays[signDays.length-1].date) {
							calendarAll[i].isDisable = true
						}
						if(calendarAll[i].date === signDays[signDays.length-1].day) {
							calendarAll[i].showBeginTips = true
						}
					}
					// 计算历史打卡日期
					let historySignList = [...new Set(res.data.historySign)]
					historySignList.forEach(item => {
					if(calendarAll[i].date === item && calendarAll[i].date !== this.currentDate) {
						calendarAll[i].isHistory = true
					}
					}) 
					if(Object.prototype.toString.call(calendarAll[i].award) ==='[object Array]') {
						if(calendarAll[i].award.length !== 0){
							calendarAll[i].isGetGift = true
							if(calendarAll[i].award[0].type === 2 || calendarAll[i].award[0].type === 4) {
							calendarAll[i].isGoodsGift = true
							} else {
							calendarAll[i].isCashGift = true
							}
						} else {
							calendarAll[i].isGetGift = false
						}
					}
					
					Vue.set(this.calendar,i,calendarAll[i])    
					this.haveSign = signDays.findIndex(i => {return i.day === this.currentDate}) !== -1 ? true : false 
				}
			},
			// 上个月
			lastMonth() {
				if(this.months === 1) {	
						this.year= this.year - 1,
						this.months= 12
				} else {
						this.months = this.months - 1 
				}
				this.getSignInfo(this.user_id,this.cart_id)
			},
			// 下个月
			nextMonth() {
				if(this.months === 12) {
						this.year=this.year + 1,
						this.months= 1
				} else {
					this.months = this.months + 1 
				}
				this.getSignInfo(this.user_id,this.cart_id)
			},
			// 打卡
			handleDay(e){
				let calendarDateInfo = e	
				this.awards = {}
				if((calendarDateInfo.canRetroactive && !calendarDateInfo.haveSign && +this.retroactive_number > 0)){
					this.retroactive(e.date)
				}else if(calendarDateInfo.haveSign && !calendarDateInfo.canRetroactive) {			
					if(Object.prototype.toString.call(calendarDateInfo.award) ==='[object Array]' && calendarDateInfo.award.length !== 0) {
						if(calendarDateInfo.award.length > 1) {						
							const conflictList = calendarDateInfo.award.map(item => ({
							...item,
							title: item.type === 1 ? 
							'7' : item.type === 2 ? 
							'30' : item.type === 3 ? 
							'42' : item.type === 4 ? 
							'60' : item.type === 5 ? 
							'7' : item.type === 6 ? 
							'14' : item.type === 7 ? 
							'40' : item.type === 8 ? 
							'50' : ''
							}))
							this.conflictList = conflictList
						} else {
							calendarDateInfo.award[0].dayNum = calendarDateInfo.award[0].type === 1 ? 
							'7' : calendarDateInfo.award[0].type === 2 ? 
							'30' : calendarDateInfo.award[0].type === 3 ? 
							'42' : calendarDateInfo.award[0].type === 4 ? 
							'60' : calendarDateInfo.award[0].type === 5 ? 
							'7' : calendarDateInfo.award[0].type === 6 ? 
							'14' : calendarDateInfo.award[0].type === 7 ? 
							'40' : calendarDateInfo.award[0].type === 8 ? 
							'50' : ''						
							if(calendarDateInfo.award[0].type == 5 || calendarDateInfo.award[0].type==6|| calendarDateInfo.award[0].type==7|| calendarDateInfo.award[0].type==8){
								calendarDateInfo.award[0].jianliType = 'money'
							}else if (calendarDateInfo.award[0].type == 2  || calendarDateInfo.award[0].type == 4 ){
								calendarDateInfo.award[0].jianliType = 'buji'
							}
							this.awards	= calendarDateInfo.award[0]				
						}
					}
				}else if(calendarDateInfo.date === this.currentDate) {
					this.signIn(this.currentDate)
				} else if((calendarDateInfo.canRetroactive && !calendarDateInfo.haveSign && +this.retroactive_number === 0)) {
					window.alert('没有补卡机会')
				}
			},
			signIn(){
				this.$ajax.post(this.API.api.apiurl.checkSigIn,{user_id:this.user_id,sign_id:this.signInfo.data.data.signIn.id}).then(res=>{
					if(res.data.code == 0){
						console.log(res.data)
						let is_give_gifts_list = res.data.data.is_give_gifts
						let _awards = res.data.data.awards?.map(item=>{
							console.log(item,'-----------item')
						})

						for (var key in _awards) {
							if (key === 1) {
								_awards[key].dayNum = '7';
								_awards[key].jianliType='money'
							}else if(key === 2){
								_awards[key].dayNum = '30';
								_awards[key].jianliType='buji'
							}else if(key === 3){
								_awards[key].dayNum = '42';
								_awards[key].jianliType='money'
							}else if(key === 4){
								_awards[key].dayNum = '60';
								_awards[key].jianliType='buji'
							}else if(key === 5){
								_awards[key].dayNum = '7';
								_awards[key].jianliType='money'
							}else if(key === 6){
								_awards[key].dayNum = '14';
								_awards[key].jianliType='money'
							}else if(key === 7){
								_awards[key].dayNum = '40';
								_awards[key].jianliType='money'
							}else if(key === 8){
								_awards[key].dayNum = '50';
								_awards[key].jianliType='money'
							}
						}
						if(is_give_gifts_list.length === 1) { // 一天获得一次奖励
						// 现金奖励
							if(is_give_gifts_list.includes(5) || is_give_gifts_list.includes(6) || is_give_gifts_list.includes(7) || is_give_gifts_list.includes(8)) {
								this.awards =  _awards[5] || _awards[6] || _awards[7] || _awards[8] 
								Vue.set(this.awards , 'jianliType', 'money')            
							}
							// 补剂奖励
							if(is_give_gifts_list.includes(2) || is_give_gifts_list.includes(4)) {             
								this.awards = _awards[2] || _awards[4]  
								Vue.set(this.awards , 'jianliType', 'buji')          
							}
						} else { // 一天获得多次奖励
							if(is_give_gifts_list.includes(7) && is_give_gifts_list.includes(8) && is_give_gifts_list.includes(4)) {
								// 同一天获得 40、50、60奖励
								this.sevenGiftAwards =[_awards[7],_awards[8]]
								this.thirtyGiftAwards =_awards[4]
							
							} else if(is_give_gifts_list.includes(2) && is_give_gifts_list.includes(7) && is_give_gifts_list.includes(8)) {
								// 同一天获得30、40、50天奖励
								this.sevenGiftAwards = [_awards[7],_awards[8]]
								this.thirtyGiftAwards=_awards[2]
							
							}else if(is_give_gifts_list.includes(7) && is_give_gifts_list.includes(8)) {
								// 同一天获得 40、50天奖励		
								this.sevenGiftAwards= [_awards[7],_awards[8]]
							} else if(is_give_gifts_list.includes(4) && is_give_gifts_list.includes(8)) {
								// 同一天获得50、60天奖励   
								this.sevenGiftAwards=_awards[8]
								this.thirtyGiftAwards=_awards[4]

							}else if(is_give_gifts_list.includes(2) && is_give_gifts_list.includes(7)) {				
								this.sevenGiftAwards=_awards[7]
								this.thirtyGiftAwards=_awards[2]				
							}
						}
						this.getSignInfo(this.user_id,this.cart_id)
					}else if(res.data.code == 121){
						window.alert(res.data.message)
					}		
				})
			},
			retroactive(date){
				this.$ajax.post(this.API.api.apiurl.retroactiveAPI,{user_id:this.user_id,sign_id:this.signInfo.data.data.signIn.id,day:date}).then(res=>{
					let is_give_gifts_list = res.data.data.is_give_gifts
					let _awards = res.data.data.awards

					for (var key in _awards) {
						if(_awards[key].trade_no){
							_awards[key].isCancel = this.checkCode(_awards[key].trade_no)
						}
						if (key == 1) {
							_awards[1].dayNum = '7';
							_awards[1].jianliType='money'
						}else if(key == 2){
							_awards[2].dayNum = '30';
							_awards[2].jianliType='buji'
						}else if(key == 3){
							_awards[3].dayNum = '42';
							_awards[3].jianliType='money'
						}else if(key == 4){
							_awards[4].dayNum = '60';
							_awards[4].jianliType='buji'
						}else if(key == 5){
							_awards[5].dayNum = '7';
							_awards[5].jianliType='money'
						}else if(key == 6){
							_awards[6].dayNum = '14';
							_awards[6].jianliType='money'
						}else if(key == 7){
							_awards[7].dayNum = '40';
							_awards[7].jianliType='money'
						}else if(key == 8){
							_awards[8].dayNum = '50';
							_awards[8].jianliType='money'
						}
					}
					if(is_give_gifts_list.length === 1) { // 一天获得一次奖励
						// 现金奖励
						if(is_give_gifts_list.includes(5) || is_give_gifts_list.includes(6) || is_give_gifts_list.includes(7) || is_give_gifts_list.includes(8)) {				 
							for (var index in _awards) {
								this.awards = _awards[index]
							}          
						}
						// 补剂奖励
						if(is_give_gifts_list.includes(2) || is_give_gifts_list.includes(4)) {             
							for (var buji in _awards) {
								this.awards = _awards[buji]
							}              
						}
					
					} else { // 一天获得多次奖励
						if(is_give_gifts_list.includes(7) && is_give_gifts_list.includes(8) && is_give_gifts_list.includes(4)) {
							// 同一天获得 40、50、60奖励					
							this.sevenGiftAwards = [_awards[7],_awards[8]]
							this.thirtyGiftAwards = [_awards[4]]
						
						} else if(is_give_gifts_list.includes(2) && is_give_gifts_list.includes(7) && is_give_gifts_list.includes(8)) {
							// 同一天获得30、40、50天奖励					
							this.sevenGiftAwards = [_awards[7],_awards[8]]
							this.thirtyGiftAwards=[_awards[2]]
						
						}else if(is_give_gifts_list.includes(7) && is_give_gifts_list.includes(8)) {
							// 同一天获得 40、50天奖励				
						
							this.sevenGiftAwards= [_awards[7],_awards[8]]
						} else if(is_give_gifts_list.includes(4) && is_give_gifts_list.includes(8)) {
							// 同一天获得50、60天奖励					
							this.sevenGiftAwards=[_awards[8]]
							this.thirtyGiftAwards=[_awards[4]]

						}else if(is_give_gifts_list.includes(2) && is_give_gifts_list.includes(7)) {						
							this.sevenGiftAwards=[_awards[7]]
							this.thirtyGiftAwards=[_awards[2]]				
						}
					}
					this.getSignInfo(this.user_id,this.cart_id)
				})
			},
			// 查询兑换码是否已核销
			checkCode(code){
				this.$ajax.post(this.API.api.apiurl.checkCode,{trade_no:code}).then(res=>{
					const {data} = res.data
					if(data.count > 0 ){
						return true
					}else{
						return false
					}
				})
			},
			userList(){
				window.location.href = `https://www.nutri-packs.cn/userJudai?user_id=${this.user_id}`
			},
			onCancel(trade_no,isCancel){
				if(isCancel){
					window.location.href = `https://api.xiaojudai.cn/web/index/oauth?trade_no=${trade_no}`
				}else{
					return
				}
				
			},
			handleSureGift(id,thirtyGiftAwards){
				if(this.radio){
					this.$ajax.post(this.API.api.apiurl.receiveAward,{plan_id:this.radio,award_id:id,user_id:this.user_id}).then(res=>{
						const {code} = res.data
						if(code == 0){
							window.alert('领取成功')
							this.getSignInfo(this.user_id,this.cart_id)
							this.radio = ''
							if(thirtyGiftAwards){
								this.thirtyGiftAwards[0].is_received = 1
							}
						}
					})
				}else{
					window.alert('请选择奖励类型')
				}				
			}
		}
	};
</script>

<style scoped>
.test-page {
	padding:0.5rem
}
.top {
	color: black;
	border:solid 1px #cccccc;
	border-radius:0.3rem ;
	font-size:0.4rem;
	padding:0.3rem 0.2rem

}
.top > div{
	display:flex;
	justify-content:space-between;
	align-items:center
}
.golist{
	color:#C25E4D
}
.list-more{
	width:0.4rem;
	height: 0.3rem;
	margin-left:4px
}
.content {
	border-radius: 8px;
	overflow: hidden;
	margin-top: 0.2rem;
	box-shadow: rgba(225,225,225,0.7) 0  10px 20px 0;
	border:solid #cccccc 1px;
	padding:0.3rem 0
}
.month {
	background: #ffffff;
	margin: 0;
	padding: 10px 15px;
	display: flex;
	justify-content: space-between;
	align-items:center;	
}
.year-month { font-size: 17px; width:auto}
.arrow {
	color: #3333339c;
	font-size: 12px;
	width:auto;	
}
i {
	font-size: 13px;
	top: 2px;
}
.monthtips{
	color:#3333339c;
	font-size:0.3rem;
	width:auto
}
.weekdays { /*头部星期*/
	margin: 0;
	padding: 10px 0;
	background-color: #ffffff;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	li {
	display: inline-block;
	text-align: center;
	}
}

.days { /*日期*/
	padding: 0 0 10px;
	background: #FFFFFF;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
li {
	list-style-type: none;
	width: 14.2%;
	padding: 1%;
	box-sizing: border-box;
	margin-bottom: 4px;
	text-align: center;
	color: #000;	
}
.cli {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;	
	padding:5px;
	border-radius:20px;
}
.haveSign{
	background:#FFECE3;
}
.days-item-icon {/*签到的日期*/
	height: 0.7rem;
	width:0.7rem;
	}
.checkButton{
	height:1rem;
	background:#C25E4D;
	color:white;
	width:4rem;
	border-radius:0.25rem;
	font-size:0.4rem;
	font-weight:bold
}
.footer{
	width:100%;
	text-align:left;
	border:solid #cccccc 1px;
	border-radius:0.2rem;
	margin-top:0.3rem;
	padding:0.3rem 0.2rem;
}
.checkList{
	display:flex;
	font-size:0.4rem;
	justify-content:space-between;	
	margin-top:0.25rem
}
.checkItem{
	width:45%
}
.getcoupon{
	position:relative;
  left: 50%;
  transform: translateX(-50%);
	height: 0.8rem;
	width:3rem;
	background:#C25E4D;
	font-size:0.3rem;
	font-weight:600;
	color:white;
	border-radius:0.2rem;	
	margin-top:0.2rem;
}
.write-Off{
	font-size:0.3rem;
	font-weight:600;
	height:0.6rem;
	width:2rem;
	color:white;
	border-radius:0.2rem;
	background:#C25E4D;
}
.hexiao{
	font-size:0.35rem;
	margin-top:0.15rem;
	display:flex;
	align-items:center;
	justify-content:space-between
}
</style>
