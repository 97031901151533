<template>
	<div class="blog">
		<div class="mb_box">
			<div class="blog-list" v-for="(item,index) in list1" :key="index" @click="godetail(index)">
				<div class="blog-box">
					<div class="blog-img">
						<img :src="item.img" alt="">
					</div>
					<div class="blog-info">
						<div class="blog-time">{{item.time}}</div>
						<div class="blog-title">{{item.title}}</div>
						<div class="blog-msg">{{item.msg}}</div>
					</div>
					<div class="blog-line"></div>
				</div>
			</div>
		</div>
		<div class="pc_box">
			<div class="blog-list" v-for="(item,index) in list1" :key="index" @click="godetail(index)">
				<div class="blog-box">
					<div class="blog-img">
						<img :src="item.img" alt="">
					</div>
					<div class="blog-info">
						<div class="blog-time">{{item.time}}</div>
						<div class="blog-title">{{item.title}}</div>
						<div class="blog-msg">{{item.msg}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'blog',
		components: {},
		data: () => ({
			data1: '',
			list1:[
				{id:'11',title:'开「橘」致胜，用升级品质致敬新一年',msg:'补剂4.0配方升级、入驻天猫国际，2021精彩刚开始……',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247491359&idx=1&sn=5745a50d9e0566fb1dfc6d201f68a80b&chksm=fd84d7b2caf35ea4c268aee6433af4337ae5207f3208c19121446405de279836ef33bbc98f68&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/11.jpg',time:'2021/2/23'},
				{id:'10',title:'送礼黑科技上线，爸妈终于不嫌弃了……',msg:'新年送礼要面面「橘」到，这个决定你春节待遇的硬核礼物，盘TA就对了。',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247490899&idx=1&sn=665611cecd214df7b2c10d417377c4c8&chksm=fd84d5fecaf35ce887459151e54afbe454267824f6cb41b0740c42d077bbdd507dce93887d6c&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/10.jpg',time:'2021/1/25'},
				{id:'9',title:'2021营养趋势：功能性补剂真香',msg:'免疫类补剂稳坐C位，心理健康类补剂势头凶猛，2021营养新鲜事抢先看！',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247490751&idx=1&sn=3ef8c2d04808e374f739e849e4d6f945&chksm=fd84d412caf35d047b29cfb2cfb92d42cfeffbc22ca9cfb190ca5110d7da74e1068931ba0940&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/9.jpg',time:'2021/1/5'},
				{id:'8',title:'免疫日福利 | 比口罩更值得囤的防护神器！',msg:'复购率99%的免疫“绝杀武器”，不声不响助你御“病菌”于身体之外。',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247489852&idx=1&sn=21e1e8c99cadb8e5b954b5fb46466f3d&chksm=fd84d191caf35887a745d6664df1942448362e4e96a79fd76cc0012f4461cdd3fa52c034878e&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/8.jpg',time:'2020/12/15'},
				{id:'7',title:'2020压轴新品 | 让你“聪明又清醒”的精力复活包正式上线',msg:'产品内测好评率100%，用过的人都打算5盒起囤，这个压轴新品买就对了！',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247489308&idx=1&sn=5750f9d8bbd94f4c479a984520360a4f&chksm=fd84dfb1caf356a7b1ac49e9dec8fb6e8461579485694801ced0883a977954f034fe049eaaf1&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/7.jpg',time:'2020/11/27'},
				{id:'6',title:'双11不熬夜 | 5折购补剂，万元福利8点开秒！',msg:' 双11狂欢来袭，定制补剂低至5折、 应急营养包全线7.5折，今晚8点还有3场秒杀等你！',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247488450&idx=1&sn=2acc555f1162c2081f1c52a34a70f991&chksm=fd84db6fcaf35279dce3ab61a6a97604979fc5a0e4bcd61291c757bd2eeba2e4225aecb20d1b&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/6.jpg',time:'2020/11/9'},
				{id:'5',title:'选营养补剂时，80%的人会踩的坑？',msg:'同品类的补剂要怎么挑选？进口补剂标签都写了什么？教你看营养标签挑补剂 ！',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247487904&idx=1&sn=0383e19d131ec107de24ae2172892e2b&chksm=fd84d90dcaf3501b2f905ceb08af696c8ee6343a13bc51934a766d0563a6df734c98da027610&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/5.jpg',time:'2020/10/24'},
				{id:'4',title:'波士顿大学最新研究：VD能降低54%新冠感染风险',msg:'美国波士顿大学医学专家发表论文，称充足维生素D或能将新冠感染风险降低54%”。更多实验还在进行中……',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247487366&idx=1&sn=556b5ea09aa6511d5e0dcfe82987325b&chksm=fd84c72bcaf34e3dadad75214e98b744f9d7d22a6f824428d84b09d8fb682c8f182cbefca908&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/4.jpg',time:'2020/09/28'},
				{id:'3',title:'约不到HPV疫苗，还有这些你可以马上做！',msg:'“HPV”到底有多大的危害？怎么做才能预防？身体免疫究竟与HPV自愈有没有关系呢？马上告诉你！',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247487219&idx=2&sn=d59fb7b773be33abe4f6d089fe11d7f8&chksm=fd84c65ecaf34f48be3bd812bba7e0180ede499fe27ea89ca3614b7a7fd8be922be9ef20ee16&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/2.jpg',time:'2020/09/21'},
				{id:'2',title:'应急营养包重磅上新，单价不到¥100，试用品秒空！',msg:'作为NUTRIPACKS 的2020重磅新品，应急包从策划开始就备受关注，试用装更是一袋难求，今天终于正式上线售卖啦！',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247487219&idx=1&sn=c2cef4e849f0a4f316cec9001573b89a&chksm=fd84c65ecaf34f48b7beca02e9d6a523d3f59269117962775d4a7aab68f1fc2dd9765e62ea89&token=871599749&lang=zh_CN#rd',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/1.jpg',time:'2020/09/21'},
				{id:'1',title:'品牌硬核升级，Digihealth™营养评估系统3.0 正式上线！',msg:'橘袋包装“焕新”，新人必入7+7体验装上线，Digihealth™营养评估系统3.0等你来体验……',img:'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/blog/3.jpg',url:'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247486615&idx=1&sn=0ab40fabe1cdb631f52f4128592ebac3&chksm=fd84c43acaf34d2c4124714897d2d1c1750b4af00d0dadeb3e1ab439e8a4efa6898f1087b186&token=871599749&lang=zh_CN#rd',time:'2020/08/26'},
			]
		}),
		mounted() {},
		methods:{
			godetail(index){
				var list = this.list1
				window.location.href = list[index].url
			}
		}
	}
</script>

<style>
	/* pc */
	.pc_box .blog-list{
		width: calc(100% - 48px);
		max-width: 1000px;
		margin: 0 auto;
	}
	.pc_box .blog-box{
		width: 100%;
		height: 390px;
		padding: 45px 0;
		display: flex;
		border-bottom: 1px solid #eaeaea;
		overflow: hidden;
		align-items: center;
	}
	.pc_box .blog-img{
		width: 300px;
		height: 300px;
		overflow: hidden;
		align-items: center;
		display: flex;
		margin-right: 16px;
		cursor: pointer;
		justify-content: center;
	}
	.pc_box .blog-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.pc_box .blog-info{
		width: calc(100% - 332px);
		height: auto;
		text-align: left;
		margin-left: 16px;
		align-items: center;
		/* margin-top: 45px; */
		cursor: pointer;
		align-items: center;
	}
	.pc_box .blog-time{
		width: 100%;
		line-height: 21px;
		font-size: 14px;
		margin-top: 14px;
		color: #b3b3b3;
	}
	.pc_box .blog-title{
		width: 100%;
		line-height: 40px;
		font-size: 27px;
		margin: 13.5px 0;
		color: #1A1A1A;
		font-weight: bold;
	}
	.pc_box .blog-msg{
		color: rgba(10,10,10,0.5);
		font-size: 18px;
		line-height: 36px;
		text-align: justify;
	}
	/* mb */
	.mb_box .blog-list{
		width: calc(100vw - 48px);
		margin: 0 auto;
	}
	.mb_box .blog-box{
		width: 100%;
		height: auto;
		padding-top: 40px;
	}
	.mb_box .blog-img{
		width: calc(100vw - 48px);
		height: calc(100vw - 48px);
		overflow: hidden;
		align-items: center;
		display: flex;
		justify-content: center;
	}
	.mb_box .blog-img img{
		width: 100%;
		height: auto;
		align-items: center;
	}
	.mb_box .blog-info{
		width: 100%;
		height: auto;
		text-align: left;
	}
	.mb_box .blog-time{
		width: 100%;
		line-height: 21px;
		font-size: 14px;
		margin-top: 14px;
		color: #b3b3b3;
	}
	.mb_box .blog-title{
		width: 100%;
		line-height: 36px;
		font-size: 24px;
		margin: 12px 0;
		color: #1A1A1A;
		font-weight: bold;
	}
	.mb_box .blog-msg{
		color: rgba(10,10,10,0.5);
		font-size: 16px;
		line-height: 31px;
		text-align: justify;
	}
	.mb_box .blog-line{
		width: 100%;
		border-bottom: 1px solid #eaeaea;
		margin-top: 40px;
		height: auto;
	}
	
	
	@media screen and (max-width:1000px) {
		.pc_box{
			display: none;
		}
		.mb_box{
			display: block;
			margin-bottom: 50px;
		}
		body{
			font: 12px/1 "Microsoft Yahei","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
		}
	}
	@media screen and (min-width:1001px) {
		.pc_box{
			display: block;
			/* margin-bottom: 50px; */
			width: 1000px;
			margin: 0 auto;
		}
		.mb_box{
			display: none!important;
		}
	}
</style>
