<template>
  <div class="foot" :style="showAI ? 'position: absolute;bottom: 0' : ''" v-if="!checkIsInApp() && !isAlipay" :class="{ 'foot-new': fullPath === '/nutrition-query-index'}">
		<!-- 页尾 -->
		<!-- pc端 -->
		<div class="ft show_pc">
			<div class="new-foot-box">
				<div class="new-foot-box-b1" v-if="!shanghaishow && !isbserve">
					<img class="new-foot-box-logo" src="../../public/imgs/index/logo.png" alt="">
					<button @click="godingzhi">
						<div class="new-foot-box-txt">开始 AI 定制</div>
						<img src="../../public/imgs/index/more.png" alt="">
					</button>
				</div>
				<div class="new-foot-box-b1" :style="shanghaishow ? 'margin: 0 auto;' : ''" v-if="shanghaishow && !isbserve">
					<img class="new-foot-box-logo" src="../../public/imgs/index/logo.png" alt="">
					<button @click="godingzhi2" v-if="showAI">
						<div class="new-foot-box-txt">开始 AI 定制</div>
						<img src="../../public/imgs/index/more.png" alt="">
					</button>
				</div>
				<!-- <div class="new-foot-box-b2"> -->
					<div class="new-foot-box-b3" v-if="!shanghaishow && !isbserve">
						<div class="new-foot-box-b-title">办公地址</div>
						<div class="new-foot-box-b-line"></div>
						<div class="new-foot-box-b-txt">纽约</div>
						<div class="new-foot-box-b-txt">成都</div>
					</div>
					<div class="new-foot-box-b4" style="" v-if="!shanghaishow && !isbserve">
						<div class="new-foot-box-b-title">联系我们</div>
						<div class="new-foot-box-b-line"></div>
						<div class="new-foot-box-b-txt">zhangfei@nutri-packs.com</div>
						<div class="new-foot-box-b-txt">028-85211401</div>
					</div>
					<div class="new-foot-box-b5" style="" v-if="!shanghaishow && !isbserve">
						<div class="new-foot-box-b-qr" style="margin-top: -2px;margin-bottom: 2px;">
							<img src="../../public/imgs/foot/official-accounts.png" alt="">
						</div>
						<div class="new-foot-box-b-msg" style="">扫码关注公众号</div>
						<div class="new-foot-box-b-msg">NUTRIPACKS 橘袋</div>
					</div>
					<div class="new-foot-box-b6" v-if="!shanghaishow && !isbserve">
						<div class="new-foot-box-b-qr">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/kefuwx.png"
								alt="" style="width: 95%;">
						</div>
						<div class="new-foot-box-b-msg">扫码体验</div>
						<div class="new-foot-box-b-msg">微信小程序</div>
					</div>
				<!-- </div> -->
			</div>
			<div class="new-foot-beian">
				<div class="new-foot-r"  v-if="!shanghaishow && !isbserve">
					<span class="iconfont cursor" @click="gof(4)">&#xe63d;</span>
					<!-- <img src="../../public/imgs/foot/twitter.png" @click="gof(4)" alt=""> -->
					<span class="iconfont cursor" @click="gof(5)">&#xe686;</span>
					<!-- <img src="../../public/imgs/foot/facebook.png" @click="gof(5)" alt=""> -->
					<span class="iconfont cursor" @click="gof(3)">&#xe618;</span>
					<!-- <img src="../../public/imgs/foot/xl.png" @click="gof(3)" alt=""> -->
					<span class="iconfont cursor" @click="gof(2)">&#xe6bb;</span>
					<!-- <img src="../../public/imgs/foot/Frame.png" @click="gof(2)" alt=""> -->
					<img class="cursor" src="../../public/imgs/foot/hongshu.png" @click="gof(1)" alt="">
				</div>
				<div class="new-foot-lbei">
					<p>Copyright © 2019 - 2022 NUTRIPACKS 橘袋. All Rights Reserved.</p>
					<p>NUTRIPACKS橘袋 版权所有 <span class="cursor" @click="beian">蜀ICP备18038614号-2</span></p>
					<div style="width:300px;margin:0 auto;">
						<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012202000914" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../../public/imgs/badges.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#1A1A1A;">川公网安备 51012202000914号</p></a>
					</div>

				</div>
				<!-- <div class="new-foot-l new-foot-r">
					<img src="../../public/imgs/foot/twitter.png" alt="">
					<img src="../../public/imgs/foot/facebook.png" alt="">
					<img src="../../public/imgs/foot/xl.png" alt="">
					<img src="../../public/imgs/foot/Frame.png" alt="">
					<img src="../../public/imgs/foot/hongshu.png" alt="">
				</div> -->
			</div>
		</div>
		<!-- h5端 -->
		<div class="mb-foot show_mb" v-if="!isAlipay && !shanghaishow && !isbserve">
			<img src="../../public/imgs/index/logo.png" alt="">
		</div>
		<div class="flist show_mb" v-if="!isAlipay && !shanghaishow && !isbserve">
			<div class="list-item">
				<img class="h5-address-icon" src="../../public/imgs/foot/address.png">
				<div class="h5-address-text">
					<span class="list-item-title">办公地址：</span>
					<span @click="gof(8)">纽约/</span>
					<span @click="gof(9)">成都</span>
				</div>
			</div>
			<div class="list-item">
				<img class="h5-address-icon" src="../../public/imgs/foot/follow-us.png">
				<div class="h5-address-text">
					<span class="list-item-title">关注我们：</span>
					<span @click="gof(1)">小红书/</span>
					<span @click="gof(2)">Instagram/</span>
					<span @click="gof(3)">微博/</span>
					<span @click="gof(4)">Twitter/</span>
					<span @click="gof(5)">Facebook</span>
				</div>
			</div>
			<div class="list-item">
				<img class="h5-address-icon" src="../../public/imgs/foot/contact.png">
				<div class="h5-address-text">
					<span class="list-item-title">联系我们：</span>
					<span @click="gof(6)">zhangfei@nutri-packs.com/</span>
					<span @click="gof(7)">028-85211401</span>
				</div>
			</div>
		</div>
		<div class="foottip foottips mb_qr show_mb" v-if="!isAlipay && !shanghaishow && !isbserve">
			<div class="h5-qr">
				<img src="../../public/imgs/foot/official-accounts.png">
				<div class="ftipmsg">
					<span>长按保存图片</span>
					<span>扫码关注公众号</span>
				</div>
			</div>
			<div class="h5-qr">
				<img src="../../public/imgs/foot/miniprogram.png">
				<div class="ftipmsg">
					<span>扫码体验</span>
					<span>微信小程序</span>
				</div>
			</div>
		</div>
		<div class="footButton show_mb" v-if="showAI">
			<button @click="goBackIndex" style="background-color: #0E6D9F;">
				<div class="new-foot-box-txt">返回首页</div>
				<img src="../../public/imgs/index/more.png" alt=""/>
			</button>
		</div>
		<div class="h5-record show_mb" v-if="!isAlipay">
			<ul>
				<li>© 2022 NUTRIPACKS 橘袋. All Rights Reserved.</li>
				<li style="display: flex;justify-content: center;">
					<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012202000914" style="display:flex;align-items:center;text-decoration:none;color: #666666">
					<img src="../../public/imgs/badges.png" style="float:left;width:0.29rem;height:auto"/>
					<p style="float:left;margin: 0px 0px 0px 5px;">川公网安备 51012202000914号</p>
				</a>
				</li>
				<li @click="beian">蜀ICP备18038614号-2</li>
			</ul>
			<div style="height:0.85rem"></div>
		</div>
		<div class="rightqr"  v-if="!shanghaishow && !isbserve">
			<img src="../../public/imgs/pckefu.png" alt="">
		</div>
		<div :class="{'rightqrh5': true, 'hide-fixed-service-buttom': isScroll }" @click="gokefu" v-if="!isAlipay && !shanghaishow && !isbserve">
			<img src="../../public/imgs/kefu.png" alt="">
		</div>
	</div>
</template>

<script>
  import checkIsInApp from '../util/app.js'
	export default {
		name: 'foot',
		components: {},
		data: () => ({
			isScroll: false,
			shanghaishow:false,
			isbserve:0,
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		mounted() {
			let timer = null
			this.shanghaishow = sessionStorage.getItem('shanghaishow');
			this.isbserve = sessionStorage.getItem('is_b_serve')
			this.showAI = sessionStorage.getItem('showAI')*1;
			window.addEventListener('scroll', () => {
				clearTimeout(timer)
				this.isScroll = true

				timer = setTimeout(() => {
						this.isScroll = false
				}, 300)
			})
		},
		created(){
			this.showAI = sessionStorage.getItem('showAI')*1

		},
		watch: {
			'$route':{
				handler(to) {
					this.fullPath = to.fullPath
				},
				immediate: true
			}
		},
		methods: {
      checkIsInApp(){
        return checkIsInApp();
      },
			beian() {
				window.open('https://beian.miit.gov.cn/')
			},
			gokefu() {
				this.$router.push({
					path: 'service'
				})
			},
			godingzhi() {
				this.$router.push({
					path: 'home'
				})
			},
			godingzhi2() {
				this.$router.push({
					path: 'home'
				})
				this.showAI = false
				sessionStorage.setItem('showAI',0);
			},
			goBackIndex(){
				this.$router.push({
					path: '/'
				})
				this.showAI = false
				sessionStorage.setItem('showAI',0);
			},
			gof(e) {
				if (e == 1) {
					window.open('https://www.xiaohongshu.com/user/profile/5dba88790000000001002ee0')
				} else if (e == 2) {
					window.open('https://www.instagram.com/nutripacks_official/')
				} else if (e == 3) {
					window.open('https://weibo.com/p/1006065832297643/home?from=page_100606&mod=TAB')
				} else if (e == 4) {
					window.open('https://twitter.com/nutripackschina')
				} else if (e == 5) {
					window.open('https://www.facebook.com/nutripackspage')
				} else if (e == 6) {
					window.location.href = 'mailto:zhangfei@nutri-packs.com'
				} else if (e == 7) {
					window.location.href = 'tel:028-85211401'
				} else if (e == 8) {
					// window.open('https://goo.gl/maps/MmqAukTguKWbN27F7')
				} else if (e == 9) {
					// window.open('https://goo.gl/maps/WaZAE74awGYP3FZbA')
				}
			}
		}
	}
</script>

<style scoped>
.hide-fixed-service-buttom {
	transform: translateX(100px);
}
</style>

<style lang="scss">
	#app .foot .show_pc {
		height: auto;
	}

	.new-foot-lbei {
		width: auto;
		height: 34px;
		font-size: 12px;
		line-height: normal;
		text-align: center;
		margin-top: 60px;
	}

	.new-foot-r {
		width: 15vw;
		min-width: 220px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
	}

	.new-foot-r img {
		width: 28px;
		height: 28px;
		margin: 8.5px 0;
		align-items: center;
		cursor: pointer;
	}

	.new-foot-beian {
		width: 86vw;
		margin: 0 auto;
		padding: 0 0 4vw 0;
		height: auto;
		justify-content: center;
	}

	.new-foot-box-b-txt {
		font-size: 14px;
		margin-bottom: 17px;
		line-height: 20px;
	}

	.new-foot-box-b3,
	.new-foot-box-b4 {
		text-align: left;
	}

	.new-foot-box-b-title {
		font-size: 14px;
		font-weight: bold;
		width: auto;
	}

	.new-foot-box-b-line {
		width: 56px;
		height: 3px;
		background: #C25E4D;
		margin: 15px 0 40px 0;
	}

	.new-foot-box-b4 {
		height: auto;
	}

	.new-foot-box-b5 {
		height: auto;
	}

	.new-foot-box-b5,
	.new-foot-box-b6 {
		font-size: 12px;
		text-align: center;
	}

	.new-foot-box-b-qr {
		width: 100px;
		height: 100px;
		margin: 0 auto;
	}

	.new-foot-box-b-qr img {
		width: 100%;
		height: auto;
	}

	.new-foot-box-b2 {
		width: auto;
		display: flex;
	}

	.new-foot-box-b1 {
		width: 188px;
		height: auto;
	}

	.new-foot-box-logo {
		width: 100%;
		height: auto;
		display: block;
	}

	.new-foot-box-b1 button {
		width: 188px;
		height: 50px;
		border-radius: 50px;
		background: #C25E4D;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 50px;
		color: #FFFFFF;
		font-size: 14px;
		margin-top: 45px;
		margin-left: -1px;
	}

	.footButton{
		width: 100%;
	}

	.footButton button {
		margin:0 auto;
		width: 188px;
		height: 50px;
		border-radius: 50px;
		background: #C25E4D;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 50px;
		color: #FFFFFF;
		font-size: 14px;
		margin-top: 45px;
	}
	.footButton button img {
		width: 24px;
		height: 24px;
		margin: calc(1.85vw - 12px) 0 calc(1.85vw - 12px) 0;
	}


	.new-foot-box-b1 button div {
		line-height: 3.9vw;
		align-items: center;
	}

	.new-foot-box-b1 button img {
		width: 24px;
		height: 24px;
		margin: calc(1.85vw - 12px) 0 calc(1.85vw - 12px) 0;
	}

	#app .foot .show_pc {
		padding-top: 82px;
	}

	.new-foot-box {
		width: 1200px;
		height: auto;
		margin: 0 auto;
		margin-bottom: 60px;
		display: flex;
		justify-content: space-between;
		line-height: normal;
	}
	@media screen and (max-width: 1439px){
		.new-foot-box {
			width: 1000px;
		}
	}

	.new-foot-l img{
		width: 1.1vw;
		height: 1.1vw;
		align-items: center;
		margin-left: 2.1vw;
		cursor: auto;
		opacity: 0;
	}

	.foot {
		width: 100%;
		height: 8.65rem;
	}

	.foot .ft {
		width: 100%;
		height: 7.02rem;
		background: #FFFFFF;
	}

	.shubiao:hover {
		text-decoration: underline;
	}

	.foot .beian {
		width: 100%;
		height: 1.59rem;
		background: #FFFFFF;
		text-align: center;
		padding-top: 30px;
	}

	.foot .ftcenter {
		width: 18.5rem;
		height: 7.02rem;
		margin: 0 auto;
	}

	.f-logo {
		width: 4.12rem;
		height: 0.87rem;
		margin: 0 auto;
		margin-top: 2rem;
	}

	.f-logo img {
		width: 100%;
		height: auto;
	}

	.tiplist {
		width: 100%;
		height: 3.59rem;
		display: flex;
		justify-content: space-between;
	}

	.tl1 {
		display: flex;
	}

	.tl2 {
		display: flex;
	}

	.foottip {
		// width: 2.7rem;
		// height: 3.59rem;
		// background: #FFFFFF;
		// padding-top: 0.59rem;
	}

	.foottipt {
		text-align: left;
	}

	.foot .foottips {
		// width: 3.2rem;
	}

	.ftiptitle {
		width: auto;
		display: inline-block;
		height: 0.51rem;
		line-height: 0.31rem;
		font-size: 14px;
		color: #1A1A1A;
		border-bottom: 2px solid #C25E4D;
		text-align: left;
	}

	.ftiptxt {
		width: 100%;
		height: auto;
		line-height: 0.41rem;
		font-size: 14px;
		color: #1A1A1A;
		text-align: left;
		margin-top: 0.49rem;
	}

	.ftiptxt span {
		display: inline-block;
	}

	.foottip img {
		width: 1.63rem;
		height: 1.63rem;
		margin: 0 auto;
	}

	.ftipmsg {
		line-height: 0.31rem;
		font-size: 0.22rem;
		color: #1A1A1A;
		margin-top: 0.15rem;
		text-align: center;
		width: 2.71rem;
	}

	.ftipmsg span {
		display: inline-block;
		width: 100%;
	}

	.beian div {
		// width: auto;
		// height: auto;
		// line-height: 0.41rem;
		// font-size: 14px;
		// color: #1A1A1A;
		// margin: 0 auto;
	}

	.rightqr {
		width: 138px;
		height: 222px;
		position: fixed;
		bottom: 5px;
		right: 5px;
	}

	.rightqr img {
		width: 100%;
		height: 100%;
	}

	.rightqrh5 {
		width: 100px;
		height: 100px;
		position: fixed;
		bottom: 3.6rem;
		right: 5px;
		border-radius: 100px;
		overflow: hidden;
		display: none;
		z-index: 5;
		transform: translateX(0);
		transition: all 0.5s;
	}

	.rightqrh5 img {
		width: 126%;
		height: 126%;
		margin-top: -13%;
		margin-left: -13%;
	}

	.foot .foottips1 img {
		width: 1.66rem;
		height: 1.65rem;
	}

	.foot .foottips1 .ftipmsg {
		margin-top: 0.12rem;
	}

	@media screen and (max-width:1405px) {
		.rightqr {
			width: 138px;
			height: 222px;
			position: fixed;
			bottom: 5px;
			right: 5px;
		}
	}

	@media screen and (max-width:1000px) {
		.rightqrh5 {
			display: block;
		}

		.rightqr {
			display: none;
		}

		.mb-foot {
			width: 100%;
			height: auto;
			color: #1A1A1A;
			display: flex;
			padding-left: 0.51rem;
		}

		.mb-foot img {
			width: 4.8rem;
			height: 0.88rem;
		}

		.foot {
			height: auto;

		}

		.foot-new {
			border-radius: 1.33rem 1.33rem 0rem 0rem;
			background: #F5F5F5;
			margin-top: -0.6rem;
			padding-top: 1.71rem;
		}

		.foot .beian {
		font-size: 0.29rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		text-align: left;
		margin: 1.31rem 0 0 0.51rem;
		li {
			margin-top: 0.03rem;
		}
		}

		.beian div {
			// font-size: 0.37rem;
			// line-height: 0.61rem;
		}

		.beian .l1 {
			width: 100%;
			margin: 0 auto;
			font-size: 12px;
			line-height: 1.5;
		}

		.beian .l2 {
			width: 56%;
			margin: 0 0;
			font-size: 12px;
			line-height: 1.2;
		}

		.new-record {
			display: flex;
			align-items: center;
		}

		.flist {
			display: flex;
			flex-direction: column;
			margin: 0.75rem 0 0 0.51rem;

			.list-item {
				display: flex;
				align-items: center;
				margin-top: 0.37rem;

					img {
						width: 0.36rem;
						height: auto;
					}

					.h5-address-text {
						color: #141414;
						font-size: 0.32rem;
						margin-left: 0.15rem;
					}

					.list-item-title {
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;

					}
			}
		}
		.flist .list-box-line{
			width: 50%;
		}
		.flist .list-box-line:nth-child(2){
			width: 40%;
			margin-left: 10%;
		}

		.flist .flist-title {
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
		}

		.flist-title .ftiptitle {
			border-bottom: none;
			font-size: 14px;
			height: 26px;
			line-height: 26px;
			margin-top: 30px;
			width: 50%;
			text-align: left;
		}
		.flist-title .ftiptitle div{
			display: inline-block;
			border-bottom: 2px solid #C25E4D;
		}

		.flist-list {
			text-align: left;
			padding: 0.1rem 0;
		}

		.flist-list div span {
			line-height: 28px;
			font-size: 14px;
			display: block;
		}

		.flist-list-box {
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
		}

		.flist-list-box .flist-list {
			width: 100%;
		}

		.mb_qr {
			margin-top: 1.35rem;
			.h5-qr {
				width: 2.8rem;
			}
		}

		.foot .mb_qr {
			display: flex;
			justify-content: space-evenly;
		}

		.foot .mb_qr img {
			width: 2.71rem;
			height: 2.71rem;
		}

		.mb_qr .ftipmsg span{
			line-height: 1.5;
			font-size: 0.29rem;
			margin-top: 0;
			text-align: center;
		}

		.h5-record {
			text-align: center;
			height: 0.4rem;
			font-size: 0.29rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 0.4rem;
			margin: 1.31rem 0 0 0.51rem;
			height: auto;
		}
	}
	body .foot{
		height: auto;
	}
	@media screen and (min-width:1921px) {
		.new-foot-box-b1 button{
			font-size: 16px;
		}
		.new-foot-box-b-title{
			font-size: 16px;
		}
		.new-foot-box-b-txt{
			font-size: 16px;
		}
		.new-foot-box-b-line{
			width: 64px;
		}
		.new-foot-box-b5, .new-foot-box-b6{
			font-size: 14px;
		}
		.new-foot-lbei{
			font-size: 14px;
		}
	}
</style>
