<template>
	<div class="addresslist main-page">
		<div class="page-bg"></div>
		<div class="address-title">
			地址管理
			<div class="manage-btn">
				<div class="manageaddress">
				</div>
				<div class="gonewaddress cursor" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</div>
			</div>
		</div>
		<div class="address-box">
			<div class="nomsg" v-if="addressList.length==0">暂未添加收货地址</div>
			<div class="defaultaddress" :class="item.is_default==1||item.isget==1?'active':''" v-for="(item,i) in addressList" :key="i" @click="getthis(i,item.id)">
				<div class="address-btn" v-if="item.is_default==1||item.isget==1">
					<div v-if="item.is_default!=1&&item.isget!=1"></div>
					<div class="leftbtn" v-if="item.is_default==1">默认地址</div>
					<div class="leftbtn" v-if="item.isget==1&&item.is_default!=1">选择地址</div>
				</div>
				<div class="address-btn" v-if="item.is_default!=1&&item.isget!=1">
				</div>
				<div class="theaddress">
					<div class="name-line">
						<div class="address-name black">
							<div class="person-name">{{item.name}}</div>
							<div class="address-phone">{{item.phone.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')}}</div>
						</div>
					</div>
					<div class="name-line">
						<div class="address-name fontsize">
							{{item.province_name+''+item.city_name+''+item.area_name+''+item.address}}
						</div>
					</div>
				</div>
				<div class="delthis" v-if="manage==true" @click="delthis(item)"><img src="../../public/imgs/deladdress.png" alt=""></div>
			</div>
			<div class="addresslist-btns" v-if="way==2">
				<div v-if="getit" class="nousethis cursor" @click="nogetthis">返回</div>
				<div v-if="getit" class="saveit public-btn cursor" @click="saveit">确认选择</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'addresslist',
		components: {},
		data: () => ({
			addressList: '',
			info: '',
			beilist: '',
			manage:false,
			getit: '',
			way:''
		}),
		mounted() {
			this.way = this.$route.query.way?this.$route.query.way:''
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login?way=address-list'
				})
			} else {
				this.info = info
				this.getAddressList()
			}
		},
		methods: {
			getAddressList() {
				var that = this
				//用户地址列表
				this.$ajax.post(this.API.api.apiurl.getAddressList, {
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							var addressList = data.data?data.data:[]
							var addressget = localStorage.getItem('addressget')?localStorage.getItem('addressget'):0
							for(var i=0;i<data.data.length;i++){
								if(data.data[i].is_default==1){
									that.beilist = i
								}
								if(addressList[i].id==addressget){
									addressList[i].isget=1
									
								}else{
									addressList[i].isget=0
								}
							}
							that.addressList = addressList
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			manageit(){
				this.manage = true
			},
			ok(){
				this.manage = false
			},
			delthis(e){
				console.log(e)
			},
			nogetthis() {
				//console.log('取消')
				var index = this.beilist
				var list = this.addressList
				this.getit = ''
				for(var i=0;i<list.length;i++){
					list[i].isget=0
				}
				list[index].is_default=1
				this.addressList=list
				// location.reload()
				this.$router.go(-1)
			},
			getthis(index,id) {
				var that = this
				var list = this.addressList
				var way = this.way
				if(way==2){
					if (list[index].isget == 1) {
						//console.log('本来就是默认')
					} else {
						for (var i = 0; i < list.length; i++) {
							list[i].isget = 0
						}
						list[index].isget = 1
						that.getit = list[index].id
					}
					this.addressList = list
				}else{
					this.$router.push({
						path: 'new-address',
						query: {
							way: 2,
							id: id
						}
					})
				}
			},
			saveit() {
				var that = this
				if (this.getit) {
					
					localStorage.setItem('addressget',that.getit)
					that.$router.go(-1)
				} else {
					//console.log('没有可保存的')
				}
			},
			newaddress() {
				this.$router.push({
					path: 'new-address',
					query: {
						way: 1
					}
				})
			},
			updateaddress(id) {
				this.$router.push({
					path: 'new-address',
					query: {
						way: 2,
						id: id
					}
				})
			},
			setDefaultAddress(id) {
				var that = this
				//设置默认地址
				this.$ajax.post(this.API.api.apiurl.getAddress, {
						id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			delAddress(id) {
				var that = this
				//删除地址
				this.$ajax.post(this.API.api.apiurl.delAddress, {
						id: id,
						member_id: that.info.member_id,
					},{
						headers:{
							'Authorization':'Bearer '+that.info.token,
						}
					})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
		}
	}
</script>

<style>
	.addresslist .address-name .person-name{
		margin-left: 0;
	}
	.address-title {
		width: 100%;
		height: 0.83rem;
		font-size: 0.59rem;
		line-height: 0.83rem;
		font-weight: bold;
		margin: 0.87rem 0 0.72rem 0;
		text-align: left;
		display: flex;
		justify-content: space-between;
	}
	.manage-btn{
		width:3.6rem;
		height: 0.83rem;
		line-height: 0.83rem;
		display: flex;
		justify-content: space-between;
	}
	.manage-btn .manageaddress{
		width: auto;
		height: 0.83rem;
		line-height: 0.83rem;
	}
	.manage-btn .manageaddress div{
		height: 0.83rem;
		font-size: 0.37rem;
		line-height: 0.83rem;
		font-weight: 400;
	}
	.mana-btn{
		color: #C25E4D;
		cursor: pointer;
	}
	.ok-btn{
		cursor: pointer;
	}

	.address-title .gonewaddress {
		width: 2.22rem;
		display: flex;
		justify-content: space-between;
		height: 0.83rem;
		font-size: 0.37rem;
		line-height: 0.83rem;
		font-weight: 400;
	}

	.gonewaddress img {
		width: 0.59rem;
		height: 0.59rem;
		margin: 0.12rem 0;
	}

	.address-box {
		width: 100%;
		height: auto;
		max-width: 18.5rem;
		/* background: #FFFFFF; */
		border-radius: 0.19rem;
		padding: 0.59rem 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.address-box .defaultaddress {
		width: 8.39rem;
		min-height: 3.04rem;
		height: auto;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.08);
		border-radius: 0.19rem;
		/* border: 0.04rem solid #D5DDE6; */
		margin-bottom: 0.59rem;
		position: relative;
		padding: 0.3rem;
	}

	.address-box .active {
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194,94,77, 0.08);
		border-radius: 0.19rem;
		/* border: 0.04rem solid #C25E4D; */
	}

	.name-line {
		margin-bottom: 0.3rem;
	}

	.name-line .line-name {
		width: 1.9rem;
		height: auto;
		line-height: 0.52rem;
		font-size: 0.37rem;
		text-align: left;
		color: #1A1A1A;
	}

	.name-line .address-name {
		width: 100%;
		line-height: 0.52rem;
		font-size: 0.37rem;
		height: auto;
		text-align: left;
		color: #1A1A1A;
		/* overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap; */
	}

	.name-line .black {
		color: #1A1A1A;
	}

	.addresslist-btns {
		width: 100%;
		height: 1.11rem;
		display: flex;
		justify-content: flex-end;
		margin-top: 0.29rem;
		margin-bottom: 0.59rem;
	}
	.addresslist .address-btn{
		position: relative;
		height: 0.31rem;
	}
	.addresslist .leftbtn{
		position: absolute;
		top: -0.3rem;
		left: -0.3rem;
	}
	.nousethis {
		width: auto;
		height: 1.11rem;
		line-height: 1.11rem;
		color: #999999;
		font-size: 0.3rem;
		margin-right: 0.6rem;
	}
	.delthis{
		width: 0.84rem;
		height: 0.49rem;
		position: absolute;
		right: 0.3rem;
		bottom: 0.1rem;
		background: #C25E4D;
		border-radius: 0.1rem;
	}
	.delthis img{
		width: 0.29rem;
		height: 0.29rem;
		margin: 0.1rem auto;
	}
	.nomsg{
		width: 100%;
		line-height: 1.6rem;
		text-align: center;
		font-size: 0.37rem;
		color: #999999;
	}
	
	@media screen and (max-width:1000px) {
		.address-box{
			justify-content: center;
			padding: 0 0.19rem;
		}
		.address-box .defaultaddress{
			width: 100%;
			padding: 0.3rem;
		}
	}
	
</style>
