<template>
	<div ref="settlementmain" class="settlement main-page" v-if="cart_list&&checkdata">
		<div class="page-bg"></div>
		<div class="stepstatus" v-if="xuyao">
			<div class="step-box">
				<div class="step1 steptab">
					<div class="steptabbox">
						<div class="steptab-num">1</div>
						<div class="steptab-name">购买健康方案</div>
					</div>
					<div class="steptab-line"></div>
				</div>
				<div class="step2 steptab">
					<div class="steptab-line"></div>
					<div class="steptabbox">
						<div class="steptab-num">2</div>
						<div class="steptab-name">激活营养顾问服务</div>
					</div>
				</div>
			</div>
		</div>
		<div class="orderdetail">
			<div class="ordertitle settlement-title">地址选择</div>
			<div class="addressbox show_pc">
				<div class="defaultaddress" v-if="default_index!=-1&&addressList.length>0">
					<div class="address-btn">
						<div class="" v-if="addressList[default_index].is_default!=1"></div>
						<div class="leftbtn" v-if="addressList[default_index].is_default==1">默认地址</div>
						<!-- <div class="rightbtn cursor" @click="updateaddress(addressList[default_index].id)">切换地址</div> -->
						<a class="rightbtn cursor" @click="updateaddress(addressList[default_index].id)">切换地址</a>
					</div>
					<div class="theaddress">
						<div class="name-line">
							<img src="../../public/imgs/address.png" alt="">
							<div class="address-name">
								<div class="person-name">{{addressList[default_index].name}}</div>
								<div class="address-phone">{{addressList[default_index].phone.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')}}</div>
							</div>
						</div>
						<div class="address-detail">{{addressList[default_index].province_name+' '+addressList[default_index].city_name+' '+addressList[default_index].area_name+' '+addressList[default_index].address}}</div>
					</div>
				</div>
				<div class="defaultaddress" v-if="default_index==-1&&addressList.length>0">
					<div class="address-btn">
						<div class=""></div>
						<!-- <div class="rightbtn cursor" @click="updateaddress(addressList[0].id)">切换地址</div> -->
						<a class="rightbtn cursor" @click="updateaddress(addressList[0].id)">切换地址</a>
					</div>
					<div class="theaddress">
						<div class="name-line">
							<img src="../../public/imgs/address.png" alt="">
							<div class="address-name">
								<div class="person-name">{{addressList[0].name}}</div>
								<div class="address-phone">{{addressList[0].phone.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')}}</div>
							</div>
						</div>
						<div class="address-detail">{{addressList[0].province_name+' '+addressList[0].city_name+' '+addressList[0].area_name+' '+addressList[0].address}}</div>
					</div>
				</div>
				<!-- <div class="newaddress cursor show_pc" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</div>
				<div class="newaddress cursor show_mb" v-if="addressList.length==0" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</div> -->

				<a class="newaddress cursor show_pc" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</a>
				<a class="newaddress cursor show_mb" v-if="addressList.length==0" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</a>
			</div>
			<div class="addressbox show_mb">
				<div class="defaultaddress" v-if="default_index!=-1&&addressList.length>0" @click="updateaddress(addressList[default_index].id)">
					<img class="address-more" src="../../public/imgs/more.png" alt="">
					<div class="theaddress">
						<div class="name-line">
							<img src="../../public/imgs/address.png" alt="">
							<div class="address-name">
								<div class="person-name">{{addressList[default_index].name}}</div>
								<div class="address-phone">{{addressList[default_index].phone.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')}}</div>
							</div>
						</div>
						<div class="address-detail">{{addressList[default_index].province_name+' '+addressList[default_index].city_name+' '+addressList[default_index].area_name+' '+addressList[default_index].address}}</div>
					</div>
				</div>
				<div class="defaultaddress" v-if="default_index==-1&&addressList.length>0" @click="updateaddress(addressList[0].id)">
					<img class="address-more" src="../../public/imgs/more.png" alt="">
					<div class="theaddress">
						<div class="name-line">
							<img src="../../public/imgs/address.png" alt="">
							<div class="address-name">
								<div class="person-name">{{addressList[0].name}}</div>
								<div class="address-phone">{{addressList[0].phone.replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')}}</div>
							</div>
						</div>
						<div class="address-detail">{{addressList[0].province_name+' '+addressList[0].city_name+' '+addressList[0].area_name+' '+addressList[0].address}}</div>
					</div>
				</div>
				<!-- <div class="newaddress cursor show_pc" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</div>
				<div class="newaddress cursor show_mb" v-if="addressList.length==0" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</div> -->
				<a class="newaddress cursor show_pc" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</a>
				<a class="newaddress cursor show_mb" v-if="addressList.length==0" @click="newaddress">
					<img src="../../public/imgs/group.png" alt="">新建地址
				</a>
			</div>
			<div class="orderbox">
				<div class="goodlist-title settlement-title margintop marginbottom">商品列表</div>
				<div class="goodslist">
					<div class="goodslist-box" v-for="(item,i) in cart_list" :key="i">
						<div class="goods-info">
							<img :src="item.img" alt="">
							<div class="goods-infobox">
								<div class="goods-name">{{item.name}}
								</div>
								<div class="goods-num fontsize" v-if="item.source==1">{{item.plan_title}}({{item.buy_days}}天)</div>
								<div class="goods-num fontsize" v-if="item.source==2">{{item.num}}套({{item.buy_days*item.num}}天)</div>

								<div class="goods-infobox-bottom">
									<div class="bold">
										￥{{item.pay_price}}<s v-if="item.pay_price!=item.total_market_price">￥{{item.total_market_price}}</s>
									</div>
									<div class="order-goods-detail cursor" @click="item.showlist=!item.showlist" v-if="item.source != 2">
										查看详情 <img :class="item.showlist==true?'morelist':''" src="../../public/imgs/chevron-down.png" alt="">
									</div>
								</div>
							</div>
						</div>
						<div v-if="item.showlist">
							<div v-for="(item1,j) in item.goods_info" :key="j">
								<div class="good-box">
									<img :src="item1.minshrink_img" alt="">
									<div class="good-infobox">
										<div class="good-num">
											<div class="good-name">{{item1.goods_name}}</div>
											<div class="good-daynum">{{item1.goods_num}}颗/天</div>
										</div>
										<div class="good-price">¥{{item1.dprice}}/天</div>
									</div>
								</div>
								<div class="good-line" v-if="j+1!=item.goods_info.length"></div>
							</div>
						</div>
					</div>
					<div class="service-box" v-if="ss!=1">
						<img :src="checkdata.nutri.img" alt="">
						<div class="service-info">
							<div class="service-title"><span>口袋式健康顾问服务</span><span class="free">免费</span></div>
							<div class="service-txt">购买后，免费为你配置一名认证健康顾问VC。他会为你提供一对一、口袋式、持续性的健康咨询服务，以及深度健康评估改善、疾病干预提醒、家庭健康档案制作等健康管理服务。</div>
						</div>
					</div>
				</div>

			</div>
			<div class="orderbox pricebox">
				<div class="price-box">
					<div class="leave-message">
						<div class="leave-title">留言</div>
						<div class="leave-txt" :class="showinput?'showinput':''">
							<input v-model="order_remark" class="show_pc" placeholder="请输入留言" type="text">
							<textarea v-model="order_remark" class="show_mb" placeholder="请输入留言" maxlength="60" name="" id="" cols="30" rows="10"></textarea>
						</div>
						<img class="message-img show_mb" :class="showinput?'showinputimg':''" src="../../public/imgs/more.png" @click="showinput=!showinput" alt="">
					</div>
					<div class="leave-line">
						<div class="line-left">商品小计</div>
						<div class="line-center"></div>
						<div class="line-right">￥{{checkdata.totalPrice}}</div>
					</div>
					<!-- <div class="leave-line">
						<div class="line-left">老关税服务 <img src="../../public/imgs/qus.png" alt="" @click="showgs=true"></div>
						<div class="line-center"></div>
						<div class="line-right">￥{{(((checkdata.tariff*1000+checkdata.tariff_postage*1000+checkdata.customs_price*1000+checkdata.postage_other*1000)/100).toFixed(1)/10).toFixed(2)}}</div>
					</div> -->
					<div class="leave-line">
						<div class="line-left">关税服务 <img src="../../public/imgs/qus.png" alt="" @click="showgs=true"></div>
						<div class="line-center"></div>
						<div class="line-right">￥{{(((checkdata.payPrice*1000+checkdata.couponDiscount*1000+checkdata.couponDiscountTariff*1000+checkdata.memberDiscount*1000+checkdata.saleDiscount*1000+checkdata.shareDiscount*1000-checkdata.totalPrice*1000-checkdata.postage*1000)/100).toFixed(1)/10).toFixed(2)}}</div>
					</div>
					<div class="leave-line" v-if="checkdata.saleDiscount!=0">
						<div class="line-left">专业人士优惠</div>
						<div class="line-center"></div>
						<div class="line-right">-￥{{checkdata.saleDiscount}}</div>
					</div>
					<div class="leave-line">
						<div class="line-left">运费</div>
						<div class="line-center"></div>
						<div class="line-right">{{checkdata.postage==0?'免邮':'￥'+checkdata.postage}}</div>
					</div>
					<div class="allprice">合计 ￥<span><b>{{(checkdata.payPrice).toFixed(2)}}</b></span></div>
				</div>
			</div>
		</div>
		<div class="buy-box">
			<div class="buy-btns">
				<div class="main-weight">
					<div class="day-price">
						<div class="day-num">待支付 <span>￥{{(checkdata.payPrice).toFixed(2)}}</span></div>
						<div class="day-money">保税区免费直邮</div>
					</div>
					<div class="actions-btns">
						<a class="buythis public-btn" @click="gopay">前往支付</a>
					</div>
				</div>
			</div>
		</div>
		<div class="notice" :class="canbuy?'':'notices'" v-if="shownotice" @click="closeit">
		</div>
		<div class="notice-box" v-if="shownotice">
			<div class="notice-title">消费者告知书 <img class="closeit" @click="closeit" src="../../public/imgs/close.png" alt=""></div>
			<div class="notice-msg">
				<div class="msg-line fontsize">尊敬的客户：</div>
				<div class="msg-line"></div>
				<div class="msg-line fontsize">你好！为帮助你更好地选购跨境商品，请你在购买前务必认真、详细阅读并完全理解本告知书的全部内容，并对自身风险承担做出客观判断。同意本告知书内容后再下单购买：</div>
				<div class="msg-line"></div>
				<div class="msg-line fontsize">1.你在本平台购买的跨境商品等同于直接境外购买，商品本身可能无中文标签，如有需要，你可以通过产品详情页查看相关商品标签中文翻译或联系客服。</div>
				<div class="msg-line"></div>
				<div class="msg-line fontsize">2.根据相关法律政策，你选购的跨境商品仅限个人自用，不得进行再次销售。</div>
				<div class="msg-line"></div>
				<div class="msg-line fontsize">3.你购买的跨境商品符合原产地有关质量、安全、卫生、环保、标识等标准或技术规范要求，可能与我国标准有所不同，由此可能产生的危害、损失或者其他风险，将由你自行承担。</div>
				<div class="msg-line"></div>
				<div class="msg-line fontsize">4.本平台按照相关防疫消杀规定，对提离口岸的高风险地区进境邮快件及跨境电商包裹第一时间逐件进行外包装各面全覆盖消毒，根据需求进行核酸抽检；所售商品均为安全商品，请您放心选购。</div>
				<div class="msg-line"></div>
				<div class="msg-line fontsize">5.我们对一线人员，严格落实个人防护、健康监测、核酸检测等要求，并且严格分隔作业区域，确保内外不交叉作业，对直接接触货物的掏箱作业人员坚决做到闭环管控。</div>
			</div>
			<div class="notice-btn public-btn cursor" @click="tongyi">同意并继续</div>
		</div>
		<div class="code_url" v-if="showqr">
			<div class="qr-box">
				<div class="close-box">
					<div class="qrtitle">扫码支付</div>
					<img class="cursor" src="../../public/imgs/close.png" alt="" @click="goclose">
				</div>
				<div class="wxtxt"><img class="wicon" src="../../public/imgs/weixin-icon.png" alt="">请使用微信扫码支付</div>
				<img class="qrimg" :src="qr" alt="">
			</div>
		</div>
		<div class="rgba" @click="closepopup" v-if="showpopup"></div>
		<popup v-if="showpopup&&!shownotice" :rdata="popdata" @getback="getbackinfo"></popup>
		<div class="closepopup" v-if="showpopup" @click="closepopup">
			<img src="../../public/imgs/no.png" alt="">
		</div>
		<div class="gsbg" v-if="showgs" @click="showgs=false"></div>
		<div class="guanshui" v-if="showgs">
			<div class="gstitle"><div>税费说明</div><img @click="showgs=false" src="../../public/imgs/close.png" alt=""></div>
			<div class="gstxt">
				<p>1.关税服务费用由跨境电商综合税及服务费用组成。</p>
				<p>2.跨境电商综合税=（补剂营养品价格+清关服务费及运费）*9.1%；清关服务费起步金额为每单2元，如有特殊产品，如应急营养系列，则每10盒需加8元，不满10盒按10盒算。</p>
				<p>3.跨境电商综合税须按一般贸易增值税及消费税税额的70%征收，税费以提交订单时的金额为准。</p>
				<p>4.根据跨境电子商务零售进口税收政策，自2019年1月1日起，个人单笔交易限值人民币5000元，个人年度交易限值人民币26000元。</p>
			</div>
		</div>

		<Modal v-model="modal8" :mask-closable="false" :closable="false" width="423.9" :styles="{top: '30%'}">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>支付未成功</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>请确认是否中断支付，或在订单页面重试。</p>
			</div>
			<div class="modalbtns">
				<div class="modalok modalbtn cursor" @click="weixinnopay">确定</div>
			</div>
			<div class="disfoot" slot="footer">
			</div>
		</Modal>
	</div>
</template>

<script>
		if (navigator.userAgent.indexOf('AliApp') > -1) {
			document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>');
		}
	</script>
<script>
	var thetime = ''
	import popup from '../components/popup.vue'
	export default {
		name: 'settlement',
		components: {
			popup
		},
		data: () => ({
			shownotice: false,
			hasshownotice: false,
			addressList: [],
			cart_list: [],
			xuyao:false,
			checkdata: '',
			showqr: false,
			modal8: false,
			ss:1,
			zu:0,
			sid: '',
			showinput:false,
			notiyan:false,
			canbuy: false,
			info: '',
			bid: '',
			tipsurl: 'https://mmbizurl.cn/s/yiDciNLJd',
			qr: '',
			showpopup: false,
			popdata: '',
			order_remark: '',
			time: 60,
			H5params:'',
			showgs:false,
			buytype: '',
			type: '',
			default_index: -1,
			addressget:'',
			source:1,
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		created() {
			window.addEventListener('beforeunload', e => this.test(e))
		},
		destroyed() {
			window.removeEventListener('beforeunload', e => this.test(e))
			window.removeEventListener('popstate', this.test(1), false);
		},
		watch: {
			$route() {
				let settlementmain = this.$refs.settlementmain
				settlementmain.scrollTo(0, 0)
			}
		},
		mounted() {
			var that = this
			var sta = localStorage.getItem('shownotice')
			this.shownotice = !sta
			this.hasshownotice = sta
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login'
				})
			}
			var addressget = localStorage.getItem('addressget')
			that.addressget = addressget
			var shownotice = localStorage.getItem('shownotice')
			this.hasshownotice = shownotice ? shownotice : false
			that.info = info
			var c = this.$route.query.cid
			var cids = ''
			if(Object.prototype.toString.call(c)=='[object String]'){
				that.cid = [this.$route.query.cid]
				cids = [this.$route.query.cid]
			}else{
				that.cid = this.$route.query.cid
				cids = this.$route.query.cid
			}
			if(this.$route.query.type){
				if(this.$route.query.type==4){
					that.source=4
				}else if(this.$route.query.type==3){
					that.source=3
				}else if(this.$route.query.type==1){
					that.source=1
				}else{
					that.source=1
				}
			}else{
				that.source=1
			}
			that.buytype = this.$route.query.type
			this.getAddressList()
			this.settleAccounts()
			//校验商品信息V2
			this.$ajax.post(this.API.api.apiurl.checkCartV2, {
					cart_ids: cids,
					member_id: that.info.member_id,
					coupon_ids: '',
					enjoy_sharing: 0,
					source: that.source
				},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
				.then(res => {
					// //console.log('123')
					var data = res.data.data
					var goodsdata = ''
					// data.sellOutData.zu = that.zu

						if (res.data.code == 0&&Object.prototype.toString.call(res.data.data.sellOutData)!='[object Object]') {
							that.checkdata = data
							that.canbuy = true
						} else if (res.data.code == 0&&Object.prototype.toString.call(res.data.data.sellOutData)=='[object Object]') {
							if(this.advanceSale) {
								that.checkdata = data
								that.canbuy = true
								return
							}
							that.checkdata = data
								goodsdata = data.sellOutData
								goodsdata.zu = that.zu
								that.popdata = goodsdata
								that.showpopup = true
								that.canbuy = false
						}else if (res.data.code == 101) {
							that.$router.replace({path:'404'})
						}else if (res.data.code == 113) {
							that.$router.replace({path:'404'})
							goodsdata = data.sellOutData
								goodsdata.zu = that.zu
							that.popdata = goodsdata
							// that.showpopup = true
							that.canbuy = false
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
				})
				.catch(error => {
					console.log(error)
					that.$Message.info('错误：购物信息有误！')
					that.$router.go(-1)
				})
		},
		methods: {
			checknopay(){
				var that = this
				var c = this.$route.query.cid
				var cids = ''
				if(Object.prototype.toString.call(c)=='[object String]'){
					that.cid = [this.$route.query.cid]
					cids = [this.$route.query.cid]
				}else{
					that.cid = this.$route.query.cid
					cids = this.$route.query.cid
				}
				this.$ajax.post(this.API.api.apiurl.checkCartV2, {
						cart_ids: cids,
						member_id: that.info.member_id,
						coupon_ids: '',
						enjoy_sharing: 0,
						source: that.source
					},{
										headers:{
											'Authorization':'Bearer '+that.info.token,
										}
									})
					.then(res => {
					// //console.log('123')
						var data = res.data.data
						// data.sellOutData.zu = that.zu
						var goodsdata = ''
						if (res.data.code == 0&&Object.prototype.toString.call(res.data.data.sellOutData)!='[object Object]') {
							//console.log(data)
							that.checkdata = data
							that.canbuy = true
						} else if (res.data.code == 0&&Object.prototype.toString.call(res.data.data.sellOutData)=='[object Object]') {
							//console.log(data)
							if(this.advanceSale) {
								that.checkdata = data
								that.canbuy = true
								return
							}
							that.checkdata = data
								goodsdata = data.sellOutData
								goodsdata.zu = that.zu
								that.popdata = goodsdata
								that.showpopup = true
								that.canbuy = false
						}else if (res.data.code == 101) {
								that.$router.replace({path:'404'})
							}else if (res.data.code == 113) {
								that.$router.replace({path:'404'})
								goodsdata = data.sellOutData
								goodsdata.zu = that.zu
								that.popdata = goodsdata
								that.showpopup = true
								that.canbuy = false
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
					})
					.catch(error => {
						console.log(error)
						that.$Message.info('错误：购物信息有误！')
						that.$router.go(-1)
					})
			},
			noshowit(){
				this.modal8 = false
			},
			closepopup() {
				this.showpopup = false
			},
			test() {
				//console.log(e, '刷新或关闭')
				clearInterval(thetime);
			},
			gocheck() {
				if (this.addressList.length == 0) {
					return this.$Message.info('请添加地址')
				}
			},
			check() {
				var that = this
				this.$ajax.post(this.API.api.apiurl.checkCartV2, {
						cart_ids: that.cid,
						member_id: that.info.member_id,
						coupon_ids: '',
						enjoy_sharing: 0,
						source: that.source
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
					// //console.log('123')
						////console.log(res)
						var data = res.data.data
						var goodsdata = ''
						// data.sellOutData.zu = that.zu

						if (res.data.code == 0&&Object.prototype.toString.call(res.data.data.sellOutData)!='[object Object]') {
							that.checkdata = data
							that.canbuy = true
							that.gopay()
						} else if (res.data.code == 0&&Object.prototype.toString.call(res.data.data.sellOutData)=='[object Object]') {
							//console.log(data)
							if(this.advanceSale) {
								that.checkdata = data
							that.canbuy = true
							that.gopay()
							return
							}
							goodsdata = data.sellOutData
								goodsdata.zu = that.zu
							that.popdata = goodsdata
							that.showpopup = true
							that.canbuy = false
						}else if (res.data.code == 113) {
							goodsdata = data
								goodsdata.zu = that.zu
							that.popdata = goodsdata
							that.showpopup = true
							that.canbuy = false
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			gobuythis() {
				this.shownotice = false
				this.gopay()
			},
			goclose() {
				this.showqr = false
				clearInterval(thetime)
				this.settleAccounts()
				this.modal8 = true
			},
			tongyi(){
				this.shownotice = false
				this.hasshownotice = true
				if(this.canbuy==false){
					this.showpopup = true
				}
				//console.log(this.showpopup)
				localStorage.setItem('shownotice', true)
			},
			gopay() {
				if(this.notiyan==true){
					this.$Message.error('商品金额已更新，请重试！');
					this.$router.go(-1)
				}else{
					var that = this
					if (localStorage.getItem('way') == 'pc') {
						that.gopayway(3)
					} else if (localStorage.getItem('way') == 'weixin') {
						that.gopayway(4)
					} else {
						if(that.isAlipay) {
							that.gopayway(5)
						} else {
							that.gopayway(2)
						}
					}
				}
				// }
			},
			gopayway(e) {
				console.log('点击支付按钮',e)
				//console.log(e)
				var that = this
				if (this.canbuy == false) {
					this.check()
				} else {
					var address = ''
					var goodids = []
					var goodsdata = []
					var addressList = that.addressList
					address = addressList.length > 0 ? addressList[0] : ''
					var cart_list = that.cart_list

					// for (var i = 0; i < addressList.length; i++) {
					// 	if (addressList[i].is_default == 1) {
					// 		address = addressList[i]
					// 	}
					// }
					addressList.forEach((item) => {
						if(item.id==that.addressget){
							address = item
						}else if (item.is_default == 1) {
							address = item
						}
					})
					//console.log(address, cart_list)
					for (var j = 0; j < cart_list.length; j++) {
						var goods = cart_list[j].goods_info
						goods.forEach((item) => {
							//console.log(index)
							goodids.push(item.goods_id)
							goodsdata.push({
								id: item.goods_id,
								num: 1
							})
						})
					}

					if (!address.name) {
						that.$Message.error('请先创建地址');
					} else {
						//console.log(that.$route.query.cid)
						var pay_type = localStorage.getItem('way')
						// var tp = pay_type == 'weixin' ? 1 : pay_type == 'pc' ? 3 : 4
						var tp = e
						//结算页面获取数据
						this.$ajax.post(this.API.api.apiurl.createOrder, {
								member_id: that.info.member_id,
								address_id: address.id,
								cart_ids: Object.prototype.toString.call(that.$route.query.cid) == '[object Array]' ? that.$route.query.cid : [
									that.$route.query.cid
								],
								pay_type: tp,
								order_type: 1,
								user_coupon_ids: [],
								order_from: tp == 5 ? '6' : '1',
								source: 1,
								order_remark: that.order_remark,
								enjoy_sharing: 0,
								c_id: 0,
								share_id: 0,
								creative_id: localStorage.getItem('creativeId') ? localStorage.getItem('creativeId') : '',
								channel_id: localStorage.getItem('sourceId') || 0
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								var data = res.data
								if (res.data.code == 0) {
									if (tp == 3) {
										var qr = data.data.returnData.qr_url
										that.checkOrder(data.data.order_id)
										that.qr = qr
										that.showqr = true
									} else if (tp == 1) {
										var returnData = data.data
										var wurl = window.location.href
										if (pay_type == 'h5') {
											var url = 'https://wx.tenpay.com/cgi-bin/mmpayweb-bin/checkmweb?prepay_id=' + returnData.returnData.prepay_id +
												'&amp;package=' + returnData.returnData.package + '&amp;redirect_url=' + wurl
											window.location.href = url
										} else {
											if (typeof WeixinJSBridge == "undefined") {
												if (document.addEventListener) {
													document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(returnData.returnData), false);
												} else if (document.attachEvent) {
													document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(returnData.returnData));
													document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(returnData.returnData));
												}
											} else {
												that.onBridgeReady(returnData.returnData);
											}
										}
										that.checkOrder(data.data.order_id)
									} else if (tp == 2) {
											var rdata = data.data
											// var rurl = window.location.href
											var zurl = rdata.returnData.payinfo
											that.$router.replace({
												path: 'paid',
												query: {
													id: data.data.order_id,
												}
											})
											setTimeout(function() {
												window.location.href = zurl
											}, 2000)
											// window.location.href = zurl
											// that.checkOrder(data.data.order_id)
									}else if(tp==4){
										//console.log(data.data)
										that.H5params = data.data.returnData
										that.weixinpay(data.data.order_id)
									} else if(tp == 5) {
										console.log('创建订单接口',data.data.returnData)
									console.log('传输数据到支付宝小程序',my)
											my.postMessage({
												data: {
													action: 'startPayment',
													orderInfo: data.data.returnData // 传递支付参数
												}
											});
											this.checkOrderAliPay(data.data.order_id)
									}
								}else if (res.data.code == 113) {
									goodsdata = data.data
									goodsdata.zu = that.zu
									that.popdata = goodsdata
									that.showpopup = true
									that.canbuy = false
								} else if(res.data.code == 131) {
									that.$Message.info('错误：' + res.data.message)
									that.$router.go(-1)
								}else {
									that.$Message.info('错误：' + res.data.message)
								}
							})
							.catch(error => {
								console.log(error)
							})
					}
				}
			},
			onBridgeReady(e) {
				var that = this
				window.WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId": e.appId, //公众号名称，由商户传入
						"timeStamp": e.timeStamp, //时间戳，自1970年以来的秒数
						"nonceStr": e.nonceStr, //随机串
						"package": e.package,
						"signType": e.signType, //微信签名方式：
						"paySign": e.paySign //微信签名
					},
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// if(res.err_msg=='ok'){
							that.$Message.info('成功')

							// }
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						} else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
							that.modal8 = true
						} else if (res.err_msg == 'get_brand_wcpay_request:fail') {
							that.$Message.info('失败')
						}
					});
			},
			// 拉起微信h5支付
			weixinpay(id){
				let _H5params = this.H5params
				//console.log(this.H5params)
				var z = 'https://syb.allinpay.com/apiweb/h5unionpay/unionorder'
				// let _url   = this.API.api.apiurl.zhifuurl + "?";
				// var str=Object.prototype.toString.call(_H5params.reqsn)=='[object String]'?_H5params.reqsn:JSON.stringify(_H5params.reqsn);//截取后4位
				// var disLength = str.length;
				// var ssr = str.substring(disLength-4,disLength);
				// var ssurl = 'https://'+window.location.host+'/wxpaid?id='+id+'&w='+ssr+'&m='+_H5params.trxamt;
				// //console.log(ssurl)
				let _url   = z + "?";
				_url=  _url + "cusid="+ _H5params.cusid;
				_url=  _url + "&appid="+ _H5params.appid;
				_url=  _url + "&version="+ _H5params.version;
				_url=  _url + "&trxamt="+ _H5params.trxamt.toFixed(0);
				_url=  _url + "&reqsn="+ _H5params.reqsn;
				_url=  _url + "&charset="+ _H5params.charset;
				_url=  _url + "&returl="+ _H5params.returl;
				_url=  _url + "&notify_url="+ _H5params.notify_url;
				// _url=  _url + "&notify_url="+ ssurl;
				_url=  _url + "&body="+ _H5params.body;
				_url=  _url + "&remark="+ _H5params.remark;
				_url=  _url + "&randomstr="+ _H5params.randomstr;
				_url=  _url + "&validtime="+ _H5params.validtime;
				_H5params.limit_pay?_url=  _url + "&limit_pay="+ _H5params.limit_pay:'';
				_url=  _url + "&sign="+ _H5params.sign
				this.$router.replace({
					path: 'paid',
					query: {
						id: id,
					}
				})
				setTimeout(function() {
					window.location.href=_url;
				}, 200)
			},
			weixinnopay(){
				this.$router.replace({
					path: 'order-list',
				})
			},
			// 支付宝支付结果
			checkOrderAliPay(e) {
				var that = this
				thetime = setInterval(function() {
					//获取报告推荐产品
					that.$ajax.post(that.API.api.apiurl.checkOrder, {
							// count_id:that.count_id,
							order_id: e,
						member_id: that.info.member_id,
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							console.log('checkOrderAliPay',res)
							if (res.data.code == 0) {
								// alert('支付完成，跳转页面（暂未开发）')
									clearInterval(thetime)
									that.$router.replace({
										path: 'paid',
										query: {
											id: e
										}
									})
								// if (res.data.data.order_status == 1 || res.data.data.order_status == 11 || res.data.data.order_status == 12) {
								// }
							} else {
								// 取消支付的情况，弹出弹窗跳转到订单列表页
									clearInterval(thetime)
									alert(res.data.message)
									this.$router.replace({
										path: 'order-list',
									})
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}, 1000)
			},
			checkOrder(e) {
				var that = this
				thetime = setInterval(function() {
					//获取报告推荐产品
					that.$ajax.post(that.API.api.apiurl.checkOrder, {
							// count_id:that.count_id,
							order_id: e,
						member_id: that.info.member_id,
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							if (res.data.code == 0) {
								// alert('支付完成，跳转页面（暂未开发）')
								if (res.data.data.order_status == 1 || res.data.data.order_status == 11 || res.data.data.order_status == 12) {
									clearInterval(thetime)
									that.$router.replace({
										path: 'paid',
										query: {
											id: e
										}
									})
								}
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}, 1000)
			},
			settleAccounts() {
				var that = this
				//结算页面获取数据
				this.$ajax.post(this.API.api.apiurl.settleAccounts, {
						cart_ids: that.cid,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
							var data = res.data.data
							var cart_list = data
							var ss = 1
							for(var i=0;i<cart_list.length;i++){
								if(cart_list[i].source==1){
									that.xuyao = true

									if(cart_list[i].buy_id==14){
										var thelist = cart_list[i].goods_info
										var dp = 0
										thelist.forEach((item) => {
											//console.log(index)
											dp += Number((Number(item.goods_price)*item.goods_num).toFixed(2))
										})
										if(dp<9){
											that.notiyan = true
										}
									}
								}
								cart_list[i].pay_price = Number(cart_list[i].pay_price.toFixed(2))
								cart_list[i].total_price = Number(cart_list[i].total_price.toFixed(2))
								cart_list[i].total_market_price = Number(cart_list[i].total_market_price.toFixed(2))
								cart_list[i].showlist = false
								var goods_info = cart_list[i].goods_info
								goods_info.forEach((item,index)=>{
									var pr = item.goods_price*item.goods_num
									goods_info[index].dprice = Number(pr).toFixed(2)
								})
								cart_list[i].goods_info = goods_info
								if(cart_list[i].source==1||cart_list[i].source==0){
									ss+=1
								}
									if(cart_list[i].source==2){
										that.zu = 1
									}
								if(cart_list[i+1]){
									if(cart_list[i].source!=cart_list[i+1].source){
										that.zu = 1
									}
								}
							}
							//console.log(ss,that.zu)
							that.ss = ss
							//console.log(cart_list)
							that.cart_list = cart_list
						}else if(res.data.code == 116){
							that.$router.replace({path:'404'})
						}else if(res.data.code == 131) {
									that.$Message.info('错误：' + res.data.message)
									that.$router.go(-1)
								} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getAddressList() {
				var that = this
				//用户地址列表
				this.$ajax.post(this.API.api.apiurl.getAddressList, {
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
						var data = res.data.data
							var addressList = data
							addressList.forEach((item, index) => {
								if(item.id==that.addressget){
									that.default_index = index
									localStorage.setItem('addressget','')
								}else if (item.is_default == 1) {
									that.default_index = index
								}
							})
							that.addressList = addressList
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			delCartGoodsAll() {
				var that = this
				var popdata = this.popdata
				var ids = []
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					ids[i] = popdata.goodsReason[i].goods_id
				}
				//批量删除橘袋产品
				this.$ajax.post(this.API.api.apiurl.delCartGoodsAll, {
						cart_ids:Object.prototype.toString.call(that.$route.query.cid) == '[object Array]'?that.$route.query.cid:[that.$route.query.cid] ,
						member_id: that.info.member_id,
						goods_ids: ids
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data.data
						if (res.data.code == 0) {
							if(data.cart_ids.length==0){
								that.$Message.info('方案内已没有补剂可以购买')
								that.$router.replace({path:'cart'})
							}else{
								that.delarr(ids)
								that.settleAccounts()
								that.checknopay()
							}
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			delarr(arr) {
				var that = this
				//console.log(arr)
				var cart_list = that.cart_list
				for (var i = 0; i < arr.length; i++) {
					cart_list.forEach((item, index) => {
						if (item.goods_id == arr[i]) {
							cart_list.splice(index, 1)
						}
					})
				}
			},
			newaddress() {
				this.$router.push({
					path: 'new-address',
					query: {
						way: 1,
						page: 'pay'
					}
				})
			},
			updateaddress(id) {
				this.$router.push({
					path: 'address-list',
					query: {
						way: 2,
						id: id,
						page: 'pay'
					}
				})
			},
			saveTheArrivalNoticeapi(e) {
				var that = this
				//保存到货通知消息推送任务
				this.$ajax.post(this.API.api.apiurl.saveTheArrivalNotice, {
						type: that.buytype,
						member_id: that.info.member_id,
						goods_id: e,
						meal_id: '',
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 1) {
							//console.log(data)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			saveTheArrivalNotice() {
				var that = this
				var popdata = this.popdata
				//console.log(popdata)
				// var saveids = []
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					// saveids[i]=popdata.goodsReason[i].goods_id
					that.saveTheArrivalNoticeapi(popdata.goodsReason[i].goods_id)
				}

				window.location.href = that.tipsurl
			},
			closeit() {
				this.shownotice = false
				this.hasshownotice = true
				if(this.canbuy==false){
					this.showpopup = true
				}
				localStorage.setItem('shownotice', true)
			},
			getbackinfo(e) {
				console.log(e)
				var that = this
				if (e.value == 1) {
					that.$Message.info('跳转页面(暂未开发)')
				} else if (e.value == 0) {
					this.saveTheArrivalNotice()
					// window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100004266&idx=1&sn=b4b6aeb22c2375391eda621cb0899cf2&chksm=7d84d9074af3501188729533d69130a12eeb15de1ff0ecea4160e403de5896fa233fc588eb29#rd'
				} else if (e.value == 3) {
					// var address = ''

					var goodids = []
					var goodsdata = []
					// var addressList = that.addressList
					var cart_list = that.cart_list

					// for (var i = 0; i < addressList.length; i++) {
					// 	if (addressList[i].is_default == 1) {
					// 		address = addressList[i]
					// 	}
					// }
					//console.log(address.length)
					for (var j = 0; j < cart_list.length; j++) {
						var goods = cart_list[j].goods_info
						goods.forEach((item) => {
							//console.log(index)
							goodids.push(item.goods_id)
							goodsdata.push({
								id: item.goods_id,
								num: 1
							})
						})
					}
					this.canbuy = true
					// this.gopay()
				} else if (e.value == 4) {
					this.$router.replace({
						path: 'questionnaire'
					})
				} else if (e.value == 5) {
					this.delCartGoodsAll()
				}else if (e.value == 6) {
					this.$router.go(-1)
				}else if (e.value == 111) {
					this.$router.go(-1)
				} else if (e.value == 10) {
					console.log('预购橘袋')
					this.advanceSale=true
				}
				this.showpopup = false
			}
		}
	}
</script>

<style>
	.settlement {
		width: 100%;
		/* max-width: 26rem; */
		margin: 0 auto;
		padding-bottom: 2.07rem;
	}

	.stepstatus {
		width: 100%;
		height: 2.33rem;
		/* background: #F6F7F8; */
		padding: 0.59rem 0;
	}

	.stepstatus .step-box {
		width: 100%;
		max-width: 12.5rem;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}

	.step-box .steptab {
		width: 45%;
		height: 1.15rem;
		display: flex;
	}

	.steptab .steptabbox {
		width: 1.77rem;
		height: 1.15rem;
	}

	.steptabbox .steptab-num {
		width: 0.59rem;
		height: 0.59rem;
		border-radius: 0.59rem;
		margin: 0 auto;
		border: 0.02rem solid #C25E4D;
		font-size: 0.35rem;
		color: #C25E4D;
		line-height: 0.59rem;
		text-align: center;
	}

	.steptabbox .steptab-name {
		width: 2.77rem;
		height: 0.41rem;
		text-align: center;
		line-height: 0.41rem;
		font-size: 0.3rem;
		margin-left: -0.5rem;
		margin-top: 0.15rem;
	}

	.steptab .steptab-line {
		width: calc(100% - 1.18rem);
		height: 0.04rem;
		background: #C25E4D;
		margin-top: 0.275rem;
	}

	.step1 .steptab-line {
		margin-left: -0.59rem;
	}

	.step2 .steptab-line {
		margin-right: -0.59rem;
		background: #cccccc;
	}

	.step2 .steptab-num {
		border: 0.02rem solid #cccccc;
		color: #cccccc;
	}

	.step1 .steptab-name {
		color: #C25E4D;
	}

	.step2 .steptab-name {
		color: #cccccc;
	}

	.orderdetail {
		width: 100%;
		height: auto;
		max-width: 18.5rem;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		flex-wrap: wrap;
	}

	.orderdetail .ordertitle {
		width: 100%;
		height: 0.83rem;
		line-height: 0.83rem;
		text-align: left;
		font-size: 0.59rem;
		font-weight: bold;
		margin: 1.41rem 0 0.59rem 0;
	}

	.orderdetail .orderbox {
		width: 9rem;
		height: auto;
	}

	.addressbox {
		width: 100%;
		height: auto;
		display: flex;
		/* justify-content: space-between; */
	}

	.addressbox .defaultaddress {
		width: 6.59rem;
		height: 2.59rem;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.08);
		border-radius: 0.19rem;
		border: 0.04rem solid #C25E4D;
		margin-right: 0.59rem;
	}

	.address-btn {
		width: 100%;
		height: 0.71rem;
		line-height: 0.71rem;
		display: flex;
		justify-content: space-between;
		margin-top: -0.02rem;
		margin-left: -0.01rem;
	}

	.leftbtn,
	.rightbtn {
		width: 1.78rem;
		text-align: center;
		background: #C25E4D;
		color: #FFFFFF;
		font-size: 0.22rem;
		border-radius: 0.19rem 0 0.19rem 0;
	}

	.address-btn .rightbtn {
		color: #C25E4D;
		background: none;
	}

	.orderdetail .pricebox {
		width: 7.15rem;
		height: auto;
		margin-top: 1.33rem;
	}

	.newaddress {
		color: #1A1A1A;
		font-size: 0.37rem;
		display: flex;
		justify-content: center;

		width: 6.59rem;
		height: 2.59rem;
		line-height: 2.59rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		border: 0.02rem dashed #D5DDE6!important;
	}
	.newaddress:hover{
		color: #1A1A1A;
	}

	.defaultaddress img {
		width: 0.36rem;
		height: 0.36rem;
		margin: 0.08rem;
	}

	.defaultaddress .theaddress {
		width: calc(100% - 0.6rem);
		height: auto;
		margin: 0 auto;
		margin-top: 0.3rem;
	}

	.name-line {
		width: 100%;
		height: auto;
		display: flex;
	}

	.address-name {
		width: calc(100% - 0.5rem);
		height: 0.52rem;
		line-height: 0.52rem;
		color: #1A1A1A;
		display: flex;
		justify-content: space-between;
		font-size: 0.3rem;
	}

	.settlement .name-line .address-name {
		width: 100%;
	}

	.address-name .person-name {
		font-weight: bold;
		margin-left: 0.1rem;
	}

	.address-detail {
		margin-top: 0.15rem;
		width: calc(100% - 0.4rem);
		line-height: 0.37rem;
		max-height: 0.7rem;
		overflow: hidden;
		font-size: 0.26rem;
		color: #1A1A1A;
		text-align: left;
		margin-left: 0.6rem;
	}

	.newaddress img {
		height: 0.59rem;
		width: 0.59rem;
		margin: 1rem 0;
		margin-right: 0.15rem;
	}

	.goodslist {
		width: 100%;
		height: auto;
		/* margin-top: 1.33rem; */
	}
	.margintop{
		margin-top: 1.33rem;
	}
	.marginbottom{
		margin-bottom: 0.59rem;
	}

	.goodslist .goodlist-title {
		width: 100%;
		height: 0.74rem;
		font-size: 0.52rem;
		line-height: 0.74rem;
		color: #1a1a1a;
		text-align: left;
		margin-bottom: 0.59rem;
	}

	.goodslist-box {
		width: 100%;
		height: auto;
		margin-bottom: 0.69rem;
	}

	.goodslist-box .goods-info {
		width: 100%;
		height: 2.78rem;
		display: flex;
		margin-bottom: 0.49rem;
	}

	.goods-info img {
		width: 2.78rem;
		height: 2.78rem;
		border-radius: 0.19rem;
	}

	.goods-info .goods-infobox {
		width: calc(100% - 3.1rem);
		height: auto;
		margin-left: 0.32rem;
		text-align: left;
	}

	.goods-infobox .goods-name {
		line-height: 0.61rem;
		/* font-size: 0.44rem; */
		font-size: 20px;
		font-weight: bold;
		color: #1a1a1a;
		display: flex;
		justify-content: space-between;
	}

	.goods-infobox .goods-num {
		margin-top: 0.2rem;
		/* margin-bottom: 0.47rem; */
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #666666;
	}

	.good-box {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
	}

	.good-box img {
		width: 1.2rem;
		height: 1.2rem;
		margin-left: 0.79rem;
	}

	.good-box .good-infobox {
		width: calc(100% - 2.2rem);
		height: auto;
		display: flex;
		justify-content: space-between;
	}

	.good-infobox .good_num {
		width: auto;
		height: 1.85rem;
		color: #1A1A1A;
	}

	.good-name {
		line-height: 0.52rem;
		/* font-size: 0.37rem; */
		font-size: 14px;
		text-align: left;
	}
	.good-num{
		padding: 0.1rem 0;
	}
	.good-daynum {
		line-height: 0.41rem;
		color: #666666;
		margin-top: 0.12rem;
		/* font-size: 0.3rem; */
		font-size: 12px;
		text-align: left;
	}

	.good-price {
		width: auto;
		height: 0.52rem;
		line-height: 0.52rem;
		color: #1A1A1A;
		/* font-size: 0.37rem; */
		font-size: 14px;
		margin-top: 0.1rem;
	}

	.good-line {
		width: calc(100% - 2.2rem);
		height: auto;
		border-bottom: 0.02rem dashed #e6e6e6;
		margin: 0.2rem 0;
		margin-left: 2.2rem;
	}

	.service-box {
		width: 100%;
		height: auto;
		margin-top: 0.59rem;
		padding: 0.59rem 0;
		border-top: 0.04rem solid #e6e6e6;
		display: flex;
		justify-content: space-between;
	}

	.service-box img {
		width: 2.78rem;
		height: 2.78rem;
		border-radius: 0.19rem;
	}

	.service-box .service-info {
		width: calc(100% - 3.1rem);
		height: auto;
	}

	.service-info .service-title {
		width: 100%;
		height: 0.61rem;
		display: flex;
		justify-content: space-between;
	}

	.service-title span {
		width: auto;
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.37rem;
		color: #1a1a1a;
		font-weight: bold;
	}

	.service-title .free {
		color: #C25E4D;
		font-weight: 500;
	}

	.service-info .service-txt {
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #666666;
		text-align: left;
		margin-top: 0.11rem;
	}

	.price-box {
		width: 100%;
		height: auto;
		background: #F5F7F9;
		border-radius: 0.19rem;
		padding: 0.59rem;
	}

	.leave-message {
		width: 100%;
		height: auto;
		line-height: 0.61rem;
		margin-bottom: 1.19rem;
	}

	.leave-message .leave-title {
		width: 100%;
		text-align: left;
		color: #1A1A1A;
		font-size: 0.37rem;
		font-weight: bold;
	}

	.leave-message input {
		width: 100%;
		height: 0.67rem;
		line-height: 0.67rem;
		border: none;
		font-size: 0.26rem;
		margin-top: 0.3rem;
		background: #FFFFFF;
		padding:0 0.3rem;
		border-radius: 0.07rem;
		font-weight: 500;
	}

	.leave-message .leave-txt {
		color: #1A1A1A;
		font-size: 0.37rem;
		font-weight: bold;
	}

	.leave-line {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.59rem;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #666666;
		position: relative;
	}

	.leave-line .line-left {
		width: 2rem;
		text-align: left;
	}

	.leave-line .line-right {
		width: 2rem;
		text-align: right;
	}

	.allprice {
		width: 100%;
		height: auto;
		line-height: 0.83rem;
		font-size: 0.26rem;
		text-align: right;
		padding-top: 0.6rem;
		color: #1A1A1A;
	}

	.allprice span {
		font-size: 0.37rem;
	}

	.pay-line {
		width: 100%;
		height: 1.26rem;
		display: flex;
		justify-content: space-between;
		margin-top: 0.65rem;
	}

	.pay-line .pay-left {
		width: auto;
		height: auto;
		padding: 0.2rem;
	}

	.pay-left .pricenum {
		height: 0.61rem;
	}

	.pay-left .pricenum .txt {
		height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		margin-top: 0.09rem;
	}

	.pay-left .pricenum span {
		line-height: 0.61rem;
		font-size: 0.44rem;
		color: #C25E4D;
	}

	.pay-left .pricenum .span {
		line-height: 0.61rem;
		font-size: 0.65rem;
		color: #C25E4D;
	}

	.price-msg {
		width: auto;
		height: 0.41rem;
		line-height: 0.41rem;
		font-size: 0.3rem;
		color: #999999;
		text-align: left;
	}

	.pay-line .pay-btn {
		width: 4.44rem;
		height: 1.26rem;
		background: #C25E4D;
		border-radius: 1.26rem;
		line-height: 1.26rem;
		text-align: center;
		font-size: 0.44rem;
		color: #ffffff;
		cursor: pointer;
	}

	.theprice {
		width: 100%;
		height: auto;
		line-height: 0.61rem;
		font-size: 0.44rem;
		color: #C25E4D;
		text-align: right;
		margin-top: 0.3rem;
	}

	.notice {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 8;
	}

	.notice-box {
		width: 82%;
		max-width: 10.78rem;
		height: 11rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		padding: 0.59rem 0.67rem;
		color: #1A1A1A;
		margin-top: 1rem;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translateX(-50%);
		margin-top: -5.5rem;
		z-index: 8;
	}

	.settlement .notice {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 12;
	}
	.settlement .notices{
		background: rgba(0, 0, 0, 0);
	}

	.settlement .notice-box {
		width: 82%;
		max-width: 10.78rem;
		height: 11rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		padding: 0.59rem 0.67rem;
		color: #1A1A1A;
		margin-top: 1rem;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translateX(-50%);
		margin-top: -5.5rem;
		z-index: 12;
	}

	.notice-box .notice-title {
		width: 100%;
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		text-align: center;
		font-weight: bold;
		position: relative;
	}

	.notice-box .notice-btn {
		width: 4.44rem;
		height: 1.26rem;
		background: #C25E4D;
		border-radius: 1.26rem;
		font-size: 0.44rem;
		color: #ffffff;
		line-height: 1.26rem;
		text-align: center;
		margin: 0 auto;
		cursor: pointer;
	}

	.notice-box .notice-msg {
		width: 100%;
		height: 7.28rem;
		margin: 0.38rem 0;
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		text-align: left;
		overflow-y: scroll;
	}

	.msg-line {
		min-height: 0.52rem;
		line-height: 0.52rem;
		font-size: 0.37rem;
		color: #1A1A1A;
		text-align: left;
	}

	.code_url {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: 9;
	}

	.code_url .qr-box {
		width: 6.6rem;
		height: 7.3rem;
		margin: 0 auto;
		margin-top: calc(50vh - 3.25rem);
		background: #FFFFFF;
		border-radius: 0.19rem;
	}

	.wxtxt {
		line-height: 0.6rem;
		font-size: 0.3rem;
		/* margin-top: 0.1rem; */
		font-weight: bold;
		margin: 0.3rem 0;
		/* margin-left: 0.5rem; */
	}
	.wxtxt .wicon{
		width: 0.5rem;
		height: 0.5rem;
		margin: 0.05rem 0.1rem;
	}
	.qrtitle{
		width: 4rem;
		height: 1rem;
		line-height: 1rem;
		margin-left: calc( 50% - 2rem );
		font-size: 0.37rem;
		font-weight: bold;
	}

	.qr-box .close-box {
		width: 100%;
		height: 0.6rem;
		display: flex;
		justify-content: space-between;
		/* margin-bottom: 0.3rem; */
	}

	.close-box img {
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.35rem 0.5rem;
	}

	.code_url .qrimg {
		width: 5rem;
		height: 5rem;
		margin: 0 auto;
	}

	.settlement .settlement-title {
		width: 100%;
		height: 0.81rem;
		border-left: 0.15rem solid #C25E4D;
		text-indent: 0.3rem;
		line-height: 0.81rem;
		font-size: 0.44rem;
		font-weight: bold;
		text-align: left;
	}

	.closeit {
		width: 0.3rem;
    height: 0.3rem;
    position: absolute;
    right: -0.3rem;
    top: -0.3rem;
		cursor: pointer;
	}
	.modalfooter{
		display: flex;
		justify-content: center;
	}
	.weixinnopay{
		width: 100%;
		height: 0.61rem;
		line-height: 0.61rem;
		text-align: center;
		font-size: 0.37rem;
	}
	.goods-name div{
		font-size: 0.3rem;
	}
	.goods-infobox-bottom s{
		font-size: 0.26rem;
		color: #999999;
	}
	.leave-txt textarea{
		border: none;
		height: 0.67rem;
		resize:none;
		text-align: left;
	}
	.goods-infobox-bottom{
		width: 100%;
		line-height: 0.41rem;
		height: 0.41rem;
		/* font-size: 0.3rem; */
		font-size: 20px;
		display: flex;
		justify-content: space-between;
		margin-top: 1.1rem;
	}
	.order-goods-detail{
		width: auto;
		display: flex;
		line-height: 0.41rem;
		font-size: 0.3rem;
	}
	.order-goods-detail img{
		height: 0.41rem;
		width: 0.41rem;
	}
	.gsbg{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		background: rgba(0,0,0,0.7);
	}
	.guanshui{
		height: auto;
		width: 9rem;
		background: #FFFFFF;
		border-radius: 0.19rem;
		position: fixed;
		top: 12vh;
		left: calc( 50% - 4.5rem );
		margin:0 auto;
		z-index: 11;
		padding: 0.59rem 0.44rem;
	}
	.gstitle{
		width: 100%;
		height: 0.51rem;
		line-height: 0.51rem;
		display: flex;
		justify-content: space-between;
		font-size: 0.37rem;
		color: #1A1A1A;
	}
	.gstitle img{
		width: 0.31rem;
		height: 0.31rem;
		margin: 0.1rem;
	}
	.gstxt{
		width: 100%;
		height: auto;
		margin-top: 0.6rem;
		text-align: left;
		line-height: 0.51rem;
		font-size: 0.3rem;
		color: #1A1A1A;
	}
	.leave-line .line-left img{
		width: 0.41rem;
		height: 0.41rem;
	}

	@media screen and (max-width:1000px) {
		.orderdetail {
			max-width: 11.11rem;
		}
		.orderdetail .goodslist{
			box-shadow: 0rem 0.09rem 0.18rem 0rem rgba(0, 0, 0, 0.1);
			border-radius:0.19rem;
			padding: 0.37rem;
		}

		.orderdetail .orderbox,
		.orderdetail .pricebox {
			margin: 0 auto;
		}

		.orderdetail .pricebox {
			width: 11.11rem;
			margin-bottom: 1.26rem;
			margin-top: 0.59rem;
		}

		.pricebox .price-box {
			width: 100%;
			padding: 0;
			background: #FFFFFF;
		}
		.leave-message .leave-txt{
			width: calc( 80% - 0.6rem );
			height: 1.21rem;
		}
		.leave-message textarea{
			width: 100%;
			height: 1.21rem;
			line-height: 0.61rem;
			padding: 0.3rem 0;
			font-size: 0.37rem;
			border: none;
			/* border-radius: 1.21rem; */
			/* text-indent: 0.65rem; */
			margin-top: 0;
			text-align: right;
			resize:none;
		}

		.leave-message .showinput{
			height: auto;
		}
		.leave-message .showinput textarea{
			height: auto;
			width: 100%;
			line-height: 0.61rem;
			font-size: 0.37rem;
			border: none;
			border-radius: 0.19rem;
			/* text-indent: 0.65rem; */
			margin-top: 0;
			text-align: right;
			max-height: 1.63rem;
			padding: 0 0.3rem;
		}
		.notice .notice-box {
			max-width: 11.11rem;
		}
		.leave-message .showinputimg{
			transform:rotate(90deg);
			-ms-transform:rotate(90deg); 	/* IE 9 */
			-moz-transform:rotate(90deg); 	/* Firefox */
			-webkit-transform:rotate(90deg); /* Safari 和 Chrome */
			-o-transform:rotate(90deg);
		}

		.settlement .defaultaddress .theaddress {
			width: 98%;
			margin: 0.3rem auto;
		}

		.settlement .actions-btns {
			height: 1.11rem;
			margin: 0.48rem 0;
		}

		.settlement .day-price {
			margin-top: 0.45rem;
		}

		.settlement .main-weight {
			padding: 0 0.45rem;
			z-index: 5;
		}
		.settlement .step-box .steptab{
			width: 35%;
		}
		.orderdetail .ordertitle{
			margin: 0.59rem 0;
		}
		.goods-name div{
			font-size: 0.37rem;
		}
		.addressbox{
			display: block;
		}
		.settlement .addressbox .defaultaddress{
			width: 100%;
			background: #FFFFFF;
			border: none;
			box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(102, 102, 102, 0.1);
			border-radius: 0.19rem;
			padding: 0.37rem 0.5rem 0.37rem 0.37rem;
			position: relative;
		}
		.settlement .name-line .address-name{
			width: calc( 100% - 1.1rem);
			line-height: 0.72rem;
			font-size: 0.41rem;
			color: #1A1A1A;
		}
		.settlement .address-detail{
			width: calc(100% - 0.5rem);
			max-height: none;
			line-height: 0.51rem;
			font-size: 0.41rem;
		}
		.settlement .addressbox .defaultaddress{
			height: auto;
		}
		.newaddress{
			width: 100%;
		}
		.defaultaddress img{
			width: 0.56rem;
			height: 0.56rem;
		}
		.defaultaddress .address-more{
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -0.28rem;
		}
		.leave-line{
			width: 60%;
			margin-left: 40%;
			margin-bottom: 0.4rem;
			height: 0.51rem;
			line-height: 0.51rem;
			font-size: 0.37rem;
		}
		.line-center{
			width: 100%;
			z-index: 0;
			position: absolute;
			height: 0.01rem;
			border-top: 0.02rem dashed #666666;
			margin-top: 0.25rem;
		}
		.leave-line .line-left,.leave-line .line-right{
			width: auto;
			background: #FFFFFF;
			z-index: 1;
		}
		.leave-line .line-left{
			padding-right: 0.25rem;
		}
		.leave-line .line-right{
			padding-left: 0.25rem;
		}
		.leave-message{
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-shadow: 0rem 0.19rem 0.37rem 0rem rgba(102, 102, 102, 0.1);
			border-radius: 0.19rem;
		}
		.leave-message .leave-title{
			width: 20%;
			height: 1.21rem;
			line-height: 1.21rem;
		}
		.message-img{
			width: 0.36rem;
			height: 0.36rem;
			margin: 0.425rem 0;
		}
		.allprice{
			padding-top: 0;
			font-size: 0.37rem;
		}
		.goods-infobox-bottom{
			margin-top: 0.75rem;
			font-size: 16px;
		}

		.goods-infobox .goods-name{
			font-size: 16px;
		}
		.goods-info img{
			width: 2.5rem;
			height: 2.5rem;
		}
		.good-box img{
			margin-left: 0.39rem;
			width: 1.4rem;
			height: 1.4rem;
		}
		.order-goods-detail img{
			width: 0.41rem;
			height: 0.41rem;
		}
		.good-box .good-infobox{
			width: calc(100% - 2rem);
		}
		.service-box img {
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 0.19rem;
		}

		.service-box .service-info {
			width: calc(100% - 2.82rem);
			height: auto;
		}
	}

	@media screen and (max-width:650px) {

		.notice .notice-box {
			max-width: 9.11rem;
			padding: 1.19rem 0.67rem;
		}

		.orderdetail {
			max-width: 9rem;
		}

		.orderdetail .pricebox {
			width: 9rem;
		}

		.orderdetail .orderbox {
			width: 9rem;
		}

		.price-box {
			width: 9rem;
		}

		.addressbox .defaultaddress {
			width: 6.59rem;
			height: 2.59rem;
			background: #FFFFFF;
			box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.08);
			border-radius: 0.19rem;
			border: 0.04rem solid #C25E4D;
		}

		.step-box .steptab {
			width: 40%;
		}

		.service-info .service-txt {
			margin-top: 0.11rem;
		}

		.popup-box .popup-title {
			padding: 0.59rem 0.9rem;
		}
	}
</style>
