<template>
 <div class="title"> {{msg}} </div>
</template>

<script>
export default {
 data(){
 return {
 msg: '暂无打卡权限'
 }

 },
 created(){
    
 }

}

</script>

<style scoped>
    .title{
        padding-top:2rem;
       color:#cccccc;
       font-size:1rem;
       text-align:center;
       font-weight:bold 
    }
</style>
