<template>
	<div class="index main-page">
		<div class="page-bg"></div>
		<div class="show_mb">
			<!-- <div class="fixed-activity-icon" @click="golist1(7)">
				<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/activity/coupon-discount.gif" alt="拼团活动悬浮 icon">
			</div> -->
			<div class="top-swiper">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(item,index) in banner" :key="index">
							<div class="banner">
								<img :src="item.imgUrl" alt="" :style="shanghaishow ? 'marginTop:-90px' : ''">
							</div>
						</div>
					</div>
				</div>

				<div class="noshowbtn nopadd" :class="hasbuyplan?'':'nopadd'">
					<button class="sdzbtn" @click="golist1(7)" v-if="!shanghaishow">
						<div>开始 AI 定制</div>
						<img src="../../public/imgs/pro/more.png" alt="">
					</button>
					<div class="sevenday" v-if="hasbuyplan">
						<div class="tips tips1">
							<div class="tips-msg">买7送7 限享1次</div>
							<div class="tipbottom"></div>
						</div>
					</div>
					<div class="xiangxia" v-if="!shanghaishow">
						<img src="../../public/imgs/indexdown.png" alt="">
					</div>
				</div>
				<div class="sdzbtnbox">
					<button class="sdzbtn sdzbtn2" @click="golist(7)" v-if="shanghaishow">
						<div>开始 AI 定制</div>
						<img src="../../public/imgs/pro/more.png" alt="">
					</button>
				</div>
			</div>
			<div v-if="!shanghaishow">
				<!-- <div class="np">
					<img :src="npurl" alt="">
				</div> -->
				<!-- <div class="volleyball-title-warp" @click="golist1(1)" >
					<div class="volleyball-title">
						<div class="index-title">SICHUAN MEN'S</div>
						<div class="index-title">VOLLEYBALL TEAM</div>
						<div class="index-ch">四川男排冠名合作营养品牌</div>
					</div>
					<div class="volleyball-title-icon" v-if="!isAlipay">
						<img src="../../public/imgs/pro/more.png" alt="">
					</div>
				</div> -->
				<!-- <div class="black-line"></div> -->
				<div class="volleyball-title-warp2">
					<div class="volleyball-title">
						<div class="index-title">WELLNESS JOURNEY</div>
						<div class="index-title">STARTS SIMPLE</div>
						<div class="index-ch">4步定制 不止营养补剂</div>
					</div>
				</div>
				<div class="index-step">
					<div class="index-step-box">
						<div class="index-step1">
							<div class="index-step-title">STEP 1</div>
							<div class="index-step-img">
								<img :src="step.i1" alt="">
							</div>
							<div class="index-step-ch">3分钟在线AI营养评估</div>
							<div class="index-step-msg">生成专属营养健康报告</div>
						</div>
						<div class="step-line1">
							<img :src="step.x1" alt="">
						</div>
					</div>
					<div class="index-step-box">
						<div class="index-step2">
							<div class="index-step-title">STEP 2</div>
							<div class="index-step-img">
								<img :src="step.i2" alt="">
							</div>
							<div class="index-step-ch">精准方案直邮到家</div>
							<div class="index-step-msg">获得你的专属营养补剂包</div>
						</div>
					</div>
					<div class="index-step-box">
						<div class="index-step3">
							<div class="index-step-title">STEP 3</div>
							<div class="index-step-img">
								<img :src="step.i3" alt="">
							</div>
							<div class="index-step-ch">免费匹配专属健康顾问</div>
							<div class="index-step-msg">预约1V1深度健康咨询，建立私人档案</div>
						</div>
						<div class="step-line2">
							<img :src="step.x1" alt="">
						</div>
					</div>
					<div class="index-step-box">
						<div class="index-step4">
							<div class="index-step-title">STEP 4</div>
							<div class="index-step-img">
								<img :src="step.i4" alt="">
							</div>
							<div class="index-step-ch">好营养，更有好服务</div>
							<div class="index-step-msg">每日一袋营养+口袋式顾问服务，用心陪伴</div>
						</div>
						<div class="step-line3">
							<img :src="step.x1" alt="">
						</div>
					</div>
				</div>
				<div class="huibg">
					<div class="volleyball-title-warp3">
						<div class="volleyball-title">
							<div class="index-title">QUALITY IS IMPORTANT</div>
							<div class="index-ch">除了 AI 定制 你更应关注补剂本身</div>
						</div>
					</div>
					<div class="center-list">
						<div class="index-center" style="margin-bottom: 1.17rem;">
							<div class="center-top">
								<img :src="center.c1" alt="">
							</div>
							<div class="center-main">
								<div class="center-img-box1" style="font-size: 15px;">
									<img :src="center.d1" alt="">
									<div class="ct1">成分透明</div>
									<div class="ct2">形态透明</div>
									<div class="ct3">剂量透明</div>
									<div class="ct4">辅料透明</div>
								</div>
								<div class="center-main-msg">
									<div class="center-msg-ch">你所看见的就是你所服用的</div>
									<button class="chengnuo" @click="golist1(2)" v-if="!isAlipay">
										查看 100% 透明承诺
										<img src="../../public/imgs/pro/more.png" alt="">
									</button>
									<div style="height:0.85rem;width:1rem" v-if="isAlipay"></div>
								</div>
							</div>
						</div>
						<div class="index-center" style="margin-bottom: 1.17rem;">
							<div class="center-top">
								<img :src="center.c2" alt="">
							</div>
							<div class="center-main">
								<div class="center-img-box2" style="overflow: hidden;">
									<div class="swiper-container">
										<div class="swiper-wrapper">
											<div class="swiper-slide" v-for="(item,index) in duibi" :key="index">
												<div class="anlis">
													<img :src="item.imgUrl" alt="">
												</div>
											</div>
										</div>
										<div class="swiper-pagination"></div>
									</div>
								</div>
								<div class="center-main-msg">
									<div class="center-msg-ch1">每款补剂配方均由纽约专家组 <p>科学研发，并公开相关研究文献</p>
									</div>
								</div>
								<button class="chengnuo" @click="golist1(3)" v-if="!isAlipay">
									查看效力保障
									<img src="../../public/imgs/pro/more.png" alt="">
								</button>
								<div style="height:0.85rem;width:1rem" v-if="isAlipay"></div>
							</div>
						</div>
						<div class="index-center">
							<div class="center-top">
								<img :src="center.c3" alt="">
							</div>
							<div class="center-main">
								<div class="cm-box" style="margin-top: 0.85rem;">
									<img class="cmi1" :src="center.e1" alt="">
									<div class="cm-txt">超 25 处专利和工艺应用</div>
									<div class="cm-detail" @click="golist1(4)" v-if="!isAlipay">
										<div>查看</div>
										<img src="../../public/imgs/pro/more.png" alt="">
									</div>
								</div>
								<div class="cm-box-line"></div>
								<div class="cm-box">
									<img class="cmi2" :src="center.e2" alt="">
									<div class="cm-txt">中美 4 重安全检测 全链权威认证</div>
									<div class="cm-detail" @click="golist1(5)" v-if="!isAlipay">
										<div>查看</div>
										<img src="../../public/imgs/pro/more.png" alt="">
									</div>
								</div>
								<div class="cm-box-line"></div>
								<div class="cm-box" style="margin-bottom: 0.85rem;">
									<img class="cmi3" :src="center.e3" alt="">
									<div class="cm-txt">ISO 双认证自动化 洁净分装</div>
									<div class="cm-detail" @click="golist1(6)" v-if="!isAlipay">
										<div>查看</div>
										<img src="../../public/imgs/pro/more.png" alt="">
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div v-if="!isAlipay">
					<div class="volleyball-title-warp4" style="margin: 2.19rem 0 0 0;">
						<div class="volleyball-title">
							<div class="index-title">CUSTOMER SNAPSHOTS</div>
							<div class="index-ch">他们正在使用</div>
						</div>
					</div>
					<div class="index-anlis" style="overflow: hidden;">
						<div class="swiper-container">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="(item,index) in anli" :key="index">
									<div class="indexanlis">
										<img :src="item.imgUrl" alt="">
									</div>
								</div>
							</div>
							<div class="swiper-pagination"></div>
						</div>
					</div>

				<div class="anlis-txt">
					<div class="shareanli" @click="golist1(8)" v-if="!isAlipay">分享你的产品体验</div>
					<div class="anlis-ch" v-if="!isAlipay">精选内容可获 30 天定制营养奖励</div>
				</div>
				</div>
				<div style="height:60px" v-if="isAlipay"></div>
			<div class="volleyball-title-warp5">
				<div class="volleyball-title">
					<div class="index-title">NUTRIPACKS BLOG</div>
					<div class="index-ch">橘袋动态</div>
				</div>

				</div>
				<div class="noticelists">
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="(item,index) in noticelist" :key="index">
								<div class="noticelist" @click="gothere(item.links)">
									<img :src="item.img_small" alt="">
								</div>
							</div>
						</div>
						<div class="swiper-pagination"></div>
					</div>
				</div>
				<div class="volleyball-title-warp6">
					<div class="volleyball-title">
						<div class="index-title">BE THERE</div>
						<div class="index-border"></div>
					</div>
				</div>
				<div class="bethere">
					<div class="betheretxt">NUTRIPACKS 橘袋愿与自然、健康、活力一道陪伴在你身边，以全方位的定制服务支持更好的健康未来。</div>
				</div>
				<button class="dzbtn" @click="golist1(7)">
					<div>开始 AI 定制</div>
					<img src="../../public/imgs/pro/more.png" alt="">
				</button>
				<div class="index-bg">
					<img :src="indexbg" alt="">
				</div>
			</div>
		</div>
		<div class="show_pc">
			<div class="ind-banner">
				<Carousel autoplay arrow="always" loop :autoplay-speed="5000" dots="none">
					<CarouselItem v-if="false">
						<div class="mainbanner" style="background: #FFDE97;">
							<div class="index-banner center-div">
								<div class="index-banner-ngg" style="background: #FFDE97;"></div>
								<div class="index-banner-ngg1" style="background: #FFDE97;"></div>
								<div class="banner-title">
									<h1 style="line-height: normal;">618</h1>
									<h1 style="line-height: normal;">组「橘」狂欢</h1>
								</div>
								<div class="banner-msg banner-msg1">6.1-6.20 限量1000份 即日开抢</div>
								<div class="index-banner-qr">
									<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/kefuwx.png" alt="">
									<div>扫码进入小程序体验</div>
								</div>
								<div class="banner-bg" style="top: 28px;">
									<img style="width: 100%;margin-top: 0;margin-left: 0;" src="../../public/imgs/index_banner.png" alt="拼团">
								</div>
							</div>
						</div>
					</CarouselItem>

					<CarouselItem>
						<div class="mainbanner">
							<div class="index-banner center-div">
								<div class="index-banner-ngg"></div>
								<div class="index-banner-ngg1"></div>
								<div class="banner-title">
									<h1>Personalized</h1>
									<h1>Dietary Supplements</h1>
									<!-- <p>Personalized</p>
									<p>Dietary Supplements</p> -->
								</div>
								<div class="banner-msg">个性匹配 因人而异的专属营养</div>
								<button class="banner-btn" @click="golist(7)">
									<p>开始 AI 定制</p>
									<img src="../../public/imgs/index/more.png" alt="">
								</button>
								<div class="banner-bg">
									<img style="width: 100%;margin-top: -20px;margin-left: 0;"
										:src="publicwww+'static/common/images/official-website/storeimgs/bgshou.png'"
										alt="">
										<div style="width: 100%;height: 100%;position: relative;">
											<div class="banner-bg-msg">
												<p>美国制造</p>
												<p>保税区直邮</p>
												<p>30 袋/盒</p>
											</div>
										</div>
								</div>
							</div>
						</div>
					</CarouselItem>
					<CarouselItem>
						<div class="mainbanner">
							<div class="index-banner center-div">
								<div class="index-banner-ngg"></div>
								<div class="index-banner-ngg1"></div>
								<div class="banner-title">
									<h1>Sichuan Men's</h1>
									<h1>Volleyball Team</h1>
									<!-- <p>Sichuan Men's </p>
									<p>Volleyball Team</p> -->
								</div>
								<!-- <div class="banner-msg">四川男排冠名合作营养品牌</div> -->
								<button class="banner-btn" @click="golist(7)">
									<p>开始 AI 定制</p>
									<img src="../../public/imgs/index/more.png" alt="">
								</button>
								<div class="banner-bg" style="padding-top: 5vw;width: 45vw;">
									<img style="width: 100%;margin-left: 0;max-width: 850px;margin-top: 80px;"
										:src="publicwww+'static/common/images/official-website/storeimgs/b22.png'"
										alt="">
								</div>
								<!-- <div class="banner-bg" style="top: 0;padding-top: 5vw;width: 45vw;right:0!important;max-width: 800px;">
									<img style="width: 45vw;margin-left: 0;max-width: 800px;"
										:src="publicwww+'static/common/images/official-website/storeimgs/b22.png'"
										alt="">
								</div> -->
							</div>
						</div>
					</CarouselItem>
					<CarouselItem v-if="!shanghaishow">
						<div class="mainbanner">
							<div class="mainbannerbg" style="background: rgb(230,233,236);display: flex;">
								<img style="width: 100%;height: 42vw;"
									:src="publicwww+'static/common/images/official-website/storeimgs/banner3.png'"
									alt="">
							</div>
							<div class="index-banner center-div">
								<div class="banner-title" style="">
									<h1>5-Day</h1>
									<h1>Care Packs</h1>
									<!-- <p>5-Day </p>
									<p>Care Packs</p> -->
								</div>
								<div class="banner-msg" style="text-align: left;">
									<p>应急营养包系列</p>
									<p>各种临时场景化需求的便捷营养方案</p>
								</div>
								<button class="banner-btn" @click="golist(10)">
									<p>即刻前往购买</p>
									<img src="../../public/imgs/index/more.png" alt="">
								</button>
							</div>
						</div>
					</CarouselItem>
				</Carousel>
			</div>
			<div class="banner-bottom-bg"></div>
			<div class="index-pc-step-box" v-if="!shanghaishow">
				<div class="index-step-ngg"></div>
				<div class="index-step-ngg1"></div>
				<div class="index-pc-title-box">
					<!-- <div class="redline"></div> -->
					<div class="index-pc-title-txt">
						<h3>Wellness Journey</h3>
						<h3>Starts Simple</h3>
						<!-- <p>Wellness Journey</p>
						<p>Starts Simple</p> -->
						<div class="index-pc-title-msg" style="font-weight: normal;font-family: Arial, Helvetica, sans-serif;">4步定制 不止营养补剂</div>
					</div>
				</div>
				<div class="index-pc-step">
					<div class="pc-step-box">
						<div class="pc-step pc-step1" style="margin-left:117px;">
							<div class="pc-step-name">STEP 1</div>
							<div class="pc-step-img">
								<img src="../../public/imgs/index/step1.png" alt="">
							</div>
							<div class="pc-step-txt">3分钟在线 AI 营养评估</div>
							<div class="pc-step-msg">生成专属营养健康报告</div>
						</div>
						<div class="pc-step">
							<div class="pc-step-name">STEP 2</div>
							<div class="pc-step-img">
								<img src="../../public/imgs/index/step2.png" alt="">
							</div>
							<div class="pc-step-txt">精准方案直邮到家</div>
							<div class="pc-step-msg">获得你的专属营养补剂包</div>
						</div>
						<div class="pc-step pc-step3">
							<div class="pc-step-name">STEP 3</div>
							<div class="pc-step-img">
								<img src="../../public/imgs/index/step3.png" alt="">
							</div>
							<div class="pc-step-txt">免费匹配专属健康顾问</div>
							<div class="pc-step-msg"><span>预约 1V1 深度健康咨询</span>
								<p>建立私人档案</p>
							</div>
						</div>
						<div class="pc-step" style="width: 256px;">
							<div class="pc-step-name">STEP 4</div>
							<div class="pc-step-img">
								<img src="../../public/imgs/index/step4.png" alt="">
							</div>
							<div class="pc-step-txt">好营养，更有好服务</div>
							<div class="pc-step-msg"><span>每日一袋营养 + 口袋式顾问服务</span>
								<p>用心陪伴</p>
							</div>
						</div>
					</div>
					<img class="img-yd" :src="publicurl+'indexyd.png'" alt="">
				</div>
			</div>
			<div class="pc-center-list-box" v-if="!shanghaishow">
				<div class="index-center-ngg"></div>
				<div class="index-pc-title-box1">
					<img class="indexye" :src="publicurl+'indexye.png'" alt="">
					<img class="indexyou" src="../../public/imgs/index/Group_128@2x.png" alt="">
					<!-- <div class="redline"></div> -->
					<div class="index-pc-title-txt juzhong">
						<div class="meal-pc-main-title-line"></div>
						<!-- <p>Quality is Important</p> -->
						<h1>Quality is Important</h1>
					</div>
					<div class="index-pc-title-msg"><span>除了 AI 定制 你更应关注补剂本身</span>
						<p>全线补剂配方由NHRC纽约营养科学团队根据国人需求进行研发</p>
					</div>
				</div>
				<div class="pc-center-list">
					<div class="pc-chengfen">
						<div class="pc-bf-box">
							<div class="index-bf-name">
								<!-- <p>营养成分</p>
								<p>100%透明承诺</p> -->
								<h5>营养成分</h5>
								<h5 style="margin-top: 8px;">100%透明承诺</h5>
							</div>
							<div class="index-bf-msg">我们将每一款补剂配方向你如实公开，承诺100%透明展示，你所需要了解的配方信息都在这一张营养成分表上了，你所看见的就是你服用的。
							</div>
							<div class="index-bf-btn" @click="golist(2)">
								<p>查看100%透明承诺</p>
								<img src="../../public/imgs/pro/more.png" alt="">
							</div>
						</div>
						<img class="indexjian" :src="publicurl+'indexjian.png'" alt="">
						<div class="index-jianlist">
							<div class="jianlist-line">
								<div class="index-dx">
									<div class="index-d"></div>
									<div class="index-x"></div>
								</div>
								<div class="index-jian-txt">成分透明</div>
							</div>
							<div class="jianlist-line" style="margin-left: 3.45vw;">
								<div class="index-dx">
									<div class="index-d"></div>
									<div class="index-x"></div>
								</div>
								<div class="index-jian-txt">形态透明</div>
							</div>
							<div class="jianlist-line" style="margin-left: 3.45vw;">
								<div class="index-dx">
									<div class="index-d"></div>
									<div class="index-x"></div>
								</div>
								<div class="index-jian-txt">辅料透明</div>
							</div>
							<div class="jianlist-line">
								<div class="index-dx">
									<div class="index-d"></div>
									<div class="index-x"></div>
								</div>
								<div class="index-jian-txt">剂量透明</div>
							</div>
						</div>
					</div>
					<div class="index-pc-pz">
						<div class="index-pz-swiper">
							<Carousel autoplay loop :autoplay-speed="5000" :radius-dot="true" dots="none">
								<CarouselItem v-for="(item,index) in duibi" :key="index" style="">
									<div class="pz-anlis">
										<img :src="item.imgUrl" alt="">
									</div>
								</CarouselItem>
							</Carousel>
						</div>
						<div class="index-pz-box">
							<div class="pc-pz-box">
								<div class="index-pz-name">
									<!-- <p>告别弱品质重营销</p>
									<p>我们追求效力</p> -->
									<h5>告别弱品质重营销</h5>
									<h5 style="margin-top: 8px;">我们追求效力</h5>
								</div>
								<div class="index-pz-msg">告别弱品质重营销的行业规则，我们追求效力！每一款补剂配方均由纽约专家组科学研发，并公开相关研究文献。</div>
								<div class="index-pz-btn" @click="golist(3)">
									<p>查看效力保障</p>
									<img src="../../public/imgs/pro/more.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="index-pc-title-box index-pc-title-box2">
					<!-- <div class="redline"></div> -->
					<div class="index-pc-title-txt">
						<!-- <p>更高标准 层层把关</p>
						<p>放心安全的品质保障</p> -->
						<h5>更高标准 层层把关</h5>
						<h5 class="" style="margin-top: 8px;">放心安全的品质保障</h5>
					</div>
					<img class="indexhand" :src="publicwww+'static/common/images/official-website/pximgs/yaodai.png'"
						alt="">
				</div>
				<div class="pc-center-main">
					<div class="pc-center-btns" style="margin-left: 0" @click="golist(4)">
						<img src="../../public/imgs/index/b1.png" alt="listbg" class="list-bg">
						<img class="index-cimg" :src="center.e1" alt="">
						<div class="pc-btns-name">超过 25 项专利和工艺应用</div>
						<div class="pc-btns-msg">从原料到辅料，从成分到工艺，我们打造长期服用安全性，更打造更高效的生物吸收利用率。</div>
						<img class="index-more" src="../../public/imgs/pro/more.png" alt="">
					</div>
					<div class="pc-center-btns" @click="golist(5)">
						<img src="../../public/imgs/index/b2.png" alt="listbg" class="list-bg">
						<img class="index-cimg" :src="center.e2" alt="">
						<div class="pc-btns-name">中美 4 重安全检测 全链权威认证</div>
						<div class="pc-btns-msg">严格符合NSF GMP美国膳食营养补充剂最高认证标准；制造环境通过FDA注册并符合cGMP要求。</div>
						<img class="index-more" src="../../public/imgs/pro/more.png" alt="">
					</div>
					<div class="pc-center-btns" @click="golist(6)">
						<img src="../../public/imgs/index/listbg3.png" alt="listbg" class="list-bg">
						<img class="index-cimg" :src="center.e3" alt="">
						<div class="pc-btns-name">ISO 双认证自动化洁净分装</div>
						<div class="pc-btns-msg">每一颗补剂在美国生产，并空运至NUTRIPACKS 天府新区保税区洁净分装中心，按照你的健康需求实时自动化分装。</div>
						<img class="index-more" src="../../public/imgs/pro/more.png" alt="">
					</div>
				</div>
			</div>
			<div class="pc-foot-box" v-if="!shanghaishow">
				<div class="index-foot-ngg"></div>
				<div class="index-foot-ngg1"></div>
				<div class="index-pc-title-box index-pc-title-box3">
					<!-- <div class="redline"></div> -->
					<div class="index-pc-title-txt">
						<!-- <p>Customer Snapshots</p> -->
						<h3>Customer Snapshots</h3>
					</div>
					<div class="index-pc-title-msg">他们正在使用</div>
				</div>
				<div class="pc-customer">
					<img class="indexdian" src="../../public/imgs/index/Group_138@2x.png" alt="">
					<div class="pc-customers">
						<Carousel autoplay arrow="always" loop :autoplay-speed="5000" dots="none">
							<CarouselItem>
								<div class="banners">
									<img :src="publicwww+'static/common/images/official-website/pximgs/b21.png'" alt="">
								</div>
							</CarouselItem>
							<CarouselItem>
								<div class="banners">
									<img :src="publicwww+'static/common/images/official-website/pximgs/b11.png'" alt="">
								</div>
							</CarouselItem>
							<CarouselItem>
								<div class="banners">
									<img :src="publicwww+'static/common/images/official-website/pximgs/b51.png'" alt="">
								</div>
							</CarouselItem>
							<CarouselItem>
								<div class="banners">
									<img :src="publicwww+'static/common/images/official-website/pximgs/b31.png'" alt="">
								</div>
							</CarouselItem>
							<CarouselItem>
								<div class="banners">
									<img :src="publicwww+'static/common/images/official-website/pximgs/b41.png'" alt="">
								</div>
							</CarouselItem>
						</Carousel>
					</div>
				</div>
				<div class="pc-bethere">
					<div class="imgs">
						<img :src="publicurl+'indexend.png'" alt="">
					</div>
					<div class="index-pc-title-box4">
						<div class="index-pc-title-txt">
							<!-- <p>Be There</p> -->
							<h1>Be There</h1>
						</div>
						<div class="redline"></div>
						<div class="index-pc-title-msg">NUTRIPACKS 橘袋愿与自然、健康、活力一道陪伴在你身边，以全方位的定制服务支持更好的健康未来。</div>
						<button class="pc-dingzhi" @click="golist(7)">
							<p>开始 AI 定制</p>
							<img class="img" src="../../public/imgs/pro/more.png" alt="">
						</button>
					</div>
				</div>
			</div>
			<div class="index-bottom"></div>
		</div>
	</div>
</template>
<script>
	if (navigator.userAgent.indexOf('AliApp') > -1) {
		document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>');
	}
</script>
<script>
// my.onMessage = function(e) {
//     console.log(e); // {'sendToWebView': '1'}
// 		alert('123')
//   }
</script>
<script>
	import Swiper from 'swiper'
	import {
		md5
	} from '../util/md5.js'
	export default {
		name: 'index',
		components: {},
		data: () => ({
			page: 'home',
			swiperOption: {
				autoplay: {
					delay: 2000,
					disableOnInteraction: true,
				},
				loop: true,
			},
			publicwww: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/',
			publicurl: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/',
			indexbg: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/Banner/images/20200922/d561c778fbd3a44b32db45aefa59f27a.jpg',
			npurl: '',
			show: false,
			nowtab: 0,
			value1: 0,
			hasbuyplan: false,
			way: 'left',
			timers: '',
			info: '',
			flag: '',
			value2: 0,
			thestyle: '',
			record: '',
			ip: '123',
			bt: false,
			biaoshi: '',
			banner: [],
			anli: '',
			duibi: '',
			noticelist: [],
			isposition: false,
			step: {
				i1: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/step1.png',
				i2: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/step2.png',
				i3: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/step3.png',
				i4: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/step4.png',
				x1: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/step-line.png',
			},
			center: {
				c1: '',
				c2: '',
				c3: '',
				d1: '',
				e1: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/quality1.png',
				e2: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/quality2.png',
				e3: 'https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/home/quality3.png'
			},
			shanghaishow:false,
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		mounted() {
			console.log('index.vue')
			if(this.$route.query.obj !== 'undefined' && typeof(this.$route.query.obj) !== 'undefined') {
				localStorage.setItem('info', this.$route.query.obj)
				console.log('本地info缓存',localStorage.getItem('info'))
			}
			console.log('111111',this.$route.query)
			if(this.$route.query) {
				if(this.$route.query.source_id !== 'undefined' && typeof(this.$route.query.source_id) !== 'undefined') {
					console.log('222222',this.$route.query.source_id)
					localStorage.setItem('sourceId', this.$route.query.source_id)
				}
				if(this.$route.query.creative_id !== 'undefined' && typeof(this.$route.query.creative_id) !== 'undefined') {
					console.log('222222',this.$route.query.creative_id)
					localStorage.setItem('creativeId', this.$route.query.creative_id)
				}
			}
			// alert(JSON.parse(this.$route.query.obj))
			window.addEventListener("scroll", this.handleScroll);
			var w = window.screen.width
			var h = window.screen.height
			if (h / w > 1.873) {
				//console.log('111')
				this.isposition = true
			}
			var zhi = localStorage.getItem('zhi') ? localStorage.getItem('zhi') : '';
			var ztimestamp = localStorage.getItem('ztimestamp') ? localStorage.getItem('ztimestamp') : '';
			if (zhi) {
				var tzhi = zhi + ztimestamp
				this.biaoshi = md5(tzhi).toUpperCase()
			} else {
				//console.log('请刷新页面')
			}
			var that = this
			setTimeout(function() {
				that.bt = true
			}, 500)
			if(this.$route.query.obj !== 'undefined' && typeof(this.$route.query.obj) !== 'undefined') {
				if(JSON.parse(localStorage.getItem('info')).pageName !== '') {
					let way = JSON.parse(localStorage.getItem('info')).pageName
					if (way == 'questionnaire') {
							this.$router.push({
								path: 'home'
							})
						} else {
							this.$router.push({
								path: way
							})
						}
				} else {
					this.$router.push({
						path: '/'
					})
				}
			} else {
				this.$router.push({
						path: '/'
					})
			}
				console.log('fewfewfe',localStorage.getItem('info'))
			var info = JSON.parse(localStorage.getItem('info')) ? JSON.parse(localStorage.getItem('info')) : {
				token: ''
			}
			that.info = info
			if (JSON.parse(localStorage.getItem('info'))) {
				this.getbuyplan()
			} else {
				this.hasbuyplan = false
			}
			window.addEventListener("scroll", this.gundong);
			setTimeout(that.showit, 1000)

			this.getbanner()
			this.getNoticeList()
			this.getindex()


		},
		created(){
			this.shanghaishow = sessionStorage.getItem('shanghaishow')
		},
		methods: {
			getindex() {
				var that = this
				this.$ajax.post(this.API.api.apiurl.getIndex, {})
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							localStorage.setItem('indexdata', JSON.stringify(data.data))
							var inlist = data.data
							inlist.home_img.forEach((item) => {
								if (item.image_no == 3) {
									that.npurl = item.url
								} else if (item.image_no == 4) {
									that.center.c1 = item.url
								} else if (item.image_no == 5) {
									that.center.d1 = item.url
								} else if (item.image_no == 16) {
									that.center.c2 = item.url
								} else if (item.image_no == 49) {
									that.center.c3 = item.url
								}
							})
						} else {
							//console.log('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getbuyplan() {
				var that = this
				this.$ajax.post(this.API.api.apiurl.getBuyPlanV2, {
						member_id: that.info.member_id,
					}, {
						headers: {
							'Authorization': 'Bearer ' + that.info.token,
						}
					})
					.then(res => {
						if (res.data.code == 0) {
							var data = res.data.data
							if (data[0].id == 14) {
								that.hasbuyplan = true
							}
						} else {
							//console.log('错误：' + res.res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 0) {
					var w = window.screen.width
					var h = window.screen.height
					var hh = h + scrollTop
					if (hh / w > 1.873) {
						this.isposition = true
					} else {
						this.isposition = false
					}
				}
			},
			gothere(url) {
				window.open(url)
			},
			logout() {
				this.$router.push({
					path: 'login'
				})
			},
			getNoticeList() {
				var that = this
				this.$ajax.post(this.API.api.apiurl.getNoticeList, {
						type: [6]
					})
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							console.log('getNoticeList',data)
							that.noticelist = data.data.type_6
							setTimeout(function() {
								new Swiper('.swiper-container', {
									loop: true,
									pagination: {
										el: '.swiper-pagination',
										clickable: true,
									},
									autoplay: {
										delay: 3000, //时间 毫秒
										disableOnInteraction: false, //用户操作之后是否停止自动轮播默认true
									},
								})
							}, 1000)
						} else {
							//console.log('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getbanner() {
				var that = this
				this.$ajax.post(this.API.api.apiurl.getBannerList)
					.then(res => {
						var data = res.data
						if (data.code == 0) {
							console.log(data,'---------------')
							that.banner = data.data.index_list
							that.duibi = data.data.element_list
							that.anli = data.data.user_stories
						} else {
							//console.log('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			golist(e) {
				if (e == 1) {
					window.open(
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007381&idx=1&sn=7fb1b9f0cf246fe87b0fc11d1782b464&chksm=7d84d5784af35c6ee70698503c4a2dd06aae94630522af292b28e8a175827a82e7a26478f559#rd'
					)
				} else if (e == 2) {
					window.open(
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100000609&idx=1&sn=b535cb2ddf905f24858f5210adccb940&chksm=7d84cbcc4af342da7a2bcbd12503a97dc716dae5f47f681b7daa19e9b1e348fea4096f860b7c#rd'
					)
				} else if (e == 3) {
					window.open(
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007377&idx=1&sn=e25d8a0a26da362bc19ac35a590092a2&chksm=7d84d57c4af35c6a384ea4d29de9bb5439742736374c7537b9a3370db19b10c3fce57629c387#rd'
					)
				} else if (e == 4) {
					window.open(
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007379&idx=1&sn=9794dc1e06a82abae9ee680fdbd09ff9&chksm=7d84d57e4af35c68e8d8d4d67ed132cc533a30f8e1940b094519da2909d7f7e46284b9ce836d#rd'
					)
				} else if (e == 5) {
					window.open(
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007378&idx=1&sn=1e5486f679f0096f4acec15f6538188e&chksm=7d84d57f4af35c69eee04db176a1a89867057b029b5b7077bdac99ae3eec99e86bcd492410ec#rd'
					)
				} else if (e == 6) {
					window.open(
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007380&idx=1&sn=b96cc5a79e0cd5c2d579360d95fb3a22&chksm=7d84d5794af35c6f2d63fee1662e46791c806248f252b8fc709f858d8ea66acc795e62f75fa4#rd'
					)
				} else if (e == 7) {
					this.$router.push({
						path: 'home'
					})
				} else if (e == 8) {
					window.open('https://nutripacks.mikecrm.com/i4tUtKz')
				} else if (e == 10) {
					this.$router.push({
						path: 'care-packs'
					})
				}
			},

			goxcx(){
				const sourceId = localStorage.getItem('sourceId')
				window.location.href= sourceId ? "https://api.xiaojudai.cn/api/s/27?source_id=" + sourceId : "https://api.xiaojudai.cn/api/s/2"
			},
			golist1(e) {
				if (e == 1) {
					if(navigator.userAgent.indexOf('AliApp') > -1){
						return
					}
					window.location.href =
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007381&idx=1&sn=7fb1b9f0cf246fe87b0fc11d1782b464&chksm=7d84d5784af35c6ee70698503c4a2dd06aae94630522af292b28e8a175827a82e7a26478f559#rd'
				} else if (e == 2) {
					if(navigator.userAgent.indexOf('AliApp') > -1){
						return
					}
					window.location.href =
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100000609&idx=1&sn=b535cb2ddf905f24858f5210adccb940&chksm=7d84cbcc4af342da7a2bcbd12503a97dc716dae5f47f681b7daa19e9b1e348fea4096f860b7c#rd'

				} else if (e == 3) {
					if(navigator.userAgent.indexOf('AliApp') > -1){
						return
					}
					window.location.href =
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007377&idx=1&sn=e25d8a0a26da362bc19ac35a590092a2&chksm=7d84d57c4af35c6a384ea4d29de9bb5439742736374c7537b9a3370db19b10c3fce57629c387#rd'

				} else if (e == 4) {
					if(navigator.userAgent.indexOf('AliApp') > -1){
						return
					}
					window.location.href =
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007379&idx=1&sn=9794dc1e06a82abae9ee680fdbd09ff9&chksm=7d84d57e4af35c68e8d8d4d67ed132cc533a30f8e1940b094519da2909d7f7e46284b9ce836d#rd'

				} else if (e == 5) {
					if(navigator.userAgent.indexOf('AliApp') > -1){
						return
					}
					window.location.href =
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007378&idx=1&sn=1e5486f679f0096f4acec15f6538188e&chksm=7d84d57f4af35c69eee04db176a1a89867057b029b5b7077bdac99ae3eec99e86bcd492410ec#rd'

				} else if (e == 6) {
					if(navigator.userAgent.indexOf('AliApp') > -1){
						return
					}
					window.location.href =
						'http://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100007380&idx=1&sn=b96cc5a79e0cd5c2d579360d95fb3a22&chksm=7d84d5794af35c6f2d63fee1662e46791c806248f252b8fc709f858d8ea66acc795e62f75fa4#rd'
				} else if (e == 7) {
					if(navigator.userAgent.indexOf('AliApp') > -1) {
						console.log('支付宝小程序')
						this.$router.push({
							path: 'home'
						})
					}else {
						console.log('非支付宝小程序')
						const sourceId = localStorage.getItem('sourceId')
						window.location.href= sourceId ? "https://api.xiaojudai.cn/api/s/27?source_id=" + sourceId : "https://api.xiaojudai.cn/api/s/2"
					}
				} else if (e == 8) {
					window.location.href = 'https://nutripacks.mikecrm.com/i4tUtKz'
				}
			}
		}
	}
</script>

<style scoped>
.fixed-activity-icon {
	position: fixed;
	z-index: 999;
	top: 200px;
	right: 10px;
}

.fixed-activity-icon img {
	width: 80px;
	height: auto;
}

.mainbanner .activity-banner {
	width: 100%;
	height: 42vw;
}
</style>

<style>
	.index-banner-qr{
		width: 150px;
		height: auto;
		position: absolute;
		text-align: left;
		top: 358px;
	}
	.index-banner-qr img{
		height: auto;
		width: 120px;
		border-radius: 20px;
	}
	.index-banner-qr div{
		font-size: 14px;
		margin-top: 16px;
	}
	.index-pz-swiper {
		width: 414px;
		box-shadow: 0px 4px 20px 0px #F1F1F1;
		margin-left: 184px;
		border-radius: 10px;
		height: 510px;
		margin-top: 51px;
	}

	.index-pz-swiper .ivu-carousel-track {
		display: flex;
	}

	.pz-anlis {
		width: 380px;
		height: 425px;
		margin: 52.5px 17px;
	}

	.pz-anlis img {
		width: 380px;
		height: auto;
	}

	.index-pc-pz {
		width: 100%;
		height: 613px;
		display: flex;
		justify-content: space-between;
		margin-top: 164px;
	}

	.index-pz-box {
		width: 700px;
		height: 613px;
		background: #D9C8BF;
		border-radius: 100px 0 0 100px;
		padding: 140px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 !important;
		margin-right: calc(50% - 50vw);
	}

	.pc-pz-box {
		align-items: center;
	}

	.index-pz-name {
		font-size: 32px;
		line-height: 45px;
		color: #1A1A1A;
		text-align: left;
		font-weight: bold;
	}

	.index-pz-msg {
		width: 370px;
		margin-top: 32px;
		line-height: 1.5;
		font-size: 18px;
		color: #1A1A1A;
		text-align: justify;
	}

	.index-pz-btn {
		width: 192px;
		height: 60px;
		margin-top: 64px;
		border-radius: 60px;
		border: 1px solid #1A1A1A;
		display: flex;
		justify-content: center;
		font-size: 16px;
		line-height: 60px;
		cursor: pointer;
		color: #1A1A1A;
	}

	.index-pz-btn img {
		width: 10px;
		height: 15px;
		margin: 22.5px 0 22.5px 15px;
	}

	.pc-chengfen {
		width: 100%;
		height: 613px;
		position: relative;
		margin-left: calc(50% - 50vw);
	}

	.pc-bf-box {
		width: 960px;
		height: 613px;
		border-radius: 0 100px 100px 0;
		background: #DCE0DC;
		padding-left: 216px;
		padding-top: 140px;
	}

	.index-bf-name {
		width: auto;
		line-height: 45px;
		font-weight: bold;
		color: #1A1A1A;
		font-size: 32px;
		text-align: left;
	}

	.index-bf-msg {
		width: 370px;
		margin-top: 32px;
		line-height: 1.5;
		font-size: 18px;
		color: #1A1A1A;
		text-align: justify;
	}

	.index-bf-btn {
		width: 234px;
		height: 60px;
		margin-top: 64px;
		border-radius: 60px;
		border: 1px solid #1A1A1A;
		display: flex;
		justify-content: center;
		font-size: 16px;
		line-height: 60px;
		cursor: pointer;
		color: #1A1A1A;
	}

	.index-bf-btn img {
		width: 12px;
		height: 18px;
		margin: 21px 0 21px 15px;
	}

	.indexjian {
		width: 180px;
		height: 474px;
		position: absolute;
		top: 75px;
		right: 500px;
	}

	.index-jianlist {
		width: auto;
		position: absolute;
		top: 136px;
		left: 970px;
	}

	.index-jianlist .jianlist-line {
		width: auto;
		line-height: 25px;
		display: flex;
		margin-bottom: 80px;
	}

	.jianlist-line .index-dx {
		width: auto;
		height: 12px;
		margin-top: 6.5px;
		display: flex;
	}

	.index-dx .index-d {
		width: 12px;
		height: 12px;
		border-radius: 12px;
		background: #1A1A1A;
	}

	.index-dx .index-x {
		width: 40px;
		height: 1px;
		background: #1A1A1A;
		margin-top: 6px;
	}

	.jianlist-line .index-jian-txt {
		line-height: 25px;
		color: #1A1A1A;
		font-size: 18px;
		margin-left: 12px;
	}

	.pc-customer {
		width: 100%;
		height: 686px;
		position: relative;
	}

	.pc-customers {
		width: 1000px;
		height: auto;
		margin: 0 auto;
		z-index: 2;
	}

	.indexdian {
		width: 140px;
		height: 140px;
		position: absolute;
		bottom: 0;
		right: 160px;
		right: 0;
		z-index: 0;
	}

	.banners {
		width: 1000px;
		height: auto;
	}

	.banners img {
		width: 100%;
		height: auto;
	}

	.index .ivu-carousel-arrow.right {
		right: -90px !important;
		z-index: 2;
	}

	.index .ivu-carousel-arrow.left {
		left: -90px !important;
		z-index: 2;
	}

	.index .ind-banner .ivu-carousel-arrow.right {
		right: 40px !important;
	}

	.index .ind-banner .ivu-carousel-arrow.left {
		left: 40px !important;
	}

	#app .index {
		height: auto;
		padding-bottom: 82px;
	}

	.index-banner,
	.ind-banner {
		width: 100%;
		position: relative;
		background: #F5F5F5;
		height: 42vw;
		max-height: 600px;
		position: relative;
	}

	.index .ind-banner {
		width: 100vw;
		margin-left: calc(50% - 50vw);
		height: auto;
		background: none;
	}

	.mainbanner {
		width: 100%;
		height: auto;
		position: relative;
	}

	.mainbanner .index-banner {
		background: none;
	}

	.mainbannerbg {
		width: 100vw;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		align-items: center;
	}

	.mainbannerbg img {
		width: 100%;
		height: auto;
	}

	.banner-title {
		width: 584px;
		height: auto;
		position: absolute;
		top: 124px;
		left: 100px;
		font-weight: 550;
		line-height: 1.2;
		font-size: 56px;
		text-align: left;
		font-family: MADE Evolve;
		z-index: 1;
		color: #1A1A1A;
	}

	.banner-btn {
		width: 13.4vw;
		height: 4.15vw;
		border-radius: 4.15vw;
		background: #C25E4D;
		color: #FFFFFF;
		display: flex;
		position: absolute;
		align-items: center;
		left: 100px;
		top: 414px;
		font-size: 1.25vw;
		line-height: 4.15vw;
		justify-content: center;
		cursor: pointer;
		width: 193px;
		height: 60px;
		line-height: 60px;
		font-size: 18px;
	}

	.banner-btn img {
		width: 18px;
		height: 18px;
		margin-left: 10px;
	}

	.banner-msg {
		width: auto;
		position: absolute;
		left: 100px;
		font-size: 1.65vw;
		line-height: 2.35vw;
		line-height: 34px;
		font-size: 24px;
		color: #1A1A1A;
		top: 292px;
	}
	.banner-msg1{
		top: 300px;
	}
	.index-banner-qr{
		left: 100px;
		top: 300px;
	}

	.banner-bg {
		width: 59.25vw;
		height: 47.55vw;
		max-width: 800px;
		max-height: 685px;
		position: absolute;
		top: -115px;
		right: 0;
		right: -60px;
	}

	.banner-bg img {
		width: 100%;
		height: auto;
		width: 123%;
		margin-left: -125px;
	}

	.banner-bg-msg {
		width: 372px;
		line-height: 1.75vw;
		display: flex;
		justify-content: space-around;
		position: absolute;
		top: 512px;
		top: 0;
		left: 50%;
		margin-left: -200px;
		margin-left: calc(-200px - 1.5%);
		font-size: 18px;
		color: #1A1A1A;
	}

	.index-pc-step-box {
		width: 100%;
		height: auto;
		background: #F5F5F5;
		position: relative;
	}

	.pc-bethere {
		width: 100%;
		height: calc(164px - 115px + 164px + 400px);
		background: #f5f5f5;
		position: relative;
		margin: 0 auto;
		display: flex;
		align-items: center;
		margin-top: 164px;
	}

	.pc-bethere .imgs {
		top: 0;
		right: calc(50% - 50vw);
		height: auto;
		z-index: 0;
		width: 100%;
		overflow: hidden;
	}

	.pc-bethere .imgs img {
		width: 100%;
		height: auto;
	}

	.banner-bottom-bg{
		width: 100vw;
		height: calc(82px - 15px);
		background: #F5F5F5;
		margin-left: calc(50% - 50vw);
	}

	.index-pc-title-box {
		width: 100%;
		height: auto;
		position: relative;
		margin: 82px 0;
		margin-top: 0;
		border-left: 12px solid #C25E4D;
		padding-left: 20px;
	}

	.index-pc-title-box .redline {
		width: 12px;
		height: 141px;
		background: #C25E4D;
		margin-left: 100px;
	}

	.index-pc-title-box .index-pc-title-txt {
		width: auto;
		height: auto;
		line-height: 57px;
		font-size: 48px;
		font-weight: bold;
		color: #1A1A1A;
		text-align: left;
		font-family: MADE Evolve;
		/* position: absolute;
		left: 135px;
		top: 40px; */
	}

	.index-pc-title-box .index-pc-title-msg {
		width: auto;
		line-height: 34px;
		color: #1A1A1A;
		font-size: 24px;
		margin-top: 16px;
		/* position: absolute;
		left: 135px;
		top: 160px; */
		text-align: justify;
	}

	.index-pc-step {
		width: 100%;
		height: calc(344px + 164px);
		position: relative;
	}

	.img-yd {
		position: absolute;
		width: 25%;
		height: auto;
		right: 0;
	}

	.pc-step-box {
		width: 1220px;
		height: 344px;
		background: #D9C8BF;
		margin-left: -16px;
		margin-left: calc(50% - 50vw);
		border-radius: 0 100px 100px 0;
		display: flex;
	}

	.pc-step {
		width: 190px;
		height: auto;
		text-align: center;
		margin-top: 60px;
		margin-left: 51px;
	}

	.pc-step:nth-child(2) {
		width: 200px;
	}

	.pc-step .pc-step-name {
		width: 100%;
		line-height: 21px;
		font-family: MADE Evolve;
		font-weight: bold;
		font-size: 18px;
	}

	.pc-step .pc-step-img {
		width: 56px;
		height: 56px;
		margin: 20px auto 36px auto;
	}

	.pc-step-img img {
		width: 100%;
		height: auto;
	}

	.pc-step .pc-step-txt {
		width: 100%;
		line-height: 25px;
		color: #1A1A1A;
		font-weight: bold;
		font-size: 18px;
	}

	.pc-step .pc-step-msg {
		width: 100%;
		line-height: 25px;
		color: #1A1A1A;
		font-size: 18px;
		margin-top: 8px;
	}

	.pc-center-list-box {
		width: 100%;
		height: auto;
		padding-bottom: 164px;
		background: #FFFFFF;
		position: relative;
	}

	.index-pc-title-box1 {
		width: 100%;
		height: 170px;
		height: calc(54px + 76px + 0.75vw);
		position: relative;
		text-align: center;
		margin: calc(164px - 6px) 0 164px 0;
		padding-top: 0;
	}

	.index-pc-title-box1 .index-pc-title-txt {
		position: absolute;
		width: 100%;
		text-align: center;
		line-height: 76px;
		font-size: 64px;
		color: #1A1A1A;
		font-family: MADE Evolve;
		font-weight: bold;
		top: 0;
		left: 0;
	}

	.index-pc-title-box1 .redline {
		position: absolute;
		width: 100px;
		height: 12px;
		background: #C25E4D;
		left: calc(50% - 50px);
		bottom: 0;
		margin-left: 0;
	}

	.index-pc-title-box1 .index-pc-title-msg {
		position: absolute;
		width: 100%;
		text-align: center;
		line-height: 27px;
		font-size: 18px;
		color: #1A1A1A;
		top: 84px;
		top: calc(76px + 0.75vw);
		top: calc(76px + 16px);
		left: 0;
	}

	.indexye {
		width: 300px;
		height: 773px;
		position: absolute;
		left: calc(50% - 50vw);
		top: -555px;
	}

	.indexyou {
		width: 100px;
		height: 140px;
		position: absolute;
		right: calc(50% - 50vw);
		top: 164px;
	}

	.index-pc-title-box2 {
		width: 100%;
		height: auto;
		margin: 164px 0 82px 0;
		padding-top: 0;
		position: relative;
	}

	.index-pc-title-box2 .redline {
		width: 12px;
		height: 90px;
		background: #C25E4D;
		margin-left: 100px;
	}

	.index-pc-title-box2 .index-pc-title-txt {
		width: auto;
		/* height: 90px; */
		line-height: 45px;
		color: #1A1A1A;
		font-size: 32px;
		font-weight: bold;
		/* position: absolute;
		top: 0;
		left: 144px; */
	}

	.index-pc-title-box2 .index-pc-title-msg {
		width: auto;
		line-height: 32px;
		color: #1A1A1A;
		font-size: 20px;
		position: absolute;
		top: 122px;
		left: 144px;
		text-align: left;
	}

	.indexhand {
		width: calc(50vw - 15%);
		height: auto;
		position: absolute;
		top: -26px;
		right: calc(50% - 50vw + 80px);
		z-index: 1;
		margin-right: 0;
		filter: drop-shadow(10px 20px 16px rgba(0, 0, 0, 0.08));
	}

	.pc-center-main {
		width: 1200px;
		height: auto;
		display: flex;
		margin: 0 auto;
	}

	.pc-center-btns {
		width: 378px;
		height: 521px;
		border-radius: 10px;
		background: #F5F5F5;
		margin-left: 31px;
		text-align: left;
		cursor: pointer;
		position: relative;
	}

	.list-bg {
		position: absolute;
		top: 0;
		right: 0;
		width: 91px;
		height: 143px;
		display: none;
	}

	.index-cimg {
		width: 88px;
		height: 90px;
		margin: 60px 0 88px 30px;
	}

	.pc-btns-name {
		line-height: 28px;
		font-size: 20px;
		text-align: left;
		width: auto;
		margin-left: 30px;
		color: #1A1A1A;
		font-weight: bold;
	}

	.pc-btns-msg {
		line-height: 32px;
		line-height: 1.5;
		font-size: 17px;
		text-align: left;
		width: 312px;
		width: 318px;
		margin-left: 30px;
		color: #1A1A1A;
		margin-top: 0.75vw;
		margin-top: 16px;
		text-align: justify;
	}

	.index-more {
		width: 14.4px;
		height: 23px;
	}

	.index-more {
		width: 14.4px;
		height: 23px;
		position: absolute;
		left: 315px;
		bottom: 60px;
	}

	.index-pc-title-box4 {
		width: 376px;
		height: auto;
		position: absolute;
		left: 100px;
		top: 130px;
		top: calc(164px - 115px);
	}

	.index-pc-title-box4 .index-pc-title-txt {
		width: auto;
		line-height: 76px;
		text-align: left;
		font-weight: bold;
		color: #1A1A1A;
		font-size: 64px;
		font-family: MADE Evolve;
	}

	.index-pc-title-box4 .redline {
		margin-top: 42px;
		width: 100px;
		height: 12px;
		background: #C25E4D;
	}

	.index-pc-title-box4 .index-pc-title-msg {
		width: 376px;
		line-height: 32px;
		line-height: 1.5;
		color: #1A1A1A;
		font-size: 20px;
		text-align: justify;
		margin: 50px 0 64px 0;
		font-family: PingFang SC;
	}

	.pc-dingzhi {
		background: none;
		width: 188px;
		height: 60px;
		border-radius: 60px;
		color: #1A1A1A;
		line-height: 60px;
		font-size: 18px;
		text-align: center;
		display: flex;
		justify-content: center;
		cursor: pointer;
		position: relative;
	}

	#app .pc-dingzhi {
		border: 1px solid #1A1A1A;
	}

	.pc-dingzhi .img {
		position: relative;
		right: 0;
		width: 12px;
		height: 18px;
		margin: 21px 0 21px 15px;
	}

	.pc-foot-box {
		width: 100%;
		height: auto;
		background: #F5F5F5;
		position: relative;
		padding-top: 164px;
	}

	.index-pc-title-box3 {
		width: 100%;
		height: 328px;
		height: calc(104px + 164px + 5.5vw);
		height: auto;
		position: relative;
	}

	.index-pc-title-box3 .redline {
		width: 12px;
		height: 104px;
		background: #C25E4D;
		position: absolute;
		left: 100px;
		top: 164px;
	}

	.index-pc-title-box3 .index-pc-title-txt {
		width: auto;
		line-height: 57px;
		font-size: 48px;
		font-family: MADE Evolve;
		font-weight: bold;
		color: #1A1A1A;
		/* position: absolute;
		left: 130px;
		top: 164px; */
	}

	.index-pc-title-box3 .index-pc-title-msg {
		width: auto;
		line-height: 39px;
		font-size: 24px;
		color: #1A1A1A;
		margin-top: 16px;
		/* position: absolute;
		left: 130px;
		top: calc(164px + 65px) */
	}
	.index-banner-ngg {
		position: absolute;
		width: 50vw;
		height: 100%;
		left: calc(50% - 50vw);
		top: 0;
		background: #F5F5F5;
		z-index: 0;
	}

	.index-banner-ngg1 {
		position: absolute;
		width: 50vw;
		height: 100%;
		right: calc(50% - 50vw);
		top: 0;
		background: #F5F5F5;
		z-index: 0;
	}

	.index-step-ngg {
		position: absolute;
		width: 50vw;
		height: 100%;
		left: calc(50% - 50vw);
		top: 0;
		background: #F5F5F5;
		z-index: 0;
	}

	.index-step-ngg1 {
		position: absolute;
		width: 50vw;
		height: 100%;
		right: calc(50% - 50vw);
		top: 0;
		background: #F5F5F5;
		z-index: 0;
	}

	.index-foot-ngg {
		position: absolute;
		width: 50vw;
		height: 100%;
		left: calc(50% - 50vw);
		top: 0;
		background: #F5F5F5;
		z-index: 0;
	}

	.index-foot-ngg1 {
		position: absolute;
		width: 50vw;
		height: 100%;
		right: calc(50% - 50vw);
		top: 0;
		background: #F5F5F5;
		z-index: 0;
	}
	.index-bottom {
		width: 100%;
		height: 93px;
		display: none;
		background: #FFFFFF;
	}
	.index .ivu-carousel-arrow {
		width: 3vw;
		height: 3vw;
		font-size: 1.5vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.index .ivu-carousel-arrow .ivu-icon {
		/* margin-top: 0.5vw !important; */
	}

	.banner-title,
	.banner-msg,
	.banner-btn {
		margin-left: calc(12.5vw - 50vw + 50%);
		margin-left: 0;
		left: 0;
	}

	.index-pc-title-box4 {
		left: calc(12.5vw - 50vw + 50%) !important;
	}

	.index-pc-title-box3,
	.index-pc-title-box {
		margin-left: calc(12.5vw - 50vw + 50% - 100px) !important;
	}

	/* 手机端 */

	@media screen and (max-width:999px) {
		.index .ivu-carousel {
			margin-top: 0;
		}

		#app .index {
			width: 100%;
			height: auto;
			padding-top: 0.4rem;
		}

		.top-swiper {
			position: relative;
			width: 100%;
			height: auto;
			overflow: hidden;
			margin-top: -80px;
		}

		.banner {
			width: 100%;
			height: auto;
		}

		.banner img {
			width: 100%;
			height: auto;
		}

		.anlis {
			width: 7.73rem;
			height: auto;
		}

		.anlis img {
			width: 100%;
			height: auto;
		}

		.indexanlis {
			width: 100vw;
			height: auto;
		}

		.indexanlis img {
			width: 100%;
			height: auto;
		}

		.index .ivu-carousel-dots-inside {
			bottom: -0.6rem;
		}

		.np {
			width: 100%;
			height: auto;
			/* margin-top: 2rem; */
		}

		.np img {
			width: 100%;
			height: auto;
		}

		.volleyball-title-warp {
			width: 100%;
			height: 2.22rem;
			border-left: 0.16rem solid #C25E4D;
			margin-top: 0.45rem;
			position: relative;
		}

		.volleyball-title {
			margin: 0 0 0 0.65rem;
			line-height: 0.64rem;
			font-size: 0.64rem;
			text-align: left;
		}

		.index-title {
			font-weight: bold;
			color: #1A1A1A;
			font-family: MADE Evolve;
		}

		.index-ch {
			margin-top: 0.16rem;
			line-height: 0.53rem;
			font-size: 0.37rem;
			color: #1A1A1A;
		}

		.volleyball-title-icon {
			width: 0.64rem;
			height: 0.64rem;
			position: absolute;
			right: 0.8rem;
			top: 1.01rem;
		}

		.volleyball-title-icon img {
			width: 0.21rem;
			height: 0.37rem;
			margin: 0.135rem 0.215rem;
		}

		.volleyball-title-warp2 {
			width: 100%;
			height: 2.55rem;
			/* margin-top: 2.19rem; */
			border-right: 0.16rem solid #C25E4D;
		}

		.volleyball-title-warp2 .index-title {
			line-height: 0.89rem;
			font-size: 0.75rem;
		}

		.volleyball-title-warp2 .volleyball-title {
			margin-left: 0.8rem;
		}

		.volleyball-title-warp2 .index-ch {
			line-height: 0.6rem;
			font-size: 0.43rem;
		}

		.index-step {
			padding: 1.17rem 0 2.19rem 0;
		}

		.index-step-box {
			width: 100%;
			height: auto;
			position: relative;
			text-align: center;
		}

		.index-step-box .index-step-img {
			width: 1.87rem;
			height: 1.87rem;
			background: #F5F5F5;
			border-radius: 1.87rem;
			margin: 0.21rem auto 0.43rem auto;
		}

		.index-step-img img {
			width: 1.33rem;
			height: 1.33rem;
			margin: 0.27rem;
		}

		.index-step-title {
			width: 100%;
			text-align: center;
			font-size: 0.37rem;
			line-height: 0.44rem;
			font-weight: bold;
			color: #1A1A1A;
		}

		.index-step-ch {
			width: 100%;
			line-height: 0.53rem;
			font-size: 0.37rem;
			color: #1A1A1A;
			font-weight: bold;
		}

		.index-step-msg {
			width: 100%;
			line-height: 0.53rem;
			color: #1A1A1A;
			margin-top: 0.11rem;
			font-size: 0.37rem;
		}

		.index-step1,
		.index-step3 {
			width: 4.33rem;
			margin-left: 0.53rem;
		}

		.index-step2,
		.index-step4 {
			width: 4.33rem;
			margin-left: calc(100% - 4.92rem);
		}

		.step-line1 {
			width: 2.77rem;
			height: 1.29rem;
			position: absolute;
			right: 2.56rem;
			top: 1.58rem;
		}

		.step-line2 {
			width: 2.77rem;
			height: 1rem;
			position: absolute;
			right: 2.56rem;
			top: 0.6rem;
			transform: rotateX(180deg);
		}

		.step-line3 {
			width: 2.91rem;
			height: 1.29rem;
			position: absolute;
			top: 0.37rem;
			left: 2.76rem;
			transform: rotate(180deg);
		}

		.step-line1 img,
		.step-line2 img,
		.step-line3 img {
			width: 100%;
			height: 100%;
		}

		.huibg {
			width: 100%;
			height: auto;
			padding: 2.19rem 0;
			background: #F5F5F5;
		}

		.volleyball-title-warp3 {
			width: 100%;
			height: 1.59rem;
			border-left: 0.16rem solid #C25E4D;
		}

		.volleyball-title-warp3 .volleyball-title {
			font-size: 0.75rem;
			line-height: 0.89rem;
		}

		.volleyball-title-warp3 .index-ch {
			font-size: 0.43rem;
			line-height: 0.6rem;
		}

		.center-list {
			width: calc(100% - 1.6rem);
			margin: 1.17rem auto 0 auto;
		}

		.index-center {
			width: 100%;
			height: auto;
			box-shadow: 0rem 0.13rem 0.27rem 0rem rgba(0, 0, 0, 0.1);
			border-radius: 0.13rem;
			overflow: hidden;
			background: #FFFFFF;
		}

		.center-top {
			width: 100%;
			height: auto;
		}

		.center-top img {
			width: 100%;
			height: auto;
		}

		.center-img-box {
			width: calc(100% - 1.5rem);
			margin: 0.85rem 0.75rem;
		}

		.center-img-box img {
			width: 100%;
			height: auto;
		}

		.center-img-box1 {
			width: 100%;
			height: auto;
			position: relative;
			margin: 0.85rem 0;
			color: #1A1A1A;
		}

		.center-img-box1 img {
			width: 100%;
			height: auto;
		}

		.center-img-box2 {
			width: 7.73rem;
			height: 9rem;
			position: relative;
			margin: 0.85rem auto 0.7rem auto;
			color: #1A1A1A;
		}

		.center-img-box2 .ivu-carousel-item {
			width: 7.73rem !important;
		}

		.index .ivu-carousel-track {
			display: flex;
		}

		.ct1 {
			position: absolute;
			top: 0;
			left: 0.9rem;
		}

		.ct2 {
			position: absolute;
			top: 0;
			right: 0.95rem;
		}

		.ct3 {
			position: absolute;
			bottom: 0.8rem;
			left: 0.9rem;
		}

		.ct4 {
			position: absolute;
			bottom: 0.8rem;
			right: 0.95rem;
		}

		.center-main-msg {
			width: 100%;
			height: auto;
		}

		.center-main-msg .center-msg-ch {
			width: 100%;
			line-height: 0.67rem;
			font-size: 0.37rem;
			color: #1A1A1A;
			text-align: center;
		}

		#app .chengnuo {
			width: 3.91rem;
			border-radius: 0.43rem;
			border: 0.03rem solid #333333;
			line-height: 0.85rem;
			font-size: 0.29rem;
			background: #FFFFFF;
			margin: 0.43rem auto 0.8rem auto;
		}

		.chengnuo img {
			width: 0.16rem;
			height: 0.25rem;
			margin-top: -0.08rem;
		}

		.black-line {
			width: calc(100% - 1.6rem);
			margin: 0.24rem auto 0.85rem auto;
			height: 0.03rem;
			background: #454645;
		}

		.center-msg-ch1 {
			width: 5.47rem;
			font-size: 0.37rem;
			margin: 0 auto;
			color: #1A1A1A;
			line-height: 0.67rem;
		}

		.cm-box {
			width: 7.6rem;
			height: 1.07rem;
			display: flex;
			margin: 0 auto;
		}

		.cm-box-line {
			height: 0.03rem;
			width: 7.6rem;
			margin: 0.85rem auto;
			background: #E6E6E6;
		}

		.cmi1 {
			width: 0.77rem;
			height: 0.8rem;
			margin: 0.135rem 0;
			margin-right: 0.43rem;
		}

		.cmi2 {
			width: 0.83rem;
			height: 0.85rem;
			margin: 0.11rem 0;
			margin-right: 0.4rem;
		}

		.cmi3 {
			width: 0.79rem;
			height: 0.85rem;
			margin: 0.11rem 0;
			margin-right: 0.4rem;
		}

		.cm-txt {
			width: 3.33rem;
			height: 1.07rem;
			text-align: left;
			color: #1A1A1A;
			font-size: 0.37rem;
			line-height: 0.53rem;
		}

		.cm-detail {
			width: 2.03rem;
			display: flex;
			justify-content: center;
			height: 0.6rem;
			line-height: 0.6rem;
			border: 0.03rem solid #333333;
			border-radius: 0.6rem;
			font-size: 0.29rem;
			margin: 0.24rem 0;
			margin-left: 0.99rem;
		}

		.cm-detail div {
			width: auto;
			line-height: 0.6rem;
			margin-top: -0.03rem;
			color: #1A1A1A;
		}

		.cm-detail img {
			width: 0.16rem;
			height: 0.25rem;
			margin: 0.15rem 0;
			margin-left: 0.1rem;
		}

		.volleyball-title-warp4 {
			width: 100%;
			height: 1.68rem;
			border-right: 0.16rem solid #C25E4D;
		}

		.volleyball-title-warp4 .volleyball-title {
			font-size: 0.75rem;
			line-height: 0.89rem;
			margin-left: 0.8rem;
		}

		.volleyball-title-warp4 .index-ch {
			font-size: 0.43rem;
			line-height: 0.6rem;
			color: #1A1A1A;
		}

		.volleyball-title-warp5 {
			width: 100%;
			height: 1.68rem;
			border-left: 0.16rem solid #C25E4D;
		}

		.volleyball-title-warp5 .volleyball-title {
			font-size: 0.75rem;
			line-height: 0.89rem;
		}

		.volleyball-title-warp5 .index-ch {
			font-size: 0.43rem;
			line-height: 0.6rem;
			color: #1A1A1A;
		}

		.volleyball-title-warp6 {
			width: 100%;
			height: 1.76rem;
		}

		.volleyball-title-warp6 .volleyball-title {
			font-size: 0.9rem;
			line-height: 0.98rem;
			margin-left: 0.8rem;
		}

		.volleyball-title-warp6 .index-ch {
			font-size: 0.43rem;
			line-height: 0.6rem;
		}

		.index-border {
			width: 1.2rem;
			height: 0.16rem;
			background: #C25E4D;
			margin-top: 0.6rem;
		}

		.bethere {
			width: 100%;
			height: auto;
			margin: 0.47rem auto 0.59rem auto;
		}

		.betheretxt {
			width: calc(100% - 1.6rem);
			margin: 0 auto;
			line-height: 0.67rem;
			font-size: 16px;
			color: #1A1A1A;
			text-align: left;
		}

		.dzbtn {
			width: 4.13rem;
			height: 1.17rem;
			display: flex;
			justify-content: center;
			background: #FFFFFF;
			border-radius: 1.17rem;
			border: 0.03rem solid #454645;
			margin-left: 0.8rem;
		}

		#app .dzbtn {
			border: 0.03rem solid #454645;
		}

		.dzbtn div {
			width: auto;
			line-height: 1.11rem;
			color: #454645;
			font-size: 0.37rem;
		}

		.dzbtn img {
			width: 0.16rem;
			height: 0.25rem;
			margin: 0.42rem 0;
			margin-left: 0.36rem;
		}

		.sdzbtn {
			width: 5.83rem;
			height: 1.17rem;
			display: flex;
			justify-content: center;
			background: #FFFFFF;
			border-radius: 1.17rem;
			border: 0.03rem solid #333333;
			margin: 0 auto;
		}

		#app .sdzbtn {
			border: 0.03rem solid #333333;
		}

		.sdzbtn div {
			width: auto;
			line-height: 1.11rem;
			color: #1A1A1A;
			font-size: 0.37rem;
		}

		.sdzbtn img {
			width: 0.16rem;
			height: 0.25rem;
			margin: 0.42rem 0;
			margin-left: 0.36rem;
		}

		.index-bg {
			width: 100%;
			height: auto;
			margin: 0.88rem 0;
		}

		.index-bg img {
			width: 100%;
			height: auto;
		}

		.index-anlis {
			width: 100%;
			height: auto;
			margin: 0.63rem 0 0.7rem 0;
		}

		.anlis-txt {
			width: 100%;
			height: auto;
			color: #1A1A1A;
			text-align: center;
			margin-bottom: 2.19rem;
		}

		.anlis-txt .shareanli {
			width: 100%;
			line-height: 0.44rem;
			font-size: 0.32rem;
			text-decoration: underline;
		}

		.anlis-txt .anlis-ch {
			width: 100%;
			line-height: 0.44rem;
			font-size: 0.32rem;
			margin-top: 0.21rem;
		}

		.noticelists {
			width: 8.4rem;
			height: 4.6rem;
			margin: 1.17rem auto 2.03rem auto;
			overflow: hidden;
		}

		.index .swiper-container {
			overflow: initial;
		}

		.index .swiper-pagination-bullet-active {
			background: rgba(135, 145, 165, 1);
		}

		.index .swiper-pagination-bullet {
			width: 6px;
			height: 6px;
		}

		:root {
			--swiper-theme-color: #515a6e;
		}

		.noticelist {
			width: 8.4rem;
			height: auto;
			border-radius: 0.13rem;
			overflow: hidden;
		}

		.noticelist img {
			width: 8.4rem;
			height: auto;
		}

		.noticelists .ivu-carousel-dots-inside {
			bottom: -0.9rem;
		}

		.showbtn {
			width: 100%;
			height: 3rem;
			position: fixed;
			bottom: 0;
			left: 0;
			z-index: 5;
		}

		.noshowbtn {
			position: absolute;
			bottom: 100px;
			width: 100%;
			height: 3.2rem;
			position: relative;
			padding-top: 1rem;
			z-index: 5;
		}

		.nopadd {
			padding-top: 0;
			height: auto;
		}

		.showbtn .sdzbtn {
			margin: 0.65rem auto 0 auto;
		}

		.noshowbtn .sdzbtn {
			margin: 0 auto 0.77rem auto;
		}

		.nopadd .sdzbtn {
			margin: 0 auto 0.37rem auto;
		}

		.noshowbtn .xiangxia {
			margin: 0 auto;
			margin-top: 0.5rem;
		}

		.xiangxia {
			width: 100%;
			height: 0.43rem;
			margin: 0.43rem auto;
			display: flex;
			justify-content: center;
			align-items: center;
			justify-content: center;
			transition-duration: .5s;
		}

		.xiangxia img {
			width: 0.43rem;
			height: 0.43rem;
			animation: move .9s infinite;
			-webkit-animation: move .9s infinite;
			animation-duration: alternate;
		}

		.showbtn .sevenday {
			width: 3.24rem;
			height: 0.8rem;
			position: absolute;
			top: -0.3rem;
			right: 1rem;
		}

		.noshowbtn .sevenday {
			width: 3.24rem;
			height: 0.8rem;
			position: absolute;
			top: 0;
			right: 1rem;
		}

		.nopadd .sevenday {
			top: -0.7rem;
		}

		.sevenday .tips {
			top: 0;
			height: 0.8rem;
			line-height: 0.8rem;
		}

		.sevenday .tips-msg {
			font-size: 0.37rem;
		}

		@keyframes move {
			0% {
				transform: translateY(0px);
			}

			50% {
				transform: translateY(-10px);
			}

			100% {
				transform: translateY(0px);
			}
		}

		@-webkit-keyframes move {
			0% {
				transform: translateY(0px);
			}

			50% {
				transform: translateY(-10px);
			}

			100% {
				transform: translateY(0px);
			}
		}

		.index .ivu-carousel-dots {
			z-index: 7;
		}

		.index .swiper-pagination {
			z-index: 7;
		}

		.index .swiper-container-horizontal>.swiper-pagination-bullets,
		.index .swiper-pagination-custom,
		.index .swiper-pagination-fraction {
			bottom: -0.6rem;
		}

		.index .ziti {
			font-family: MADEEvolveSans-Bold, MADEEvolveSans;
		}

		.index .index-title {
			font-size: 0.68rem;
		}

		.swiper-container {
			height: auto;
			width: 100%;

			.swiper-wrapper {
				.swiper-slide {
					width: 100%;
					height: 100%;
					/* background-color: #42b983; */
					text-align: center;
				}
			}
		}
	}

	@media screen and (min-width: 640px) and (max-width: 999px) {

		.ct3,
		.ct4 {
			position: absolute;
			bottom: calc(0.8rem + 7%);
		}
	}

	@media screen and (min-width: 600px) and (max-width: 750px) {
		.index-step {
			padding: 1.17rem 6% 2.19rem 6%;
		}
	}

	@media screen and (min-width: 751px) and (max-width: 999px) {
		.index-step {
			padding: 1.17rem 10% 2.19rem 10%;
		}
	}

	.center-div{
		width: 100%;
		margin: 0 auto;
		position: relative;
	}
	.index-pc-title-box3,.index-pc-title-box{
		margin-left: 0!important;
	}
	.index-pc-title-box .redline,.index-pc-title-box2 .redline{
		margin-left: 0;
	}
	.index-pc-title-box3 .redline{
		left: 0;
	}
	.index-pc-title-box .index-pc-title-txt,.index-pc-title-box2 .index-pc-title-txt,.index-pc-title-box3 .index-pc-title-txt{
		left: 35px;
		text-align: left;
	}
	.index-pc-title-box .index-pc-title-msg,.index-pc-title-box3 .index-pc-title-msg{
		left: 35px;
	}
	.pc-step-box{
		width: 100%;
		width: calc(50vw + 500px);
	}
	.pc-step:nth-child(1){
		margin-left: calc(50vw - 50%);
	}
	@media screen and (max-width: 1440px){
		.indexye{
			width: 180px!important;
			height: auto;
			top: -400px;
		}
		.index-bf-msg,.pc-pz-box{
			width: 60%!important;
		}
		.index-pz-msg{
			width: 100%;
		}
	}

	@media screen and (max-width: 1300px){
		.pc-center-main{
			width: 1000px;
			justify-content: center;
			margin-left: calc(50% - 500px)!important;
		}
		.pc-center-btns{
			width: 300px;
		}
		.pc-btns-msg{
			width: 260px;
			margin-left: 20px;
		}
		.pc-btns-name{
			width: 260px;
			margin-left: 20px;
		}
		.index-cimg{
			margin-left: 20px;
		}
		.index-more{
			left: 265px;
		}
	}
	@media screen and (max-width: 1200px){
		.center-div{
			max-width: 1000px;
		}
		.banner-title{
			font-size: 56px;
			line-height: 1.2;
			top: 5vw;
		}
		.banner-msg{
			top: calc(5.75vw + 140px);
		}
		.banner-msg1{
			top: calc(5.75vw + 150px);
		}
	.index-banner-qr{
		left: auto;
		top: 270px;
	}
		.banner-btn{
			top: calc(15vw + 140px);
		}
		.banner-bg {
			right: calc(250px - 34vw)!important;
		}
		.pc-step-box{
			width:90vw;
			max-width: 100vw;
			justify-content: space-around;
		}
		.pc-step:nth-child(1){
			margin-left: 0!important;
		}
		.img-yd{
			width: 20%;
			right: calc(50% - 50vw);
			bottom: 400px;
			width: 280px!important;
			right: calc(50% - 50vw - 40px)!important;
			top: -250px!important;
		}
		.pc-step{
			margin-left: 0;
		}
		.pc-chengfen{
			width: 66vw;
		}
		.pc-bf-box{
			width: 100%;
			padding-left: calc(50% - 230px);
		}
		.index-jianlist{
			right: -300px;
			left: auto;
		}
		.indexjian{
			right: -90px;
		}
		.index-pz-swiper{
			margin-left: 50px;
		}
		.index .index-pc-pz .ivu-carousel-arrow.right{
			right: -40px !important;
		}
		.index .index-pc-pz .ivu-carousel-arrow.left{
			left: -40px !important;
		}
		.index-pz-box{
			width: 48%;
		}
		.index-pc-title-box4{
			left: 0!important;
		}
		.indexhand{
			right: calc(50% - 50vw);
		}
		.pc-bethere{
			height: 650px;
		}
		.banner-bg-msg{
			left: 50%;
			margin-left: -200px;
			margin-left: calc(-200px - 1.5%);
		}
		.indexdian{
			right: 0;
		}
		.index .pc-customers .ivu-carousel-arrow.left{
			left: -40px!important;
		}
		.index .pc-customers .ivu-carousel-arrow.right{
			right: -40px!important;
		}
		.index .ind-banner .ivu-carousel-arrow.left{
			left: calc(1.5vw - 15px)!important;
		}
		.index .ind-banner .ivu-carousel-arrow.right{
			right: calc(1.5vw - 15px)!important;
		}
	}
	@media screen and (max-width: 1100px){
		.index .pc-customers .ivu-carousel-arrow.left{
			left: 0px!important;
		}
		.index .pc-customers .ivu-carousel-arrow.right{
			right: 0px!important;
		}
		.center-div{
			max-width: 920px;
		}
		.index .ind-banner .ivu-carousel-arrow.right{
			right: calc(1.5vw + 5px)!important;
		}
		.main-page{
			max-width: 920px!important;
		}
	}
	@media screen and (min-width: 1201px) and (max-width: 1439px){
		.banner-title{
			left:calc(50vw - 500px);
			left:100px;
		}
		.banner-msg{
			left:calc(50vw - 500px);
			left:100px;
		}
		.banner-msg1{
			top: calc(50vw - 480px);
		}

		.index-banner-qr{
			left: 100px!important;
			top: 300px!important;
		}
		.banner-btn{
			left:calc(50vw - 500px);
			left:100px;
		}
		.pc-step-box{
			width:90vw;
			width: calc(50vw + 500px);
			max-width: 100vw;
			justify-content: center;
		}
		.img-yd{
			right: -130px!important;
		}
	}
	@media screen and (min-width: 1201px) and (max-width: 1600px){
		.center-div{
			max-width: 1200px;
		}
		.banner-bg-msg{
		left: 50%;
		margin-left: -200px;
		margin-left: calc(-200px - 1.5%);
		}
		.banner-title{
			font-size: 56px;
			line-height: 1.2;
			top: 5vw;
		}
		.banner-msg{
			top: calc(5.75vw + 140px);
		}
		.banner-msg1{
			top: calc(5.75vw + 165px);
		}
		.index-banner-qr{
			left: 0;
			top: 350px;
		}
		.banner-btn{
			top: calc(15vw + 140px);
		}
		.pc-step:nth-child(1){
			margin-left: calc(50vw - 600px)!important;
		}
		.img-yd{
			width: 24%;
			/* right: -12.5%; */
			width: 280px!important;
			/* right: -8%!important; */
			right: calc(50vw - 50% - 210px);
			right: -40px;
			top: -250px!important;
		}
		.pc-chengfen{
			width: 66vw;
		}
		.pc-bf-box{
			width: 100%;
			padding-left: calc(50% - 230px);
		}
		.index-jianlist{
			right: -300px;
			left: auto;
		}
		.indexjian{
			right: -90px;
		}
		.index-pz-swiper{
			margin-left: 0;
		}
		.index .index-pc-pz .ivu-carousel-arrow.right{
			right: -70px !important;
		}
		.index .index-pc-pz .ivu-carousel-arrow.left{
			left: -70px !important;
		}
		.index-pz-box{
			width: 60%;
		}
		.indexhand{
			right: calc(50% - 50vw);
			width: calc(50vw - 20%);
		}
		.pc-center-main{
			margin-left: calc(50% - 600px);
		}
		.index-pc-title-box4{
			left: 0!important;
		}
		.indexdian{
			right: 0;
		}
	}
	@media screen and (min-width: 1601px){

		.index-banner-qr{
			left: 0;
			top: 380px;
		}
		.center-div{
			max-width: 1200px;
		}
		#app .index{
			max-width: 1200px;
		}
		.indexjian{
			right: calc(1200px - 960px - 90px);
		}
		.index-jianlist{
			left: auto;
			right: 100px;
			right: -100px;
		}
		.pc-chengfen{
			min-width: 70vw;
			width: 100%;
		}
		.pc-step-box{
			width:calc(50vw + 500px);
			min-width: 1220px;
		}
		.img-yd{
			width: 23%;
			right: -3vw;
			right: -11%;
			top: -40%;
			width: 300px;
			height: auto;
			right: -60px;
			top: -270px;
		}
		.pc-step:nth-child(1){
			margin-left: calc(50vw - 600px)!important;
		}
		.indexhand{
			width: calc(50vw - 25%);
			right: calc(50% - 50vw + 0px);
		}
		.index-pc-title-box4{
			left: 0!important;
		}
		.pc-bethere .imgs{
			right: 0;
			left: 10%;
		}
		.pc-bf-box{
			width: calc(100% - 1200px + 960px);
			padding-left: calc(50vw - 600px);
		}
		.index-pz-box{
			width: calc(45vw - 600px + 700px);
			margin-right: calc(600px - 50vw);
			justify-content: left;
			padding-left: 230px!important;
		}
		.index-pz-swiper{
			margin-left: 0;
		}
		.index-banner,.ind-banner{
			height: 42vw;
			max-height: 700px;
		}
		.banner-bg{
			top: 0;
			top: -70px;
		}

		.index .ivu-carousel-arrow {
			width: 60px;
			height: 60px;
			font-size: 20px;
			align-items: center;
		}

		.index .ivu-carousel-arrow .ivu-icon {
			margin-top: 0 !important;
		}
	}
	@media screen and (min-width:1000px) {
		.index .ivu-carousel-arrow .ivu-icon{
			margin-top: 0!important;
		}
	}
	@media screen and (min-width: 1921px){
		.banner-bg {
			width: 50vw;
			height: 50vw;
			max-width: 50vw;
			max-width: 800px;
			max-height: 50vw;
			top: 0;
			top: -70px;
		}
		.index-banner-qr{
			left: 0;
			top: 420px;
		}
		.banner-bg-msg {
			width: 17.5vw;
		left: 50%;
		margin-left: -9vw;
			margin-left: calc(-9vw - 2.35%);
			font-size: 1vw;
			font-size: 18px;
		}
		.indexhand{
			width: calc(50vw - 35%);
			right: calc(50% - 50vw + 0px);
		}
	}
	@media screen and (min-width: 2160px){
		.banner-bg-msg {
			left: 50%;
			margin-left: -9vw;
			margin-left: calc(-9vw - 2.35%);
		}
		.index-banner,.ind-banner{
			height: 42vw;
			max-height: 860px;
		}
		.banner-bg {
			width: 50vw;
			height: 50vw;
			max-width: 50vw;
			max-width: 950px;
			max-height: 50vw;
			top: 0;
			right: -150px!important;
			top: -150px;
		}
	}
	.sdzbtnbox{
		width: 100vw;
		position: fixed;
		z-index: 9999;
		bottom: 10px;
		padding: 0;
		margin: 0;
	}
	.sdzbtn2{
		margin: 0 auto;
	}
</style>
