<template>
	<div class="login get-report main-page">
		<div class="page-bg"></div>
		<div class="login-box">
			<div class="get-report-title">登录获取报告</div>
			<div class="get-report-msg">登录后可保存并查看专属营养评估报告</div>

			<div class="loginmsg">一旦登录，即表明你同意NUTRIPACKS的 <span @click="yinsi">隐私政策</span>和 <span
					@click="shiyong">使用条款</span>。</div>
			<div class="get-report-border"></div>
			<div class="get-report-num" :class="showtu?'hasphonecss':''" @click="focusit">
				<img v-if="!showtu" src="../../public/imgs/phone1.png" alt="">
				<img v-if="showtu" src="../../public/imgs/phone2.png" alt="">
				<input ref="inputs" type="text" @blur="blurit"  class="phoneinput" maxlength="11" v-model="phone" placeholder="请输入手机号">
				<img v-if="showtu" @click="delphone" class="delimg cursor" src="../../public/imgs/delphone.png" alt="">
			</div>
			<div class="get-report-code" :class="showtus?'hasphonecss':''">
				<img src="../../public/imgs/code.png" alt="">
				<input type="text" class="codeinput" @blur="blurits" @focus='focusits' maxlength="6" v-model="code" placeholder="请输入验证码">
				<div class="time sendcode" v-if="send==1">{{time}}s重新获取</div>
				<div class="sendcode cursor" v-if="send==0" @click="getcode">获取验证码</div>
			</div>
			<div class="go-report-btn cursor hasnum" v-if="code&&phone" @click="ok" @keyup.enter="ok">查看我的报告</div>
			<div class="go-report-btn cursor" v-if="!code||!phone">查看我的报告</div>
		</div>
		<div class="showtips" v-if="showtips">
			<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
			<div class="tmsg">
				<div class="t1">验证码已发送至：{{phone}}</div>
				<div class="t2">验证码已成功发送至你手机，请注意查收。</div>
			</div>
		</div>
		<div class="lodding-box" v-if="showlodding">
			<!-- <div class="lodding-box"> -->
			<div class="loadingmain" id="loading">
				<div class="loadEffect">
					<div class="loadimg">
						<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/rec_generating.gif" alt="">
						<div class="progressnum">{{progressnum}}%</div>
					</div>
					<div class="txts">
						<transition name="slide-fade">
							<p v-if="show1">同态加密处理</p>
						</transition>
						<transition name="slide-fade">
							<p v-if="show2">深度算法处理</p>
						</transition>
						<transition name="slide-fade">
							<p v-if="show3">正在生成报告</p>
						</transition>
					</div>
					<div class="reportv">算法版本号：V301.237</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {md5} from '../util/md5.js'
	export default{
		name:'getreport',
		components:{
		},
		data:()=>({
			send:0,
			time:60,
			phone:'',
			goid:'',
			hascode:false,
			showtu:false,
			showtips:false,
			showtus:false,
			
			show1: false,
			show2: false,
			show3: false,
			showlodding:false,
			progressnum: 0,
			
			li:false,
			rid:'',
			code:'',
			info:'',
			phonetest:'^1(3([0-35-9]\\d|4[1-8])|4[14-9]\\d|5([0-35689]\\d|7[1-79])|66\\d|7[2-35-8]\\d|8\\d{2}|9[13589]\\d)\\d{7}$',
			way:''
		}),
		created() {
			let that = this;
			document.onkeypress = function(e) {
				var keycode = document.all ? event.keyCode : e.which;
				if (keycode == 13) {
					that.ok(); // 登录方法名
					return false;
				}
			};
		},
		mounted() {
			var info = localStorage.getItem('info')?JSON.parse(localStorage.getItem('info')):'';
			if(info){
				this.$router.replace({
					path: 'home',
				})
			}else{
				var zhi =localStorage.getItem('zhi')?localStorage.getItem('zhi'):'';
				var ztimestamp = localStorage.getItem('ztimestamp')?localStorage.getItem('ztimestamp'):'';
				// location.reload()
				// this.$router.go(0)
				// this.gettime()
				if(zhi){
					var tzhi = zhi+ztimestamp
					// //console.log(md5(tzhi).toUpperCase())
					this.biaoshi = md5(tzhi).toUpperCase()
				}else{
					//console.log('请刷新页面')
				}
				this.rid = this.$route.query.id
			}
		},
		methods:{
			blurit(){
				var that = this
					setTimeout(function(){
						if(that.li){
							that.showtu=true
							that.$nextTick(()=>{
								that.$refs.inputs.focus();
							})
						}else{
							that.showtu=false
						}
					},200)
				
			},
			focusit(){
				var that = this
						that.li = true
						setTimeout(function(){
							that.li = false
						},500)
				if(that.showtu==true){
					//console.log('')
				}else{
						that.showtu=true
						this.$nextTick(()=>{
							this.$refs.inputs.focus();
						})
				}
			},
			focusits(){
				this.showtus=true
			},
			blurits(){
				this.showtus=false
			},
			delphone(){
				this.li = true
				var that = this
					setTimeout(function(){
						that.li = false
					},500)
				this.phone = ''
				// this.$nextTick(()=>{
				// 	this.$refs.inputs.focus();
				// })
			},
			getcode(){
				this.showtu=false
				var testphone = new RegExp(this.phonetest)
				if(this.hascode==false){
					var phone = this.phone
					var that = this
					if(!phone||!testphone.test(phone)){
						that.$Message.info('请输入正确的手机号')
					}else{
						this.gettime()
						this.hascode = true
						//发送短信接口
						this.$ajax.post(this.API.api.apiurl.nsendSms,{
							phone:phone,
						})
						.then(res=> {
							////console.log(res)
							var data = res.data
							if(data.code==0){
								that.showtips = true
								setTimeout(function(){
									that.showtips=false
								},2000)
								//console.log('验证码发送成功')
								that.send=1
							}else{
								that.$Message.info('错误：'+data.message)
							}
						})
						.catch(error=>{
							console.log(error)
						})
					}
				}else{
					//console.log('已经发送验证码了')
				}
			},
			getl(info){
				var that =this
				that.$ajax.post(that.API.api.apiurl.getCartList, {
					member_id: info.member_id,
				},{
					headers:{
						'Authorization':'Bearer '+that.info.token,
					}
				})
				.then(res => {
					//console.log(res, 'res')
					var data = res.data.data
						var clist = []
					if (res.data.code == 0) {
						var cartlist = data
						for(var i=0;i<cartlist.length;i++){
							if(cartlist[i].source==1||cartlist[i].source == 2){
								clist.push(cartlist[i])
							}
						}
						var ll = 0
								clist.forEach((item)=>{
									ll +=item.num
								})
								//console.log(ll)
								localStorage.setItem('clength', ll)
						
						//缓存
					}else {
						//console.log('错误：' + res.data.message)
					}
				})
				.catch(error => {
					// clearInterval(changel)
					console.log(error)
				})
			},
			ok(){
				var testphone = new RegExp(this.phonetest)
				var phone = this.phone
				var code = this.code
				var that = this
				if(!phone||!code||!testphone.test(phone)){
					that.$Message.info('请输入正确的手机号和验证码')
				}else{
					this.$ajax.post(this.API.api.apiurl.getIndex, {})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							localStorage.setItem('indexdata', JSON.stringify(data.data))
						} else {
							//console.log('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
					//登录注册
					this.$ajax.post(this.API.api.apiurl.nphoneRegister,{
						phone:phone,
						check_code:code,
						sid:that.biaoshi
					})
					.then(res=> {
						////console.log(res)
						var data = res.data
						if(data.code==0){
							if(data.data.memberInfo){
								//console.log('成功')
								var timestamp = (new Date()).getTime();
								var info = data.data.memberInfo
								if(info.status==-1){
									that.$Message.info({content: '该账户异常，无法继续访问！',duration: 10,closable: true});
								}else{
									info.timestamp = timestamp
									info.member_id = info.mid
									info.token = data.data.token
									that.info=info
									that.getl(info)
									localStorage.setItem('info',JSON.stringify(info))
									var ua = navigator.userAgent.toLowerCase();
									var isWeixin = ua.indexOf('micromessenger') != -1;
									if (isWeixin) {
										// this.$Message.info('微信打开');
										localStorage.setItem('way', 'weixin')
									} else {
										if (that._isMobile()) {
											// this.$Message.info("手机端");
											localStorage.setItem('way', 'h5')
										} else {
											// this.$Message.info("pc端");
											localStorage.setItem('way', 'pc')
										}
									}
										// that.goreport()
										that.getmyreport()
								}
								
							}else{
								that.$Message.info(data.message)
							}
							// that.gettime()
						}else{
							that.$Message.info('错误：'+data.message)
						}
						
					})
					.catch(error=>{
						console.log(error)
					})
				}
			},
			goreport(){
				localStorage.setItem('rel',true)
				this.$router.replace({
					path: 'report',
					query: {
						id: 'get_my_report',
						way:'get_my_report'
					}
				})
			},
			getmyreport() {
				var that = this
				that.showlodding = true
				var progressnum = that.progressnum
				var timer = setInterval(function() {
					if (progressnum < 100) {
						progressnum++
						that.progressnum = progressnum
					}
				}, 50)
				var txtnum = 0
				var timer1 = setInterval(function() {
					// setTimeout(function(){
					that.show1 = false
					that.show2 = false
					that.show3 = false
					// },200)
					if (txtnum == 0 || txtnum == 3) {
						setTimeout(function() {
							that.show1 = true
							that.show2 = false
							that.show3 = false
						}, 300)
					} else if (txtnum == 1 || txtnum == 4) {
						setTimeout(function() {
							that.show1 = false
							that.show2 = true
							that.show3 = false
						}, 300)
					} else if (txtnum == 2 || txtnum == 5) {
						setTimeout(function() {
							that.show1 = false
							that.show2 = false
							that.show3 = true
						}, 300)
					}
					txtnum++
				}, 1000)
				var info = JSON.parse(localStorage.getItem('info')) 
				setTimeout(function() {
					that.$ajax.post(that.API.api.apiurl.getCountListNew, {
							member_id: info.member_id,
							type: 0,
							page: 1,
						},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								var data1 = data.data.list
								var list = data1.data
								
								var id = list[0].id
								that.goid = id
								// that.$router.replace({
								// 	path: 'report',
								// 	query: {
								// 		id: id,
								// 		way:'list'
								// 	}
								// })
								localStorage.setItem('rel',true)
								localStorage.setItem('hasshow', id)
								that.$router.replace({
									path: 'report',
									query: {
										id: id,
										way:'get_my_report'
									}
								})
								// setTimeout(function() {
								// location.reload();
								// },100)
							} else {
								//console.log('错误：' + data.msg)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}, 4900)
				setTimeout(function() {
					// //console.log(timer,timer1)
					clearInterval(timer);
					clearInterval(timer1);
					txtnum = 0
					that.show1 = true
					// that.showlodding = false
					that.hasshow = true
					// localStorage.setItem('hasshow', that.goid)
				
					
					
				}, 5000)
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			gettime(){
				this.send = 1
				var that = this
				var time = this.time
				var thetime = setInterval(function(){
					if(time>0){
						time--
						that.time = time
					}else{
						that.send = 0
						that.hascode = false
						that.time = 60
						clearInterval(thetime);
					}
				}, 1000)
			},
			yinsi(){
				this.$router.push({
					path:'/terms-of-use'
				})
				// window.location.href = 'https://h5.nutri-packs.cn/privacy'
			},
			shiyong(){
				this.$router.push({
					path:'/privacy-policy'
				})
			}
		}
	}
</script>

<style>
	.get-report{
		padding-top: 2.5rem;
	}
	.get-report input[type='number']::-webkit-outer-spin-button,
	.get-report input[type='number']::-webkit-inner-spin-button{
		-webkit-appearance: none !important;
		margin: 0;
	}
	.get-report .login-box{
		height: 14.28rem;
	}
	
		.get-report-title{
			width: auto;
			line-height: 0.74rem;
			font-size: 0.52rem;
			color: #1A1A1A;
			font-weight: bold;
		}
		.get-report-msg{
			width: auto;
			line-height: 0.41rem;
			font-size: 0.3rem;
			margin-top: 0.15rem;
			font-weight: 400;
		}
		.get-report-border{
			width: 0.56rem;
			height: 0.11rem;
			margin: 0 auto;
			background: #C25E4D;
			border-radius: 0.06rem;
			margin-top: 0.59rem;
		}
		.get-report-num{
			width: 5.28rem;
			line-height: 0.96rem;
			border-radius: 0.93rem;
			border: 0.04rem solid #D5DDE6;
			margin: 0 auto;
			margin-top: 2.31rem;
			display: flex;
		}
		.get-report-code{
			width: 5.28rem;
			line-height: 0.96rem;
			border-radius: 0.93rem;
			border: 0.04rem solid #D5DDE6;
			margin: 0 auto;
			margin-top: 0.56rem;
			display: flex;
		}
		.hasphonecss{
			border: 0.04rem solid #C25E4D;
			box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(216, 118, 101, 0.26);
		}
		.get-report input{
			border: none;
			width: 2.4rem;
			line-height: 0.96rem;
			font-size: 0.26rem;
			background: none;
		}
		.get-report-num input{
			width: calc( 100% - 1.8rem );
		}
		.get-report img{
			height: 0.3rem;
			width: 0.3rem;
			margin: 0.33rem 0.3rem;
		}
		.get-report-num .delimg{
			width: 0.44rem;
			height: 0.44rem;
			margin: 0.26rem 0.3rem  0.26rem 0.16rem;
		}
		.sendcode{
			width: calc( 100% - 3.6rem);
			height: 0.37rem;
			margin: 0.295rem 0;
			line-height: 0.37rem;
			text-align: right;
			font-size: 0.26rem;
			color: #C25E4D;
			/* text-indent: 0.28rem; */
			border-left: 0.04rem solid #D5DDE6;
		}
		.get-report .time{
			width: calc( 100% - 3.6rem );
			height: 0.37rem;
			margin: 0.295rem 0;
			line-height: 0.37rem;
			font-size: 0.26rem;
			border-radius: 0;
			background: none;
			/* text-indent: 0.28rem; */
			border-left: 0.04rem solid #D5DDE6;
			color: #999999;
		}
		.go-report-btn{
			width: 4.22rem;
			line-height: 1.11rem;
			background: #D5DDE6;
			border-radius: 0.56rem;
			font-size: 0.3rem;
			color: #FFFFFF;
			margin: 0 auto;
			margin-top: 4.06rem;
		}
		.hasnum{
			background: #C25E4D;
			box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.25);
		}
		.showtips{
			width: 7.85rem;
			height: 2.65rem;
			background: #FFFFFF;
			box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
			border-radius: 0.19rem;
			position: fixed;
			top: 50%;
			left: 50%;
			margin-top: -1.325rem;
			margin-left: -3.925rem;
			padding: 0.59rem;
			display: flex;
		}
		.showtips i{
			transform:rotate(180deg);
			width: 24px;
			height: 24px;
		}
		.showtips .tmsg{
			width: 5.93rem;
			height: auto;
			text-align: left;
			margin-left: 0.3rem;
		}
		.tmsg .t1{
			line-height: 0.52rem;
			font-size: 0.37rem;
			color: #1A1A1A;
			font-weight: bold;
		}
		.tmsg .t2{
			margin-top: 0.54rem;
			line-height: 0.41rem;
			font-size: 0.3rem;
			color: #666666;
		}
	
	
	@media screen and (max-width:600px){
		.get-report-num,.get-report-code{
			width: 6.28rem;
			height: 1.01rem;
			line-height: 0.94rem;
			padding: 0.01rem;
		}
		.get-report input{
			height: 0.94rem;
			/* border: 0.01rem solid #eee; */
			line-height: 0.96rem;
		}
		.get-report-code img{
			margin: 0.3rem;
		}
		.sendcode{
			line-height: 0.39rem;
		}
		
		.get-report-num .delimg{
			width: 0.44rem;
			height: 0.44rem;
			margin: 0.24rem 0.3rem  0.26rem 0.16rem;
		}
	}
</style>
