// const ip = 'https://admin.xiaojudai.cn/'
// const nip = 'https://api.xiaojudai.cn/api'
// const iway = 'admin'
// const ip = 'https://test.xiaojudai.cn/';
// const nip = 'https://testapi.xiaojudai.cn/api'
// const iway = 'test'
// const ip = 'https://dev.xiaojudai.cn/';
// const nip = 'https://devapi.xiaojudai.cn/api'
// const iway = 'dev'

const ip = process.env.VUE_APP_IP_BASE_API
const nip = process.env.VUE_APP_NIP_BASE_API
const iway = process.env.VUE_APP_ENV
const bip =process.env.VUE_APP_BIP_BASE_API
const cip = process.env.VUE_APP_CIP_BASE_API
console.log(ip,nip,iway,bip,cip,'----------ip')
const zurl = 'https://syb.allinpay.com/apiweb/h5unionpay/unionorder'
const curl = 'https://test.allinpaygd.com/apiweb/h5unionpay/unionorder'
const api = {
  apiurl: {
    way: iway,
    login: ip + '',
    zhifuurl: iway == 'admin' ? zurl : curl,
    // getIndex:ip+'index/index/getIndex',//获取首页内容
    getquestion: ip + 'index/index/getQuestion',
    sendsms: ip + 'index/sms/sendSms', //发送短信接口
    phoneRegister: ip + 'index/H5api/phoneRegister', //手机号注册登录（用户注册）
    savequestion: ip + 'index/index/saveQuestion',
    isQuestionRecord: ip + 'index/index/isQuestionRecord', //问卷-是否有问卷记录
    continueQuestion: ip + 'index/index/continueQuestion', //问卷-继续问卷请求接口
    againQuestion: ip + 'index/index/againQuestion', //问卷-重新问卷
    // getCountInfoNew:ip+'index/index/getCountInfoNew',//获取报告详情3.0版本
    // getBuyPlanV2:ip+'index/Allcart/getBuyPlanV2',//获取购买方案
    // getCountGoods:ip+'index/index/getCountGoods',//获取报告推荐产品
    // addCart:ip+'index/Allcart/addCart',//添加产品，套餐，报告到新购物车
    // checkCartV2:ip+'index/Allcart/checkCartV2',//校验商品信息V2
    // getAddressList:ip+'index/Address/getAddressList',//用户地址列表
    // getAddress:ip+'index/Address/getAddress',//获取单条地址信息
    // saveAddress:ip+'index/Address/saveAddress',//保存地址信息
    // delAddress:ip+'index/Address/delAddress',//删除地址
    // setDefaultAddress:ip+'index/Address/setDefaultAddress',//设置默认地址
    // settleAccounts:ip+'index/Allcart/settleAccounts',//购物车到结算页面
    // replaceCartGoodsNew:ip+'index/Allcart/replaceCartGoodsNew',//批量替换橘袋[报告加入购物车时]
    // getGoodsList:ip+'index/goods/getGoodsList',//库存新版产品列表
    // getGoodsInfo:ip+'index/goods/getGoodsInfoNew',//新版报告3.0产品详情
    // checkOrder:ip+'index/Order/checkOrder',//PC端-查询订单状态
    // delCartGoodsAll:ip+'index/Allcart/delCartGoodsAll',//批量删除橘袋产品
    // saveTheArrivalNotice:ip+'index/goods/saveTheArrivalNotice',//保存到货通知消息推送任务
    // getCartList:ip+'index/Allcart/getCartList',//获取购物车列表
    // delCart:ip+'index/Allcart/delCart',//删除或清空购物车中橘袋
    // updateBuyPlan:ip+'index/Allcart/updateBuyPlan',//修改购买方案
    // getCountListNew:ip+'index/index/getCountListNew',//问卷-获取报告列表
    // delCount:ip+'index/index/delCount',//问卷-用户删除报告
    // getReportInfo:ip+'index/index/getReportInfo',//报告研究
    // getReportList:ip+'index/index/getReportList',//报告研究列表
    // getOrderList:ip+'index/Order/getOrderList',//订单列表
    // OrderDetail:ip+'index/Order/OrderDetailV2',//订单详情
    // receiveOrder:ip+'index/Order/receiveOrder',//完成订单（确认收货）
    // cancelOrder:ip+'index/Order/cancelOrder',//取消未支付订单
    // getLogistics:ip+'index/order/getLogistics',//物流轨迹查询
    // delOrder:ip+'index/Order/delOrder',//删除订单
    // payOrder:ip+'index/H5api/payOrder',//支付未付款的订单
    // createOrder:ip+'index/order/unifiedOrder',//新*创建订单
    // pay:ip+'index/order/unifiedPay',//新*支付未付款的订单
    // getNutritionistInfo:ip+'index/index/getNutritionistInfo',//获取营养师详情
    // getAppointment:ip+'index/service/getAppointment',//获取预约详情
    // cancelAppointment:ip+'index/service/cancelAppointment',//清除下单前临时预约状态
    // checkTimeSegment:ip+'index/service/checkTimeSegment',//检测时段是否被预约过
    // getAppointmentTime:ip+'index/service/getAppointmentTime',//获取营养师预约时段
    // changeUserInfo:ip+'index/H5api/changeUserInfo',//更新用户基本信息（昵称）

    //new
    getBannerList: nip + '/index/getBannerList', //获取轮播图列表
    nisQuestionRecord: nip + '/report/isQuestionRecord', //查询是否有问卷记录
    nphoneRegister: nip + '/auth/phoneRegister', //pc登陆注册
    nsendSms: nip + '/auth/sendSms', //获取登录验证码
    ncontinueQuestion: nip + '/report/continueQuestion', //问卷-继续问卷请求接口c
    nagainQuestion: nip + '/report/againQuestion', //问卷-重新问卷c
    ngetquestion: nip + '/report/getQuestion', //获取题目接口c
    nsavequestion: nip + '/report/saveQuestion', //题目提交保存接口c
    bgetQuestion:bip+'/report/get-question',//获取题目接口b
    bsavequestion:bip + '/report/save-question',//提交问卷

    getIndex: nip + '/index/getIndexInfo', //获取首页内容
    getCountInfoNew: nip + '/report/getCountInfo', //获取报告详情3.0版本
    getBuyPlanV2: nip + '/cart/getBuyPlan', //获取购买方案
    getCountGoods: nip + '/report/getCountGoods', //获取报告推荐产品
    addCart: nip + '/cart/addCart', //添加产品，套餐，报告到新购物车
    checkCartV2: nip + '/cart/checkCart', //校验商品信息V2
    getAddressList: nip + '/userAddress/getList', //用户地址列表
    getAddress: nip + '/userAddress/getAddressById', //获取单条地址信息
    saveAddress: nip + '/userAddress/save', //保存地址信息
    delAddress: nip + '/userAddress/del', //删除地址
    setDefaultAddress: nip + '/userAddress/setDefault', //设置默认地址
    settleAccounts: nip + '/cart/settleAccounts', //购物车到结算页面
    replaceCartGoodsNew: nip + '/cart/replaceCartGoods', //批量替换橘袋[报告加入购物车时]
    getGoodsList: nip + '/goods/getGoodsList', //库存新版产品列表
    getGoodsInfo: nip + '/goods/getGoodsInfo', //新版报告3.0产品详情
    checkOrder: nip + '/order/checkOrder', //PC端-查询订单状态
    delCartGoodsAll: nip + '/cart/delCartGoodsBatch', //批量删除橘袋产品
    saveTheArrivalNotice: nip + '/goods/saveTheArrivalNotice', //保存到货通知消息推送任务
    getCartList: nip + '/cart/getCartList', //获取购物车列表
    delCart: nip + '/cart/delCart', //删除或清空购物车中橘袋
    updateBuyPlan: nip + '/cart/updateBuyPlan', //修改购买方案
    getCountListNew: nip + '/report/getCountList', //问卷-获取报告列表
    delCount: nip + '/report/delCount', //问卷-用户删除报告
    getReportInfo: nip + '/report/getReportInfo', //报告研究
    getReportList: nip + '/report/getReportList', //报告研究列表
    getOrderList: nip + '/order/getOrderList', //订单列表
    OrderDetail: nip + '/order/orderDetail', //订单详情
    receiveOrder: nip + '/order/receiveOrder', //完成订单（确认收货）
    cancelOrder: nip + '/order/cancelOrder', //取消未支付订单
    getLogistics: nip + '/order/getLogistics', //物流轨迹查询
    delOrder: nip + '/order/delOrder', //删除订单
    createOrder: nip + '/order/createOrder', //新*创建订单
    pay: nip + '/order/pay', //新*支付未付款的订单
    getNutritionistInfo: nip + '/dietitian/getNutritionistInfo', //获取营养师详情
    getAppointment: nip + '/dietitian/getAppointment', //获取预约详情
    cancelAppointment: nip + '/dietitian/cancelAppointment', //清除下单前临时预约状态
    checkTimeSegment: nip + '/dietitian/checkTimeSegment', //检测时段是否被预约过
    getAppointmentTime: nip + '/dietitian/getAppointmentTime', //获取营养师预约时段
    changeUserInfo: nip + '/user/changeUserInfo', //更新用户基本信息（昵称）
    getMealImgText: nip + '/goods/getMealImgText', //查看套餐文图信息

    getJuDaiMealList: nip + '/goods/getJuDaiMealList', //获取应急包套餐列表
    getMealDetail: nip + '/goods/getMealDetail', //获取套餐详情
    editCartNum: nip + '/cart/editCartNum', //修改购物车套餐数量
    getNoticeList: nip + '/index/getNoticeList', //获取公告列表 （同第七周 获取支付后公告）
    // food
    getFoodDetail: nip + '/food/detail',
    getFoodCategory: nip + '/food/category',
    getFoodRecommendKeywrods: nip + '/food/recommendSearchKeywords',
    getFoodList: nip + '/food/list',
    getCartInfo:nip  + '/index/getCartInfo',
    getSignInfo:nip + '/index/getSignInfo',
    checkSigIn:nip + '/index/signIn',
    retroactiveAPI:nip + '/index/retroactive',
    getUserCartList:nip + '/index/getCartList',
    onBind:nip + '/index/bind',
    changeBind:nip + '/index/unbind',
    checkCode : nip + '/index/checkCode',
    receiveAward:nip + '/index/receiveAward',
    getConfig:cip + '/web/get-config',
    // app
    appActive: nip + '/app/active',
  },
}
export default {
  api: api,
}
