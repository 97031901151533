<template>
  <div class="category">
      <!-- 搜索框 -->
      <van-search v-model="value" shape="round" @focus="onFoucs" placeholder="请输入食物名称" />
      <!-- 分类 -->
      <div class="category-content">
        <!-- 侧边栏 -->
        <div class="sidebar-wrapper">
        <van-sidebar v-model="activeKey" @change="onChange">
          <van-sidebar-item  v-for="item in categoryList" :key="item.id">
            <template #title>
              <div class="sidebar-item">
                <img :src="item.image_url" style="width:1.33rem;height:1.33rem">
                <span>{{item.name}}</span>
              </div>
            </template>
          </van-sidebar-item>
        </van-sidebar>
        </div>
        <!-- 内容 -->
        <div class="food-list">
        <van-list
          v-model="categoryList[activeKey].loading"
          :finished="categoryList[activeKey].isFinished"
          loading-text="加载中..."
          @load="loadMore"
        >
          <ul v-if="categoryList.length">
            <li v-for="item in categoryList[activeKey].childrenList" :key="item.id" @click="goToDetail(item.id)">
            <van-image
              fit="cover"
              :error-icon="require('../../../public/imgs/nutritionQuery/food_default_cover.png')"
              :src="item.image_url"
            />

              <div class="food-info">
                <div class="food-name">{{item.name}}</div>
                <div class="food-calorie"><span>{{ item.enerc_cal }}</span>卡/100克</div>
              </div>
            </li>
          </ul>
        </van-list>
        </div>
      </div>

  </div>
</template>

<script>
  import Vue from 'vue'
  export default {
    data() {
      return {
        value: '',
        categoryList: [],
        activeKey: 0,
      }
    },
    mounted(){
        document.title = "食品营养素查询"
        this.getData();
    },
    methods: {
        log(){
        },
        getData(){
            var that = this;
            this.$ajax.post(this.API.api.apiurl.getFoodCategory, {
            },{
            }).then(res => {
                that.categoryList = res.data.data;
                for(var i = 0; i < that.categoryList.length; i++){
                    var item = that.categoryList[i];
                    if(item.id == that.$route.query.category_id){
                        that.activeKey = i;
                    }
                    item.page = 0;
                    item.childrenList = [];
                    that.categoryList[i] = item;
                }
            });
        },
        onFoucs() {
            this.$router.push({
                path: 'food-search'
            })
        },
        onChange() {
            var that = this;
            var activeKey = that.activeKey;
            this.$router.push({
                path: '/food-category?category_id='+that.categoryList[activeKey].id
            })
//            if(that.categoryList[activeKey].childrenList.length == 0){
//                //this.$ajax.post("http://api.np.local:7777/api/food/list", {
//                    this.$ajax.post(this.API.api.apiurl.getFoodList, {
//                    category_id: that.categoryList[activeKey].id
//                },{}).then(res => {
//                    var data = res.data;
//                    if(data.code != 0){
//                        return;
//                    }
//                    if(data.data.data.length == 0){
//                        return;
//                    }
//                    that.categoryList[activeKey].childrenList = that.categoryList[activeKey].childrenList.concat(data.data.data);
//                    that.categoryList[activeKey].totalPage = data.data.total_page;
//                    that.categoryList[activeKey].page = 2;
//                    that.categoryList[activeKey].isFinished = false;
//                    Vue.set(that.categoryList, activeKey, that.categoryList[activeKey]);
//                });
//            }
        },
        loadMore(){
            var that = this;
            var activeKey = that.activeKey;
            that.categoryList[activeKey].isFinished = that.categoryList[activeKey].totalPage <= ++that.categoryList[activeKey].page
            that.categoryList[activeKey].loading = true;
            Vue.set(that.categoryList, activeKey, that.categoryList[activeKey]);
            //this.$ajax.post("http://api.np.local:7777/api/food/list", {
            this.$ajax.post(this.API.api.apiurl.getFoodList, {
                category_id: that.categoryList[activeKey].id,
                page:that.categoryList[activeKey].page,
            },{}).then(res => {
                that.categoryList[activeKey].loading = false;
                Vue.set(that.categoryList, activeKey, that.categoryList[activeKey]);
                var data = res.data;
                if(data.code != 0){
                    return;
                }
                if(data.data.data.length == 0){
                    return;
                }
                that.categoryList[activeKey].childrenList = that.categoryList[activeKey].childrenList.concat(data.data.data);
                that.categoryList[activeKey].totalPage = data.data.total_page;
                that.categoryList[activeKey].isFinished = that.categoryList[activeKey].totalPage <= that.categoryList[activeKey].page
                Vue.set(that.categoryList, activeKey, that.categoryList[activeKey]);
            });
        },
        goToDetail(id){
                this.$router.push({
					path: '/food-nutrition?id='+id
				})
        },
    }
  }
</script>

<style lang="scss" >
    #app{
        overflow-x: visible;
    }
   .sidebar-wrapper{
       height:100%;
       overflow-y:scroll;
       overscroll-behavior:contain;
   }
  .category {
        overflow-y: unset;
          height: calc(100vh - 72px);
      .category-content {
          touch-action: pan-y;
          overflow-y: scroll;
          overscroll-behavior:contain;
          height: calc(100vh - 114px);
          display: flex;
          margin-bottom: 2rem;
      }

    .van-search {
      padding: 0.27rem 0.53rem;
    }

    .van-search__content {
      height: 0.91rem;
    }

    .van-search__action {
      font-size: 0.29rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #141414;
      padding: 0;
      margin-left: 0.27rem;
    }
    .van-sidebar-wrapper {
        height: 100%;
        overflow-y: scroll;
    }

    .van-sidebar {
        background:#fff;
      width: 2.13rem;
      border-radius: 0rem 0.13rem 0rem 0rem;
    }

    .van-sidebar-item {
      padding: 0;
    }

    .sidebar-item {
      display: flex;
      flex-direction: column;
      justify-content:center;
      padding: 0.29rem 0.4rem 0.11rem;
      box-sizing: border-box;
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
    }

    .van-sidebar-item--select::before {
      display: none;
    }

    .food-list {
overflow-y: scroll;
      font-size: 0.32rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-left: 0.27rem;

      li {
        display: flex;
        justify-content: center;
        margin-bottom: 0.63rem;

        .van-image {
          width: 1.11rem;
          height: 1.09rem;
        }

        .food-info {
          position: relative;
          margin-left: 0.41rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 6.05rem;

          .food-name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 80%;
              text-align: left;
          }

          .food-calorie {

            span {
              color: #C25E4D;
            }

          }

        }

        .food-info::after{
          content: '';
          position: absolute;
          bottom: -0.32rem;
          height: 0.01rem;
          background: #E6E6E6;
          border-radius: 0.01rem;
          width: 100%;
        }
      }
    }
  }
</style>
<style lang="scss">
  .category {
    .van-icon-search::before {
      color: #979797;
    }
  }
</style>
